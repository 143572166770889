<mat-toolbar id="TheRealToolbar" class="tw-h-12 tw-flex tw-justify-between">
    <h1 class="tw-text-m3OnSurface">{{ getTitle() }}</h1>

    <div class="tw-flex">
        <img
            id="ps-logo"
            src="assets/images/logo.png"
            class="tw-self-center tw-h-7 tw-mr-2"
        />

        <div
            class="tw-flex tw-place-items-center tw-size-7 tw-hover:cursor-pointer"
            id="avatar-preview"
            [matMenuTriggerFor]="avatarMenu"
        >
            @if (!currentProfileImage) {
                <mat-icon
                    class="tw-size-6 tw-border-solid tw-border-2 tw-rounded-full tw-border-m3OnSurfaceVariant"
                >
                    account_circle
                </mat-icon>
            } @else {
                <img
                    class="tw-size-7 tw-border-solid tw-rounded-full tw-border-2 tw-border-m3OnSurfaceVariant"
                    [src]="currentProfileImage"
                />
            }
        </div>
    </div>
</mat-toolbar>

<mat-menu #avatarMenu="matMenu">
    <div mat-menu-item routerLink="/user-settings">User Settings</div>
    <div mat-menu-item (click)="openSubmitFeedback()">Submit Feedback</div>
    <div mat-menu-item routerLink="/my-feedback">My Feedback</div>
    <div mat-menu-item (click)="onLogoutClick()">Log Out</div>
</mat-menu>
