<div
    class="tw-flex tw-flex-row tw-justify-between tw-text-center mat-elevation-z8 tw-bg-m3SurfaceContainer"
>
    <div class="tw-flex tw-flex-1 tw-flex-row tw-m-auto tw-w-3/4 tw-p-3">
        <button
            mat-button
            matTooltip="Previous Week"
            (click)="onClickPreviousWeek()"
        >
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>

        <mat-form-field [formGroup]="dateFormGroup" appearance="outline">
            <mat-label>{{ weekIdentifier }}</mat-label>
            <mat-date-range-input
                [rangePicker]="picker"
                (click)="picker.open()"
            >
                <input matStartDate formControlName="beginningOfWeek" />
                <input matEndDate formControlName="endOfWeek" />
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
                matSuffix
                [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <button mat-button matTooltip="Next Week" (click)="onClickNextWeek()">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>

    <div
        class="tw-flex tw-flex-1 tw-flex-row tw-m-auto tw-w-3/4 tw-p-3 tw-justify-around"
    >
        <div>
            Hours Saved
            <mat-divider></mat-divider>
            {{ weekHours.saved }}
        </div>
        <div>
            Hours Submitted
            <mat-divider></mat-divider>
            {{ weekHours.submitted }}
        </div>
        <div>
            Hours Locked
            <mat-divider></mat-divider>
            {{ weekHours.locked }}
        </div>
    </div>

    <div
        class="tw-flex tw-flex-1 tw-flex-row tw-m-auto tw-w-3/4 tw-p-3 tw-justify-evenly"
    >
        <button
            mat-raised-button
            matTooltip="Save All Timesheets"
            type="button"
            (click)="saveTimesheetsEvent.emit()"
        >
            Save All
        </button>

        <button
            mat-raised-button
            matTooltip="Submit All Timesheets"
            type="button"
            (click)="submitTimesheetsEvent.emit()"
        >
            Submit All
        </button>

        <button
            mat-raised-button
            matTooltip="Edit Active Task"
            type="button"
            (click)="editTaskEvent.emit()"
        >
            Edit Active Tasks
        </button>
    </div>
</div>
