import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import {
    MatPaginator,
    MatPaginatorModule,
    PageEvent,
} from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Client } from 'src/app/models/client.model';
import { Page } from 'src/app/models/page.model';
import { Project } from 'src/app/models/project-model';
import { ClientService } from 'src/app/services/client.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { AddProjectComponent } from './add-project/add-project.component';
import { EditProjectComponent } from './edit-project/edit-project.component';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgFor, NgIf } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AppStateService } from '../../services/app-state-service';
import { VisibleDirective } from '../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import {
    CLIENTID,
    COUNT,
    NAME,
    OFFSET,
    STATUS,
} from 'src/app/constants/queryParamConstants';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss'],
    imports: [
        ReactiveFormsModule,
        MatSlideToggleModule,
        NgIf,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatButtonModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        EditProjectComponent,
        VisibleDirective,
        MatCardModule,
        MatIconModule,
    ],
})
export class ProjectsComponent implements OnInit {
    @ViewChild(EditProjectComponent)
    editProjectComponent!: EditProjectComponent;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    clients: Client[] = [];
    projectSelected = false;
    projectStatus = true;
    loadingProjects = false;
    searchForm = new FormGroup({
        search: new FormControl<string>(null),
        clientId: new FormControl<string>(null),
    });
    canViewClient: boolean = false;
    page = new Page<Project>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });
    delay: number = 300;

    pageIndex = 0;
    queryParams = new Map<string, string | number>();

    constructor(
        private projectService: ProjectsService,
        private clientService: ClientService,
        private dialogService: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private appStateService: AppStateService,
    ) {}

    ngOnInit(): void {
        const queryParamClientId =
            this.activatedRoute.snapshot.queryParamMap.get(CLIENTID);

        this.populateFilters();

        this.searchForm.controls.clientId.valueChanges
            .pipe(debounceTime(this.delay), distinctUntilChanged())
            .subscribe(() => {
                if (this.editProjectComponent) {
                    this.editProjectComponent.project = null;
                    this.editProjectComponent.initializeForm();
                }

                this.updateUrl();
                this.setQueryParams();
                this.changePageIndex(0);
            });

        this.queryParams.set(COUNT, -1);
        this.clientService
            .get([], this.queryParams)
            .subscribe((response: Page<Client>) => {
                this.clients = response.items;

                if (queryParamClientId) {
                    this.searchForm.controls.clientId.setValue(
                        queryParamClientId,
                    );
                } else {
                    this.searchForm.controls.clientId.setValue(
                        this.clients[0].id,
                    );
                }
            });

        this.searchForm.controls.search.valueChanges
            .pipe(debounceTime(this.delay))
            .subscribe(() => {
                this.getProjects();
            });

        this.queryParams.delete(COUNT);
    }

    toggleActiveInactive(): void {
        this.projectStatus = !this.projectStatus;
        this.changePageIndex(0);
    }

    onClientRoute(clientId: string): void {
        this.appStateService.title$.next('Clients');
        void this.router.navigate(['clients'], {
            queryParams: { clientId: clientId },
        });
    }

    clientChange() {
        this.projectSelected = false;
    }

    projectChange(event: Project | null): void {
        if (event === null) {
            this.projectSelected = false;
        }
    }

    openAddDialog(): void {
        const map = new Map<string, string | number>();
        map.set(STATUS, ACTIVE);
        map.set(COUNT, -1);
        this.clientService.get([], map).subscribe(() => {
            const dialogRef = this.dialogService.open(AddProjectComponent, {
                disableClose: true,
                data: this.searchForm.controls.clientId.value,
                width: '25%',
            });

            dialogRef.afterClosed().subscribe((added) => {
                if (added) {
                    this.getProjects();
                }
            });
        });
    }

    private populateFilters(): void {
        const params: Params = this.activatedRoute.snapshot.queryParams;

        if (STATUS in params) {
            this.projectStatus =
                (params[STATUS] as string).toUpperCase() !== INACTIVE;
        }

        if (NAME in params) {
            this.searchForm.controls.search.setValue(params[NAME] as string);
        }

        if (CLIENTID in params) {
            this.searchForm.controls.clientId.setValue(
                params[CLIENTID] as string,
            );
        }
    }

    private updateUrl(): void {
        const queryParams: Params = {};

        if (this.projectStatus !== null && this.projectStatus !== undefined) {
            queryParams[STATUS] = this.projectStatus ? ACTIVE : INACTIVE;
        }

        const nameValue = this.searchForm.controls.search.value;
        if (nameValue) {
            queryParams[NAME] = nameValue;
        }

        const clientId = this.searchForm.controls.clientId.value;
        if (clientId) {
            queryParams[CLIENTID] = clientId;
        }

        void this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
        });
    }

    onLoadingProjectEdit(value: boolean): void {
        if (!value) {
            this.loadingProjects = false;
            this.getProjects();
        } else {
            this.loadingProjects = true;
        }
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number) {
        this.pageIndex = index;
        this.getProjects();
    }

    setQueryParams(): void {
        this.queryParams.set(COUNT, 10);

        if (this.projectStatus) {
            this.queryParams.set(STATUS, ACTIVE);
        } else {
            this.queryParams.set(STATUS, INACTIVE);
        }

        if (this.pageIndex) {
            this.queryParams.set(OFFSET, this.pageIndex * 8);
        } else {
            this.queryParams.set(OFFSET, 0);
        }

        if (
            this.searchForm.controls.search.value &&
            this.searchForm.controls.search.value !== ''
        ) {
            this.queryParams.set(NAME, this.searchForm.controls.search.value);
        }

        if (this.searchForm.controls.search.value === '') {
            if (this.queryParams.has(NAME)) {
                this.queryParams.delete(NAME);
            }
        }

        if (this.searchForm.controls.clientId.value) {
            this.queryParams.set(
                CLIENTID,
                this.searchForm.controls.clientId.value,
            );
        }

        this.updateUrl();
    }

    getProjects(): void {
        this.setQueryParams();
        this.projectService
            .get([], this.queryParams)
            .subscribe((response: Page<Project>) => {
                this.page = response;
            });
    }

    projectSelectionChange(event: MatSelectionListChange): void {
        this.editProjectComponent.editingTasks = false;
        if (!this.projectSelected) {
            this.projectSelected = true;
        }

        const map = new Map<string, string | number>();
        map.set(STATUS, ACTIVE);
        map.set(COUNT, -1);
        this.clientService.get([], map).subscribe(() => {
            this.editProjectComponent.setEditProject(
                event.options[0].value as Project,
            );
        });
    }
}
