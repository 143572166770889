import { Injectable } from '@angular/core';
import { AbstractService } from './abstract-service.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService extends AbstractService {
    path = '/user';

    postImage(formData: FormData): Observable<any> {
        const url = `${this.baseUrl}${this.path}/upload-image`;

        const options = {
            headers: new HttpHeaders({
                'Content-Type': `multipart/form-data; boundary='my-boundary'`,
                Authorization: `Bearer ${window.sessionStorage.getItem(
                    'PSSUserAuthToken',
                )}`,
            }),
        };

        return this.httpClient.post(url, formData, options);
    }
}
