<div
    class="tw-absolute tw-h-screen tw-w-screen tw-z-[-1] tw-bg-center tw-bg-no-repeat tw-bg-cover"
    style="
        background-image: url(https://psportalqa.blob.core.windows.net/logo-container/LoginBackground.png);
    "
></div>
<div
    class="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full"
    [ngClass]="{ 'tw-bg-white': theme === 'light-theme' }"
>
    <form
        *ngIf="!completed"
        [formGroup]="forgotPasswordForm"
        class="tw-flex tw-flex-col tw-justify-center tw-w-1/4"
    >
        <!-- TODO add regex -->
        <mat-progress-bar
            [appVisible]="loading"
            class="m3-error"
            mode="indeterminate"
        ></mat-progress-bar>

        <div class="tw-text-center tw-flex tw-m-3">
            Provide your email below for a password reset.
        </div>

        <mat-form-field class="tw-w-full tw-self-center" appearance="fill">
            <mat-label>Email</mat-label>
            <input name="email" matInput formControlName="email" />
            <mat-error *ngIf="this.forgotPasswordForm.get('email').invalid">
                {{ getErrorMessage() }}
            </mat-error>
        </mat-form-field>

        <div class="tw-flex tw-justify-evenly tw-w-full">
            <button
                class="m3-error tw-w-2/5"
                mat-raised-button
                type="submit"
                (click)="onForgotPasswordClick()"
            >
                Reset
            </button>
            <button
                class="tw-w-2/5"
                mat-raised-button
                routerLink="/login"
                routerLinkActive="active"
            >
                Login Page
            </button>
        </div>
    </form>
</div>
