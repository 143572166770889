import { Component, Inject, OnInit } from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogContent,
} from '@angular/material/dialog';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { Client } from 'src/app/models/client.model';
import { ClientService } from 'src/app/services/client.service';
import { DialogFooterComponent } from '../../dialog-footer/dialog-footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { VisibleDirective } from '../../../directives/visible.directive';

@Component({
    templateUrl: './add-client.component.html',
    styleUrls: ['./add-client.component.scss'],
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        DialogFooterComponent,
        VisibleDirective,
    ],
})
export class AddClientComponent implements OnInit {
    clientForm!: FormGroup;
    loading = false;
    failed = false;
    activeClients!: Client[];

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: Client[],
        private dialogRef: MatDialogRef<AddClientComponent>,
        private clientService: ClientService,
    ) {}

    ngOnInit(): void {
        this.initializeForm();
        this.activeClients = this.data;
    }

    initializeForm(): void {
        this.clientForm = new FormGroup({
            name: new FormControl('', [
                Validators.required.bind(this),
                Validators.maxLength(100),
            ]),
            signOffFlag: new FormControl(false),
            nonBillable: new FormControl(false),
        });
    }

    onFormSave(): void {
        this.clientForm.updateValueAndValidity();
        if (!this.clientForm.valid) {
            return;
        }
        this.loading = true;
        const client = new Client(this.clientForm.value as Client);
        client.status = ACTIVE;

        this.clientService.post([], client).subscribe(
            (response) => {
                this.loading = false;
                this.dialogRef.close({ success: true, clientId: response });
            },
            () => {
                this.loading = false;
                this.failed = true;
            },
        );
    }

    onCloseClick(): void {
        this.dialogRef.close(false);
    }
}
