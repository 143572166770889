import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const PasswordMatchValidator: ValidatorFn = (
    control: AbstractControl,
): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    if (
        password.value !== confirmPassword.value &&
        password.value !== null &&
        confirmPassword.value !== null
    ) {
        control.get('confirmPassword').setErrors({ passwordMatch: true });
        control.get('password').setErrors({ passwordMatch: true });

        return { passwordMatch: true };
    } else {
        delete password?.errors?.passwordMatch;
        delete confirmPassword?.errors?.passwordMatch;

        if (password?.errors) {
            if (Object.keys(password?.errors).length === 0) {
                password?.setErrors(null);
            }
        }

        if (confirmPassword?.errors) {
            if (Object.keys(confirmPassword?.errors).length === 0) {
                confirmPassword?.setErrors(null);
            }
        }

        return null;
    }
};
