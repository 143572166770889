import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionListChange, MatListModule } from '@angular/material/list';
import {
    MatPaginator,
    PageEvent,
    MatPaginatorModule,
} from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Client } from 'src/app/models/client.model';
import { Page } from 'src/app/models/page.model';
import { ClientService } from 'src/app/services/client.service';
import { AddClientComponent } from './add-client/add-client.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { VisibleDirective } from '../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { debounceTime } from 'rxjs';

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.scss'],
    imports: [
        MatSlideToggleModule,
        NgIf,
        MatProgressBarModule,
        MatButtonModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        EditClientComponent,
        VisibleDirective,
        MatCardModule,
        MatIconModule,
    ],
})
export class ClientComponent implements OnInit, AfterViewInit {
    @ViewChild(EditClientComponent) editClientComponent!: EditClientComponent;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    clientStatus = true;
    loadingClients = false;
    clientSelected = false;
    showOptions = false;

    searchForm = new FormGroup({
        search: new FormControl<string>(''),
    });

    pageIndex = 0;
    page = new Page<Client>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    queryParams = new Map();

    constructor(
        private clientService: ClientService,
        public dialogService: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.clientId) {
                this.clientService
                    .get([params.clientId])
                    .subscribe((client: Client) => {
                        this.clientSelected = true;
                        this.editClientComponent.setEditClient(client);
                    });
            }
        });

        this.populateFilters();
        this.getClients();

        this.searchForm.controls.search.valueChanges
            .pipe(debounceTime(500))
            .subscribe(() => {
                this.changePageIndex(0);
            });
    }

    ngAfterViewInit(): void {
        this.editClientComponent.loadingClientEdit.subscribe((value) => {
            this.loadingClients = value;
            if (value === false) {
                this.getClients();
            }
        });
    }

    clientChanged(event: boolean): void {
        this.clientSelected = event;
    }

    toggleActiveInactive(): void {
        this.clientStatus = !this.clientStatus;
        this.changePageIndex(0);
    }

    openAddDialog(): void {
        const map = new Map();
        map.set('status', ACTIVE);
        map.set('count', 100);
        this.clientService.get([], map).subscribe((response: Page<Client>) => {
            const dialogRef = this.dialogService.open(AddClientComponent, {
                disableClose: true,
                data: response.items,
                width: '25%',
            });
            dialogRef.afterClosed().subscribe((added) => {
                if (added) {
                    this.getClients();
                }
            });
        });
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.getClients();
    }

    setQueryParams(): void {
        this.queryParams.set('count', 10);

        if (this.clientStatus) {
            this.queryParams.set('status', ACTIVE);
        } else {
            this.queryParams.set('status', INACTIVE);
        }

        if (this.pageIndex) {
            this.queryParams.set('offset', this.pageIndex * 10);
        } else {
            this.queryParams.set('offset', 0);
        }

        if (
            this.searchForm.controls.search.value &&
            this.searchForm.controls.search.value !== ''
        ) {
            this.queryParams.set('name', this.searchForm.controls.search.value);
        }

        if (this.searchForm.controls.search.value === '') {
            if (this.queryParams.has('name')) {
                this.queryParams.delete('name');
            }
        }

        this.updateUrl();
    }

    getClients(): void {
        this.setQueryParams();

        this.clientService
            .get([], this.queryParams)
            .subscribe((response: Page<Client>) => {
                this.page = response;
            });
    }

    private populateFilters(): void {
        const params: Params = this.activatedRoute.snapshot.queryParams;

        if ('status' in params) {
            this.clientStatus = params['status'] !== 'INACTIVE';
        }

        if ('name' in params) {
            this.searchForm.controls.search.setValue(params['name'] as string);
        }
    }

    private updateUrl(): void {
        const queryParams: Params = {};

        if (this.clientStatus !== null && this.clientStatus !== undefined) {
            queryParams['status'] = this.clientStatus ? ACTIVE : INACTIVE;
        }

        const nameValue = this.searchForm.controls.search.value;
        if (nameValue) {
            queryParams['name'] = nameValue;
        }

        void this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
        });
    }

    clientSelectionChange(event: MatSelectionListChange): void {
        if (!this.clientSelected) {
            this.clientSelected = true;
        }

        this.editClientComponent.setEditClient(event.options[0].value);
    }
}
