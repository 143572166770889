import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Me } from 'src/app/models/me.model';
import { Modules } from 'src/app/enums/modules.enum';
import { PermissionsService } from 'src/app/services/permission.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { Permission } from 'src/app/models/permission.model';
import { Route } from 'src/app/models/route.model';
import { MatDividerModule } from '@angular/material/divider';
import { NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { AppStateService } from '../../services/app-state-service';

export const ROUTES: Route[] = [
    {
        name: 'Home',
        headerName: 'Home',
        route: 'home',
        permission: Modules.HOME,
        icon: 'house',
    },
    {
        name: 'Users',
        headerName: 'Users',
        route: 'users',
        permission: Modules.USERS,
        icon: 'supervisor_account',
    },
    {
        name: 'Documents',
        headerName: 'Documents',
        route: 'documents',
        permission: Modules.DOCUMENTS,
        icon: 'description',
    },
    {
        name: 'Locations',
        headerName: 'Locations',
        route: 'locations',
        permission: Modules.LOCATIONS,
    },
    {
        name: 'Projects',
        headerName: 'Projects',
        route: 'projects',
        permission: Modules.PROJECTS,
    },
    {
        name: 'Client Management',
        headerName: 'Client',
        route: 'clients',
        permission: Modules.CLIENTS,
        icon: 'work',
    },
    {
        name: 'User Feedback',
        headerName: 'User Feedback',
        route: 'user-feedback',
        permission: Modules.USERFEEDBACK,
    },
    {
        name: 'Submissions',
        headerName: 'Submissions',
        route: 'submitted-timesheets',
        permission: Modules.SUBMISSIONS,
    },
    {
        name: 'Work Orders',
        headerName: 'Work Orders',
        route: 'workorders',
        permission: Modules.WORKORDERS,
    },
    {
        name: 'Role Management',
        headerName: 'Role',
        route: 'role',
        permission: Modules.ROLES,
    },
    {
        name: 'Timesheets',
        headerName: 'Timesheets',
        route: 'Timesheet-Dashboard',
        permission: Modules.TIMESHEETS,
        icon: 'vertical_split',
    },
    {
        name: 'Contractor Timesheets',
        headerName: 'Contractor Timesheets',
        route: 'Contractor-Timesheet-Dashboard',
        permission: Modules.CONTRACTORTIMESHEETS,
    },
    {
        name: 'Admin Timesheets',
        headerName: 'Admin Timesheets',
        route: 'Admin-Timesheet',
        permission: Modules.ADMINTIMESHEETS,
    },
    {
        name: 'Reports',
        headerName: 'Reports',
        route: 'reports',
        permission: Modules.REPORTS,
        icon: 'assessment',
    },
    {
        name: 'Employee Directory',
        headerName: 'Employees',
        route: 'employee-directory',
        permission: Modules.EMPLOYEEDIRECTORY,
        icon: 'face',
    },
    {
        name: 'Employee Types',
        headerName: 'Employee Types',
        route: 'employee-types',
        permission: Modules.EMPLOYEETYPES,
    },
    {
        name: 'Configuration',
        headerName: 'Configuration',
        route: 'configuration',
        permission: Modules.CONFIGURATION,
    },
    {
        name: 'Calendar',
        headerName: 'Calendar',
        route: 'calendar',
        permission: Modules.CALENDAR,
        icon: 'calendar_today',
    },
];

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    imports: [
        MatListModule,
        MatTooltipModule,
        MatIconModule,
        NgFor,
        NgIf,
        MatDividerModule,
    ],
})
export class SidenavComponent implements OnInit {
    approvePermissions = new Map<string, boolean>();
    permissions = new Map<string, boolean>();
    userPermissions: Permission[];
    Modules = Modules;
    userLevel: number;
    routes: Array<Route>;

    @Output() searchClick = new EventEmitter<null>();

    constructor(
        private appStateService: AppStateService,
        public permissionService: PermissionsService,
        public navigationService: NavigationService,
    ) {}

    ngOnInit(): void {
        this.routes = ROUTES;
        this.appStateService.me$.subscribe((me: Me) => {
            if (me.id) {
                this.userPermissions = me.pagePermissions;
            }
            if (me.employeeLevel) {
                this.userLevel = me.employeeLevel;
            }
        });
    }

    onRouteChangeClick(route: string): void {
        this.navigationService.Route(route);
    }

    toggleSearch(): void {
        this.searchClick.emit(null);
    }

    checkPermissions(route: Route): boolean {
        if (
            !route.permission ||
            route.permission.length === 0 ||
            route.permission === 'Home'
        ) {
            return true;
        }

        let canView = false;
        if (this.userPermissions) {
            canView =
                this.userPermissions.filter(
                    (perm) => perm.module == route.permission,
                )[0] != undefined;
        }

        return canView;
    }
}
