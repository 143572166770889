import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Me } from '../models/me.model';

@Injectable({
    providedIn: 'root',
})
export class AppStateService {
    public readonly title$ = new BehaviorSubject<string>('Title');
    public readonly me$ = new BehaviorSubject<Me>(new Me());
}
