import { Page } from './../../models/page.model';
import { Component, Inject, OnInit } from '@angular/core';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/user.service';
import { Task } from '../../models/task.model';
import {
    MAT_DIALOG_DATA,
    MatDialogContent,
    MatDialogRef,
} from '@angular/material/dialog';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { DialogExitComponent } from '../dialog-exit/dialog-exit.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgFor, NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { VisibleDirective } from '../../directives/visible.directive';

@Component({
    selector: 'app-edit-task-dialog',
    templateUrl: './edit-task-dialog.component.html',
    styleUrls: ['./edit-task-dialog.component.scss'],
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatProgressBarModule,
        NgFor,
        MatCheckboxModule,
        MatDividerModule,
        DialogExitComponent,
        VisibleDirective,
    ],
})
export class EditTaskDialogComponent implements OnInit {
    allTasks: Task[] = [];
    loadingTasks = false;
    updated = false;

    constructor(
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public user: User,
        private dialogRef: MatDialogRef<EditTaskDialogComponent>,
    ) {}

    ngOnInit(): void {
        this.loadingTasks = true;
        this.userService
            .get([this.user.id, 'task'])
            .subscribe((response: Page<Task>) =>
                this.getTasksCallback(response),
            );
    }

    getTasksCallback(response: Page<Task>): void {
        this.user.tasks = response.items;
        this.loadingTasks = false;
    }

    updateTaskStatus(task: Task): void {
        if (task.userTaskStatus.toUpperCase() === ACTIVE) {
            task.userTaskStatus = INACTIVE;
        } else {
            task.userTaskStatus = ACTIVE;
        }

        this.userService
            .put([this.user.id, 'changeTaskStatus'], task)
            .subscribe(() => {
                if (this.updated === false) {
                    this.updated = true;
                }
            });
    }

    onClickClose(): void {
        this.dialogRef.close();
    }
}
