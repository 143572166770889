import {
    Component,
    Output,
    Input,
    OnInit,
    EventEmitter,
    OnDestroy,
    Inject,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { EmployeeTypeService } from 'src/app/services/employee-type.service';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
    MatDialogContent,
} from '@angular/material/dialog';
import { DialogFooterComponent } from '../../dialog-footer/dialog-footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';

@Component({
    selector: 'app-add-employee-type',
    templateUrl: './add-employee-type.component.html',
    styleUrl: './add-employee-type.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        MatCheckboxModule,
        MatButtonModule,
        DialogFooterComponent,
    ],
})
export class AddEmployeeTypeComponent implements OnInit, OnDestroy {
    @Input() employeeTypeObservable: Observable<EmployeeType>;
    @Output() saveCompleted: EventEmitter<void> = new EventEmitter();

    failed = false;
    saving = false;

    dialogSubscription: Subscription;
    deleteSubscription: Subscription;
    saveSubscription: Subscription;
    employeeTypeSubscription: Subscription;
    employeeTypeForm: FormGroup;

    get id() {
        return this.employeeTypeForm.get('id')?.value || null;
    }

    constructor(
        private employeeTypeService: EmployeeTypeService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AddEmployeeTypeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.employeeTypeForm = new FormGroup({
            id: new FormControl(null),
            name: new FormControl('', Validators.required),
            isActive: new FormControl(true),
            showInUtilization: new FormControl(true),
        });
    }

    ngOnInit(): void {
        this.employeeTypeSubscription = this.employeeTypeObservable.subscribe(
            (et) => {
                this.updateFormFromEmployeeType(et);
            },
        );
    }

    ngOnDestroy(): void {
        this.dialogSubscription?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
        this.employeeTypeSubscription?.unsubscribe();
        this.saveSubscription?.unsubscribe();
    }

    updateFormFromEmployeeType(employeeType: EmployeeType): void {
        this.employeeTypeForm.setValue({
            id: employeeType.id,
            name: employeeType.name,
            isActive: employeeType.isActive,
            showInUtilization: employeeType.showInUtilization,
        });
    }

    saveEmployeeType(): void {
        if (!this.employeeTypeForm.valid) {
            return;
        }
        this.saving = true;
        const postData = this.employeeTypeForm.value;
        let saveObservable: Observable<any>;

        if (postData.id) {
            saveObservable = this.employeeTypeService.put(
                [postData.id],
                postData,
            );
        } else {
            saveObservable = this.employeeTypeService.post([], postData);
        }

        this.saveSubscription = saveObservable.subscribe(
            () => this.saveComplete(),
            (error: any) => (this.failed = true),
        );

        this.dialogRef.close();
    }

    saveComplete(): void {
        this.saveCompleted.emit();
    }

    onResetClick(): void {
        this.employeeTypeForm.reset();
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }
}
