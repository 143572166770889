<mat-progress-bar
    [appVisible]="loadingReport"
    mode="indeterminate"
></mat-progress-bar>
<div class="tw-m-auto tw-w-3/4 tw-p-3 tw-flex tw-flex-col tw-mt-4">
    <div class="tw-flex tw-flex-row">
        <form
            class="tw-flex tw-flex-row tw-gap-3"
            [formGroup]="filterForm"
            autocomplete="off"
        >
            @if (showFilterMenu) {
                <mat-form-field>
                    <mat-label>Search by name...</mat-label>
                    <input
                        matInput
                        type="text"
                        formControlName="name"
                        (input)="filterChange()"
                    />
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Client</mat-label>

                    <mat-select
                        formControlName="clientId"
                        (selectionChange)="filterChange()"
                    >
                        <mat-option value="">View All</mat-option>
                        <mat-option
                            *ngFor="let client of clients"
                            [value]="client.id"
                        >
                            {{ client.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            }
        </form>

        <div class="tw-flex tw-flex-row tw-gap-2 tw-ml-auto">
            <mat-slide-toggle
                [checked]="enableToggle"
                (change)="showFilters()"
                style="margin-top: 5px"
            >
                Filter
            </mat-slide-toggle>

            <button
                mat-raised-button
                matTooltip="Clear Filters"
                *ngIf="showFilterMenu === true"
                (click)="clearFilters()"
            >
                Clear Filters
            </button>

            <button
                mat-raised-button
                matTooltip="Create Work Order"
                (click)="openWorkOrder(true)"
                class="tw-mb-10"
            >
                Create Work Order
            </button>
        </div>
    </div>

    <div class="mat-elevation-z8">
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            class="mat-elevation-z8 tw-w-full"
        >
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Work Order Name
                </th>
                <td
                    mat-cell
                    *matCellDef="let workOrder"
                    matTooltip="{{ workOrder.name }}"
                    matTooltipPosition="left"
                >
                    {{ workOrder.name | slice: 0 : 50
                    }}{{ workOrder.name.length > 30 ? "..." : "" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="clientName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Client Name
                </th>
                <td mat-cell *matCellDef="let workOrder">
                    {{ workOrder.clientName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="createDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Date Created
                </th>
                <td mat-cell *matCellDef="let workOrder">
                    {{ workOrder.createDate | date: "MM/dd/yyyy" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="open">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let workOrder">
                    <button
                        mat-icon-button
                        (click)="openWorkOrder(false, workOrder)"
                    >
                        <mat-icon>launch</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="new">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let workOrder">
                    <button
                        mat-icon-button
                        (click)="openWorkOrder(true, workOrder)"
                        matTooltip="Clone Template"
                        matTooltipPosition="right"
                    >
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
            [pageIndex]="pageIndex"
            [pageSize]="page.countRequested"
            [length]="page.totalCount"
            [hidePageSize]="true"
            (page)="changePage($event)"
        ></mat-paginator>
    </div>
</div>
