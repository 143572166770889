import { Component, OnInit, ViewChild } from '@angular/core';
import {
    MatDatepickerInputEvent,
    MatDatepickerModule,
} from '@angular/material/datepicker';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { Page } from 'src/app/models/page.model';
import { Payroll } from 'src/app/models/report-models/payroll.model';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/user.service';
import { ReportsService } from 'src/app/services/reports.service';
import { saveAs } from 'file-saver';
import { getISOWeek } from 'date-fns';
import { TimesheetService } from 'src/app/services/timesheet-service.service';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportTypes } from 'src/app/enums/report-types';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor } from '@angular/common';
import { VisibleDirective } from '../../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

interface IFilterFormControls {
    date: FormControl<Date>;
    employee: FormControl<string>;
    billingHours: FormControl<string>;
    ptoHours: FormControl<string>;
}

@Component({
    selector: 'app-payroll-report',
    templateUrl: './payroll-report.component.html',
    styleUrls: ['./payroll-report.component.scss'],
    imports: [
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatSlideToggleModule,
        MatButtonModule,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatPaginatorModule,
        VisibleDirective,
        MatSort,
        MatCardModule,
        MatIconModule,
    ],
})
export class PayrollReportComponent implements OnInit {
    reports: Payroll;
    reportTypes = ReportTypes;
    loadingReport = false;
    requestFailed = false;
    userProfileId: string;
    queryParams = new Map<string, string | boolean>();

    // Hide all PTO from UI until PTO is fixed //
    /*     displayedColumns = ['name', 'billingHours', 'ptoHours', 'week', 'year', 'paid']; */

    displayedColumns = [
        'name',
        'billingHours',
        'week',
        'year',
        'paid',
        'denialDetails',
    ];
    date = new Date();
    users: User[];
    count: number;
    offset: number;
    showFilterMenu = false;
    enableToggle = false;
    sortAscending: string = null;
    sortProperty: string = null;

    filterForm: FormGroup<IFilterFormControls> = new FormGroup({
        date: new FormControl(new Date()),
        employee: new FormControl(null),
        billingHours: new FormControl(null),
        ptoHours: new FormControl(null),
    });

    pageIndex = 0;
    page = new Page<Payroll>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    dataSource: MatTableDataSource<Payroll>;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private reportService: ReportsService,
        private userService: UserService,
        private timesheetService: TimesheetService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        const map = this.route.snapshot.queryParamMap;
        const reportType = map.get('report');

        if (map.has('day') && map.has('month') && map.has('year')) {
            const day = Number(map.get('day'));
            const month = Number(map.get('month'));
            const year = Number(map.get('year'));
            const newDate = new Date(year, month, day);
            this.filterForm.controls.date.setValue(newDate);
            this.date = newDate;
        }

        if (reportType == (this.reportTypes.Payroll as string)) {
            if (
                map.has('employee') ||
                map.has('billingHours') ||
                map.has('ptoHours')
            ) {
                this.enableToggle = true;
                this.showFilterMenu = true;
                this.populateFilters();
            } else if (map.has('sortAscending') || map.has('sortProperty')) {
                this.populateFilters();
            }
        }

        this.filterForm.controls.billingHours.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.filterForm.controls.ptoHours.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.filterChange();
        this.getPayrollReport();
        this.getUsers();
    }

    private setQueryParams(): void {
        if (this.userProfileId) {
            this.queryParams.set('userProfileId', this.userProfileId);
        } else {
            this.queryParams.delete('userProfileId');
        }

        if (this.page.countRequested || this.page.countRequested == 0) {
            this.queryParams.set('count', this.page.countRequested.toString());
            this.queryParams.set(
                'offset',
                (this.pageIndex * this.page.countRequested).toString(),
            );
        }

        const week = getISOWeek(this.date);
        let year = this.date.getFullYear();

        if (week === 1 && this.date.getMonth() === 11) {
            year += 1;
        }

        this.queryParams.set('week', week.toString());
        this.queryParams.set('year', year.toString());
    }

    protected lockUnlockAllTimesheets(isLocked: boolean): void {
        const queryParams: Map<string, unknown> = new Map<string, unknown>([
            ['week', getISOWeek(this.date)],
            ['year', this.date.getFullYear()],
            ['isLocked', isLocked],
        ]);

        this.timesheetService
            .put(['LockUnlockTimesheet'], {}, queryParams)
            .subscribe(() => {
                this.getPayrollReport();
            });
    }

    protected toggleLockedStatus(payrollItem: Payroll): void {
        const queryParams: Map<string, unknown> = new Map<string, unknown>([
            ['week', payrollItem.week],
            ['year', payrollItem.year],
            ['isLocked', !payrollItem.isPaid],
        ]);

        this.timesheetService
            .put(
                ['LockUnlockTimesheet', payrollItem.userProfileId],
                {},
                queryParams,
            )
            .subscribe(() => {
                this.getPayrollReport();
            });
    }

    getPayrollReport(): void {
        this.loadingReport = true;
        this.setQueryParams();

        this.reportService.get(['payroll'], this.queryParams).subscribe(
            (response: Page<Payroll>) => {
                this.page = response;
                this.page.items.forEach((payrollItem: Payroll) => {
                    const queryParams: Map<string, unknown> = new Map<
                        string,
                        unknown
                    >([
                        ['week', payrollItem.week],
                        ['year', payrollItem.year],
                    ]);

                    this.timesheetService
                        .get(
                            ['TimesheetsPaid', payrollItem.userProfileId],
                            queryParams,
                        )
                        .subscribe((result: boolean) => {
                            payrollItem.isPaid = !result;
                        });
                });
                this.dataSource = new MatTableDataSource(this.page.items);
                this.dataSource.sort = this.sort;
                this.loadingReport = false;
            },
            () => {
                this.requestFailed = true;
                this.loadingReport = false;
            },
        );
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number): void {
        this.pageIndex = index;
        this.getPayrollReport();
    }

    private getUsers(): void {
        const queryParams = new Map<string, number | string>();
        queryParams.set('count', -1);
        queryParams.set('status', ACTIVE);

        this.userService
            .get([], queryParams)
            .subscribe((response: Page<User>) => {
                this.users = response.items;
            });
    }

    dateChange(event: MatDatepickerInputEvent<Date>): void {
        this.date = event.value;

        this.clearFilters();
        this.changePageIndex(0);
    }

    exportReport(): void {
        this.reportService
            .exportReport(['payroll', 'export'], this.queryParams)
            .subscribe(
                (buffer: Blob) => {
                    const data: Blob = new Blob([buffer], {
                        type: 'text/csv',
                    });
                    saveAs(data, 'payroll-report.csv');
                    this.loadingReport = false;
                },
                () => {
                    this.requestFailed = true;
                    this.loadingReport = false;
                },
            );
    }

    filterChange(): void {
        if (
            this.filterForm.controls.employee.value &&
            this.filterForm.controls.employee.value !== ''
        ) {
            this.userProfileId = this.filterForm.controls.employee.value;
        } else {
            this.userProfileId = null;
        }

        if (
            this.filterForm.controls.billingHours.value &&
            this.filterForm.controls.billingHours.value !== ''
        ) {
            this.queryParams.set(
                'BillingHours',
                this.filterForm.controls.billingHours.value,
            );
        } else {
            this.queryParams.delete('BillingHours');
        }

        if (
            this.filterForm.controls.ptoHours.value &&
            this.filterForm.controls.ptoHours.value !== ''
        ) {
            this.queryParams.set(
                'PtoHours',
                this.filterForm.controls.ptoHours.value,
            );
        } else {
            this.queryParams.delete('PtoHours');
        }

        this.updateUrl();
        this.changePageIndex(0);
    }

    showFilters(): void {
        this.showFilterMenu = !this.showFilterMenu;
        this.clearFilters();
    }

    clearFilters(): void {
        this.userProfileId = null;
        this.queryParams.delete('BillingHours');
        this.queryParams.delete('PtoHours');

        this.filterForm = new FormGroup({
            date: new FormControl(this.filterForm.controls.date.value),
            employee: new FormControl(null),
            billingHours: new FormControl(null),
            ptoHours: new FormControl(null),
        });

        this.filterForm.controls.billingHours.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.filterForm.controls.ptoHours.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.updateUrl();
        this.changePageIndex(0);
    }

    populateFilters(): void {
        const map = this.route.snapshot.queryParamMap;

        if (map.has('month') && map.has('year') && map.has('day')) {
            const day = Number(map.get('day'));
            const month = Number(map.get('month'));
            const year = Number(map.get('year'));
            const newDate = new Date(year, month, day);
            this.filterForm.controls.date.setValue(newDate);
            this.date = newDate;
        }

        if (map.has('employee')) {
            this.filterForm.controls.employee.setValue(map.get('employee'));
        }

        if (map.has('billingHours')) {
            const billingHours = map.get('billingHours');
            this.filterForm.controls.billingHours.setValue(billingHours);
        }

        if (map.has('ptoHours')) {
            const ptoHours = map.get('ptoHours');
            this.filterForm.controls.ptoHours.setValue(ptoHours);
        }

        if (map.has('sortAscending')) {
            this.queryParams.set('isSortAscending', map.get('sortAscending'));
        }

        if (map.has('sortProperty')) {
            this.queryParams.set('sortProperty', map.get('sortProperty'));
        }
    }

    updateUrl(): void {
        void this.router.navigate([]);

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                report: this.reportTypes.Payroll,
                count: this.count,
                offset: this.offset,
                day: this.filterForm.controls.date.value.getDate().toString(),
                month: this.filterForm.controls.date.value
                    .getMonth()
                    .toString(),
                year: this.filterForm.controls.date.value
                    .getFullYear()
                    .toString(),
                employee: this.filterForm.controls.employee.value,
                billingHours: this.filterForm.controls.billingHours.value,
                ptoHours: this.filterForm.controls.ptoHours.value,
                sortAscending: this.sortAscending,
                sortProperty: this.sortProperty,
            },
        });
    }
}
