<app-dialog-header [title]="title"></app-dialog-header>

<div mat-dialog-content>
    <div *ngFor="let event of data.dayEvents">
        <div class="border-cal tw-overflow-x-auto">
            {{ event.title }}
        </div>
    </div>
</div>
<app-dialog-exit (closeClick)="onClickClose()"></app-dialog-exit>
