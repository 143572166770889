<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingProjects"
></mat-progress-bar>

<form [formGroup]="searchForm" class="tw-flex tw-mt-6 tw-justify-center">
    <div class="tw-flex tw-justify-between tw-w-3/4">
        <div class="tw-text-2xl">
            Add/Edit Projects

            <mat-slide-toggle
                class="tw-ml-2"
                (change)="toggleActiveInactive()"
                [checked]="projectStatus"
            >
                @if (projectStatus) {
                    Active
                } @else {
                    Inactive
                }
            </mat-slide-toggle>
        </div>

        <div class="tw-flex tw-flex-row tw-items-baseline tw-gap-2">
            <mat-form-field appearance="fill">
                <mat-label>Select your Client</mat-label>
                <mat-select
                    formControlName="clientId"
                    (valueChange)="clientChange()"
                >
                    <mat-option
                        *ngFor="let client of clients"
                        [value]="client.id"
                    >
                        {{ client.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button
                *ngIf="searchForm.controls.clientId.value"
                mat-raised-button
                (click)="onClientRoute(searchForm.controls.clientId.value)"
            >
                View Client Details
            </button>
        </div>
    </div>
</form>

<div class="tw-flex tw-flex-row tw-justify-center tw-gap-2 tw-items-start">
    <div
        class="tw-flex tw-flex-col tw-p-6 tw-gap-4 tw-border-2 tw-box-border tw-border-solid tw-rounded-m3lg tw-border-customborder tw-w-1/4"
    >
        <div class="tw-flex tw-justify-between">
            <div class="tw-text-2xl">Projects</div>

            <button
                mat-raised-button
                matTooltip="Add Project"
                (click)="openAddDialog()"
            >
                + Project
            </button>
        </div>

        <div [formGroup]="searchForm">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>Search Projects...</mat-label>
                <input matInput type="text" formControlName="search" />
            </mat-form-field>
        </div>

        <mat-selection-list
            #userSelectionList
            hideSingleSelectionIndicator
            class="scrollable"
            multiple="false"
            (selectionChange)="projectSelectionChange($event)"
        >
            @for (project of page.items; track project) {
                <mat-list-option [value]="project">
                    {{ project.name }}
                </mat-list-option>
                @if (!$last) {
                    <mat-divider></mat-divider>
                }
            }
        </mat-selection-list>

        <mat-paginator
            class="tw-rounded-b-lg"
            [pageIndex]="pageIndex"
            [pageSize]="page.countRequested"
            [length]="page.totalCount"
            [hidePageSize]="true"
            (page)="changePage($event)"
        ></mat-paginator>
    </div>

    <div class="tw-w-1/2">
        <app-edit-project
            [hidden]="!projectSelected"
            (projectChangeEvent)="projectChange($event)"
            (loadingProjectEdit)="onLoadingProjectEdit($event)"
        ></app-edit-project>
    </div>
</div>
