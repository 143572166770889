import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MatDialogActions,
    MatDialogRef,
    MatDialogTitle,
} from '@angular/material/dialog';

@Component({
    selector: 'app-del-wrkorder-note',
    imports: [
        MatDialogTitle,
        MatDialogActions,
        MatDialogActions,
        MatButtonModule,
    ],
    templateUrl: './del-wrkorder-note.component.html',
    styleUrl: './del-wrkorder-note.component.scss',
})
export class DelWrkorderNoteComponent {
    constructor(public dialogRef: MatDialogRef<DelWrkorderNoteComponent>) {}

    onYesClick(): void {
        this.dialogRef.close(true);
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }
}
