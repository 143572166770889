interface IChangePasswordJson {
    currentPassword: string;
    newPassword: string;
}

interface IChangePasswordConstructor {
    currentPassword: string;
    newPassword: string;
}

export interface ChangePassword extends IChangePasswordConstructor {
    currentPassword: string;
    newPassword: string;
}

export class ChangePassword {
    constructor(input?: IChangePasswordConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: IChangePasswordJson): ChangePassword | null {
        if (json == null) {
            return null;
        }

        return new ChangePassword();
    }
}
