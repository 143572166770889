<app-dialog-header [title]="'Add Comment'"></app-dialog-header>

<div mat-dialog-content>
    <p *ngIf="failed" class="warning">Save Failed</p>

    <mat-progress-bar
        [appVisible]="loading"
        mode="indeterminate"
    ></mat-progress-bar>

    <form
        [formGroup]="commentForm"
        class="tw-flex tw-flex-col"
        (keydown.enter)="$event.preventDefault()"
    >
        <mat-form-field appearance="fill">
            <mat-label>Type here...</mat-label>
            <textarea
                matInput
                formControlName="text"
                maxlength="2000"
            ></textarea>
            <mat-hint align="start">Max 2000 characters</mat-hint>
            <mat-error *ngIf="commentForm.controls.text.errors?.required">
                Text field is required
            </mat-error>
        </mat-form-field>
    </form>
    <button
        [disabled]="loading"
        mat-raised-button
        class="m3-error"
        (click)="initializeForm()"
    >
        Reset
    </button>
</div>

<app-dialog-footer
    [disabled]="loading"
    (buttonClick)="onFormSave()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
