import { Component } from '@angular/core';
import { MatDialogActions, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
    selector: 'app-file-dialog',
    templateUrl: './file-dialog.component.html',
    styleUrls: ['./file-dialog.component.scss'],
    imports: [MatButtonModule, DialogHeaderComponent, MatDialogActions],
})
export class FileDialogComponent {
    constructor(private dialogRef: MatDialogRef<FileDialogComponent>) {}

    onClickClose(event: MouseEvent): void {
        const target = event.currentTarget as HTMLButtonElement;
        this.dialogRef.close(JSON.parse(target.value));
    }
}
