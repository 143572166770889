export interface IWeekHoursJson {
    saved: number;
    submitted: number;
    locked: number;
}

interface IWeekHoursConstructor {
    saved: number;
    submitted: number;
    locked: number;
}

export interface WeekHours extends IWeekHoursConstructor {
    saved: number;
    submitted: number;
    locked: number;
}

export class WeekHours {
    constructor(input: IWeekHoursConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: IWeekHoursJson): WeekHours | null {
        if (json == null) {
            return null;
        }

        return new WeekHours({
            ...json,
        });
    }
}
