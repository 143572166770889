import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PASSWORDPATTERN } from 'src/app/constants/regexConstants';
import {
    ENTEREMAIL,
    ENTERVALUE,
    PASSWORDREQUIREMENTS,
} from 'src/app/constants/stringConstants';

@Injectable({
    providedIn: 'root',
})
export class InputValidationService {
    getPasswordErrorMessage(form: AbstractControl): string {
        if (form.value === null) {
            return ENTERVALUE;
        }

        if (!(form.value as string).match(PASSWORDPATTERN)) {
            return PASSWORDREQUIREMENTS;
        }

        return '';
    }

    getEmailErrorMessage(form: AbstractControl): string {
        if (form.hasError('required')) {
            return ENTERVALUE;
        }

        if (form.hasError('email')) {
            return ENTEREMAIL;
        }

        return '';
    }
}
