import { Component } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogActions,
} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';

@Component({
    selector: 'app-update-user-role-dialog',
    templateUrl: './update-user-role-dialog.component.html',
    styleUrls: ['./update-user-role-dialog.component.scss'],
    imports: [MatButtonModule, DialogHeaderComponent, MatDialogActions],
})
export class UpdateUserRoleDialog {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: string,
        private router: Router,
        private dialogRef: MatDialogRef<UpdateUserRoleDialog>,
    ) {}

    routeToUsers(): void {
        void this.router.navigate(['users'], {
            queryParams: { roleId: this.data },
        });
        this.dialogRef.close({ continueUpdate: false });
    }

    closeDialog(): void {
        this.dialogRef.close({ continueUpdate: true });
    }
}
