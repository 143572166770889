export interface IUserCommentJson {
    id?: string;
    createDate?: string;
    text: string;
    submittedById: string;
    feedbackId: string;
    submittedByName: string;
}

interface IUserCommentConstructor {
    id?: string;
    createDate?: string;
    text: string;
    submittedById: string;
    feedbackId: string;
    submittedByName?: string;
}

export interface UserComment extends IUserCommentConstructor {
    id?: string;
    createDate?: string;
    text: string;
    submittedById: string;
    feedbackId: string;
    submittedByName?: string;
}

export class UserComment {
    constructor(input: IUserCommentConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: IUserCommentConstructor): UserComment | null {
        if (json == null) {
            return null;
        }

        return new UserComment({
            ...json,
        });
    }
}
