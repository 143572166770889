<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingDocuments"
></mat-progress-bar>
<div class="tw-grid documents-container tw-gap-4 tw-box-border tw-px-8 tw-pt-8">
    <div
        class="document-header tw-flex tw-flex-col tw-gap-2 tw-p-4 tw-box-border tw-rounded-m3lg tw-border-customborder tw-border-2 tw-border-solid"
    >
        <div class="tw-text-3xl">Documents</div>
        <div class="tw-flex tw-gap-2 tw-items-center">
            <mat-slide-toggle
                [checked]="isTreeCollapsed"
                (change)="onTreeCollapseChange()"
            >
                <span style="font-size: 0.75rem">{{ treeCollapseToggle }}</span>
            </mat-slide-toggle>

            <button
                [disabled]="!usersPermissions.get(Crud.Create)"
                mat-raised-button
                matTooltip="Add Category"
                class="tw-ml-auto"
                (click)="createContainer()"
            >
                + Category
            </button>
        </div>
    </div>

    <div
        class="documents tw-self-start tw-p-4 tw-rounded-m3lg tw-box-border tw-border-customborder tw-border-2 tw-border-solid"
    >
        <div class="tw-overflow-auto tw-max-h-[65vh]">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-tree-node
                    class="tw-cursor-pointer tw-gap-2 hover:tw-bg-m3SurfaceContainer-Highest"
                    *matTreeNodeDef="let node"
                    matTreeNodePadding
                    (click)="viewFile(node)"
                    (contextmenu)="onRightClick($event, node)"
                >
                    <button mat-icon-button disabled></button>
                    <mat-icon>document_scanner</mat-icon>
                    <div>
                        {{ node.name }}
                    </div>
                </mat-tree-node>

                <mat-tree-node
                    *matTreeNodeDef="let node; when: hasChild"
                    matTreeNodePadding
                >
                    <button
                        mat-icon-button
                        matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.name"
                    >
                        <mat-icon>
                            {{
                                treeControl.isExpanded(node)
                                    ? "expand_more"
                                    : "chevron_right"
                            }}
                        </mat-icon>
                    </button>

                    <div
                        class="tw-cursor-pointer tw-flex tw-items-center tw-gap-2"
                        (contextmenu)="onRightClick($event, node)"
                    >
                        <mat-icon>folder</mat-icon>
                        <div>
                            {{ node.name }}
                        </div>
                    </div>
                    <button
                        class="tw-cursor-pointer"
                        mat-icon-button
                        [disabled]="!usersPermissions.get(Crud.Create)"
                        (click)="openAddDialog(node)"
                        [matTooltip]="
                            !usersPermissions.get(Crud.Create)
                                ? 'You do not have permission to create documents.'
                                : ''
                        "
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-tree-node>
            </mat-tree>
        </div>

        <div
            style="visibility: hidden; position: fixed"
            [style.left]="menuTopLeftPosition.x"
            [style.top]="menuTopLeftPosition.y"
            [matMenuTriggerFor]="rightMenu"
        ></div>

        <mat-menu #rightMenu="matMenu">
            <ng-template matMenuContent let-item="item">
                <button mat-menu-item (click)="removeDocument(item.document)">
                    Delete
                </button>
            </ng-template>
        </mat-menu>
    </div>

    <div
        class="pdf tw-self-start tw-flex tw-flex-col tw-gap-4 tw-box-border tw-p-4 tw-rounded-m3lg tw-border-customborder tw-border-2 tw-border-solid"
        *ngIf="showDocument"
    >
        <div class="tw-flex tw-flex-row tw-gap-4 tw-self-end">
            <button mat-raised-button (click)="downloadFile()">
                <mat-icon>download</mat-icon>
                Download
            </button>

            <button
                mat-raised-button
                class="m3-error"
                (click)="showDocument = !showDocument"
            >
                <mat-icon>close</mat-icon>
                Close
            </button>
        </div>

        <pdf-viewer
            [src]="docInt8Array"
            [rotation]="0"
            [original-size]="false"
            [show-all]="true"
            [fit-to-page]="false"
            [zoom]="1"
            [zoom-scale]="'page-fit'"
            [stick-to-page]="false"
            [render-text]="true"
            [external-link-target]="'blank'"
            [autoresize]="true"
            [show-borders]="false"
            class="tw-w-full tw-h-[75vh]"
        ></pdf-viewer>
    </div>
</div>
