export interface IClientJson {
    name: string;
    signOffFlag: boolean;
    status: string;
    id: string;
    customerId: string;
    nonBillable: boolean;
}

interface IClientConstructor {
    name: string;
    signOffFlag: boolean;
    status: string;
    id: string;
    customerId: string;
    nonBillable: boolean;
}

export interface Client extends IClientConstructor {
    name: string;
    signOffFlag: boolean;
    status: string;
    id: string;
    customerId: string;
    nonBillable: boolean;
}

export class Client {
    constructor(input: IClientConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: IClientJson): Client | null {
        if (json == null) {
            return null;
        }

        return new Client({
            ...json,
        });
    }
}
