import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const PastDateValidator: ValidatorFn = (
    control: AbstractControl,
): ValidationErrors | null => {
    const checkinDateControl = control.get('startDate');
    const checkoutDateControl = control.get('endDate');

    if (
        checkinDateControl?.value != null &&
        checkoutDateControl?.value != null
    ) {
        const checkinDate = new Date(checkinDateControl?.value);
        const checkoutDate = new Date(checkoutDateControl?.value);

        if (checkoutDate.getTime() > checkinDate.getTime()) {
            return null;
        } else {
            checkoutDateControl?.setErrors({ pastDate: true });

            return { pastDate: true };
        }
    } else {
        return null;
    }
};
