import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Me } from 'src/app/models/me.model';
import { Modules } from 'src/app/enums/modules.enum';
import { Crud } from 'src/app/enums/crud.enums';
import { PermissionsService } from 'src/app/services/permission.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { Permission } from 'src/app/models/permission.model';
import { Route } from 'src/app/models/route.model';
import { ThemeService } from 'src/app/services/theme.service';
import { RouteSearchComponent } from '../route-search/route-search.component';
import { MatDividerModule } from '@angular/material/divider';
import { NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { AppStateService } from '../../services/app-state-service';

export const ROUTES: Route[] = [
    {
        name: 'Home',
        headerName: 'Home',
        route: 'home',
        permission: [],
        icon: 'house',
    },
    {
        name: 'Users',
        headerName: 'Users',
        route: 'users',
        permission: [Modules.USERS],
        icon: 'supervisor_account',
    },
    {
        name: 'Documents',
        headerName: 'Documents',
        route: 'documents',
        permission: [Modules.DOCUMENTS],
        icon: 'description',
    },
    {
        name: 'Locations',
        headerName: 'Locations',
        route: 'locations',
        permission: [Modules.LOCATIONS],
    },
    {
        name: 'Projects',
        headerName: 'Projects',
        route: 'projects',
        permission: [Modules.PROJECTS, Modules.CLIENTS],
    },
    {
        name: 'Client Management',
        headerName: 'Client',
        route: 'clients',
        permission: [Modules.CLIENTS],
        icon: 'work',
    },
    {
        name: 'User Feedback',
        headerName: 'User Feedback',
        route: 'user-feedback',
        permission: [Modules.USERFEEDBACK],
    },
    {
        name: 'Submissions',
        headerName: 'Submissions',
        route: 'submitted-timesheets',
        permission: [Modules.SUBMISSIONS],
    },
    {
        name: 'Work Orders',
        headerName: 'Work Orders',
        route: 'workorders',
        permission: [Modules.WORKORDERS],
    },
    {
        name: 'Role Management',
        headerName: 'Role',
        route: 'role',
        permission: [Modules.ROLES],
    },
    {
        name: 'Timesheets',
        headerName: 'Timesheets',
        route: 'Timesheet-Dashboard',
        permission: [Modules.TIMESHEETS],
        icon: 'vertical_split',
    },
    {
        name: 'Contractor Timesheets',
        headerName: 'Contractor Timesheets',
        route: 'Contractor-Timesheet-Dashboard',
        permission: [Modules.CONTRACTORTIMESHEETS],
    },
    {
        name: 'Admin Timesheets',
        headerName: 'Admin Timesheets',
        route: 'Admin-Timesheet',
        permission: [Modules.ADMINTIMESHEETS],
    },
    /* {name: "PTO Management", headerName: 'PTO Management', route: "paid-time-off", permission:[Modules.PTOREQUEST]},
{name: "My PTO Requests", headerName: 'PTO Requests', route: "pto-request", permission:[Modules.PTOREQUEST]},
    {name: "PTO Accrual Rates", headerName: 'PTO Accrual Rates', route: "pto-accrual-rates", permission:[Modules.PTOACCRUALRATES]},
    {name: "Office Events", headerName: 'PTO Days', route: "pto-days", permission:[Modules.PTODAYS]}, */
    {
        name: 'Reports',
        headerName: 'Reports',
        route: 'reports',
        permission: [Modules.REPORT],
        icon: 'assessment',
    },
    {
        name: 'Employee Directory',
        headerName: 'Employees',
        route: 'employee-directory',
        permission: [Modules.EMPLOYEEDIRECTORY],
        icon: 'face',
    },
    {
        name: 'Configuration',
        headerName: 'Configuration',
        route: 'configuration',
        permission: [Modules.SETTINGS],
    },
    {
        name: 'Employee Types',
        headerName: 'Employee Types',
        route: 'employee-types',
        permission: [Modules.USERS],
    },
    {
        name: 'Calendar',
        headerName: 'Calendar',
        route: 'calendar',
        permission: [Modules.CALENDAR],
        icon: 'calendar_today',
    },
];

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    standalone: true,
    imports: [
        MatListModule,
        MatTooltipModule,
        MatIconModule,
        NgFor,
        NgIf,
        MatDividerModule,
        RouteSearchComponent,
    ],
})
export class SidenavComponent implements OnInit {
    approvePermissions = new Map<string, boolean>();
    permissions = new Map<string, boolean>();
    userPermissions: Permission[];
    Modules = Modules;
    userLevel: number;
    routes: Array<Route>;

    @Output() searchClick = new EventEmitter<null>();

    constructor(
        private appStateService: AppStateService,
        public permissionService: PermissionsService,
        public navigationService: NavigationService,
        private themeService: ThemeService,
    ) {}

    ngOnInit(): void {
        this.routes = ROUTES;
        this.appStateService.me$.subscribe((me: Me) => {
            if (me.id) {
                this.userPermissions = me.permissions;
            }
            if (me.employeeLevel) {
                this.userLevel = me.employeeLevel;
            }
            this.getNavigation();
        });
    }

    onRouteChangeClick(route: string): void {
        this.navigationService.Route(route);
    }

    toggleSearch(): void {
        this.searchClick.emit(null);
    }

    getNavigation(): void {
        Object.values(this.Modules).forEach((key: string) => {
            const hasRead = this.permissionService.permissionsHas(
                key,
                Crud.Read,
                this.userPermissions,
            );
            const hasApprove = this.permissionService.permissionsHas(
                key,
                Crud.Approve,
                this.userPermissions,
            );

            if (hasRead) {
                this.permissions.set(key, hasRead);
            }
            if (hasApprove) {
                this.approvePermissions.set(key, hasApprove);
            }
        });
    }

    checkPermissions(route: Route): boolean {
        if (!route.permission || route.permission.length === 0) {
            return true;
        }

        let canView = false;
        route.permission.forEach((permission: string) => {
            const hasRole = this.permissions.get(permission);
            if (hasRole) {
                canView = true;
            }
        });

        return canView;
    }
}
