<app-dialog-header [title]="'Confirm'"></app-dialog-header>

<div mat-dialog-content>
    <h3>Are you sure you want to remove this task from your timesheet?</h3>
</div>

<app-dialog-footer
    (buttonClick)="yesClicked()"
    (closeClick)="onCloseClick()"
    posButtonText="Confirm"
></app-dialog-footer>
