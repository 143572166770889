import { Permission } from './permission.model';

export interface IRoleJson {
    id: string;
    name: string;
    status: string;
    permissions: Permission[];
}

interface IRoleConstructor {
    id: string;
    name: string;
    status: string;
    permissions: Permission[];
}

export interface Role extends IRoleConstructor {
    id: string;
    name: string;
    status: string;
    permissions: Permission[];
}

export class Role {
    constructor(input: IRoleConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: IRoleJson): Role | null {
        if (json == null) {
            return null;
        }

        return new Role({
            ...json,
        });
    }
}
