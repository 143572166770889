import { Component, OnInit } from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Email } from 'src/app/models/Email';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ForgotPasswordService } from 'src/app/services/forgot-password.service';
import { InputValidationService } from 'src/app/services/input-validation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgClass, NgIf } from '@angular/common';
import { VisibleDirective } from '../../directives/visible.directive';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    imports: [
        NgClass,
        NgIf,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        RouterLink,
        RouterLinkActive,
        VisibleDirective,
    ]
})
export class ForgotPasswordComponent implements OnInit {
    completed = false;
    loading = false;
    forgotPasswordForm: FormGroup = new FormGroup({
        email: new FormControl<string>(null, [
            Validators.email.bind(this),
            Validators.required.bind(this),
        ]),
    });

    theme = localStorage.getItem('theme');
    constructor(
        private forgotPasswordService: ForgotPasswordService,
        private auth: AuthenticationService,
        private router: Router,
        private inputValidationService: InputValidationService,
        private snackBar: MatSnackBar,
    ) {}

    ngOnInit(): void {
        const isAuthenticated = this.auth.checkLoginAuthentication();
        if (isAuthenticated) {
            void this.router.navigate(['home']);
        }
    }

    onForgotPasswordClick(): void {
        this.forgotPasswordForm.updateValueAndValidity();
        if (this.forgotPasswordForm.valid) {
            const value: Email = this.forgotPasswordForm.value as Email;

            this.loading = true;

            this.forgotPasswordService
                .sendPasswordResetEmail(value['email'])
                .subscribe(
                    () => {
                        this.loading = false;
                        this.completed = false;
                    },
                    () => {
                        this.loading = false;
                        this.completed = false;
                    },
                );
            this.snackBar.openFromComponent(ResetDialogComponent, {
                duration: 3500,
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
        }
    }

    getErrorMessage(): string {
        return this.inputValidationService.getEmailErrorMessage(
            this.forgotPasswordForm.get('email'),
        );
    }
}

@Component({
    selector: 'forgot-password-dialog-component',
    templateUrl: 'forgot-password-dialog-component.html',
    styles: [
        `
            .dialog {
                color: accent;
                font-size: 15px;
            }
        `,
    ],
})
export class ResetDialogComponent {}
