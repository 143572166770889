import { Injectable } from '@angular/core';
import { Permission } from '../models/permission.model';
import { AbstractService } from './abstract-service.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService extends AbstractService {
    path = '/Permission';

    permissionsHas(
        module: string,
        action: string,
        permissions: Permission[],
    ): boolean {
        let permissionToCheck: Permission;
        if (permissions) {
            permissions.forEach((permission) => {
                if (
                    permission.module === module &&
                    permission.action == action
                ) {
                    permissionToCheck = permission;
                }
            });
            if (permissionToCheck) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }
}
