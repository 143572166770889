import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { Client } from 'src/app/models/client.model';
import { ClientService } from 'src/app/services/client.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/user.service';
import { Page } from 'src/app/models/page.model';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

interface IClientFormControls {
    name: FormControl<string | null>;
    salesRepId: FormControl<string | null>;
    signOffFlag: FormControl<boolean>;
    status: FormControl<boolean>;
    nonBillable: FormControl<boolean>;
}

@Component({
    selector: 'app-edit-client',
    templateUrl: './edit-client.component.html',
    styleUrls: ['./edit-client.component.scss'],
    imports: [
        ReactiveFormsModule,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatSelectModule,
        MatOptionModule,
    ],
})
export class EditClientComponent implements OnInit {
    @Input() client: Client = null;
    @Output() loadingClientEdit = new EventEmitter<boolean>();
    @Output() clientChangedEvent = new EventEmitter<boolean>();
    failed = false;
    loading = false;
    salesReps: User[];

    clientForm = new FormGroup<IClientFormControls>({
        name: new FormControl(''),
        salesRepId: new FormControl(''),
        signOffFlag: new FormControl(false),
        status: new FormControl(false),
        nonBillable: new FormControl({ value: false, disabled: true }),
    });

    constructor(
        private clientService: ClientService,
        private navigationService: NavigationService,
        private userService: UserService,
    ) {}
    ngOnInit(): void {
        const params = new Map<string, string | boolean>();
        params.set('status', ACTIVE);
        params.set('count', '-1');
        params.set('isSalesRep', true);
        this.userService.get([], params).subscribe((response: Page<User>) => {
            this.salesReps = response.items;
        });
    }

    initializeForm(client?: Client): void {
        if (client) {
            this.clientForm = new FormGroup({
                name: new FormControl(client.name, [
                    Validators.required.bind(this),
                    Validators.maxLength(100),
                ]),
                salesRepId: new FormControl(client.salesRepId),
                signOffFlag: new FormControl(client.signOffFlag ? true : false),
                status: new FormControl(
                    client.status.toUpperCase() == ACTIVE.toUpperCase()
                        ? true
                        : false,
                ),
                nonBillable: new FormControl({
                    value: client.nonBillable,
                    disabled: true,
                }),
            });
        } else {
            this.clientForm = new FormGroup({
                name: new FormControl(Validators.required.bind(this)),
                signOffFlag: new FormControl(),
                salesRepId: new FormControl(),
                status: new FormControl(),
                nonBillable: new FormControl({ value: false, disabled: true }),
            });
        }
    }

    setEditClient(client: Client): void {
        this.client = client;
        this.initializeForm(client);
    }

    onFormSave(): void {
        this.loading = true;
        this.clientForm.updateValueAndValidity();

        if (!this.clientForm.valid) {
            this.loading = false;
            return;
        } else {
            this.loadingClientEdit.emit(true);
            const client = new Client({
                ...this.clientForm.getRawValue(),
                status: this.clientForm.value.status ? ACTIVE : INACTIVE,
                id: undefined,
                customerId: undefined,
            });

            this.clientService.put([this.client.id], client).subscribe(
                () => {
                    this.clientChangedEvent.emit(true);
                    this.loadingClientEdit.emit(false);
                    this.initializeForm(client);
                    this.loading = false;
                },
                () => {
                    this.loading = false;
                    this.failed = true;
                },
            );
        }
    }

    openProject(): void {
        this.navigationService.Route(
            'projects',
            new Map<string, string>().set('clientId', this.client.id),
        );
    }
}
