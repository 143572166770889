export interface ITaskJson {
    name: string;
    projectId: string;
    id: string;
    userTaskStatus: string;
    clientName: string;
    usersTasksId: string;
    status: string;
    projectName: string;
    nonBillable: boolean;
    approverRequired: boolean;
}

interface ITaskConstructor {
    name: string;
    projectId: string;
    id: string;
    userTaskStatus: string;
    clientName: string;
    usersTasksId: string;
    status: string;
    projectName: string;
    nonBillable: boolean;
    approverRequired: boolean;
}

export interface Task extends ITaskConstructor {
    name: string;
    projectId: string;
    id: string;
    userTaskStatus: string;
    clientName: string;
    usersTasksId: string;
    status: string;
    projectName: string;
    nonBillable: boolean;
    approverRequired: boolean;
}

export class Task {
    constructor(input: ITaskConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: ITaskJson): Task | null {
        if (json == null) {
            return null;
        }

        return new Task({
            ...json,
        });
    }
}
