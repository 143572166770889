<mat-progress-bar
    [appVisible]="loadingRoles"
    mode="indeterminate"
></mat-progress-bar>

<div class="tw-flex tw-my-6 tw-justify-center">
    <div class="tw-flex tw-flex-row tw-justify-between tw-w-3/4">
        <div class="tw-text-2xl">
            Role/Permission Management

            <mat-slide-toggle
                class="tw-ml-2"
                [checked]="isViewingActiveRoles"
                (change)="toggleRolesList()"
            >
                @if (isViewingActiveRoles) {
                    <ng-container>Active</ng-container>
                } @else {
                    <ng-container>Inactive</ng-container>
                }
            </mat-slide-toggle>
        </div>

        <div
            class="tw-flex tw-flex-row tw-gap-2 tw-ml-auto"
            [matTooltip]="
                !rolesPermissions.get(Crud.Delete)
                    ? 'You do not have permission to deactivate roles'
                    : ''
            "
        >
            @if (isViewingActiveRoles) {
                <button
                    mat-raised-button
                    class="m3-error"
                    [disabled]="
                        !isRoleSelected || !rolesPermissions.get(Crud.Delete)
                    "
                    (click)="changeActiveStatus()"
                >
                    Deactivate Role
                </button>
            } @else {
                <button
                    mat-raised-button
                    [disabled]="!isRoleSelected"
                    (click)="changeActiveStatus()"
                >
                    Activate Role
                </button>
            }

            <button
                mat-raised-button
                [disabled]="
                    !isRoleSelected || !rolesPermissions.get(Crud.Update)
                "
                (click)="selectUnselectAllPermissions()"
            >
                {{ !checkAll ? "Select All" : "Unselect All" }}
            </button>

            <button
                mat-raised-button
                [matTooltip]="
                    !rolesPermissions.get(Crud.Update)
                        ? 'You do not have permission to update roles'
                        : ''
                "
                [disabled]="
                    disableSaveChanges || !rolesPermissions.get(Crud.Update)
                "
                (click)="savePermissionChanges()"
            >
                Save Changes
            </button>
        </div>
    </div>
</div>

<div class="tw-flex tw-flex-row tw-justify-center tw-gap-2 tw-items-start">
    <div
        class="tw-flex tw-flex-col tw-box-border tw-p-4 tw-gap-2 tw-border-2 tw-border-solid tw-rounded-m3lg tw-border-customborder tw-w-1/4"
    >
        <div class="tw-flex tw-justify-between">
            <div class="tw-text-2xl">Roles</div>

            <button
                mat-raised-button
                matTooltip="Add Role"
                [matTooltip]="
                    !rolesPermissions.get(Crud.Create)
                        ? 'You do not have permission to create roles'
                        : ''
                "
                [disabled]="!rolesPermissions.get(Crud.Create)"
                (click)="openDialog()"
            >
                + Role
            </button>
        </div>

        <div class="form-group">
            <mat-form-field subscriptSizing="dynamic">
                <input
                    matInput
                    type="text"
                    placeholder="Search Roles..."
                    [formControl]="roleNameFormControl"
                />
            </mat-form-field>
        </div>

        <mat-selection-list
            hideSingleSelectionIndicator
            class="tw-max-h-[70vh] tw-overflow-y-auto"
            [multiple]="false"
            (selectionChange)="onSelectRole($event)"
        >
            @for (role of roles; track role) {
                <mat-list-option
                    [value]="role"
                    [selected]="role.name === selectedRole?.name ?? null"
                >
                    {{ role.name }}
                </mat-list-option>
                <mat-divider></mat-divider>
            }
        </mat-selection-list>

        <mat-paginator
            [pageIndex]="pageIndex"
            [pageSize]="countSize"
            [length]="fullLength"
            [hidePageSize]="true"
            (page)="changePage($event)"
        ></mat-paginator>
    </div>

    <div
        class="tw-w-1/2"
        [ngClass]="{
            'tw-border-2 tw-border-solid tw-border-customborder tw-rounded-lg':
                isRoleSelected
        }"
    >
        @if (isRoleSelected) {
            <mat-progress-bar
                [appVisible]="loadingSelectedRolePermissions"
                mode="indeterminate"
            ></mat-progress-bar>

            <table
                mat-table
                [dataSource]="selectedRolePermissions"
                class="mat-elevation-z8 tw-w-full"
            >
                <ng-container matColumnDef="Module">
                    <th mat-header-cell *matHeaderCellDef>Module</th>
                    <td mat-cell *matCellDef="let permission">
                        {{ permission.module }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Create">
                    <th mat-header-cell *matHeaderCellDef>Create</th>

                    <td mat-cell *matCellDef="let permission">
                        <mat-checkbox
                            [disabled]="!isViewingActiveRoles"
                            [checked]="permission.create || checkAll"
                            (change)="
                                updateSelectedRolePermissions(
                                    permission,
                                    'create'
                                )
                            "
                            class="tw-m-auto tw-w-3/4 tw-p-3"
                        ></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Read">
                    <th mat-header-cell *matHeaderCellDef>Read</th>

                    <td mat-cell *matCellDef="let permission">
                        <mat-checkbox
                            [disabled]="!isViewingActiveRoles"
                            [checked]="permission.read || checkAll"
                            (change)="
                                updateSelectedRolePermissions(
                                    permission,
                                    'read'
                                )
                            "
                            class="tw-m-auto tw-w-3/4 tw-p-3"
                        ></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Update">
                    <th mat-header-cell *matHeaderCellDef>Update</th>

                    <td mat-cell *matCellDef="let permission">
                        <mat-checkbox
                            [disabled]="!isViewingActiveRoles"
                            [checked]="permission.update || checkAll"
                            (change)="
                                updateSelectedRolePermissions(
                                    permission,
                                    'update'
                                )
                            "
                            class="tw-m-auto tw-w-3/4 tw-p-3"
                        ></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Delete">
                    <th mat-header-cell *matHeaderCellDef>Delete</th>

                    <td mat-cell *matCellDef="let permission">
                        <mat-checkbox
                            [disabled]="!isViewingActiveRoles"
                            [checked]="permission.delete || checkAll"
                            (change)="
                                updateSelectedRolePermissions(
                                    permission,
                                    'delete'
                                )
                            "
                            class="tw-m-auto tw-w-3/4 tw-p-3"
                        ></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Approve">
                    <th mat-header-cell *matHeaderCellDef>Approve</th>

                    <td mat-cell *matCellDef="let permission">
                        <mat-checkbox
                            [disabled]="
                                !this.hasApproveForModules.get(
                                    permission.module
                                )
                            "
                            [checked]="
                                permission.approve ||
                                (checkAll &&
                                    this.hasApproveForModules.get(
                                        permission.module
                                    ))
                            "
                            (change)="
                                updateSelectedRolePermissions(
                                    permission,
                                    'approve'
                                )
                            "
                            class="tw-m-auto tw-w-3/4 tw-p-3"
                        ></mat-checkbox>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        }
    </div>
</div>
