import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';
import { Page } from 'src/app/models/page.model';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, NgFor } from '@angular/common';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { Location } from 'src/app/models/location.model';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-wheel-of-lunch',
    templateUrl: './wheel-of-lunch.component.html',
    styleUrls: ['./wheel-of-lunch.component.scss'],
    imports: [MatIconModule, CommonModule, SafePipe, NgFor, MatButtonModule],
})
export class WheelOfLunchComponent implements OnInit {
    url: string;
    page = new Page<Location>();
    queryParams: Map<string, unknown>;
    locationStatus = true;
    cityIcons = {
        Altoona: 'train',
        Dallas: 'star',
        Pittsburgh: 'ac_unit',
        Wilmington: 'beach_access',
    };

    constructor(private locationService: LocationService) {}
    ngOnInit(): void {
        this.getOfficeLocations();
    }

    private getOfficeLocations() {
        this.locationService
            .get([], this.queryParams)
            .subscribe((page: Page<Location>) => {
                this.page = page;
            });
    }

    displayWOL(officeLocation): void {
        const baseUrl = 'https://wheelof.com/lunch/';
        const zipcode = officeLocation.zipCode;
        const query = 'lunch';
        const radius = 5;
        this.url = `${baseUrl}?zip=${zipcode}&query=${query}&radius=${radius}`;
    }

    getCityIcon(city: string): string {
        return this.cityIcons[city] || '';
    }
}
