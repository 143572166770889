import { Task } from './task.model';

export interface IUserJson {
    userLoginId?: string;
    roleId?: string;
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    loginAttempts?: number;
    lastLoginDate?: string;
    status: string;
    id?: string;
    tasks?: Task[];
    managerId: string;
    startDate: string;
    locationId: string;
    employeeTypeId?: string;
    profileImageURL: string;
    is1099: boolean;
    approver: boolean;
    recruiterId?: string;
    isRecruiter: boolean;
    isSalesRep: boolean;
}

interface IUserConstructor {
    userLoginId?: string;
    roleId?: string;
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    loginAttempts?: number;
    lastLoginDate?: string;
    status: string;
    id?: string;
    tasks?: Task[];
    managerId: string;
    startDate: string;
    locationId: string;
    employeeTypeId?: string;
    profileImageURL: string;
    is1099: boolean;
    approver: boolean;
    recruiterId?: string;
    isRecruiter: boolean;
    isSalesRep: boolean;
}

export interface User extends IUserConstructor {
    userLoginId?: string;
    roleId?: string;
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    loginAttempts?: number;
    lastLoginDate?: string;
    status: string;
    id?: string;
    tasks?: Task[];
    managerId: string;
    startDate: string;
    locationId: string;
    employeeTypeId?: string;
    profileImageURL: string;
    is1099: boolean;
    approver: boolean;
    recruiterId?: string;
    isRecruiter: boolean;
    isSalesRep: boolean;
}

export class User {
    constructor(input: IUserConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: IUserConstructor): User | null {
        if (json == null) {
            return null;
        }

        return new User({
            ...json,
        });
    }
}
