@if (userFeedback) {
    <div
        class="tw-flex-1 tw-flex-col tw-border-solid tw-rounded-lg tw-border-2 tw-border-customborder"
    >
        <mat-toolbar
            class="tw-items-center tw-bg-transparent tw-border-b tw-border-customborder tw-border-solid tw-border-0"
        >
            <div
                class="tw-justify-between tw-text-xl tw-flex-col tw-flex tw-w-3/4 tw-mr-2"
            >
                <div [formGroup]="ticketForm">
                    <mat-form-field subscriptSizing="dynamic">
                        <mat-label>Ticket Number</mat-label>
                        <input
                            matInput
                            type="text"
                            maxlength="50"
                            formControlName="ticket"
                            [readonly]="this.userView"
                        />
                    </mat-form-field>
                </div>
            </div>

            <mat-chip
                [ngClass]="{
                    'm3-success': userFeedback.status === 'Processed',
                    'm3-caution': userFeedback.status === 'Updated',
                    'm3-primary': userFeedback.status === 'New'
                }"
                highlighted
                class="tw-ml-auto"
            >
                <div class="tw-min-w-20 tw-text-center">
                    {{ userFeedback.status }}
                </div>
            </mat-chip>
        </mat-toolbar>

        <div class="tw-flex tw-flex-col tw-gap-1">
            <div class="tw-m-3 tw-min-h-20 tw-flex tw-flex-col">
                <div
                    class="tw-m-3 tw-mb-0 tw-text-xl"
                    [innerHTML]="userFeedback.details"
                ></div>
                <div class="tw-flex tw-ml-auto tw-m-3">
                    <div>- {{ userFeedback.submittedByName }}</div>
                </div>
            </div>

            <div class="tw-mt-auto tw-m-3">
                <button
                    mat-raised-button
                    class="tw-text-l"
                    (click)="addComment()"
                    [disabled]="
                        statusForm.controls.processed.value ||
                        statusForm.controls.duplicate.value
                    "
                >
                    <mat-icon>add</mat-icon>
                    Comment
                </button>
            </div>
            <div class="tw-gap-2 tw-flex tw-flex-col tw-m-3">
                @for (comment of page.items; track comment) {
                    <div
                        class="tw-border-solid tw-rounded-lg tw-border-2 tw-border-customborder"
                    >
                        <div class="tw-m-2">{{ comment.text }}</div>
                        <div class="tw-m-2 tw-flex tw-justify-end">
                            - {{ comment.submittedByName }}
                        </div>
                    </div>
                }
            </div>
            @if (!userView) {
                <form
                    [formGroup]="statusForm"
                    class="tw-flex tw-flex-row tw-ml-auto tw-m-3 tw-gap-3"
                >
                    <label>
                        <input
                            type="checkbox"
                            formControlName="processed"
                            (change)="onCheckboxChange('Processed')"
                        />
                        Mark as Processed
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            formControlName="duplicate"
                            (change)="onCheckboxChange('Duplicate')"
                        />
                        Mark as Duplicate
                    </label>
                </form>
            }
        </div>
    </div>
}
