<div class="tw-mt-1 tw-m-auto tw-p-5 tw-w-3/4">
    <div
        class="tw-flex tw-items-baseline tw-bg-m3SurfaceContainer tw-box-border tw-rounded-m3lg tw-gap-5 tw-p-4"
    >
        <h3>Filters:</h3>
        <form
            [formGroup]="filterForm"
            class="tw-flex tw-flex-row tw-gap-5 tw-mb-0"
        >
            <mat-form-field>
                <mat-label>Office locations</mat-label>
                <mat-select formcontrolName="location">
                    <mat-option
                        *ngFor="let location of officeLocations"
                        [value]="location.Id"
                        (onSelectionChange)="
                            locationFilterChange(location.Id, $event)
                        "
                    >
                        {{ location.Name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Search Users...</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="search"
                    [matAutocomplete]="auto"
                />
            </mat-form-field>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                    *ngFor="let name of suggestNameArr"
                    [value]="name.firstName + ' ' + name.lastName"
                    (onSelectionChange)="
                        userSelectionChange(
                            name.firstName + ' ' + name.lastName
                        )
                    "
                >
                    {{ name.firstName + " " + name.lastName }}
                </mat-option>
            </mat-autocomplete>
        </form>
        <button
            mat-raised-button
            matTooltip="Clear Filters"
            (click)="resetUsers()"
        >
            Clear
        </button>
    </div>
    <div class="mat-elevation-z8">
        <table
            mat-table
            class="mat-elevation-z8 tw-w-full"
            [dataSource]="dataSource"
            matSort
        >
            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.firstName + " " + element.lastName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>
            <ng-container matColumnDef="locationName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Office Locations
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.locationName }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
            [pageIndex]="pageIndex"
            [pageSize]="Employees.countRequested"
            [length]="Employees.totalCount"
            [hidePageSize]="true"
            (page)="changePage($event)"
        ></mat-paginator>
    </div>
</div>
