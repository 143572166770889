import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-footer',
    templateUrl: './dialog-footer.component.html',
    styleUrl: './dialog-footer.component.scss',
    standalone: true,
    imports: [MatDialogActions, MatButtonModule],
})
export class DialogFooterComponent {
    @Input() posButtonText = 'Save';
    @Input() closeButtonText = 'Cancel';
    @Input() disabled: boolean;
    @Output() buttonClick = new EventEmitter();
    @Output() closeClick = new EventEmitter();
}
