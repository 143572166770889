import { Permission } from './permission.model';

export interface IMeJson {
    id: string;
    userLoginId: string;
    rolesId: string;
    firstName: string;
    lastName: string;
    email: string;
    status: string;
    employeeLevel: number;
    managerId: string;
    startDate: string;
    tasks: Task[];
    permissions: Permission[];
    ptoBalance: number;
    theme: string;
}

interface IMeConstructor {
    id: string;
    userLoginId: string;
    rolesId: string;
    firstName: string;
    lastName: string;
    email: string;
    status: string;
    employeeLevel: number;
    managerId: string;
    startDate: string;
    tasks: Task[];
    permissions: Permission[];
    ptoBalance: number;
    theme: string;
}

export interface Me extends IMeConstructor {
    id: string;
    userLoginId: string;
    rolesId: string;
    firstName: string;
    lastName: string;
    email: string;
    status: string;
    employeeLevel: number;
    managerId: string;
    startDate: string;
    tasks: Task[];
    permissions: Permission[];
    ptoBalance: number;
    theme: string;
}

export class Me {
    constructor(input?: IMeConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: IMeJson): Me | null {
        if (json == null) {
            return null;
        }

        return new Me({
            ...json,
            tasks: json.tasks.map((task) => ({ ...task }) as Task),
            permissions: json.permissions.map(
                (permission) => ({ ...permission }) as Permission,
            ),
        });
    }
}
