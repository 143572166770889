<app-dialog-header [title]="data.title"></app-dialog-header>
<div mat-dialog-content>
    <form [formGroup]="approversForm">
        <mat-chip-set #chipList *ngIf="taskApprovers.length > 0">
            <mat-chip
                *ngFor="let approver of taskApprovers"
                (removed)="deleteApprover(approver)"
                highlighted
            >
                {{ approver.firstName + " " + approver.lastName }}

                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-set>

        <mat-form-field appearance="fill" class="tw-w-full tw-pt-1">
            <mat-label>Add approvers...</mat-label>
            <input matInput formControlName="approverSearch" />
        </mat-form-field>

        <mat-nav-list class="tw-max-h-16 tw-overflow-y-auto">
            <ng-container *ngFor="let approver of filteredApprovers">
                <a mat-list-item (click)="addApprover(approver)">
                    {{ approver.firstName + " " + approver.lastName }}
                </a>
            </ng-container>
        </mat-nav-list>
    </form>
</div>

<app-dialog-footer
    [disabled]="saving"
    (buttonClick)="onSaveClick()"
    (closeClick)="closeDialog()"
></app-dialog-footer>
