import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { Project } from 'src/app/models/project-model';
import { WorkOrder } from 'src/app/models/work-order.model';
import { WorkOrderService } from 'src/app/services/work-order.service';
import { PastDateValidator } from 'src/app/validators/past-date.validator';
import { ClientService } from 'src/app/services/client.service';
import { Page } from 'src/app/models/page.model';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { AddClientComponent } from '../../clients/add-client/add-client.component';
import { AddProjectComponent } from '../../projects/add-project/add-project.component';
import { ProjectsService } from 'src/app/services/projects.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import {
    ErrorSnackbarComponent,
    ErrorSnackbarProps,
} from '../../snackbars/error-snackbar/error-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VisibleDirective } from '../../../directives/visible.directive';
import { WorkOrderNote } from 'src/app/models/work-order-notes';
import { WorkOrderNoteService } from 'src/app/services/work-order-note.service';
import { AppStateService } from 'src/app/services/app-state-service';
import { MatCardModule } from '@angular/material/card';

interface IWorkOrderFormControls {
    id: FormControl<string>;
    name: FormControl<string>;
    clientId: FormControl<string>;
    projectId: FormControl<string>;
    contactName: FormControl<string>;
    streetAddress: FormControl<string>;
    location: FormControl<string>;
    phone: FormControl<string>;
    email: FormControl<string>;
    consultantName: FormControl<string>;
    consultantRate: FormControl<string>;
    consultantPosition: FormControl<string>;
    billableExpense: FormControl<string>;
    startDate: FormControl<Date>;
    endDate: FormControl<Date>;
    completedDate: FormControl<Date>;
    createDate: FormControl<Date>;
    signed: FormControl<boolean>;
    active: FormControl<boolean>;
    notesSelection: FormControl<string>;
    notes: FormControl<string>;
}

@Component({
    selector: 'app-open-work-order',
    templateUrl: './open-work-order.component.html',
    styleUrls: ['./open-work-order.component.scss'],
    imports: [
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatTooltipModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatDatepickerModule,
        DatePipe,
        MatCheckboxModule,
        VisibleDirective,
        MatCardModule,
    ]
})
export class OpenWorkOrderComponent implements OnInit {
    workOrder: WorkOrder;
    clients: Client[];
    projects: Project[];
    filteredProjects: Project[];
    workOrderNotes: WorkOrderNote[];
    loading: boolean;
    creating: boolean;
    pdfLoading: boolean;

    address = {
        streetAddress: '',
        location: '',
    };

    checkForm = new FormGroup({
        startDateTBD: new FormControl(false),
        endDateTBD: new FormControl(false),
        populateNames: new FormControl(false),
    });

    workOrderForm: FormGroup<IWorkOrderFormControls> = new FormGroup(
        {
            id: new FormControl(null),
            name: new FormControl(null, [
                Validators.required.bind(this),
                Validators.maxLength(150),
            ]),
            clientId: new FormControl(null, [Validators.required.bind(this)]),
            contactName: new FormControl(null, [
                Validators.required.bind(this),
                Validators.maxLength(100),
            ]),
            streetAddress: new FormControl(null, [
                Validators.required.bind(this),
                Validators.maxLength(50),
            ]),
            location: new FormControl(null, [
                Validators.required.bind(this),
                Validators.maxLength(50),
            ]),
            phone: new FormControl(null, [
                Validators.required.bind(this),
                Validators.maxLength(50),
            ]),
            email: new FormControl(null, [
                Validators.required.bind(this),
                Validators.maxLength(150),
            ]),
            consultantName: new FormControl(null, [
                Validators.required.bind(this),
                Validators.maxLength(100),
            ]),
            consultantPosition: new FormControl(null, [
                Validators.required.bind(this),
                Validators.maxLength(100),
            ]),
            consultantRate: new FormControl(null, [
                Validators.required.bind(this),
                Validators.maxLength(50),
            ]),
            billableExpense: new FormControl(null, [
                Validators.required.bind(this),
                Validators.maxLength(50),
            ]),
            startDate: new FormControl(null),
            endDate: new FormControl(null),
            projectId: new FormControl(null, [Validators.required.bind(this)]),
            completedDate: new FormControl(null),
            createDate: new FormControl(null),
            signed: new FormControl(null),
            active: new FormControl(null),
            notesSelection: new FormControl(null),
            notes: new FormControl(null),
        },
        [PastDateValidator],
    );

    constructor(
        private route: ActivatedRoute,
        private workOrderService: WorkOrderService,
        private clientService: ClientService,
        private projectsService: ProjectsService,
        private dialogService: MatDialog,
        private navigationService: NavigationService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private router: Router,
        private workOrderNoteService: WorkOrderNoteService,
        private appStateService: AppStateService,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params['workOrderId']) {
                this.loading = true;
                this.workOrderService
                    .get([params['workOrderId']])
                    .subscribe((response: WorkOrder) => {
                        this.workOrder = response;
                        this.address = JSON.parse(this.workOrder.address);

                        this.checkCreating(params['creating'] === 'true');
                        this.initializeForm();

                        if (this.workOrder.signed) {
                            this.workOrderForm.disable();
                        }

                        if (!this.workOrder.active) {
                            this.workOrderForm.controls.active.enable();
                        }

                        if (!this.workOrder.startDate) {
                            this.checkForm.controls.startDateTBD.setValue(true);
                        }

                        if (!this.workOrder.endDate) {
                            this.checkForm.controls.endDateTBD.setValue(true);
                        }

                        this.loading = false;
                    });
            } else {
                this.workOrder = this.buildWorkOrder();
                this.checkCreating(params['creating'] === 'true');
                this.initializeForm();
            }
        });

        this.checkForm.controls.startDateTBD.valueChanges.subscribe((value) => {
            if (value) {
                this.workOrderForm.controls.startDate.clearValidators();
                this.workOrderForm.controls.startDate.updateValueAndValidity();
            } else {
                this.workOrderForm.controls.startDate.setValidators([
                    Validators.required,
                    PastDateValidator,
                ]);
                this.workOrderForm.controls.startDate.updateValueAndValidity();
            }
        });

        this.checkForm.controls.endDateTBD.valueChanges.subscribe((value) => {
            if (value) {
                this.workOrderForm.controls.endDate.setValue(null);
                this.workOrderForm.controls.endDate.clearValidators();
                this.workOrderForm.get('endDate').updateValueAndValidity();
            } else {
                this.workOrderForm.controls.endDate.setValidators([
                    Validators.required,
                    PastDateValidator,
                ]);
                this.workOrderForm.controls.endDate.updateValueAndValidity();
            }
        });

        this.getClients();
        this.getProjects();
        this.getWorkOrderNotes();
    }

    checkCreating(creating: boolean) {
        this.creating = creating;

        if (this.creating) {
            this.workOrder.active = false;
            this.workOrder.signed = false;
            this.workOrder.completedDate = null;
            this.workOrder.createDate = null;
        }
    }

    onClientSelectionChange(event: MatSelectChange): void {
        if (event.value === '') {
            const map = new Map();
            map.set('count', -1);

            this.clientService
                .get([], map)
                .subscribe((response: Page<Client>) => {
                    const dialogRef = this.dialogService.open(
                        AddClientComponent,
                        { disableClose: true, data: response.items },
                    );

                    dialogRef.afterClosed().subscribe((dialogResult) => {
                        if (dialogResult.success) {
                            this.getClients();
                            this.workOrderForm.controls.clientId.setValue(
                                dialogResult.clientId.id,
                            );
                        }
                    });
                });
        }

        this.filterProjects();

        const associatedProject = this.filteredProjects.find(
            (project) =>
                project.clientId === this.workOrderForm.controls.clientId.value,
        );

        if (associatedProject) {
            this.workOrderForm.controls.projectId.setValue(
                associatedProject.id,
            );
        } else {
            this.workOrderForm.controls.projectId.setValue(null);
        }
    }

    filterProjects(): void {
        this.filteredProjects = this.projects.filter(
            (p) => p.clientId == this.workOrderForm.controls.clientId.value,
        );
    }

    onProjectSelectionChange(event: MatSelectChange): void {
        if (event.value === '') {
            const map = new Map();
            map.set('count', -1);

            this.clientService.get([], map).subscribe(() => {
                const dialogRef = this.dialogService.open(AddProjectComponent, {
                    disableClose: true,
                    data: this.workOrderForm.controls.clientId.value,
                });

                dialogRef.afterClosed().subscribe((dialogResult) => {
                    if (dialogResult.success) {
                        this.getProjects();
                        this.workOrderForm.controls.projectId.setValue(
                            dialogResult.projectId.id,
                        );
                    }
                });
            });
        }
    }

    close(): void {
        const dialog = this.dialog.open(ConfirmationDialogComponent, {
            data: 'Are you sure you want to close this work order? \nWarning: All unsaved changes will be lost.',
        });

        dialog.afterClosed().subscribe((response: boolean) => {
            if (response === true) {
                this.navigationService.Route('workorders');
            }
        });
    }

    save(): boolean {
        this.workOrderForm.updateValueAndValidity();

        if (
            this.workOrderForm.valid ||
            this.workOrderForm.controls.active.disabled
        ) {
            this.loading = true;
            this.address.location = this.workOrderForm.controls.location.value;
            this.address.streetAddress =
                this.workOrderForm.controls.streetAddress.value;

            const workOrder = this.buildWorkOrder();
            workOrder.consultantRate = workOrder.consultantRate.toString();
            workOrder.billableExpense = workOrder.billableExpense.toString();
            workOrder.address = JSON.stringify(this.address);

            if (this.creating) {
                workOrder.id = null;
                workOrder.active = false;

                this.workOrderService.post([], workOrder).subscribe({
                    next: (response) => {
                        this.workOrderForm.patchValue(response);

                        this.workOrderForm.controls.createDate.setValue(
                            new Date(),
                        );
                        this.loading = false;
                        this.creating = false;
                        this.updateUrl();
                    },
                });
            } else {
                workOrder.createDate =
                    this.workOrderForm.controls.createDate.value;

                this.workOrderService
                    .put([workOrder.id], workOrder)
                    .subscribe(() => {
                        this.loading = false;
                    });
            }

            return true;
        } else {
            Object.values(this.workOrderForm.controls).forEach((control) => {
                control.markAsTouched();
            });

            return false;
        }
    }

    initializeForm(workOrder?: WorkOrder): void {
        if (workOrder) {
            this.workOrderForm.patchValue(workOrder);
            this.workOrderForm.controls.streetAddress.setValue(
                this.address.streetAddress,
            );
            this.workOrderForm.controls.location.setValue(
                this.address.location,
            );
        } else if (this.workOrder) {
            this.workOrderForm.controls.streetAddress.setValue(
                this.address.streetAddress,
            );
            this.workOrderForm.controls.location.setValue(
                this.address.location,
            );
            this.workOrderForm.patchValue(this.workOrder);
        } else {
            this.workOrderForm.setValue({
                id: null,
                name: null,
                clientId: null,
                contactName: null,
                streetAddress: null,
                location: null,
                phone: null,
                email: null,
                consultantName: null,
                consultantPosition: null,
                consultantRate: null,
                billableExpense: null,
                startDate: null,
                endDate: null,
                projectId: null,
                completedDate: null,
                signed: false,
                active: false,
                createDate: null,
                notesSelection: null,
                notes: null,
            });
        }

        if (this.creating) {
            this.workOrderForm.controls.id.setValue('');
        }
    }

    buildWorkOrder(): WorkOrder {
        const workOrder: WorkOrder = {
            id: this.workOrderForm.controls.id.value,
            name: this.workOrderForm.controls.name.value,
            clientId: this.workOrderForm.controls.clientId.value,
            contactName: this.workOrderForm.controls.contactName.value,
            address: JSON.stringify(this.address),
            phone: this.workOrderForm.controls.phone.value,
            email: this.workOrderForm.controls.email.value,
            consultantName: this.workOrderForm.controls.consultantName.value,
            consultantPosition:
                this.workOrderForm.controls.consultantPosition.value,
            consultantRate: this.workOrderForm.controls.consultantRate.value,
            billableExpense: this.workOrderForm.controls.billableExpense.value,
            startDate: this.workOrderForm.controls.startDate.value,
            endDate: this.workOrderForm.controls.endDate.value,
            projectId: this.workOrderForm.controls.projectId.value,
            completedDate: this.workOrderForm.controls.completedDate.value,
            signed: this.workOrderForm.controls.signed.value,
            active: this.workOrderForm.controls.active.value,
            notes: this.workOrderForm.controls.notes.value,
            userName: null,
        };

        if (this.checkForm.controls.startDateTBD.value) {
            workOrder.startDate = null;
        }

        if (this.checkForm.controls.endDateTBD.value) {
            workOrder.endDate = null;
        }

        if (this.checkForm.controls.populateNames.value) {
            workOrder.userName =
                this.appStateService.me$.value.firstName +
                ' ' +
                this.appStateService.me$.value.lastName;
        }

        return workOrder;
    }

    export() {
        if (this.save()) {
            this.pdfLoading = true;
            const workOrder = this.buildWorkOrder();
            workOrder.consultantRate = workOrder.consultantRate.toString();
            workOrder.billableExpense = workOrder.billableExpense.toString();

            if (this.creating) {
                workOrder.id = null;
                workOrder.createDate = new Date();
            } else {
                workOrder.createDate =
                    this.workOrderForm.controls.createDate.value;
            }

            var queryParams = new Map<string, any>();
            queryParams.set('workOrder', JSON.stringify(workOrder));

            this.workOrderService
                .exportReport(['export'], workOrder)
                .subscribe({
                    next: (buffer: Blob) => {
                        let mimeType = 'application/pdf';

                        const data: Blob = new Blob([buffer], {
                            type: mimeType,
                        });

                        const url = window.URL.createObjectURL(data);
                        window.open(url, '_blank');

                        this.pdfLoading = false;
                    },
                    error: () => {
                        this.pdfLoading = false;
                        this.showErrorSnackbar(
                            'There was an error retrieving the file from the server.',
                        );
                    },
                });
        }
    }

    sign() {
        const dialog = this.dialog.open(ConfirmationDialogComponent, {
            data: 'Are you sure you want to sign this work order? \nWarning: A signed work order is uneditable.',
        });

        dialog.afterClosed().subscribe((response: boolean) => {
            if (response === true) {
                this.workOrderForm.controls.signed.setValue(true);
                this.workOrderForm.controls.completedDate.setValue(new Date());

                if (this.save() && !this.workOrderForm.controls.active.value) {
                    this.workOrderForm.disable();
                    this.workOrderForm.controls.active.enable();
                } else if (this.save()) {
                    this.workOrderForm.disable();
                } else {
                    this.workOrderForm.controls.signed.setValue(false);
                    this.workOrderForm.controls.completedDate.setValue(null);
                }
            } else {
                this.workOrderForm.controls.signed.setValue(false);
            }
        });
    }

    showErrorSnackbar(message: string): void {
        const snackbarData: ErrorSnackbarProps = {
            message: message,
        };

        this.snackbar.openFromComponent(ErrorSnackbarComponent, {
            data: snackbarData,
            duration: 2000,
        });
    }

    activeChange() {
        this.workOrderForm.updateValueAndValidity();

        if (this.workOrderForm.valid) {
            this.workOrderForm.controls.active.setValue(
                this.workOrderForm.controls.active.value,
            );

            if (this.workOrderForm.controls.signed.value) {
                this.workOrderForm.disable();
            }
        } else {
            this.workOrderForm.controls.active.setValue(
                !this.workOrderForm.controls.active.value,
            );
        }
    }

    private getClients(): void {
        var queryParams = new Map<string, any>();
        queryParams.set('count', -1);

        this.clientService
            .get([], queryParams)
            .subscribe((response: Page<Client>) => {
                this.clients = response.items;
            });
    }

    private getProjects(): void {
        var queryParams = new Map<string, any>();
        queryParams.set('count', -1);

        this.projectsService
            .get([], queryParams)
            .subscribe((response: Page<Project>) => {
                this.projects = response.items;
                this.filterProjects();
            });
    }

    updateUrl(): void {
        void this.router.navigate([]);

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                workOrderId: this.workOrderForm.controls.id.value,
                creating: this.creating,
            },
        });
    }

    getWorkOrderNotes(): void {
        this.workOrderNoteService
            .get([])
            .subscribe((response: WorkOrderNote[]) => {
                this.workOrderNotes = response;
            });
    }

    onNoteSelectionChange(event): void {
        this.workOrderForm.controls.notes.setValue(event.value.body);
    }
}
