export interface IUserSettingsJson {
    theme: string;
}

interface IUserSettingsConstructor {
    theme: string;
}

export interface IUserSettings extends IUserSettingsConstructor {
    theme: string;
}

export class UserSettings {
    theme: string;

    constructor(input?: IUserSettings) {
        Object.assign(this, input);
    }

    static fromJson(json: IUserSettingsJson): UserSettings | null {
        if (json == null) {
            return null;
        }

        return new UserSettings();
    }
}
