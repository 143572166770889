<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingProjects"
></mat-progress-bar>

<form [formGroup]="searchForm">
    <div
        class="tw-grid tw-grid-flow-row md:tw-grid-cols-10 tw-items-start tw-gap-4 tw-m-4"
    >
        <mat-card
            class="tw-flex tw-flex-col tw-gap-2 tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-3 lg:tw-col-start-2 2xl:tw-col-start-2 tw-min-w-fit"
        >
            <mat-card-content class="tw-grid tw-grid-flow-row tw-gap-2">
                <div class="tw-flex tw-justify-between">
                    <mat-form-field appearance="fill">
                        <mat-label>Select Client</mat-label>
                        <mat-select
                            formControlName="clientId"
                            (valueChange)="clientChange()"
                        >
                            <mat-option
                                *ngFor="let client of clients"
                                [value]="client.id"
                            >
                                {{ client.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button
                        class="tw-mt-2"
                        *ngIf="searchForm.controls.clientId.value"
                        mat-raised-button
                        (click)="
                            onClientRoute(searchForm.controls.clientId.value)
                        "
                    >
                        View Client Details
                    </button>
                </div>

                <div>
                    <mat-form-field subscriptSizing="dynamic" class="tw-w-full">
                        <mat-label>Search Projects</mat-label>
                        <input matInput type="text" formControlName="search" />
                    </mat-form-field>
                </div>

                <div class="tw-flex tw-justify-between">
                    <mat-slide-toggle
                        class="tw-content-center"
                        (change)="toggleActiveInactive()"
                        [checked]="projectStatus"
                    >
                        <div *ngIf="projectStatus; else elseBlock">Active</div>
                        <ng-template #elseBlock>Inactive</ng-template>
                    </mat-slide-toggle>
                    <button
                        mat-raised-button
                        matTooltip="Add Project"
                        (click)="openAddDialog()"
                    >
                        <mat-icon>add</mat-icon>
                        Project
                    </button>
                </div>

                <mat-selection-list
                    #projectSelectionList
                    hideSingleSelectionIndicator
                    class="scrollable"
                    multiple="false"
                    (selectionChange)="projectSelectionChange($event)"
                >
                    @for (project of page.items; track project) {
                        <mat-list-option [value]="project">
                            {{ project.name }}
                        </mat-list-option>
                        @if (!$last) {
                            <mat-divider></mat-divider>
                        }
                    }
                </mat-selection-list>
            </mat-card-content>
            <mat-card-actions class="tw-ml-auto">
                <mat-paginator
                    class="tw-bg-transparent"
                    [pageIndex]="pageIndex"
                    [pageSize]="page.countRequested"
                    [length]="page.totalCount"
                    [hidePageSize]="true"
                    (page)="changePage($event)"
                ></mat-paginator>
            </mat-card-actions>
        </mat-card>

        <div
            class="tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-5 tw-min-w-fit"
        >
            <app-edit-project
                [hidden]="!projectSelected"
                (projectChangeEvent)="projectChange($event)"
                (loadingProjectEdit)="onLoadingProjectEdit($event)"
            ></app-edit-project>
        </div>
    </div>
</form>
