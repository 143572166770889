<app-dialog-header [title]="'Add Task'"></app-dialog-header>

<form
    [formGroup]="addUserTaskForm"
    class="tw-flex tw-flex-col"
    mat-dialog-content
>
    <mat-form-field appearance="fill">
        <mat-label>Client</mat-label>
        <mat-select
            [disabled]="!user || !this.isClients"
            formControlName="clientId"
            (selectionChange)="updateProjects()"
        >
            <mat-option *ngFor="let client of clients" [value]="client.id">
                {{ client.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field
        appearance="fill"
        *ngIf="this.addUserTaskForm.get('clientId').value"
    >
        <mat-label>Project</mat-label>
        <mat-select
            formControlName="projectId"
            (selectionChange)="updateTasks()"
        >
            <mat-option *ngFor="let project of projects" [value]="project.id">
                {{ project.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field
        appearance="fill"
        *ngIf="this.addUserTaskForm.get('projectId').value"
    >
        <mat-label>Task</mat-label>
        <mat-select formControlName="taskId">
            <mat-option *ngFor="let task of tasks" [value]="task">
                {{ task.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="tw-flex tw-flex-row tw-justify-between" *ngIf="!this.isClients">
        <h4 class="error">No clients to add</h4>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-between">
        <button mat-raised-button class="m3-error" (click)="onResetClick()">
            Reset
        </button>
        <button
            mat-raised-button
            [disabled]="!this.addUserTaskForm.get('taskId').value"
            (click)="saveTask()"
        >
            Save
        </button>
    </div>
</form>
