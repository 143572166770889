<div
    class="tw-w-full grid tw-text-center tw-h-20 tw-items-center tw-justify-items-center"
    [class]="darkMode ? 'dark-themed-background' : 'light-themed-background'"
>
    <div style="grid-column: span 2">Daily Totals</div>

    <div>
        Monday
        <mat-divider></mat-divider>
        {{ weeklyTotalsFormGroup.controls.mondayTotal.value }}
    </div>

    <div>
        Tuesday
        <mat-divider></mat-divider>
        {{ weeklyTotalsFormGroup.controls.tuesdayTotal.value }}
    </div>

    <div>
        Wednesday
        <mat-divider></mat-divider>
        {{ weeklyTotalsFormGroup.controls.wednesdayTotal.value }}
    </div>

    <div>
        Thursday
        <mat-divider></mat-divider>
        {{ weeklyTotalsFormGroup.controls.thursdayTotal.value }}
    </div>

    <div>
        Friday
        <mat-divider></mat-divider>
        {{ weeklyTotalsFormGroup.controls.fridayTotal.value }}
    </div>

    <div>
        Saturday
        <mat-divider></mat-divider>
        {{ weeklyTotalsFormGroup.controls.saturdayTotal.value }}
    </div>

    <div>
        Sunday
        <mat-divider></mat-divider>
        {{ weeklyTotalsFormGroup.controls.sundayTotal.value }}
    </div>

    <div>
        Week Total
        <mat-divider></mat-divider>
        {{ weeklyTotalsFormGroup.controls.weekTotal.value }}
    </div>
</div>
