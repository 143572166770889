import { NgIf, NgFor, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Page } from 'src/app/models/page.model';
import { TimeSheet } from 'src/app/models/timesheet.model';
import { User } from 'src/app/models/User';
import { ITimesheetFormControls } from 'src/app/interfaces/timesheets';
import { MatChipsModule } from '@angular/material/chips';
import { EditSubmissionComponent } from './edit-submissions/edit-submissions.component';
import { MeService } from 'src/app/services/me.service';
import { VisibleDirective } from '../../directives/visible.directive';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-submissions',
    imports: [
        ReactiveFormsModule,
        MatSlideToggleModule,
        NgIf,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatButtonModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        NgClass,
        MatChipsModule,
        EditSubmissionComponent,
        MatTooltipModule,
        VisibleDirective,
        MatCardModule,
    ],
    templateUrl: './submissions.component.html',
    styleUrl: './submissions.component.scss',
})
export class SubmissionsComponent implements OnInit {
    constructor(
        private meService: MeService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {}

    searchForm = new FormGroup({
        userSearch: new FormControl<string>(null),
        statusSearch: new FormControl<string>(null),
    });

    page = new Page<TimeSheet>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    pageIndex: number = 0;
    timesheetSelected: boolean = false;
    loadingTimesheets: boolean = false;
    selectedTimesheet: TimeSheet = null;

    users: User[];
    queryParams = new Map<string, number | string>();
    approverStatuses: string[] = ['New', 'Approved', 'Denied', 'Updated'];
    timesheetForms: FormGroup<ITimesheetFormControls>[] = [];

    ngOnInit(): void {
        this.meService
            .get(['users-approvable'])
            .subscribe((response: Page<User>) => {
                this.users = response.items;
            });

        this.searchForm.controls.statusSearch.setValue(
            this.approverStatuses[0],
        );

        this.activatedRoute.queryParams.subscribe((params) => {
            if (params['id']) {
                this.queryParams.set('id', params['id'] as string);
            } else {
                this.queryParams.delete('id');
            }
        });

        this.loadingTimesheets = true;
        this.populateFilters();
        this.setQueryParams();
    }

    protected getTimesheetsApprovable(): void {
        this.meService
            .get(['timesheets-approvable'], this.queryParams)
            .subscribe((response: Page<TimeSheet>) => {
                this.page = response;
                this.activatedRoute.queryParams.subscribe((params) => {
                    if (params['id']) {
                        this.selectedTimesheet = this.page.items.find(
                            (item) => item.id === params['id'],
                        );
                    }
                });
                this.loadingTimesheets = false;
            });
    }

    setQueryParams(): void {
        this.queryParams.set('count', 8);

        if (
            this.searchForm.controls.userSearch.value &&
            this.searchForm.controls.userSearch.value !== ''
        ) {
            this.queryParams.set(
                'userId',
                this.searchForm.controls.userSearch.value,
            );
        } else {
            this.queryParams.delete('userId');
        }

        if (this.pageIndex) {
            this.queryParams.set('offset', this.pageIndex * 10);
        } else {
            this.queryParams.set('offset', 0);
        }

        if (
            this.searchForm.controls.statusSearch.value &&
            this.searchForm.controls.statusSearch.value !== ''
        ) {
            this.queryParams.set(
                'approvalState',
                this.searchForm.controls.statusSearch.value,
            );
        } else {
            this.queryParams.delete('approvalState');
        }

        this.updateUrl();
        this.getTimesheetsApprovable();
    }

    private populateFilters(): void {
        const params: Params = this.activatedRoute.snapshot.queryParams;

        if ('status' in params) {
            const status = (params['status'] as string).toUpperCase();

            const matchingStatus = this.approverStatuses.find(
                (s) => s.toUpperCase() === status,
            );

            if (matchingStatus) {
                this.searchForm.controls.statusSearch.setValue(matchingStatus);
            }
        }

        if ('userId' in params) {
            this.searchForm.controls.userSearch.setValue(
                params['userId'] as string,
            );
        }
    }

    private updateUrl(): void {
        const queryParams: Params = {};

        const status = this.searchForm.controls.statusSearch.value;
        if (status) {
            queryParams['status'] = status;
        }

        const userId = this.searchForm.controls.userSearch.value;
        if (userId) {
            queryParams['userId'] = userId;
        }

        if (this.selectedTimesheet) {
            const timesheetId = this.selectedTimesheet.id;
            if (timesheetId) {
                queryParams['id'] = timesheetId;
            }
        } else if ('id' in this.activatedRoute.snapshot.queryParams) {
            queryParams['id'] = this.activatedRoute.snapshot.queryParams[
                'id'
            ] as string;
        }

        void this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
        });
    }

    timesheetSelectionChange(event: MatSelectionListChange): void {
        this.selectedTimesheet = event.options[0].value as TimeSheet;

        void this.router.navigate([], {
            queryParams: {
                id: this.selectedTimesheet.id,
            },
            queryParamsHandling: 'merge',
        });
    }

    changePage(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.setQueryParams();
    }

    timesheetChange(event): void {
        if (event === null) {
            this.timesheetSelected = false;
        }
    }
}
