import { Component } from '@angular/core';
import { UserFeedbackComponent } from '../user-feedback/user-feedback.component';

@Component({
    selector: 'app-my-feedback',
    imports: [UserFeedbackComponent],
    templateUrl: './my-feedback.component.html',
    styleUrl: './my-feedback.component.scss'
})
export class MyFeedbackComponent {}
