import { Injectable } from '@angular/core';
import { AbstractService } from './abstract-service.service';

@Injectable({
    providedIn: 'root',
})
export class LoginService extends AbstractService {
    path = '/login';

    logoutUser(): void {
        window.sessionStorage.removeItem('PSSUserAuthToken');
    }
}
