<div mat-dialog-actions align="end" class="tw-gap-2">
    <button
        mat-button
        [disabled]="disabled"
        (click)="closeClick.emit()"
        class="tw-text-base"
    >
        {{ closeButtonText }}
    </button>
    <button
        mat-button
        [disabled]="disabled"
        (click)="buttonClick.emit()"
        class="tw-text-base"
    >
        {{ posButtonText }}
    </button>
</div>
