interface ILocationJson {
    id?: string;
    city: string;
    state: string;
    zipCode: string;
    name: string;
    status: string;
}

interface ILocationConstructor {
    id?: string;
    city: string;
    state: string;
    zipCode: string;
    name: string;
    status: string;
}

export interface Location extends ILocationConstructor {
    id?: string;
    city: string;
    state: string;
    zipCode: string;
    name: string;
    status: string;
}

export class Location {
    constructor(input: ILocationJson) {
        Object.assign(this, input);
    }

    static fromJson(json: ILocationJson): Location | null {
        if (json == null) {
            return null;
        }

        return new Location({
            ...json,
        });
    }
}
