import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractService } from './abstract-service.service';
import { HttpClient } from '@angular/common/http';
import { ACTIVE } from '../constants/statusConstant';

@Injectable({
    providedIn: 'root',
})
export class NavigationService extends AbstractService {
    path = '/navigation';

    constructor(
        private router: Router,
        protected httpClient: HttpClient,
    ) {
        super(httpClient);
    }

    Route(route: string, params?: Map<string, string>): void {
        if (route == 'admin' || route == 'role') {
            void this.router.navigate([route], {
                queryParams: {
                    count: 10,
                    offset: 0,
                    status: ACTIVE.toLowerCase(),
                },
            });
        } else if (route == 'projects') {
            if (params != null) {
                if (params.has('clientId')) {
                    void this.router.navigate([route], {
                        queryParams: { clientId: params.get('clientId') },
                    });
                }
            } else {
                void this.router.navigate([route]);
            }
        } else {
            void this.router.navigate([route]);
        }
    }
}
