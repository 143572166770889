import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from './abstract-service.service';

@Injectable({
    providedIn: 'root',
})
export class ForgotPasswordService extends AbstractService {
    path = '/login';

    sendPasswordResetEmail<T>(email: string): Observable<T> {
        return this.httpClient.get<T>(this.baseUrl + this.path + '/' + email);
    }
}
