<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loading || pdfLoading"
></mat-progress-bar>

<form
    class="tw-flex tw-flex-col"
    [formGroup]="workOrderForm"
    autocomplete="off"
    class="tw-w-3/4 tw-m-auto tw-p-3 tw-gap-2 tw-flex tw-flex-col"
>
    <div class="tw-flex-row tw-flex">
        <mat-form-field class="tw-w-2/5">
            <mat-label>Work Order Name:</mat-label>
            <input
                matInput
                type="text"
                formControlName="name"
                maxlength="150"
            />
        </mat-form-field>

        <div class="tw-gap-2 tw-flex tw-flex-row tw-ml-auto">
            <mat-slide-toggle
                formControlName="active"
                [checked]="workOrderForm.get('active').value"
                (change)="activeChange()"
                class="tw-mt-2"
            >
                Active
            </mat-slide-toggle>

            <mat-slide-toggle
                formControlName="signed"
                [checked]="workOrderForm.get('signed').value"
                (change)="sign()"
                class="tw-mt-2"
            >
                Signed
            </mat-slide-toggle>

            <button mat-raised-button matTooltip="Save" (click)="save()">
                Save
            </button>

            <button
                mat-raised-button
                matTooltip="Save and Export"
                (click)="export()"
            >
                Save And Export
            </button>
            <button
                mat-raised-button
                matTooltip="Close"
                (click)="close()"
                class="m3-error"
            >
                Close
            </button>
        </div>
    </div>

    @if (workOrderForm.controls.completedDate.value) {
        <ng-container>
            <p>
                Completed on:
                {{
                    workOrderForm.controls.completedDate.value
                        | date: "MM/dd/yyyy"
                }}
            </p>
        </ng-container>
    }

    <div class="tw-flex tw-flex-row tw-justify-between">
        <div
            class="tw-flex tw-flex-col tw-p-4 tw-box-border tw-border-customborder tw-border-solid tw-rounded-m3lg tw-w-[45%]"
        >
            <mat-label class="tw-text-lg">Client Info:</mat-label>

            <mat-form-field>
                <mat-label>Client</mat-label>

                <mat-select
                    formControlName="clientId"
                    (selectionChange)="onClientSelectionChange($event)"
                >
                    <mat-option value="">New Client</mat-option>
                    <mat-option
                        *ngFor="let client of clients"
                        [value]="client.id"
                    >
                        {{ client.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Project</mat-label>

                <mat-select
                    formControlName="projectId"
                    (selectionChange)="onProjectSelectionChange($event)"
                >
                    <mat-option value="">New Project</mat-option>
                    <mat-option
                        *ngFor="let project of filteredProjects"
                        [value]="project.id"
                    >
                        {{ project.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Contact:</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="contactName"
                    maxlength="100"
                />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Street Address</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="streetAddress"
                    maxlength="50"
                />
            </mat-form-field>

            <mat-form-field>
                <mat-label>City, State Zip</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="location"
                    maxlength="50"
                />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Phone:</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="phone"
                    maxlength="50"
                />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Email:</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="email"
                    maxlength="150"
                />
            </mat-form-field>
        </div>

        <div
            class="tw-flex tw-flex-col tw-p-4 tw-gap-4 tw-box-border tw-border-customborder tw-border-solid tw-rounded-m3lg tw-w-[45%]"
        >
            <mat-label class="tw-text-lg">Consultant Info:</mat-label>

            <mat-form-field>
                <mat-label>Name:</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="consultantName"
                    maxlength="100"
                />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Position:</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="consultantPosition"
                    maxlength="100"
                />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Consultant Hourly Rate:</mat-label>
                <input
                    matInput
                    formControlName="consultantRate"
                    maxlength="50"
                />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Billable Expenses:</mat-label>
                <span matTextPrefix>$ &nbsp;</span>
                <input
                    matInput
                    type="number"
                    formControlName="billableExpense"
                    maxlength="50"
                    pattern="^\d+(\.\d{1,2})?$"
                />
            </mat-form-field>

            <form
                class="tw-flex tw-flex-col"
                [formGroup]="checkForm"
                autocomplete="off"
            >
                <mat-checkbox formControlName="startDateTBD">
                    Start Date TBD?
                </mat-checkbox>
            </form>

            <mat-form-field
                appearance="fill"
                *ngIf="!checkForm.get('startDateTBD').value"
            >
                <mat-label>Start Date</mat-label>
                <input
                    matInput
                    [matDatepicker]="startDatePicker"
                    formControlName="startDate"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="startDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
                <mat-error
                    *ngIf="
                        this.workOrderForm.controls.startDate.errors?.required
                    "
                >
                    Start date is required
                </mat-error>
                <mat-error *ngIf="this.workOrderForm.errors?.pastDate">
                    Start Date must be before End date
                </mat-error>
            </mat-form-field>

            <form
                class="tw-flex tw-flex-col"
                [formGroup]="checkForm"
                autocomplete="off"
            >
                <mat-checkbox formControlName="endDateTBD">
                    End Date TBD?
                </mat-checkbox>
            </form>

            <mat-form-field
                appearance="fill"
                *ngIf="!checkForm.get('endDateTBD').value"
            >
                <mat-label>End Date</mat-label>
                <input
                    matInput
                    [matDatepicker]="endDatePicker"
                    formControlName="endDate"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="endDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
                <mat-error *ngIf="this.workOrderForm.errors?.pastDate">
                    Start Date must be before End date
                </mat-error>
                <mat-error
                    *ngIf="this.workOrderForm.controls.endDate.errors?.required"
                >
                    End date is required
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="tw-flex tw-flex-col tw-justify-start">
        <mat-form-field class="tw-w-1/5">
            <mat-label>Work Order Note</mat-label>
            <mat-select
                formControlName="notesSelection"
                placeholder="Select a note"
                (selectionChange)="onNoteSelectionChange($event)"
            >
                <mat-option></mat-option>
                <mat-option
                    *ngFor="let note of this.workOrderNotes"
                    [value]="note"
                >
                    {{ note.subject }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="tw-w-full">
            <mat-label>Notes:</mat-label>
            <textarea
                matInput
                type="text"
                formControlName="notes"
                rows="8"
            ></textarea>
        </mat-form-field>
    </div>
</form>
