import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    ITimesheetFormControls,
    IWeeklyTotalsFormControls,
} from 'src/app/interfaces/timesheets';
import { ThemeService } from 'src/app/services/theme.service';
import { MatDividerModule } from '@angular/material/divider';

@Component({
    selector: 'app-timesheet-totals',
    templateUrl: './timesheet-totals.component.html',
    styleUrls: ['./timesheet-totals.component.scss'],
    standalone: true,
    imports: [MatDividerModule],
})
export class TimesheetTotalsComponent implements OnInit {
    @Input() weeklyTotalsFormGroup: FormGroup<IWeeklyTotalsFormControls>;
    @Input() timesheetForms: FormGroup<ITimesheetFormControls>[];

    constructor(private themeService: ThemeService) {}

    ngOnInit(): void {
        this.calcTotals(this.timesheetForms);
    }

    calcTotals(timesheetForms: FormGroup<ITimesheetFormControls>[]): void {
        let newMondayTotal = 0;
        let newTuesdayTotal = 0;
        let newWednesdayTotal = 0;
        let newThursdayTotal = 0;
        let newFridayTotal = 0;
        let newSaturdayTotal = 0;
        let newSundayTotal = 0;
        let newWeekTotal = 0;

        timesheetForms.forEach((timesheetForm) => {
            newMondayTotal += timesheetForm.controls.monday.value;
            newTuesdayTotal += timesheetForm.controls.tuesday.value;
            newWednesdayTotal += timesheetForm.controls.wednesday.value;
            newThursdayTotal += timesheetForm.controls.thursday.value;
            newFridayTotal += timesheetForm.controls.friday.value;
            newSaturdayTotal += timesheetForm.controls.saturday.value;
            newSundayTotal += timesheetForm.controls.sunday.value;
        });

        newWeekTotal =
            newMondayTotal +
            newTuesdayTotal +
            newWednesdayTotal +
            newThursdayTotal +
            newFridayTotal +
            newSaturdayTotal +
            newSundayTotal;

        this.weeklyTotalsFormGroup.controls.mondayTotal.setValue(
            newMondayTotal,
        );
        this.weeklyTotalsFormGroup.controls.tuesdayTotal.setValue(
            newTuesdayTotal,
        );
        this.weeklyTotalsFormGroup.controls.wednesdayTotal.setValue(
            newWednesdayTotal,
        );
        this.weeklyTotalsFormGroup.controls.thursdayTotal.setValue(
            newThursdayTotal,
        );
        this.weeklyTotalsFormGroup.controls.fridayTotal.setValue(
            newFridayTotal,
        );
        this.weeklyTotalsFormGroup.controls.saturdayTotal.setValue(
            newSaturdayTotal,
        );
        this.weeklyTotalsFormGroup.controls.sundayTotal.setValue(
            newSundayTotal,
        );
        this.weeklyTotalsFormGroup.controls.weekTotal.setValue(newWeekTotal);
    }

    protected get darkMode(): boolean {
        const mode = this.themeService.getCurrentTheme();
        if (mode.toLowerCase() == 'dark-theme') {
            return true;
        } else {
            return false;
        }
    }
}
