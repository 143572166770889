import { Route } from '../../models/route.model';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Location, NgIf } from '@angular/common';
import { ROUTES } from '../sidenav/sidenav.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router, RouterLink } from '@angular/router';
import { Me } from 'src/app/models/me.model';
import { UserService } from 'src/app/services/user.service';
import { ProfileImage } from 'src/app/interfaces/profile-image';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { SubmitUserFeedbackDialogComponent } from '../submit-userFeedback-dialog/submit-userFeedback-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppStateService } from '../../services/app-state-service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: true,
    imports: [
        MatToolbarModule,
        MatMenuModule,
        NgIf,
        MatIconModule,
        RouterLink,
        MatButtonModule,
    ],
})
export class NavbarComponent implements OnInit {
    sideNavName!: Observable<string>;
    private listTitles!: Route[];
    currentProfileImage = '';

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private appStateService: AppStateService,
        private location: Location,
        private userService: UserService,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.getUserProfileImage();
        this.listTitles = ROUTES.filter((listTitle) => listTitle);
        this.sideNavName = this.appStateService.title$.asObservable();
    }

    getUserProfileImage(): void {
        this.userService
            .get(['profile-image'])
            .subscribe((profileImage: ProfileImage) => {
                this.currentProfileImage = profileImage.base64Value;
            });
    }

    onLogoutClick(): void {
        const dialog = this.dialog.open(ConfirmationDialogComponent, {
            data: 'Are you sure you want to logout?',
        });

        dialog.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.authService.logoutUser();
                this.appStateService.me$.next(new Me());
                void this.router.navigate(['login']);
            }
        });
    }

    getTitle(): string {
        let title = this.location.prepareExternalUrl(this.location.path());

        if (title.charAt(0) === '/') {
            if (title.includes('?')) {
                title = title.slice(1);
                title = title.split('?')[0];
            } else {
                title = title.slice(1);
            }
        }

        for (let item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].route === title) {
                return this.listTitles[item].headerName;
            }
        }

        if (title.includes('openworkorder')) {
            return 'Work Order Info';
        }

        if (title.includes('my-feedback')) {
            return 'My Feedback';
        }

        return 'Home';
    }

    openSubmitFeedback(): void {
        this.dialog.open(SubmitUserFeedbackDialogComponent, {
            width: '700px',
        });
    }
}
