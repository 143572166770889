import { Component } from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MatDialogActions,
    MatDialogContent,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
    selector: 'app-denial-dialog',
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogActions,
        MatDialogContent,
        DialogHeaderComponent,
    ],
    templateUrl: './denial-dialog.component.html',
    styleUrl: './denial-dialog.component.scss',
})
export class DenialDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<
            DenialDialogComponent,
            DenialDialogOutput
        >,
    ) {}

    notesForm = new FormGroup({
        notes: new FormControl<string | null>(null, [
            Validators.required.bind(this),
        ]),
    });

    onClickDeny(): void {
        this.notesForm.updateValueAndValidity();
        if (this.notesForm.controls.notes.valid) {
            this.dialogRef.close({
                success: true,
                body: this.notesForm.controls.notes.value,
            });
        }
    }

    onClickCancel(): void {
        this.dialogRef.close({ success: false });
    }
}

interface DenialDialogOutput {
    success: boolean;
    body?: string;
}
