import {
    Component,
    Output,
    Input,
    OnInit,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { EmployeeTypeService } from 'src/app/services/employee-type.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-edit-employee-type',
    templateUrl: './edit-employee-type.component.html',
    styleUrls: ['./edit-employee-type.component.scss'],
    imports: [
        ReactiveFormsModule,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
    ],
})
export class EditEmployeeTypeComponent implements OnInit, OnDestroy {
    @Input() employeeTypeObservable: Observable<EmployeeType>;
    @Output() saveCompleted: EventEmitter<void> = new EventEmitter();

    failed = false;

    dialogSubscription: Subscription;
    deleteSubscription: Subscription;
    saveSubscription: Subscription;
    employeeTypeSubscription: Subscription;
    employeeTypeForm: FormGroup;

    get id() {
        return (this.employeeTypeForm.get('id')?.value as string) || null;
    }

    constructor(
        private employeeTypeService: EmployeeTypeService,
        private dialog: MatDialog,
    ) {
        this.employeeTypeForm = new FormGroup({
            id: new FormControl(null),
            name: new FormControl(''),
            isActive: new FormControl(true),
            showInUtilization: new FormControl(true),
        });
    }

    ngOnInit(): void {
        this.employeeTypeSubscription = this.employeeTypeObservable.subscribe(
            (et) => {
                this.updateFormFromEmployeeType(et);
            },
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeIfSet(this.dialogSubscription);
        this.unsubscribeIfSet(this.deleteSubscription);
        this.unsubscribeIfSet(this.employeeTypeSubscription);
        this.unsubscribeIfSet(this.saveSubscription);
    }

    unsubscribeIfSet(subscription: Subscription | null): void {
        if (subscription) {
            subscription.unsubscribe();
        }
    }

    updateFormFromEmployeeType(employeeType: EmployeeType): void {
        this.employeeTypeForm.setValue({
            id: employeeType.id,
            name: employeeType.name,
            isActive: employeeType.isActive,
            showInUtilization: employeeType.showInUtilization,
        });
    }

    onClickReset(): void {
        this.employeeTypeForm.controls.name.reset();
        this.employeeTypeForm.controls.isActive.reset();
        this.employeeTypeForm.controls.showInUtilization.reset();
    }

    saveEmployeeType(): void {
        this.failed = false;

        const postData = this.employeeTypeForm.value;
        let saveObservable: Observable<any>;

        if (postData.id) {
            saveObservable = this.employeeTypeService.put(
                [postData.id],
                postData,
            );
        } else {
            saveObservable = this.employeeTypeService.post([], postData);
        }

        this.saveSubscription = saveObservable.subscribe(
            () => this.saveComplete(),
            (error: any) => (this.failed = true),
        );
    }

    saveComplete(): void {
        this.saveCompleted.emit();
    }
}
