<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingUsers"
></mat-progress-bar>

<div class="tw-flex tw-mt-12 tw-justify-center">
    <div class="tw-flex tw-justify-between tw-items-center tw-mb-2 tw-w-4/5">
        <div>
            <button
                mat-button
                matTooltip="Previous Week"
                (click)="onClickPreviousWeek()"
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

            <mat-form-field [formGroup]="dateFormGroup" appearance="outline">
                <mat-label>Select Week</mat-label>
                <mat-date-range-input
                    [rangePicker]="picker"
                    (click)="picker.open()"
                >
                    <input
                        matStartDate
                        formControlName="beginningOfWeek"
                        (dateChange)="onStartDateChange($event)"
                    />
                    <input
                        matEndDate
                        formControlName="endOfWeek"
                        (dateChange)="onEndDateChange($event)"
                    />
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <button
                mat-button
                matTooltip="Next Week"
                (click)="onClickNextWeek()"
            >
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>

        <div class="tw-flex tw-flex-row tw-gap-4">
            <button
                mat-raised-button
                matTooltipPosition="left"
                matTooltip="Lock All User Timesheets"
                class="m3-error"
                (click)="lockAllReports()"
            >
                Lock All
            </button>
            <button
                mat-raised-button
                matTooltipPosition="right"
                matTooltip="Export Reports"
                (click)="exportReport()"
            >
                Payroll Report
            </button>
        </div>
    </div>
</div>

<div class="tw-grid tw-grid-cols-10 tw-gap-2 tw-mb-2">
    <mat-card class="tw-col-start-2 tw-col-span-8">
        <mat-card-content class="tw-flex tw-justify-between">
            <div class="tw-flex tw-flex-col tw-gap-2">
                <div class="tw-flex tw-gap-4 tw-items-center">
                    <div [formGroup]="searchForm">
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-label>Search Users...</mat-label>
                            <input
                                matInput
                                type="text"
                                formControlName="searchBar"
                            />
                        </mat-form-field>
                    </div>
                    <mat-checkbox
                        [(ngModel)]="is1099Only"
                        [disabled]="filteredPage.items.length === 0"
                        (change)="onSelectChange()"
                    >
                        1099 Only
                    </mat-checkbox>
                    <mat-checkbox
                        [(ngModel)]="isUnsubmittedOnly"
                        (change)="onSelectChange()"
                        [disabled]="filteredPage.items.length === 0"
                    >
                        Unsubmitted
                    </mat-checkbox>
                </div>

                <div class="tw-flex tw-justify-normal tw-gap-6 tw-items-center">
                    <mat-checkbox
                        [(ngModel)]="isSelectAll"
                        (change)="selectAll()"
                        [disabled]="filteredPage.items.length === 0"
                    >
                        Select All
                    </mat-checkbox>
                </div>
            </div>

            <div class="tw-flex tw-gap-4">
                <button
                    mat-raised-button
                    matTooltip="Send An Email"
                    (click)="openEmailDialog()"
                    class="m3-tertiary"
                    [disabled]="filteredPage.items.length === 0"
                >
                    Email
                </button>
                <button
                    mat-raised-button
                    matTooltip="Unlock Selected Users"
                    (click)="unlockReports()"
                    [disabled]="filteredPage.items.length === 0"
                    class="m3-error"
                >
                    Unlock
                </button>
                <button
                    mat-raised-button
                    matTooltip="Refresh Page"
                    (click)="refresh()"
                >
                    Refresh
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="tw-col-start-2 tw-col-span-8">
        <mat-card-content>
            <table
                mat-table
                [dataSource]="dataSource"
                matSort
                multiTemplateDataRows
                class="tw-bg-transparent"
            >
                <ng-container matColumnDef="selected">
                    <th mat-header-cell *matHeaderCellDef>Select</th>
                    <td mat-cell *matCellDef="let report">
                        <mat-checkbox
                            [(ngModel)]="report.isSelected"
                        ></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name" class="!tw-w-[10%]">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        User
                    </th>
                    <td mat-cell *matCellDef="let report">{{ report.name }}</td>
                </ng-container>

                <ng-container matColumnDef="billingHours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Hours
                    </th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.billingHours }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="expand">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        aria-label="row actions"
                    >
                        &nbsp;
                    </th>
                    <td mat-cell *matCellDef="let report">
                        <button
                            mat-icon-button
                            aria-label="expand row"
                            (click)="
                                expandedTimesheet =
                                    expandedTimesheet === report
                                        ? null
                                        : report;
                                $event.stopPropagation()
                            "
                        >
                            @if (expandedTimesheet === report) {
                                <mat-icon>keyboard_arrow_up</mat-icon>
                            } @else {
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            }
                        </button>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td
                        mat-cell
                        *matCellDef="let report"
                        [attr.colspan]="displayedColumns.length"
                    >
                        <div
                            class="!tw-w-full"
                            [@detailExpand]="
                                report === expandedTimesheet
                                    ? 'expanded'
                                    : 'collapsed'
                            "
                        >
                            <div
                                *ngIf="
                                    report.timesheetGroup !== null;
                                    else elseBlock
                                "
                            >
                                <ng-container
                                    *ngFor="
                                        let timesheetForm of report.timesheetGroup
                                    "
                                >
                                    <app-view-timesheet
                                        class="tw-mb-2 tw-w-full"
                                        [timesheetDates]="dateFormGroup"
                                        [timesheetForm]="timesheetForm"
                                        [readOnly]="true"
                                    ></app-view-timesheet>
                                </ng-container>
                            </div>
                            <ng-template #elseBlock>
                                No Timesheets to show
                            </ng-template>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let report; columns: displayedColumns"
                    class="example-element"
                    [class.example-expanded]="expandedTimesheet === report"
                    (click)="
                        expandedTimesheet =
                            expandedTimesheet === report ? null : report
                    "
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: ['expandedDetail']"
                    class="tw-h-0"
                ></tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>
