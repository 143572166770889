import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Permission } from 'src/app/models/permission.model';
import { Role } from 'src/app/models/role.model';
import { PermissionsService } from 'src/app/services/permission.service';
import { RolesService } from 'src/app/services/roles.service';
import { AddRoleDialog } from './add-role-dialog/add-role-dialog.component';
import { UpdateUserRoleDialog } from './update-user-role-dialog/update-user-role-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
    MatPaginator,
    MatPaginatorModule,
    PageEvent,
} from '@angular/material/paginator';
import { Me } from 'src/app/models/me.model';
import { MeService } from 'src/app/services/me.service';
import { UserService } from 'src/app/services/user.service';
import { SEARCH_DEBOUNCE_MS } from 'src/app/constants/searchConstants';
import { Page } from 'src/app/models/page.model';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { User } from 'src/app/models/User';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
    MatSlideToggleChange,
    MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppStateService } from '../../services/app-state-service';
import { VisibleDirective } from '../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { P } from '@angular/cdk/keycodes';

export interface dialogRefResponse {
    continueUpdate: boolean;
}

@Component({
    selector: 'app-roles-permissions',
    templateUrl: './roles-permissions.component.html',
    styleUrls: ['./roles-permissions.component.scss'],
    imports: [
        MatTooltipModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatListModule,
        MatPaginatorModule,
        MatTableModule,
        MatCheckboxModule,
        VisibleDirective,
        MatCardModule,
        MatIconModule,
    ],
})
export class RolesPermissionsComponent implements OnInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    checkAll = false;
    rolesPage = new Page<Role>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });
    permissions: Permission[] = [];
    modules: string[] = [];
    selectedRolePermissions: Permission[] = [];
    selectedRole: Role;
    isRoleSelected = false;
    displayedColumns: string[] = ['Module', 'Full', 'Restricted'];
    loadingPermissions = false;
    loadingRoles = false;
    disableSaveChanges = true;
    roleStatus = true;
    roleNameFormControl = new FormControl<string>('');
    restrictedModules: string[] = [];
    roleId: string;

    pageIndex = 0;
    queryParams: Map<string, unknown> = new Map<string, unknown>();
    fullLength: number;
    countSize: number;

    constructor(
        private rolesService: RolesService,
        private permissionService: PermissionsService,
        public dialog: MatDialog,
        private appStateService: AppStateService,
        private meService: MeService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        this.selectedRole = null;
        this.isRoleSelected = false;

        this.roleNameFormControl.valueChanges
            .pipe(debounceTime(SEARCH_DEBOUNCE_MS), distinctUntilChanged())
            .subscribe(() => {
                this.getRoles();
            });
    }

    ngOnInit(): void {
        this.populateFilters();
        this.setQueryParams();
        this.getRoles();
        this.getPermissions();
    }

    getRoles(): void {
        this.loadingRoles = true;
        this.setQueryParams();

        this.rolesService
            .get([], this.queryParams)
            .subscribe((roles: Page<Role>) => {
                this.rolesPage = roles;
                this.loadingRoles = false;
            });
    }

    getPermissions(): void {
        this.loadingPermissions = true;
        this.permissions = [];
        const queryParams = new Map([['count', -1]]);

        this.permissionService
            .get<Page<Permission>>([], queryParams)
            .subscribe({
                next: (response: Page<Permission>) => {
                    this.permissions = response.items;
                    this.createArrayOfModuleNames();
                    this.getPagesWithRestrictedAccess();
                    this.loadingPermissions = false;
                },
                error: () => {
                    this.loadingPermissions = false;
                },
            });
    }

    toggleActiveInactive(event: MatSlideToggleChange): void {
        this.roleStatus = event.checked;
        this.selectedRole = null;
        this.changePageIndex(0);
        this.getRoles();
        this.getPermissions();

        this.isRoleSelected = false;
        this.disableSaveChanges = true;
        this.selectedRole = null;
        this.selectedRolePermissions = [];
    }

    createArrayOfModuleNames(): void {
        const modules: string[] = this.permissions.map(
            (entry) => entry['module'],
        );
        const set = new Set(modules);
        this.modules = Array.from(set);
    }

    getPagesWithRestrictedAccess(): void {
        const moduleMap = new Map<string, string[]>();

        this.permissions.forEach((permission) => {
            if (!moduleMap.has(permission.module)) {
                moduleMap.set(permission.module, []);
            }
            moduleMap.get(permission.module)?.push(permission.accessLevel);
        });

        // Find modules with multiple access types
        const restricted = Array.from(moduleMap.entries())
            .filter(([, accessTypes]) => new Set(accessTypes).size > 1)
            .map(([module]) => module);

        this.restrictedModules = restricted;
    }

    createSelectedRolePermissions(): void {
        this.selectedRolePermissions = [];
        this.disableSaveChanges = true;

        this.modules.forEach((module) => {
            const permission: Permission = new Permission();
            permission.module = module;

            const perm = this.selectedRole.pagePermissions.filter(
                (selectedRolePermission) => {
                    return selectedRolePermission.module === module;
                },
            )[0];
            if (perm) {
                permission.accessLevel = perm.accessLevel;
            }

            this.selectedRolePermissions.push(permission);
        });
    }

    private setQueryParams(): void {
        this.queryParams.set('count', 10);

        if (
            this.roleNameFormControl.value &&
            this.roleNameFormControl.value !== ''
        ) {
            this.queryParams.set('name', this.roleNameFormControl.value);
            this.pageIndex = 0;
        } else {
            this.queryParams.delete('name');
        }

        if (this.pageIndex) {
            this.queryParams.set('offset', this.pageIndex * 10);
        } else {
            this.queryParams.set('offset', 0);
        }

        this.updateUrl();
    }

    onSelectRole(role: MatSelectionListChange | Role): void {
        if (role instanceof MatSelectionListChange) {
            this.selectedRole = role.options[0].value as Role;
        } else {
            this.selectedRole = role;
        }

        this.createArrayOfModuleNames();
        this.isRoleSelected = true;
        this.createSelectedRolePermissions();

        this.updateUrl();
    }

    savePermissionChanges(): void {
        const updatePerms: Permission[] = this.selectedRolePermissions.filter(
            (p) => p.accessLevel != null,
        );

        updatePerms.forEach(
            (perm) =>
                (perm.id = this.permissions.filter(
                    (permission) => permission.module == perm.module,
                )[0].id),
        );

        this.rolesService
            .put([this.selectedRole.id], {
                pagePermissions: updatePerms,
            })
            .subscribe(() => {
                this.selectedRole = null;
                this.isRoleSelected = false;
                this.getRoles();
                this.getPermissions();
                this.disableSaveChanges = true;
                this.meService.get([]).subscribe((me: Me) => {
                    this.appStateService.me$.next(me);
                });
            });
    }

    changeActiveStatus(): void {
        const changedRole = this.selectedRole;

        if (changedRole.status.toUpperCase() === ACTIVE) {
            const queryParams: Map<string, unknown> = new Map<string, unknown>([
                ['status', ACTIVE],
                ['count', 10],
                ['offset', 0],
                ['roleId', this.selectedRole.id],
            ]);

            this.userService
                .get([], queryParams)
                .subscribe((result: Page<User>) => {
                    if (result.totalCount > 0) {
                        const dialogRef = this.dialog.open(
                            UpdateUserRoleDialog,
                            {
                                data: this.selectedRole.id,
                            },
                        );

                        dialogRef
                            .afterClosed()
                            .subscribe((response: dialogRefResponse) => {
                                if (response.continueUpdate) {
                                    changedRole.status = INACTIVE;

                                    this.rolesService
                                        .put(
                                            [this.selectedRole.id],
                                            changedRole,
                                        )
                                        .subscribe(() => {
                                            this.getRoles();
                                            this.getPermissions();

                                            this.selectedRolePermissions = [];
                                            this.selectedRole = null;
                                            this.isRoleSelected = false;
                                            this.disableSaveChanges = true;
                                        });
                                }
                            });
                    } else {
                        changedRole.status = INACTIVE;

                        this.rolesService
                            .put([this.selectedRole.id], changedRole)
                            .subscribe(() => {
                                this.getRoles();
                                this.getPermissions();

                                this.selectedRolePermissions = [];
                                this.selectedRole = null;
                                this.isRoleSelected = false;
                                this.disableSaveChanges = true;
                            });
                    }
                });
        } else {
            changedRole.status = ACTIVE;

            this.rolesService
                .put([this.selectedRole.id], changedRole)
                .subscribe(() => {
                    this.getRoles();
                    this.getPermissions();

                    this.selectedRolePermissions = [];
                    this.selectedRole = null;
                    this.isRoleSelected = false;
                    this.disableSaveChanges = true;
                });
        }
    }

    openAddDialog(): void {
        const dialogRef = this.dialog.open(AddRoleDialog);

        dialogRef.afterClosed().subscribe((response: string) => {
            if (response != null) {
                this.rolesService.get([response]).subscribe((role: Role) => {
                    this.getRoles();
                    this.onSelectRole(role);
                });
            }
        });
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
        this.getRoles();
    }

    changePageIndex(index: number): void {
        this.pageIndex = index;
    }

    selectUnselectAllPermissions(): void {
        if (this.selectedRole) {
            this.selectedRolePermissions.forEach(
                (p) => (p.accessLevel = !this.checkAll ? 'Admin' : null),
            );
        }

        this.checkAll = !this.checkAll;
        this.disableSaveChanges = false;
    }

    private populateFilters(): void {
        const params: Params = this.activatedRoute.snapshot.queryParams;

        if ('name' in params) {
            this.queryParams.set('name', params['name'] as string);
            this.roleNameFormControl.setValue(params['name'] as string);
        }

        if ('status' in params) {
            this.roleStatus = params['status'] !== INACTIVE;
        }
    }

    private updateUrl(): void {
        const queryParams: Params = {};

        const nameValue = this.roleNameFormControl.value;
        if (nameValue) {
            queryParams['name'] = nameValue;
        }

        if (this.roleStatus !== null && this.roleStatus !== undefined) {
            queryParams['status'] = this.roleStatus ? ACTIVE : INACTIVE;
        }

        void this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
        });
    }
}
