<app-dialog-header
    title="Please provide a reason for denying the selected timesheet:"
></app-dialog-header>

<mat-dialog-content>
    <form [formGroup]="notesForm" class="tw-w-full">
        <mat-form-field appearance="fill" class="tw-w-full">
            <mat-label>Notes</mat-label>
            <textarea matInput formControlName="notes"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="tw-gap-2">
    <button mat-button (click)="onClickCancel()" class="tw-text-base">
        Cancel
    </button>
    <button
        mat-raised-button
        (click)="onClickDeny()"
        class="tw-text-base m3-error"
    >
        Deny
    </button>
</mat-dialog-actions>
