import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { RolesService } from 'src/app/services/roles.service';
import { Role } from 'src/app/models/role.model';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { DialogFooterComponent } from '../../dialog-footer/dialog-footer.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';

@Component({
    selector: 'add-role-dialog',
    templateUrl: 'add-role-dialog.html',
    styleUrls: ['./add-role-dialog.scss'],
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DialogFooterComponent,
    ],
})
export class AddRoleDialog implements OnInit {
    roleForm: FormGroup;
    value = '';
    createRoleError = false;
    saving = false;

    constructor(
        private roleService: RolesService,
        private dialogRef: MatDialogRef<AddRoleDialog>,
    ) {}

    ngOnInit(): void {
        this.roleForm = new FormGroup({
            name: new FormControl<string>(null, [
                Validators.required.bind(this),
            ]),
        });
    }

    submit(): void {
        if (!this.roleForm.valid) {
            return;
        }
        this.saving = true;
        this.roleService
            .post([], { name: this.roleForm.get('name').value })
            .subscribe(
                (response: Role) => {
                    this.createRoleError = false;
                    this.dialogRef.close(response.id);
                },
                () => {
                    this.createRoleError = true;
                },
            );
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }
}
