import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgClass, NgIf } from '@angular/common';
import { PASSWORDPATTERN } from 'src/app/constants/regexConstants';
import { PasswordMatchValidator } from 'src/app/validators/passwords-match.validator';
import { VisibleDirective } from '../../directives/visible.directive';

interface IPasswordResetFormControls {
    password: FormControl<string>;
    confirmPassword: FormControl<string>;
}

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        RouterLink,
        RouterLinkActive,
        VisibleDirective,
    ],
})
export class PasswordResetComponent implements OnInit {
    loading = false;
    passwordSuccess: boolean = undefined;
    passwordResetForm: FormGroup<IPasswordResetFormControls>;

    theme!: string;
    apiSuccess: boolean = undefined;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private loginService: LoginService,
    ) {}

    ngOnInit(): void {
        this.passwordResetForm = new FormGroup(
            {
                password: new FormControl(null, [
                    Validators.pattern(PASSWORDPATTERN),
                    Validators.required.bind(this),
                ]),
                confirmPassword: new FormControl(null, [
                    Validators.required.bind(this),
                    Validators.pattern(PASSWORDPATTERN),
                ]),
            },
            PasswordMatchValidator,
        );

        if (this.authenticationService.checkLoginAuthentication()) {
            void this.router.navigate(['home']);
        }
        this.theme = localStorage.getItem('theme');
    }

    onResetPasswordClick(): void {
        this.apiSuccess = undefined;
        this.passwordSuccess = undefined;

        if (this.passwordResetForm.valid) {
            const body = {
                password: this.passwordResetForm.controls.password.value,
            };

            this.loginService.patch([], body).subscribe(
                () => {
                    this.apiSuccess = true;
                    this.passwordSuccess = true;
                },
                () => {
                    this.apiSuccess = false;
                    this.passwordSuccess = false;
                },
            );
        }
    }
}
