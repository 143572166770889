import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TenantSettings } from 'src/app/models/tenant-settings.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MeService } from 'src/app/services/me.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class HomeComponent {
    tenantSettings: TenantSettings;

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private meService: MeService,
        private dialog: MatDialog,
    ) {}

    onLogout(): void {
        this.authService.logoutUser();
        void this.router.navigate(['/login']);
    }

    ngOnInit(): void {
        this.meService
            .get(['tenant-settings'])
            .subscribe((response: TenantSettings) => {
                this.tenantSettings = response;
            });
    }
}
