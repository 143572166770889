<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingClients"
></mat-progress-bar>

<div class="tw-flex tw-justify-center tw-mt-6">
    <div class="tw-text-2xl tw-w-3/4">
        Add/Edit Clients

        <mat-slide-toggle
            class="tw-ml-2"
            (change)="toggleActiveInactive()"
            [checked]="clientStatus"
        >
            <div *ngIf="clientStatus; else elseBlock">Active</div>
            <ng-template #elseBlock>Inactive</ng-template>
        </mat-slide-toggle>
    </div>
</div>

<div
    class="tw-flex tw-flex-row tw-justify-center tw-mt-6 tw-items-start tw-gap-4"
>
    <div
        class="tw-flex tw-flex-col tw-p-6 tw-gap-2 tw-box-border tw-border-solid tw-rounded-m3lg tw-border-customborder tw-border-2 tw-w-1/4"
    >
        <div class="tw-flex tw-justify-between">
            <div class="tw-text-2xl">Clients</div>

            <button
                mat-raised-button
                matTooltip="Add Client"
                (click)="openAddDialog()"
            >
                Add Client
            </button>
        </div>

        <div [formGroup]="searchForm">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>Search Clients...</mat-label>
                <input matInput type="text" formControlName="search" />
            </mat-form-field>
        </div>

        <mat-selection-list
            #clientSelectionList
            hideSingleSelectionIndicator
            [multiple]="false"
            (selectionChange)="clientSelectionChange($event)"
        >
            @for (client of page.items; track client) {
                <mat-list-option [value]="client">
                    {{ client.name }}
                </mat-list-option>
                @if (!$last) {
                    <mat-divider></mat-divider>
                }
            }
        </mat-selection-list>

        <mat-paginator
            class="tw-rounded-b-lg"
            [pageIndex]="pageIndex"
            [pageSize]="page.countRequested"
            [length]="page.totalCount"
            [hidePageSize]="true"
            (page)="changePage($event)"
        ></mat-paginator>
    </div>

    <div class="tw-w-1/2">
        <app-edit-client
            [hidden]="!clientSelected"
            (clientChangedEvent)="clientChanged(true)"
        ></app-edit-client>
    </div>
</div>
