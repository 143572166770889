import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
    MatSlideToggleChange,
    MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { Event } from '@angular/router';
import { Client } from 'src/app/models/client.model';
import { Project } from 'src/app/models/project-model';
import { ClientService } from 'src/app/services/client.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { TaskService } from 'src/app/services/task-service.service';
import { Task } from 'src/app/models/task.model';
import { UpsertTaskComponent } from '../../upsert-task/upsert-task.component';
import { PastDateValidator } from 'src/app/validators/past-date.validator';
import { Page } from 'src/app/models/page.model';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { MatSort, MatSortModule } from '@angular/material/sort';

interface IProjectFormControls {
    name: FormControl<string>;
    primaryContact: FormControl<string>;
    startDate: FormControl<string>;
    endDate: FormControl<string>;
    notes: FormControl<string>;
    clientId: FormControl<string>;
    status: FormControl<boolean>;
    nonBillable: FormControl<boolean>;
}

@Component({
    selector: 'app-edit-project',
    templateUrl: './edit-project.component.html',
    styleUrls: ['./edit-project.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatTableModule,
        MatIconModule,
        MatDivider,
        MatSort,
        MatSortModule,
    ],
})
export class EditProjectComponent {
    @Input() project: Project = null;
    @Output() loadingProjectEdit = new EventEmitter<boolean>();
    @Output() projectChangeEvent = new EventEmitter<Project | null>();
    viewingActiveTasks = true;
    failed = false;
    loading = false;
    editingTasks = false;
    activeTasks!: Task[];
    inactiveTasks!: Task[];
    client!: Client;

    displayedColumns: string[] = ['name', 'billType', 'action', 'edit'];
    dataSource: MatTableDataSource<Task>;
    @ViewChild(MatSort) sort: MatSort;

    projectForm: FormGroup<IProjectFormControls> = new FormGroup({
        name: new FormControl(),
        primaryContact: new FormControl(),
        startDate: new FormControl(),
        endDate: new FormControl(),
        notes: new FormControl(),
        clientId: new FormControl(),
        status: new FormControl(),
        nonBillable: new FormControl(false),
    });

    get nonBillable(): boolean {
        return this.projectForm.get('nonBillable')?.value ?? false;
    }

    constructor(
        private clientService: ClientService,
        private projectService: ProjectsService,
        private taskService: TaskService,
        private dialog: MatDialog,
    ) {}

    initializeForm(project?: Project): void {
        if (project) {
            this.updateTasks(project);

            this.projectForm = new FormGroup(
                {
                    name: new FormControl(project.name, [
                        Validators.required.bind(this),
                        Validators.maxLength(100),
                    ]),
                    primaryContact: new FormControl(project.primaryContact, [
                        Validators.required.bind(this),
                    ]),
                    startDate: new FormControl(project.startDate, [
                        Validators.required.bind(this),
                    ]),
                    endDate: new FormControl(project.endDate),
                    notes: new FormControl(project.notes),
                    clientId: new FormControl(project.clientId, [
                        Validators.required.bind(this),
                    ]),
                    status: new FormControl(
                        project.status.toLowerCase() === ACTIVE.toLowerCase()
                            ? true
                            : false,
                    ),
                    nonBillable: new FormControl({
                        value: project.nonBillable,
                        disabled: true,
                    }),
                },
                [PastDateValidator],
            );
        } else if (this.project) {
            this.projectForm = new FormGroup(
                {
                    name: new FormControl(this.project.name, [
                        Validators.required.bind(this),
                        Validators.maxLength(100),
                    ]),
                    primaryContact: new FormControl(
                        this.project.primaryContact,
                        [Validators.required.bind(this)],
                    ),
                    startDate: new FormControl(this.project.startDate, [
                        Validators.required.bind(this),
                    ]),
                    endDate: new FormControl(this.project.endDate),
                    notes: new FormControl(this.project.notes),
                    clientId: new FormControl(this.project.clientId, [
                        Validators.required.bind(this),
                    ]),
                    status: new FormControl(
                        this.project.status.toLowerCase() ===
                        ACTIVE.toLowerCase()
                            ? true
                            : false,
                    ),
                    nonBillable: new FormControl({
                        value: this.project.nonBillable,
                        disabled: true,
                    }),
                },
                [PastDateValidator],
            );
        } else {
            this.projectForm = new FormGroup({
                name: new FormControl(null, [
                    Validators.required.bind(this),
                    Validators.maxLength(100),
                ]),
                primaryContact: new FormControl(null, [
                    Validators.required.bind(this),
                ]),
                startDate: new FormControl(null, [
                    Validators.required.bind(this),
                ]),
                endDate: new FormControl(null),
                notes: new FormControl(null),
                clientId: new FormControl(null, [
                    Validators.required.bind(this),
                ]),
                status: new FormControl(null),
                nonBillable: new FormControl({ value: false, disabled: true }),
            });
        }
    }

    updateTasks(project: Project): void {
        this.loading = true;
        if (!this.client) {
            this.clientService
                .get([project.clientId])
                .subscribe((client: Client) => {
                    this.client = client;
                });
        }

        if (this.viewingActiveTasks) {
            const activeQueryParams = new Map<string, unknown>([
                ['status', ACTIVE],
                ['countRequested', 200],
                ['projectId', this.project.id],
            ]);

            this.taskService
                .get([], activeQueryParams)
                .subscribe((tasks: Page<Task>) => {
                    this.loading = false;
                    this.activeTasks = tasks.items;
                });
        } else {
            const inactiveQueryParams = new Map<string, unknown>([
                ['status', INACTIVE],
                ['countRequested', 200],
                ['projectId', this.project.id],
            ]);

            this.taskService
                .get([], inactiveQueryParams)
                .subscribe((tasks: Page<Task>) => {
                    this.loading = false;
                    this.inactiveTasks = tasks.items;
                });
        }
    }

    onActiveChange(event: MatSlideToggleChange): void {
        this.viewingActiveTasks = event.checked;
        this.updateTasks(this.project);
    }

    setEditProject(project: Project): void {
        this.project = project;
        this.initializeForm(project);
    }

    onFormSave(deselectUser?: boolean): void {
        this.projectForm.updateValueAndValidity();

        if (!this.projectForm.valid) {
            return;
        } else {
            this.loadingProjectEdit.emit(true);
            const project = new Project({
                name: this.projectForm.controls.name.value,
                primaryContact: this.projectForm.controls.primaryContact.value,
                startDate: this.projectForm.controls.startDate.value,
                endDate: this.projectForm.controls.endDate.value,
                clientId: this.projectForm.controls.clientId.value,
                nonBillable: this.projectForm.controls.nonBillable.value,
                notes: this.projectForm.controls.notes.value,
                status: '',
            });

            if (this.projectForm.controls.status.value === true) {
                project.status = ACTIVE;
            } else {
                project.status = INACTIVE;
            }

            project.nonBillable = this.nonBillable;

            this.projectService.put([this.project.id], project).subscribe(
                () => {
                    if (deselectUser) {
                        this.projectChangeEvent.emit(null);
                    }
                    this.loadingProjectEdit.emit(false);
                    this.project = null;
                    this.initializeForm();
                },
                () => {
                    this.failed = true;
                },
            );
        }
    }

    addTask(): void {
        const dialogRef = this.dialog.open(UpsertTaskComponent, {
            data: {
                title: 'New Task',
                task: null,
                clientName: this.client.name,
                projectName: this.project.name,
                projectId: this.project.id,
                approverRequired: false,
            },
        });

        dialogRef.afterClosed().subscribe((val) => {
            if (val) {
                this.updateTasks(this.project);
            }
        });
    }

    removeTask(task: Task): void {
        this.loading = true;
        task.status = INACTIVE;
        this.taskService.put([task.id], task).subscribe(() => {
            this.loading = false;
            this.updateTasks(this.project);
        });
    }

    makeTaskActive(task: Task): void {
        this.loading = true;
        task.status = ACTIVE;
        this.taskService.put([task.id], task).subscribe(() => {
            this.loading = false;
            this.updateTasks(this.project);
        });
    }

    editTask(task: Task): void {
        const dialogref = this.dialog.open(UpsertTaskComponent, {
            data: {
                title: 'Edit Task',
                task: task,
                clientName: this.client.name,
                projectName: this.project.name,
                projectId: this.project.id,
                approverRequired: false,
            },
        });

        dialogref.afterClosed().subscribe(() => {
            this.updateTasks(this.project);
        });
    }

    editProjectTasks(event?: Event): void {
        if (!event) {
            this.editingTasks = true;
        } else {
            this.editingTasks = !this.editingTasks;
        }
    }

    onResetClick(): void {
        this.projectForm.reset();
    }

    getDataSource(): MatTableDataSource<Task> {
        if (this.viewingActiveTasks) {
            this.dataSource = new MatTableDataSource(this.activeTasks);
            this.dataSource.sort = this.sort;
            return this.dataSource;
        } else {
            this.dataSource = new MatTableDataSource(this.inactiveTasks);
            this.dataSource.sort = this.sort;
            return this.dataSource;
        }
    }
}
