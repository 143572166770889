import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { Login } from '../../models/Login';
import { InputValidationService } from 'src/app/services/input-validation.service';
import { MeService } from 'src/app/services/me.service';
import { Token } from 'src/app/interfaces/Token';
import { Me } from 'src/app/models/me.model';
import { ThemeService } from 'src/app/services/theme.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { AppStateService } from '../../services/app-state-service';

interface IloginFormControls {
    email: FormControl<string>;
    password: FormControl<string>;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    imports: [
        ReactiveFormsModule,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        RouterLink,
        RouterLinkActive,
        MatProgressSpinnerModule,
    ]
})
export class LoginComponent implements OnInit {
    enteredIncorrectly = false;
    loadingSpinnerActive = false;
    loginForm: FormGroup<IloginFormControls> = new FormGroup({
        email: new FormControl(null, [
            Validators.required.bind(this),
            Validators.email.bind(this),
        ]),
        password: new FormControl(null, [Validators.required.bind(this)]),
    });

    showPassword = false;
    targetUrl: string;

    constructor(
        private authenticationService: AuthenticationService,
        private inputValidationService: InputValidationService,
        private meService: MeService,
        private appStateService: AppStateService,
        private themeService: ThemeService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.targetUrl = new URLSearchParams(window.location.search).get(
            'targetUrl',
        );

        this.themeService.setTheme('dark-theme');

        if (this.authenticationService.checkLoginAuthentication()) {
            this.redirect();
        }
    }

    onLoginClick(): void {
        const loginValues: Login = this.loginForm.value as Login;

        this.loadingSpinnerActive = true;
        this.authenticationService.post([], loginValues).subscribe(
            (val) => {
                this.authenticationService.saveToken(val as Token);

                this.meService.get([]).subscribe((me: Me) => {
                    if (
                        me.theme != null &&
                        me.theme != '' &&
                        me.theme != undefined
                    ) {
                        this.themeService.setTheme(me.theme);
                    } else {
                        this.themeService.setTheme('dark-theme');
                    }

                    this.appStateService.title$.next('Home');
                    this.appStateService.me$.next(me);
                    this.loadingSpinnerActive = false;
                    this.redirect();
                    this.loginForm.reset();
                });
            },
            () => {
                this.loadingSpinnerActive = false;
                this.enteredIncorrectly = true;
            },
        );
    }

    redirect() {
        if (this.targetUrl) {
            window.location.href = this.targetUrl;
        } else {
            void this.router.navigate(['home']);
        }
    }

    getErrorMessage(form: AbstractControl): string {
        return this.inputValidationService.getEmailErrorMessage(form);
    }
}
