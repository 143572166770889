import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserFeedbackStatuses } from 'src/app/interfaces/userFeedback-Form';
import { UserComment } from 'src/app/models/UserComment.model';
import { UserFeedback } from 'src/app/models/UserFeedback.model';
import { Page } from 'src/app/models/page.model';
import { CommentService } from 'src/app/services/comment.service';
import {
    AddCommentComponent,
    DialogData,
} from './add-comment/add-comment.component';
import { UserFeedbackService } from 'src/app/services/userFeedback.service';
import { debounceTime } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
    selector: 'app-edit-user-feedback',
    standalone: true,
    imports: [
        MatToolbarModule,
        MatTooltipModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatChipsModule,
        NgClass,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
    ],
    templateUrl: './edit-user-feedback.component.html',
    styleUrl: './edit-user-feedback.component.scss',
})
export class EditUserFeedbackComponent implements OnInit {
    constructor(
        public dialog: MatDialog,
        private commentService: CommentService,
        private userFeedbackService: UserFeedbackService,
        private snackBar: MatSnackBar,
    ) {}
    @Input() userView?: boolean;

    ngOnInit(): void {
        if (!this.userView) {
            this.ticketForm.controls.ticket.valueChanges
                .pipe(debounceTime(1500))
                .subscribe((value: string) => {
                    this.ticketForm.updateValueAndValidity();
                    if (this.ticketForm.valid) {
                        this.userFeedback.ticketNum = value;
                        this.userFeedbackService
                            .put([this.userFeedback.id], this.userFeedback)
                            .subscribe();
                    } else {
                        const config = new MatSnackBarConfig();

                        config.duration = 3000;
                        config.verticalPosition = 'top';
                        this.snackBar.open(
                            'Ticket number is too long.',
                            'Close',
                            config,
                        );
                    }
                });
        }

        this.getComments();
    }

    statusForm = new FormGroup({
        processed: new FormControl<boolean>(false),
        duplicate: new FormControl<boolean>(false),
    });

    page = new Page<UserComment>({
        countRequested: -1,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    ticketForm = new FormGroup({
        ticket: new FormControl<string>('', [Validators.maxLength(50)]),
    });

    queryParams = new Map<string, string>();
    feedbackStatuses: UserFeedbackStatuses;

    @Input({ required: true })
    get userFeedback(): UserFeedback {
        return this._userFeedback;
    }
    set userFeedback(userFeedback: UserFeedback) {
        this._userFeedback = userFeedback;
        this.initFeedback();
    }
    _userFeedback: UserFeedback;

    initFeedback(): void {
        if (this.userFeedback.ticketNum) {
            this.ticketForm.controls.ticket.setValue(
                this.userFeedback.ticketNum,
            );
        } else {
            this.ticketForm.reset();
        }

        this.page.items = [];
        this.getComments();
        this.statusForm.reset();

        if (this.userFeedback.status === 'Processed') {
            this.statusForm.controls.processed.setValue(true);
        } else if (this.userFeedback.status === 'Duplicate') {
            this.statusForm.controls.duplicate.setValue(true);
        }
    }

    addComment(): void {
        const dialog = this.dialog.open<
            AddCommentComponent,
            DialogData,
            boolean
        >(AddCommentComponent, {
            disableClose: true,
            width: '25%',
            data: { feedbackId: this.userFeedback.id },
        });

        dialog.afterClosed().subscribe((result) => {
            if (result === true) {
                this.getComments();
            }
        });
    }

    onCheckboxChange(status: string): void {
        if (status === 'Processed') {
            this.statusForm.controls['duplicate'].setValue(false);
        } else if (status === 'Duplicate') {
            this.statusForm.controls['processed'].setValue(false);
        }

        if (
            !this.statusForm.controls.processed.value &&
            !this.statusForm.controls.duplicate.value
        ) {
            status = 'Read';
        }

        this.userFeedback.status = status;

        this.userFeedbackService
            .put([this.userFeedback.id], this.userFeedback)
            .subscribe();
    }

    getComments(): void {
        this.queryParams.set('feedbackId', this.userFeedback.id);

        this.commentService
            .get([], this.queryParams)
            .subscribe((response: Page<UserComment>) => {
                this.page = response;
            });
    }
}
