import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Role } from 'src/app/models/role.model';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogContent,
} from '@angular/material/dialog';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/User';
import { PASSWORDREQUIREMENTS } from 'src/app/constants/stringConstants';
import { Location } from 'src/app/models/location.model';
import { LocationService } from 'src/app/services/location.service';
import { Page } from 'src/app/models/page.model';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { Subscription } from 'rxjs';
import { EmployeeTypeService } from 'src/app/services/employee-type.service';
import { SettingsService } from 'src/app/services/setting.service';
import { DEFAULT_EMPLOYEE_TYPE_SETTING_ID } from 'src/app/constants/settingContants';
import { Setting } from 'src/app/models/setting.model';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { DialogFooterComponent } from '../../dialog-footer/dialog-footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor } from '@angular/common';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { VisibleDirective } from '../../../directives/visible.directive';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss'],
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatButtonModule,
        DialogFooterComponent,
        VisibleDirective,
    ]
})
export class AddUserComponent implements OnInit, OnDestroy {
    roles!: Role[];
    managers: User[];
    userForm!: FormGroup;
    saveFailed = false;
    saving = false;
    levels: number[] = [1, 2, 3];
    locations: Location[];
    passwordRequirements = PASSWORDREQUIREMENTS;

    employeeTypes: EmployeeType[] = [];
    employeeTypeSubscription: Subscription;

    defaultEmployeeType!: EmployeeType;
    settingsSubscription: Subscription;

    queryParams: Map<string, unknown> = new Map<string, unknown>([
        ['count', 1000],
    ]);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Role[],
        private dialogRef: MatDialogRef<boolean>,
        private userService: UserService,
        private locationService: LocationService,
        private employeeTypeService: EmployeeTypeService,
        private settingsService: SettingsService,
    ) {}

    ngOnInit(): void {
        this.userForm = this.initializeForm();
        this.roles = this.data;
        this.userService
            .get(
                [],
                new Map<string, unknown>([
                    ['status', ACTIVE],
                    ['count', 1000],
                ]),
            )
            .subscribe((users: Page<User>) => (this.managers = users.items));
        this.locationService
            .get([], this.queryParams)
            .subscribe((x: Page<Location>) => (this.locations = x.items));

        const activeMap = new Map<string, string>([['status', ACTIVE]]);

        this.employeeTypeSubscription = this.employeeTypeService
            .get([], activeMap)
            .subscribe((x: Page<EmployeeType>) => {
                this.employeeTypes = x.items;
            });

        this.settingsSubscription = this.settingsService
            .get([DEFAULT_EMPLOYEE_TYPE_SETTING_ID])
            .subscribe((setting: Setting) => {
                // If there was a default setting set, we need to set the Drop Down's value.
                if (
                    setting.value.trim() !=
                    '00000000-0000-0000-0000-000000000000'
                ) {
                    this.userForm
                        .get('employeeTypeId')
                        ?.setValue(setting.value);
                }
            });
    }

    ngOnDestroy(): void {
        this.employeeTypeSubscription.unsubscribe();
        this.settingsSubscription.unsubscribe();
    }

    onResetClick(): void {
        this.userForm.reset();
    }

    onCloseClick(): void {
        this.dialogRef.close(false);
    }

    onSaveClick(): void {
        this.userForm.markAllAsTouched();
        if (!this.userForm.valid) {
            return;
        }
        this.saving = true;
        const user = new User(this.userForm.value);
        user.roleId = this.userForm.controls.roleId.value as string;
        user.status = ACTIVE;
        this.userService.post([], user).subscribe(
            () => {
                this.dialogRef.close(true);
            },
            () => {
                this.saveFailed = true;
                this.saving = false;
            },
        );
    }

    private initializeForm(): FormGroup {
        return new FormGroup({
            email: new FormControl(null, {
                validators: [
                    Validators.email.bind(this),
                    Validators.required.bind(this),
                ],
            }),
            firstName: new FormControl(null, {
                validators: [Validators.required.bind(this)],
            }),
            lastName: new FormControl(null, {
                validators: [Validators.required.bind(this)],
            }),
            roleId: new FormControl(null, {
                validators: [Validators.required.bind(this)],
            }),
            locationId: new FormControl(null, {
                validators: [Validators.required.bind(this)],
            }),
            managerId: new FormControl(null),
            startDate: new FormControl(null, {
                validators: [Validators.required.bind(this)],
            }),
            employeeTypeId: new FormControl(null, [
                Validators.required.bind(this),
            ]),
            is1099: new FormControl(false, {
                validators: [Validators.required.bind(this)],
            }),
            approver: new FormControl(false, {
                validators: [Validators.required.bind(this)],
            }),
        });
    }
}
