import { Injectable } from '@angular/core';
import {
    MatDateRangeSelectionStrategy,
    DateRange,
} from '@angular/material/datepicker';
import { startOfISOWeek, endOfISOWeek } from 'date-fns';

// Selects the entire ISO Week when a date is selected in a Mat-Date-Range-Picker
@Injectable()
export class ISOWeekSelectionStrategy
    implements MatDateRangeSelectionStrategy<Date>
{
    selectionFinished(date: Date | null): DateRange<Date> {
        return this._createISOWeekRange(date);
    }

    createPreview(
        activeDate: Date | null,
        currentRange: DateRange<Date>,
    ): DateRange<Date> {
        if (activeDate) {
            return this._createISOWeekRange(activeDate);
        } else {
            return currentRange;
        }
    }

    private _createISOWeekRange(inputDate: Date | null): DateRange<Date> {
        if (inputDate) {
            return new DateRange<Date>(
                startOfISOWeek(inputDate),
                endOfISOWeek(inputDate),
            );
        } else {
            return null;
        }
    }
}
