<div class="tw-h-full tw-w-full tw-box-border tw-flex tw-flex-row">
    @if (this.authService.loggedIn) {
        <app-sidenav (searchClick)="sidenav.toggle()"></app-sidenav>
    }
    <div class="tw-flex-1 tw-flex tw-flex-col">
        <mat-sidenav-container class="tw-flex-1" [hasBackdrop]="false">
            <mat-sidenav
                #sidenav
                mode="over"
                [disableClose]="true"
                class="tw-w-auto"
            >
                <app-route-search></app-route-search>
            </mat-sidenav>
            <mat-sidenav-content>
                @if (this.authService.loggedIn) {
                    <app-navbar></app-navbar>
                }
                <router-outlet></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
