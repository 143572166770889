import { Injectable } from '@angular/core';
import { AbstractService } from './abstract-service.service';
import {
    FormGroup,
    AbstractControl,
    FormControl,
    Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ApprovalState,
    ITimesheetFormControls,
} from '../interfaces/timesheets';
import { TimeSheet } from '../models/timesheet.model';

@Injectable({
    providedIn: 'root',
})
export class TimesheetService extends AbstractService {
    path = '/timesheet';

    getTimesheets(
        route: string[],
        queryParameters?: Map<string, number>,
    ): Observable<FormGroup<ITimesheetFormControls>[]> {
        let url = this.baseUrl + this.path;

        url +=
            this._buildRouteFromStringArray(route) +
            this._buildQueryParameters(queryParameters);

        return this.httpClient
            .get(url)
            .pipe(map((t: TimeSheet[]) => this.createTimesheetFormGroups(t)));
    }

    private createTimesheetFormGroups(
        timesheets: TimeSheet[],
    ): FormGroup<ITimesheetFormControls>[] {
        const invalidDecimal = (control: AbstractControl) => {
            if (control.value == null) return null;

            const value = Number(control.value);
            if (isNaN(value)) return { invalidDecimal: true }; // Not a valid number

            const decimalPart = value % 1; // Extract decimal part

            if (
                decimalPart !== 0 &&
                decimalPart !== 0.25 &&
                decimalPart !== 0.5 &&
                decimalPart !== 0.75
            ) {
                return { invalidDecimal: true }; // Invalid decimal
            }

            return null; // Valid input
        };

        const timesheetFormGroups: FormGroup<ITimesheetFormControls>[] = [];

        timesheets.forEach((timesheet) => {
            const timesheetForm = new FormGroup<ITimesheetFormControls>({
                id: new FormControl<string>(timesheet.id),
                projectId: new FormControl<string>(timesheet.projectId),
                taskId: new FormControl<string>(timesheet.taskId),
                week: new FormControl<number>(timesheet.week),
                year: new FormControl<number>(timesheet.year),
                monday: new FormControl<number>(timesheet.monday, [
                    Validators.max(24),
                    Validators.min(0),
                    invalidDecimal,
                ]),
                tuesday: new FormControl<number>(timesheet.tuesday, [
                    Validators.max(24),
                    Validators.min(0),
                    invalidDecimal,
                ]),
                wednesday: new FormControl<number>(timesheet.wednesday, [
                    Validators.max(24),
                    Validators.min(0),
                    invalidDecimal,
                ]),
                thursday: new FormControl<number>(timesheet.thursday, [
                    Validators.max(24),
                    Validators.min(0),
                    invalidDecimal,
                ]),
                friday: new FormControl<number>(timesheet.friday, [
                    Validators.max(24),
                    Validators.min(0),
                    invalidDecimal,
                ]),
                saturday: new FormControl<number>(timesheet.saturday, [
                    Validators.max(24),
                    Validators.min(0),
                    invalidDecimal,
                ]),
                sunday: new FormControl<number>(timesheet.sunday, [
                    Validators.max(24),
                    Validators.min(0),
                    invalidDecimal,
                ]),
                mondayNotes: new FormControl<string>(
                    timesheet.mondayNotes ? timesheet.mondayNotes : null,
                    Validators.maxLength(300),
                ),
                tuesdayNotes: new FormControl<string>(
                    timesheet.tuesdayNotes ? timesheet.tuesdayNotes : null,
                    Validators.maxLength(300),
                ),
                wednesdayNotes: new FormControl<string>(
                    timesheet.wednesdayNotes ? timesheet.wednesdayNotes : null,
                    Validators.maxLength(300),
                ),
                thursdayNotes: new FormControl<string>(
                    timesheet.thursdayNotes ? timesheet.thursdayNotes : null,
                    Validators.maxLength(300),
                ),
                fridayNotes: new FormControl<string>(
                    timesheet.fridayNotes ? timesheet.fridayNotes : null,
                    Validators.maxLength(300),
                ),
                saturdayNotes: new FormControl<string>(
                    timesheet.saturdayNotes ? timesheet.saturdayNotes : null,
                    Validators.maxLength(300),
                ),
                sundayNotes: new FormControl<string>(
                    timesheet.sundayNotes ? timesheet.sundayNotes : null,
                    Validators.maxLength(300),
                ),
                taskName: new FormControl<string>(timesheet.taskName),
                usersTasksId: new FormControl<string>(timesheet.usersTasksId),
                clientName: new FormControl<string>(timesheet.clientName),
                userProfileId: new FormControl<string>(timesheet.userProfileId),
                firstName: new FormControl<string>(timesheet.firstName),
                lastName: new FormControl<string>(timesheet.lastName),
                projectName: new FormControl<string>(timesheet.projectName),
                submitted: new FormControl<boolean>(timesheet.submitted),
                locked: new FormControl<boolean>(timesheet.locked),
                saved: new FormControl<boolean>(timesheet.saved),
                approvalState: new FormControl<ApprovalState>(
                    timesheet.approvalState,
                ),
            });

            timesheetFormGroups.push(timesheetForm);
        });

        return timesheetFormGroups;
    }
}
