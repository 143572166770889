import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot_password/forgot-password.component';
import { HomeComponent } from './components/home/home.component';
import { PasswordResetComponent } from './components/password_reset/password-reset.component';
import { RolesPermissionsComponent } from './components/roles-permissions/roles-permissions.component';
import { AuthGuard } from './auth.guard';
import { UsersComponent } from './components/users/users.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ClientComponent } from './components/clients/client.component';
import { LocationsComponent } from './components/locations/locations.component';
import { ReportsComponent } from './components/reports/reports.component';
import { BillingReportComponent } from './components/reports/billing-report/billing-report.component';
import { PayrollReportComponent } from './components/reports/payroll-report/payroll-report.component';
import { PaidTimeOffReportComponent } from './components/reports/paid-time-off-report/paid-time-off-report.component';
import { Modules } from './enums/modules.enum';
import { PermissionGuard } from './permission.guard';
import { DocumentsComponent } from './components/documents/documents.component';
import { TimesheetDashboardComponent } from './components/timesheet-dashboard/timesheet-dashboard.component';
import { EmployeeDirectoryComponent } from './components/employee-directory/employee-directory.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { EmployeeTypesComponent } from './components/employee-types/employee-types.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { ContractorTimesheetDashboardComponent } from './components/contractor-timesheet-dashboard/contractor-timesheet-dashboard.component';
import { AdminTimesheetsComponent } from './components/admin-timesheet/admin-timesheet';
import { WheelOfLunchComponent } from './components/wheel-of-lunch/wheel-of-lunch.component';
import { WorkOrdersComponent } from './components/work-orders/work-orders.component';
import { OpenWorkOrderComponent } from './components/work-orders/open-work-order/open-work-order.component';
import { SubmissionsComponent } from './components/submissions/submissions.component';
import { UserFeedbackComponent } from './components/user-feedback/user-feedback.component';
import { MyFeedbackComponent } from './components/my-feedback/my-feedback.component';

export const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
    },
    {
        path: 'role',
        component: RolesPermissionsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Role', module: Modules.ROLES },
    },
    {
        path: 'locations',
        component: LocationsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Locations', module: Modules.LOCATIONS },
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'wheel-of-lunch',
        component: WheelOfLunchComponent,
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: { title: 'Home' },
    },
    {
        path: 'reset-password/:token',
        component: PasswordResetComponent,
    },
    {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Users', module: Modules.USERS },
    },
    {
        path: 'Admin-Timesheet',
        component: AdminTimesheetsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Admin Timesheets', module: Modules.ADMINTIMESHEETS },
    },
    {
        path: 'submitted-timesheets',
        component: SubmissionsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Submissions', module: Modules.SUBMISSIONS },
    },
    {
        path: 'Timesheet-Dashboard',
        component: TimesheetDashboardComponent,
        canActivate: [AuthGuard],
        data: { title: 'Timesheets', module: Modules.TIMESHEETS },
    },
    {
        path: 'my-feedback',
        component: MyFeedbackComponent,
        canActivate: [AuthGuard],
        data: { title: 'My Feedback', module: Modules.MYFEEDBACK },
    },
    {
        path: 'Contractor-Timesheet-Dashboard',
        component: ContractorTimesheetDashboardComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
            title: 'Contractor Timesheets',
            module: Modules.CONTRACTORTIMESHEETS,
        },
    },
    {
        path: 'projects',
        component: ProjectsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Projects', module: Modules.PROJECTS },
    },
    {
        path: 'clients',
        component: ClientComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Client', module: Modules.CLIENTS },
    },
    {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Reports', module: Modules.REPORT },
    },
    {
        path: 'reports/billing-report',
        component: BillingReportComponent,
    },
    {
        path: 'reports/payroll-report',
        component: PayrollReportComponent,
    },
    {
        path: 'reports/paid-time-off-report',
        component: PaidTimeOffReportComponent,
    },
    {
        path: 'user-feedback',
        component: UserFeedbackComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'User Feedback', module: Modules.USERFEEDBACK },
    },
    {
        path: 'workorders',
        component: WorkOrdersComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Work Orders', module: Modules.WORKORDERS },
    },
    {
        path: 'openworkorder',
        component: OpenWorkOrderComponent,
        data: { title: 'Work Order Info', module: Modules.OPENWORKORDER },
    },
    {
        path: 'documents',
        component: DocumentsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Documents', module: Modules.DOCUMENTS },
    },
    {
        path: 'employee-directory',
        component: EmployeeDirectoryComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
            title: 'Employee Directory',
            module: Modules.EMPLOYEEDIRECTORY,
        },
    },
    {
        path: 'calendar',
        component: CalendarComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Calendar', module: Modules.CALENDAR },
    },
    {
        path: 'configuration',
        component: ConfigurationComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Configuration', module: Modules.SETTINGS },
    },
    {
        path: 'employee-types',
        component: EmployeeTypesComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { title: 'Employee Types', module: Modules.USERS },
    },
    {
        path: 'user-settings',
        component: UserSettingsComponent,
        canActivate: [AuthGuard],
        data: { title: 'User Settings' },
    },
    {
        path: '**',
        redirectTo: 'home',
        data: { title: 'Home' },
    },
];
