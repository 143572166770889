<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingTypes"
></mat-progress-bar>

<div class="tw-flex tw-justify-center tw-mt-6">
    <div class="tw-text-2xl tw-w-3/4">
        Add/Edit Employee Type

        <mat-slide-toggle
            class="tw-ml-2"
            (change)="toggleActiveInactive()"
            [checked]="employeeTypeStatus"
        >
            @if (employeeTypeStatus) {
                <ng-container>Active</ng-container>
            } @else {
                <ng-container>Inactive</ng-container>
            }
        </mat-slide-toggle>
    </div>
</div>

<div
    class="tw-flex tw-flex-row tw-justify-center tw-mt-6 tw-items-start tw-gap-4"
>
    <div
        class="tw-flex tw-flex-col tw-p-4 tw-gap-4 tw-box-border tw-border-2 tw-border-solid tw-rounded-m3lg tw-border-customborder tw-w-1/4"
    >
        <div class="tw-flex tw-justify-between">
            <div class="tw-text-2xl">Employee Types</div>

            <button
                mat-raised-button
                matTooltip="Add Employee Type"
                (click)="openAddDialog()"
            >
                + Employee Type
            </button>
        </div>

        <div [formGroup]="searchForm" class="form-group">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>Search Employee Types</mat-label>
                <input matInput type="text" formControlName="search" />
            </mat-form-field>
        </div>

        <mat-selection-list
            #employeeTypeSelectionList
            hideSingleSelectionIndicator
            class="scrollable"
            [multiple]="false"
            (selectionChange)="employeeTypeSelectionChange($event)"
        >
            @for (employeeType of page.items; track employeeType) {
                <mat-list-option [value]="employeeType">
                    {{ employeeType.name }}
                </mat-list-option>
                @if (!$last) {
                    <mat-divider></mat-divider>
                }
            }
        </mat-selection-list>

        <mat-paginator
            [pageIndex]="pageIndex"
            [pageSize]="page.countRequested"
            [length]="page.totalCount"
            [hidePageSize]="true"
            (page)="changePage($event)"
        ></mat-paginator>
    </div>

    <div class="tw-w-1/2">
        <mat-progress-bar
            mode="indeterminate"
            [appVisible]="loadingEmployeeTypeEdit"
        ></mat-progress-bar>

        <app-edit-employee-type
            [hidden]="!employeeTypeSelected"
            [employeeTypeObservable]="employeeTypeSubject.asObservable()"
            (saveCompleted)="saveCompleted()"
        ></app-edit-employee-type>
    </div>
</div>
