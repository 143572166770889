import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Crud } from 'src/app/enums/crud.enums';
import { Permission } from 'src/app/models/permission.model';
import { Role } from 'src/app/models/role.model';
import { PermissionsService } from 'src/app/services/permission.service';
import { RolesService } from 'src/app/services/roles.service';
import { AddRoleDialog } from './add-role-dialog/add-role-dialog.component';
import { UpdateUserRoleDialog } from './update-user-role-dialog/update-user-role-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
    MatPaginator,
    MatPaginatorModule,
    PageEvent,
} from '@angular/material/paginator';
import { Modules } from 'src/app/enums/modules.enum';
import { Me } from 'src/app/models/me.model';
import { MeService } from 'src/app/services/me.service';
import { UserService } from 'src/app/services/user.service';
import { SEARCH_DEBOUNCE_MS } from 'src/app/constants/searchConstants';
import { Page } from 'src/app/models/page.model';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { User } from 'src/app/models/User';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppStateService } from '../../services/app-state-service';
import { VisibleDirective } from '../../directives/visible.directive';

export interface dialogRefResponse {
    continueUpdate: boolean;
}

@Component({
    selector: 'app-roles-permissions',
    templateUrl: './roles-permissions.component.html',
    styleUrls: ['./roles-permissions.component.scss'],
    standalone: true,
    imports: [
        MatTooltipModule,
        NgIf,
        MatButtonModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatListModule,
        NgFor,
        MatPaginatorModule,
        MatTableModule,
        MatCheckboxModule,
        VisibleDirective,
        NgClass,
    ],
})
export class RolesPermissionsComponent implements OnInit {
    Crud = Crud;
    rolesPermissions = new Map<string, boolean>([
        [Crud.Create, false],
        [Crud.Update, false],
        [Crud.Delete, false],
    ]);

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    checkAll = false;
    roles: Role[] = [];
    permissions: Permission[] = [];
    modules: string[] = [];
    selectedRolePermissions = [];
    selectedRole: Role;
    isRoleSelected = false;
    activePermissions: Permission[] = [];
    hasApproveForModules: Map<string, boolean>;
    displayedColumns: string[] = [
        'Module',
        'Create',
        'Read',
        'Update',
        'Delete',
        'Approve',
    ];
    permissionsUpdateArray = [];
    loadingSelectedRolePermissions = false;
    loadingPermissions = false;
    loadingRoles = false;
    disableSaveChanges = true;
    isViewingActiveRoles = true;
    roleNameFormControl = new FormControl<string>('');

    pageIndex = 0;
    queryParams: Map<string, unknown> = new Map<string, unknown>();
    fullLength: number;
    countSize: number;

    constructor(
        private rolesService: RolesService,
        private permissionService: PermissionsService,
        public dialog: MatDialog,
        private appStateService: AppStateService,
        private meService: MeService,
        private userService: UserService,
    ) {
        this.selectedRole = null;
        this.isRoleSelected = false;

        this.roleNameFormControl.valueChanges
            .pipe(debounceTime(SEARCH_DEBOUNCE_MS), distinctUntilChanged())
            .subscribe(() => {
                this.filterRoles();
            });
    }

    ngOnInit(): void {
        this.getAllRoles();
        this.getAllPermissions();

        this.appStateService.me$.subscribe((me: Me) => {
            if (me.id) {
                this.rolesPermissions.forEach((value: boolean, key: string) => {
                    const has = this.permissionService.permissionsHas(
                        Modules.ROLES,
                        key,
                        me.permissions,
                    );
                    if (has) {
                        this.rolesPermissions.set(key, has);
                    }
                });
            }
        });
    }

    getAllRoles(): void {
        this.roles = [];
        this.loadingRoles = true;

        this.queryParams.set('count', 10);

        if (this.pageIndex) {
            this.queryParams.set('offset', this.pageIndex * 10);
        } else {
            this.queryParams.set('offset', 0);
        }

        if (this.isViewingActiveRoles) {
            this.queryParams.set('status', ACTIVE);
        } else {
            this.queryParams.set('status', INACTIVE);
        }

        this.rolesService
            .get([], this.queryParams)
            .subscribe((roles: Page<Role>) => {
                this.roles = roles.items;
                this.countSize = 10;
                this.fullLength = roles.totalCount;
                this.loadingRoles = false;
            });
    }

    getAllPermissions(): void {
        this.loadingPermissions = true;
        this.permissions = [];
        const queryParams = new Map([['count', -1]]);

        this.permissionService.get([], queryParams).subscribe(
            (response: Page<Permission>) => {
                this.permissions = response.items;
                this.createArrayOfModuleNames();
                this.loadingPermissions = false;
                this.hasApproveForModules =
                    this.mapModulesWithApprovePermission();
            },
            () => {
                this.loadingPermissions = false;
            },
        );
    }

    clearSearchValue(): void {
        this.roleNameFormControl.reset();
    }

    toggleRolesList(): void {
        this.clearSearchValue();
        this.isViewingActiveRoles = !this.isViewingActiveRoles; //Needs to happen before getAllRoles
        this.paginator.firstPage();
        this.changePageIndex(0);
        this.getAllPermissions();

        this.selectedRolePermissions = [];
        this.selectedRole = null;
        this.isRoleSelected = false;
        this.disableSaveChanges = true;
    }

    createArrayOfModuleNames(): void {
        const modules: string[] = this.permissions.map(
            (entry) => entry['module'],
        );
        const set = new Set(modules);
        this.modules = Array.from(set);
    }

    createSelectedRolePermissions(): void {
        this.selectedRolePermissions = [];
        this.disableSaveChanges = true;

        this.modules.forEach((module) => {
            const permission: Permission = new Permission();
            permission.module = module;

            permission.create =
                this.selectedRole.permissions.filter(
                    (selectedRolePermission) => {
                        return (
                            selectedRolePermission.module === module &&
                            selectedRolePermission.action.toLowerCase() ===
                                Crud.Create.toLowerCase()
                        );
                    },
                ).length > 0;

            permission.read =
                this.selectedRole.permissions.filter(
                    (selectedRolePermission) => {
                        return (
                            selectedRolePermission.module === module &&
                            selectedRolePermission.action.toLowerCase() ===
                                Crud.Read.toLowerCase()
                        );
                    },
                ).length > 0;

            permission.update =
                this.selectedRole.permissions.filter(
                    (selectedRolePermission) => {
                        return (
                            selectedRolePermission.module === module &&
                            selectedRolePermission.action.toLowerCase() ===
                                Crud.Update.toLowerCase()
                        );
                    },
                ).length > 0;

            permission.delete =
                this.selectedRole.permissions.filter(
                    (selectedRolePermission) => {
                        return (
                            selectedRolePermission.module === module &&
                            selectedRolePermission.action.toLowerCase() ===
                                Crud.Delete.toLowerCase()
                        );
                    },
                ).length > 0;

            permission.approve =
                this.selectedRole.permissions.filter(
                    (selectedRolePermission) => {
                        return (
                            selectedRolePermission.module === module &&
                            selectedRolePermission.action.toLowerCase() ===
                                Crud.Approve.toLowerCase()
                        );
                    },
                ).length > 0;

            this.selectedRolePermissions.push(permission);
        });
    }

    mapModulesWithApprovePermission(): Map<string, boolean> {
        const hasApproveForModules = new Map<string, boolean>();

        this.permissions.forEach((permission) => {
            if (hasApproveForModules.get(permission.module)) {
                return;
            }

            hasApproveForModules.set(
                permission.module,
                permission.action.toLowerCase() == Crud.Approve.toLowerCase(),
            );
        });

        return hasApproveForModules;
    }

    onSelectRole(event: MatSelectionListChange | Role): void {
        let role: Role;

        if (event instanceof Role) {
            role = event;
        } else {
            role = event.options[0].value as Role;
        }

        this.disableSaveChanges = true;
        this.permissionsUpdateArray = [];
        this.loadingSelectedRolePermissions = true;
        this.loadingPermissions = true;
        this.rolesService.get([role.id]).subscribe(
            (foundRole: Role) => {
                //These are in a specific order due to methods needing certain variables set up
                this.selectedRole = foundRole;
                this.createArrayOfModuleNames();
                this.isRoleSelected = true;
                this.selectedRole = foundRole;
                this.createSelectedRolePermissions();
                this.loadingSelectedRolePermissions = false;
                this.loadingPermissions = false;
                this.setButtonLabelForSelectAll();
            },
            () => {
                this.loadingPermissions = false;
            },
        );
    }

    filterRoles(): void {
        this.loadingRoles = true;
        const map = new Map();
        map.set('name', this?.roleNameFormControl?.value ?? '');

        this.rolesService.get([], map).subscribe((roles: Page<Role>) => {
            this.selectedRole = null;
            this.isRoleSelected = false;

            if (this.isViewingActiveRoles) {
                this.roles = roles.items.filter(
                    (role) =>
                        role.status.toLowerCase() === ACTIVE.toLowerCase(),
                );
            } else {
                this.roles = roles.items.filter(
                    (role) =>
                        role.status.toLowerCase() === INACTIVE.toLowerCase(),
                );
            }

            this.loadingRoles = false;
        });
    }

    updateSelectedRolePermissions(
        permission: Permission,
        action: string,
    ): void {
        this.disableSaveChanges = false;
        this.permissionsUpdateArray = [];

        this.selectedRolePermissions.forEach(
            (selectedRolePermission: Permission) => {
                if (
                    selectedRolePermission.module === permission.module &&
                    action.toLowerCase() === Crud.Create.toLowerCase()
                ) {
                    selectedRolePermission.create =
                        !selectedRolePermission.create;
                }

                if (
                    selectedRolePermission.module === permission.module &&
                    action.toLowerCase() === Crud.Read.toLowerCase()
                ) {
                    selectedRolePermission.read = !selectedRolePermission.read;
                }

                if (
                    selectedRolePermission.module === permission.module &&
                    action.toLowerCase() === Crud.Update.toLowerCase()
                ) {
                    selectedRolePermission.update =
                        !selectedRolePermission.update;
                }

                if (
                    selectedRolePermission.module === permission.module &&
                    action.toLowerCase() === Crud.Delete.toLowerCase()
                ) {
                    selectedRolePermission.delete =
                        !selectedRolePermission.delete;
                }

                if (
                    selectedRolePermission.module === permission.module &&
                    action.toLowerCase() === Crud.Approve.toLowerCase()
                ) {
                    selectedRolePermission.approve =
                        !selectedRolePermission.approve;
                }
            },
        );
    }

    savePermissionChanges(): void {
        this.selectedRolePermissions.forEach(
            (selectedRolePermission: Permission) => {
                if (selectedRolePermission.create) {
                    const newModule: Permission = new Permission();
                    newModule.id = this.permissions.filter(
                        (permission) =>
                            permission.module ===
                                selectedRolePermission.module &&
                            permission.action.toLowerCase() ===
                                Crud.Create.toLowerCase(),
                    )[0].id;
                    newModule.action = Crud.Create;
                    newModule.module = selectedRolePermission.module;
                    this.permissionsUpdateArray.push(newModule);
                }

                if (selectedRolePermission.read) {
                    const newModule: Permission = new Permission();
                    newModule.id = this.permissions.filter(
                        (permission) =>
                            permission.module ===
                                selectedRolePermission.module &&
                            permission.action.toLowerCase() ===
                                Crud.Read.toLowerCase(),
                    )[0].id;
                    newModule.action = Crud.Read;
                    newModule.module = selectedRolePermission.module;
                    this.permissionsUpdateArray.push(newModule);
                }

                if (selectedRolePermission.update) {
                    const newModule: Permission = new Permission();
                    newModule.id = this.permissions.filter(
                        (permission) =>
                            permission.module ===
                                selectedRolePermission.module &&
                            permission.action.toLowerCase() ===
                                Crud.Update.toLowerCase(),
                    )[0].id;
                    newModule.action = Crud.Update;
                    newModule.module = selectedRolePermission.module;
                    this.permissionsUpdateArray.push(newModule);
                }

                if (selectedRolePermission.delete) {
                    const newModule: Permission = new Permission();
                    newModule.id = this.permissions.filter(
                        (permission) =>
                            permission.module ===
                                selectedRolePermission.module &&
                            permission.action.toLowerCase() ===
                                Crud.Delete.toLowerCase(),
                    )[0].id;
                    newModule.action = Crud.Delete;
                    newModule.module = selectedRolePermission.module;
                    this.permissionsUpdateArray.push(newModule);
                }

                if (selectedRolePermission.approve) {
                    const newModule: Permission = new Permission();
                    newModule.id = this.permissions.filter(
                        (permission) =>
                            permission.module ===
                                selectedRolePermission.module &&
                            permission.action.toLowerCase() ===
                                Crud.Approve.toLowerCase(),
                    )[0].id;
                    newModule.action = Crud.Approve;
                    newModule.module = selectedRolePermission.module;
                    this.permissionsUpdateArray.push(newModule);
                }
            },
        );

        this.rolesService
            .put([this.selectedRole.id], {
                permissions: this.permissionsUpdateArray,
            })
            .subscribe(() => {
                this.selectedRole = null;
                this.isRoleSelected = false;
                this.getAllRoles();
                this.getAllPermissions();
                this.disableSaveChanges = true;
                this.meService.get([]).subscribe((me: Me) => {
                    this.appStateService.me$.next(me);
                    this.rolesPermissions = new Map<string, boolean>([
                        [Crud.Create, false],
                        [Crud.Update, false],
                        [Crud.Delete, false],
                    ]);

                    this.rolesPermissions.forEach(
                        (value: boolean, key: string) => {
                            const has = this.permissionService.permissionsHas(
                                Modules.ROLES,
                                key,
                                me.permissions,
                            );
                            if (has) {
                                this.rolesPermissions.set(key, has);
                            }
                        },
                    );
                });
            });
    }

    changeActiveStatus(): void {
        const changedRole = this.selectedRole;

        if (changedRole.status.toUpperCase() === ACTIVE) {
            const queryParams: Map<string, unknown> = new Map<string, unknown>([
                ['status', ACTIVE],
                ['count', 10],
                ['offset', 0],
                ['roleId', this.selectedRole.id],
            ]);

            this.userService
                .get([], queryParams)
                .subscribe((result: Page<User>) => {
                    if (result.totalCount > 0) {
                        const dialogRef = this.dialog.open(
                            UpdateUserRoleDialog,
                            {
                                data: this.selectedRole.id,
                            },
                        );

                        dialogRef
                            .afterClosed()
                            .subscribe((response: dialogRefResponse) => {
                                if (response.continueUpdate) {
                                    changedRole.status = INACTIVE;

                                    this.rolesService
                                        .put(
                                            [this.selectedRole.id],
                                            changedRole,
                                        )
                                        .subscribe(() => {
                                            this.getAllRoles();
                                            this.getAllPermissions();

                                            this.selectedRolePermissions = [];
                                            this.selectedRole = null;
                                            this.isRoleSelected = false;
                                            this.disableSaveChanges = true;
                                        });
                                }
                            });
                    } else {
                        changedRole.status = INACTIVE;

                        this.rolesService
                            .put([this.selectedRole.id], changedRole)
                            .subscribe(() => {
                                this.getAllRoles();
                                this.getAllPermissions();

                                this.selectedRolePermissions = [];
                                this.selectedRole = null;
                                this.isRoleSelected = false;
                                this.disableSaveChanges = true;
                            });
                    }
                });
        } else {
            changedRole.status = ACTIVE;

            this.rolesService
                .put([this.selectedRole.id], changedRole)
                .subscribe(() => {
                    this.getAllRoles();
                    this.getAllPermissions();

                    this.selectedRolePermissions = [];
                    this.selectedRole = null;
                    this.isRoleSelected = false;
                    this.disableSaveChanges = true;
                });
        }
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(AddRoleDialog);

        dialogRef.afterClosed().subscribe((response) => {
            if (response != null) {
                this.rolesService.get([response]).subscribe((role: Role) => {
                    this.selectedRole = role;
                    this.isRoleSelected = true;
                    this.onSelectRole(role);
                    this.getAllRoles();
                    this.isViewingActiveRoles = true;
                });
            }
        });
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number): void {
        this.pageIndex = index;
        this.getAllRoles();
    }

    setButtonLabelForSelectAll(): void {
        const rolesWithApprove = new Map(
            [...this.hasApproveForModules].filter(([, v]) => v),
        );

        const rolesConditonal = (element: Permission) => {
            return (
                !element.create ||
                !element.read ||
                !element.update ||
                !element.delete ||
                (rolesWithApprove.has(element.module) && !element.approve)
            );
        };

        this.checkAll = !this.selectedRolePermissions.some(rolesConditonal);
    }

    selectUnselectAllPermissions(): void {
        const rolesWithApprove = new Map(
            [...this.hasApproveForModules].filter(([, v]) => v),
        );
        this.checkAll = !this.checkAll;
        this.disableSaveChanges = false;

        this.selectedRolePermissions.forEach(
            (selectedRolePermission: Permission) => {
                selectedRolePermission.create = this.checkAll;
                selectedRolePermission.read = this.checkAll;
                selectedRolePermission.update = this.checkAll;
                selectedRolePermission.delete = this.checkAll;
                if (rolesWithApprove.has(selectedRolePermission.module)) {
                    selectedRolePermission.approve = this.checkAll;
                }
            },
        );
    }
}
