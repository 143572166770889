import { Injectable } from '@angular/core';
import { AbstractService } from './abstract-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DocumentService extends AbstractService {
    path = '/Document';

    constructor(private http: HttpClient) {
        super(http);
    }

    // post the file as a form body with enctype header
    uploadFile<T>(
        document: File,
        fileName: string,
        containerName: string,
    ): Observable<T> {
        const formData: FormData = new FormData();
        formData.append('formFile', document, fileName);

        const options = {
            headers: new HttpHeaders({
                enctype: 'multipart/form-data',
                Accept: 'application/json',
            }),
        };

        return this.http.post<T>(
            `${this.baseUrl}${this.path}?containerName=${containerName}`,
            formData,
            options,
        );
    }

    download<T>(documentId: string): Observable<T> {
        return this.http.get<T>(
            `${this.baseUrl}${this.path}/${documentId}/Download`,
            this.httpOptions,
        );
    }
}
