import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { Location } from 'src/app/models/location.model';
import { LocationService } from 'src/app/services/location.service';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

interface State {
    value: string;
    viewValue: string;
}

interface ILocationFormControls {
    state: FormControl<string>;
    city: FormControl<string>;
    zipCode: FormControl<string>;
    name: FormControl<string>;
    status: FormControl<boolean>;
}

@Component({
    selector: 'app-edit-location',
    templateUrl: './edit-location.component.html',
    styleUrls: ['./edit-location.component.scss'],
    imports: [
        ReactiveFormsModule,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
    ],
})
export class EditLocationComponent implements OnInit {
    @Output() updateSuccess = new EventEmitter<boolean>();
    @Output() loadingLocations = new EventEmitter<boolean>();

    location!: Location;
    locationForm: FormGroup<ILocationFormControls>;
    failed = false;
    loading = false;

    states: State[] = [
        { value: 'Alabama-0', viewValue: 'Alabama' },
        { value: 'Alaska-1', viewValue: 'Alaska' },
        { value: 'Arizona-2', viewValue: 'Arizona' },
        { value: 'Arkansas-3', viewValue: 'Arkansas' },
        { value: 'California-4', viewValue: 'California' },
        { value: 'Colorado-5', viewValue: 'Colorado' },
        { value: 'Connecticut-6', viewValue: 'Connecticut' },
        { value: 'Delaware-7', viewValue: 'Delaware' },
        { value: 'Florida-8', viewValue: 'Florida' },
        { value: 'Georgia-9', viewValue: 'Georgia' },
        { value: 'Hawaii-10', viewValue: 'Hawaii' },
        { value: 'Idaho-11', viewValue: 'Idaho' },
        { value: 'Illinois-12', viewValue: 'Illinois' },
        { value: 'Indiana-13', viewValue: 'Indiana' },
        { value: 'Iowa-14', viewValue: 'Iowa' },
        { value: 'Kansas-15', viewValue: 'Kansas' },
        { value: 'Kentucky-16', viewValue: 'Kentucky' },
        { value: 'Louisiana-17', viewValue: 'Louisiana' },
        { value: 'Maine-18', viewValue: 'Maine' },
        { value: 'Maryland-19', viewValue: 'Maryland' },
        { value: 'Massachusetts-20', viewValue: 'Massachusetts' },
        { value: 'Michigan-21', viewValue: 'Michigan' },
        { value: 'Minnesota-22', viewValue: 'Minnesota' },
        { value: 'Mississippi-23', viewValue: 'Mississippi' },
        { value: 'Missouri-24', viewValue: 'Missouri' },
        { value: 'Montana-25', viewValue: 'Montana' },
        { value: 'Nebraska-26', viewValue: 'Nebraska' },
        { value: 'Nevada-27', viewValue: 'Nevada' },
        { value: 'NewHampshire-28', viewValue: 'New Hampshire' },
        { value: 'NewJersey-29', viewValue: 'New Jersey' },
        { value: 'NewMexico-30', viewValue: 'New Mexico' },
        { value: 'NewYork-31', viewValue: 'New York' },
        { value: 'NorthCarolina-32', viewValue: 'North Carolina' },
        { value: 'NorthDakota-33', viewValue: 'North Dakota' },
        { value: 'Ohio-34', viewValue: 'Ohio' },
        { value: 'Oklahoma-35', viewValue: 'Oklahoma' },
        { value: 'Oregon-36', viewValue: 'Oregon' },
        { value: 'Pennsylvania-37', viewValue: 'Pennsylvania' },
        { value: 'RhodeIsland-38', viewValue: 'Rhode Island' },
        { value: 'SouthCarolina-39', viewValue: 'South Carolina' },
        { value: 'SouthDakota-40', viewValue: 'South Dakota' },
        { value: 'Tennessee-41', viewValue: 'Tennessee' },
        { value: 'Texas-42', viewValue: 'Texas' },
        { value: 'Utah-43', viewValue: 'Utah' },
        { value: 'Vermont-44', viewValue: 'Vermont' },
        { value: 'Virginia-45', viewValue: 'Virginia' },
        { value: 'Washington-46', viewValue: 'Washington' },
        { value: 'WestVirginia-47', viewValue: 'West Virginia' },
        { value: 'Wisconsin-48', viewValue: 'Wisconsin' },
        { value: 'Wyoming-49', viewValue: 'Wyoming' },
    ];

    constructor(private locationService: LocationService) {}

    ngOnInit(): void {
        this.initializeForm();
    }

    public resetComponent(): void {
        this.location = null;
        this.initializeForm();
    }

    onFormSave(deselectLocation?: boolean): void {
        if (!this.locationForm.valid) {
            return;
        }

        this.loading = true;
        this.loadingLocations.emit(this.loading);

        const locationToSend = new Location({
            city: this.locationForm.controls.city.value,
            state: this.locationForm.controls.state.value,
            name: this.locationForm.controls.name.value,
            zipCode: this.locationForm.controls.zipCode.value,
            status: '',
        });

        if (this.locationForm.controls.status.value === true) {
            locationToSend.status = ACTIVE;
        } else {
            locationToSend.status = INACTIVE;
        }

        this.locationService.put([this.location.id], locationToSend).subscribe(
            () => {
                this.loading = false;

                if (deselectLocation) {
                    this.updateSuccess.emit(deselectLocation);
                } else {
                    this.updateSuccess.emit();
                }

                this.loadingLocations.emit(this.loading);
            },
            () => {
                this.loadingLocations.emit(this.loading);
                this.failed = true;
            },
        );
    }

    public initializeForm(): void {
        if (this.failed) {
            this.failed = false;
        }

        if (this.location) {
            let checked: boolean;

            if (this.location.status.toLowerCase() === ACTIVE.toLowerCase()) {
                checked = true;
            } else {
                checked = false;
            }
            this.locationForm = new FormGroup<ILocationFormControls>({
                state: new FormControl(this.location.state, [
                    Validators.required.bind(this),
                ]),
                city: new FormControl(this.location.city, [
                    Validators.required.bind(this),
                    Validators.maxLength(50),
                ]),
                zipCode: new FormControl(this.location.zipCode, [
                    Validators.required.bind(this),
                ]),
                name: new FormControl(this.location.name, [
                    Validators.required.bind(this),
                    Validators.maxLength(75),
                ]),
                status: new FormControl(checked),
            });
        } else {
            this.locationForm = new FormGroup<ILocationFormControls>({
                city: new FormControl('', [Validators.required.bind(this)]),
                state: new FormControl('', [Validators.required.bind(this)]),
                zipCode: new FormControl('', [Validators.required.bind(this)]),
                name: new FormControl('', [Validators.required.bind(this)]),
                status: new FormControl(false),
            });
        }
    }
}
