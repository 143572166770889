<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingLocations"
></mat-progress-bar>

<div class="tw-flex tw-justify-center tw-mt-6">
    <div class="tw-text-2xl tw-w-3/4">
        Add/Edit Locations

        <mat-slide-toggle
            class="tw-ml-2"
            (change)="toggleActiveInactive($event)"
            [checked]="locationStatus"
        >
            <div *ngIf="locationStatus; else inactiveLocation">Active</div>
            <ng-template #inactiveLocation>Inactive</ng-template>
        </mat-slide-toggle>
    </div>
</div>

<div
    class="tw-flex tw-flex-row tw-justify-center tw-mt-6 tw-gap-2 tw-items-start"
>
    <div
        class="tw-flex tw-flex-col tw-box-border tw-p-6 tw-gap-4 tw-border-solid tw-rounded-m3lg tw-border-2 tw-border-customborder tw-w-1/4"
    >
        <div class="tw-flex tw-justify-between">
            <div class="tw-text-2xl">Locations</div>

            <button
                [disabled]="!locationsPermissions.get(Crud.Create)"
                mat-raised-button
                matTooltip="Add Location"
                (click)="openAddDialog()"
            >
                + Location
            </button>
        </div>

        <div [formGroup]="searchForm">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>Search Locations...</mat-label>
                <input matInput type="text" formControlName="search" />
            </mat-form-field>
        </div>

        <mat-selection-list
            #locationSelectionList
            hideSingleSelectionIndicator
            class="scrollable"
            multiple="false"
        >
            @for (location of page.items; track location) {
                <mat-list-option [value]="location">
                    {{ location.name }}, {{ location.state }}
                </mat-list-option>
                @if (!$last) {
                    <mat-divider></mat-divider>
                }
            }
        </mat-selection-list>

        <mat-paginator
            class="tw-rounded-b-lg"
            [pageIndex]="pageIndex"
            [pageSize]="page.countRequested"
            [length]="page.totalCount"
            [hidePageSize]="true"
            (page)="changePage($event)"
        ></mat-paginator>
    </div>

    <div class="tw-w-1/2">
        <app-edit-location
            [hidden]="!optionSelected"
            [permissions]="locationsPermissions"
            (updateSuccess)="onUpdateSuccessLoad($event)"
            (loadingLocations)="locationsLoadingToggle($event)"
        ></app-edit-location>
    </div>
</div>
