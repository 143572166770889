import {
    booleanAttribute,
    Directive,
    HostBinding,
    Input,
    OnChanges,
} from '@angular/core';

@Directive({
    selector: '[appVisible]',
    standalone: true,
})
export class VisibleDirective implements OnChanges {
    @Input({ transform: booleanAttribute, required: true })
    appVisible!: boolean;

    @HostBinding('class.tw-invisible') isTwInvisible!: boolean;

    ngOnChanges(): void {
        this.isTwInvisible = !this.appVisible;
    }
}
