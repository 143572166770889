import { NgIf } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import {
    FormGroup,
    FormControl,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
    MAT_DIALOG_DATA,
    MatDialogContent,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DialogFooterComponent } from 'src/app/components/dialog-footer/dialog-footer.component';
import { DialogHeaderComponent } from 'src/app/components/dialog-header/dialog-header.component';
import { VisibleDirective } from 'src/app/directives/visible.directive';
import { StronglyTypedDialog } from 'src/app/models/StronglyTypedDialog';
import { UserComment } from 'src/app/models/UserComment.model';
import { CommentService } from 'src/app/services/comment.service';

export interface DialogData {
    feedbackId: string;
}

@Component({
    selector: 'app-add-comment',
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        DialogFooterComponent,
        VisibleDirective,
    ],
    templateUrl: './add-comment.component.html',
    styleUrl: './add-comment.component.scss'
})
export class AddCommentComponent
    extends StronglyTypedDialog<DialogData, boolean>
    implements OnInit
{
    protected cancelClick = () => this.dialogRef.close(false);
    protected okClick = () => this.dialogRef.close(true);

    commentForm: FormGroup;
    loading = false;
    failed = false;

    constructor(private commentService: CommentService) {
        super();
    }

    ngOnInit(): void {
        this.initializeForm();
    }

    initializeForm(): void {
        this.commentForm = new FormGroup({
            text: new FormControl<string>('', [
                Validators.required.bind(this),
                Validators.maxLength(2000),
            ]),
        });
    }

    onFormSave(): void {
        this.commentForm.updateValueAndValidity();
        if (!this.commentForm.valid) {
            return;
        }
        this.loading = true;
        const comment = new UserComment(this.commentForm.value);
        comment.feedbackId = this.data.feedbackId;

        this.commentService.post([], comment).subscribe({
            next: () => {
                (this.loading = false), this.dialogRef.close(true);
            },
            error: () => {
                (this.failed = true), (this.loading = false);
            },
        });
    }

    onCloseClick(): void {
        this.dialogRef.close(false);
    }
}
