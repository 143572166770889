import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UpdateProfileImageComponent } from '../../update-profile-image/update-profile-image.component';
import { MatDialog } from '@angular/material/dialog';
import { IUserProfileForm } from 'src/app/interfaces/user-profile-form';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ThemeService } from 'src/app/services/theme.service';
import { NgFor } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-my-profile',
    templateUrl: './my-profile.component.html',
    styleUrls: ['./my-profile.component.scss'],
    imports: [
        MatCardModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatRadioModule,
        NgFor,
        MatIconModule,
    ]
})
export class MyProfileComponent {
    themeOptions: string[] = this.themeService.getThemeOptions();

    @Input() userProfileForm: FormGroup<IUserProfileForm>;
    @Input() currentProfileImage: string;

    @Output() updateProfileImageEvent = new EventEmitter();
    @Output() saveThemeEvent = new EventEmitter();

    constructor(
        private dialog: MatDialog,
        private themeService: ThemeService,
    ) {}

    openProfileImageUpdate(): void {
        const dialogRef = this.dialog.open(UpdateProfileImageComponent, {
            width: '1000px',
        });

        dialogRef.afterClosed().subscribe(() => {
            this.updateProfileImageEvent.emit();
        });
    }

    saveTheme(): void {
        this.themeService.setTheme(this.userProfileForm.value.theme);
        this.saveThemeEvent.emit();
    }
}
