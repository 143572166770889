<form
    class="tw-flex tw-flex-col tw-p-6 tw-gap-4 tw-box-border tw-border-solid tw-rounded-m3lg tw-border-2 tw-border-customborder"
    [formGroup]="locationForm"
>
    <div class="tw-text-2xl">Edit</div>

    <div
        class="tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer tw-rounded-m3sm tw-p-2 tw-text-center"
        *ngIf="failed"
    >
        Failed to edit locations.
    </div>

    <mat-form-field appearance="fill">
        <mat-label>Office Name</mat-label>
        <input [readonly]="!location" matInput formControlName="name" />
        <mat-error *ngIf="this.locationForm.controls.name.errors?.required">
            Office Name field is required
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>City</mat-label>
        <input [readonly]="!location" matInput formControlName="city" />
        <mat-error *ngIf="this.locationForm.controls.city.errors?.required">
            City field is required
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>State</mat-label>
        <mat-select formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state.viewValue">
                {{ state.viewValue }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="locationForm.controls.state.errors?.required">
            State field is required
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Zip Code</mat-label>
        <input [readonly]="!location" matInput formControlName="zipCode" />
        <mat-error *ngIf="this.locationForm.controls.zipCode.errors?.required">
            Zip Code field is required
        </mat-error>
    </mat-form-field>

    <mat-checkbox
        [disabled]="!location || !permissions.get(Crud.Delete)"
        formControlName="status"
    >
        Active
    </mat-checkbox>

    <div class="tw-flex tw-justify-between">
        <button
            [disabled]="!location || loading || !permissions.get(Crud.Update)"
            [matTooltip]="getTooltipFromPermission()"
            mat-raised-button
            class="m3-error"
            (click)="onResetClick()"
        >
            Reset
        </button>

        <button
            [disabled]="!location || loading || !permissions.get(Crud.Update)"
            [matTooltip]="getTooltipFromPermission()"
            mat-raised-button
            (click)="onFormSave()"
        >
            Save Changes
        </button>
    </div>
</form>
