<app-dialog-header [title]="data.header"></app-dialog-header>
<div mat-dialog-content>
    <form [formGroup]="emailForm">
        <mat-form-field class="tw-w-full">
            <mat-label>Subject</mat-label>
            <input
                matInput
                maxlength="200"
                #subject
                formControlName="subject"
            />
            <mat-hint align="end">{{ subject.value.length }} / 200</mat-hint>
            <mat-error *ngIf="emailForm.controls.subject.errors?.required">
                Subject is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="tw-w-full" appearance="fill">
            <mat-label>Body</mat-label>

            <textarea
                matInput
                #body
                maxlength="1800"
                rows="5"
                formControlName="body"
            ></textarea>
            <mat-hint align="end">{{ body.value.length }} / 1800</mat-hint>
            <mat-error *ngIf="emailForm.controls.body.errors?.required">
                Body is required.
            </mat-error>
        </mat-form-field>
    </form>
</div>

<app-dialog-footer
    posButtonText="Send Email"
    [disabled]="saving"
    (buttonClick)="onClickSend()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
