import { Component, OnInit } from '@angular/core';
import { MatDialogActions, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { ProfileImage } from 'src/app/interfaces/profile-image';
import { Me } from 'src/app/models/me.model';
import { ProfileImageService } from 'src/app/services/profile-image-service';
import { UserService } from 'src/app/services/user.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { DialogFooterComponent } from '../dialog-footer/dialog-footer.component';
import { AppStateService } from '../../services/app-state-service';

@Component({
    selector: 'app-update-profile-image',
    templateUrl: './update-profile-image.component.html',
    styleUrls: ['./update-profile-image.component.scss'],
    imports: [
        DialogHeaderComponent,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        ImageCropperModule,
        DialogFooterComponent,
    ],
})
export class UpdateProfileImageComponent implements OnInit {
    imageChangedEvent: Event;
    croppedImage: string;
    croppedImageFile: Blob;
    currentUser: Me;
    currentProfileImage = '';

    constructor(
        private dialogRef: MatDialogRef<UpdateProfileImageComponent>,
        private snackBar: MatSnackBar,
        private userService: UserService,
        private appStateService: AppStateService,
        private profileImageService: ProfileImageService,
    ) {}

    ngOnInit(): void {
        this.appStateService.me$.subscribe((me: Me) => {
            this.currentUser = me;
            this.userService
                .get(['profile-image'])
                .subscribe((profileImage: ProfileImage) => {
                    this.currentProfileImage = profileImage.base64Value;
                });
        });
    }

    fileChangeEvent(event: Event): void {
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const files: FileList = event.target['files'] as FileList;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (allowedFileTypes.indexOf(file.type) === -1) {
                this.snackBar.open(
                    'Invalid file type. Allowed file types are JPEG and PNG.',
                    'OK',
                    {
                        duration: 5000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        panelClass: ['error-snackbar'],
                    },
                );
                return;
            }

            const allowedFileSize = 1024 * 1024 * 8; // 8 MB
            if (file.size > allowedFileSize) {
                this.snackBar.open(
                    'File size exceeds allowed limit of 8 MB.',
                    'OK',
                    {
                        duration: 5000,
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        panelClass: ['error-snackbar'],
                    },
                );
                return;
            }
        }

        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
    }

    saveImage(): void {
        const profileImage: ProfileImage = {
            base64Value: this.croppedImage,
            imageUrl: '',
        };

        this.userService
            .post(['save-profile-image'], profileImage)
            .subscribe((savedProfileImage: ProfileImage) => {
                this.profileImageService.announceProfileImageChange();
                this.closeDialog(savedProfileImage.imageUrl);
            });
    }

    loadImageFailed(): void {
        this.snackBar.open('Failed to load image', 'OK', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['error-snackbar'],
        });
    }

    closeDialog(url?: string): void {
        const image: ProfileImage = {
            base64Value: '',
            imageUrl: url,
        };
        if (url) {
            this.dialogRef.close(image);
        } else {
            this.dialogRef.close();
        }
    }
}
