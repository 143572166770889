import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Setting } from 'src/app/models/setting.model';
import { SettingsService } from 'src/app/services/setting.service';
import { Page } from 'src/app/models/page.model';
import { EditSettingComponent } from './edit-setting/edit-setting.component';
import {
    MatPaginator,
    PageEvent,
    MatPaginatorModule,
} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MatSelectionListChange, MatListModule } from '@angular/material/list';
import { Subscription } from 'rxjs';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { SettingEnum } from 'src/app/enums/settings.enum';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { VisibleDirective } from '../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import {
    COUNT,
    NAME,
    OFFSET,
    STATUS,
} from 'src/app/constants/queryParamConstants';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
    imports: [
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        EditSettingComponent,
        VisibleDirective,
        MatCardModule,
    ],
})
export class ConfigurationComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    @ViewChild(EditSettingComponent)
    editSettingComponent!: EditSettingComponent;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    settingEnum = SettingEnum;
    settingStatus = true;
    loadingSettings = false;
    settingSelected = false;

    searchForm = new FormGroup({
        search: new FormControl<string>(''),
    });

    pageIndex = 0;
    page = new Page<Setting>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    queryParams = new Map<string, string | number>();

    routeSubscription: Subscription;
    searchSubscription: Subscription;
    loadEditSubscription: Subscription;
    latestSettingsSubscription: Subscription;

    constructor(
        private settingsService: SettingsService,
        public dialogService: MatDialog,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.routeSubscription = this.activatedRoute.queryParams.subscribe(
            (params) => {
                if (params.id) {
                    this.settingsService
                        .get([params.id])
                        .subscribe((setting: Setting) => {
                            this.settingSelected = true;
                            this.editSettingComponent.setEditSetting(setting);
                        });
                }
            },
        );

        this.getLatestSettings();

        this.searchSubscription =
            this.searchForm.controls.search.valueChanges.subscribe(() => {
                if (this.paginator) {
                    this.paginator.firstPage();
                }
                this.changePageIndex(0);
            });
    }

    ngAfterViewInit(): void {
        this.loadEditSubscription =
            this.editSettingComponent.loadingSettingEdit.subscribe((value) => {
                this.loadingSettings = value;
                if (value === false) {
                    this.getLatestSettings();
                }
            });
    }

    ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
        this.searchSubscription.unsubscribe();
        this.loadEditSubscription.unsubscribe();
        this.latestSettingsSubscription.unsubscribe();
    }

    settingChanged(event: boolean): void {
        this.settingSelected = event;
    }

    toggleActiveInactive(): void {
        this.settingStatus = !this.settingStatus;
        this.changePageIndex(0);
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number) {
        this.pageIndex = index;
        this.getLatestSettings();
    }

    setQueryParams(): void {
        this.queryParams.set(COUNT, 10);

        if (this.settingStatus) {
            this.queryParams.set(STATUS, ACTIVE);
        } else {
            this.queryParams.set(STATUS, INACTIVE);
        }

        if (this.pageIndex) {
            this.queryParams.set(OFFSET, this.pageIndex * 10);
        } else {
            this.queryParams.set(OFFSET, 0);
        }

        if (
            this.searchForm.controls.search.value &&
            this.searchForm.controls.search.value !== ''
        ) {
            this.queryParams.set(NAME, this.searchForm.controls.search.value);
        }

        if (this.searchForm.controls.search.value === '') {
            if (this.queryParams.has(NAME)) {
                this.queryParams.delete(NAME);
            }
        }
    }

    getLatestSettings(): void {
        this.setQueryParams();

        this.latestSettingsSubscription = this.settingsService
            .get([], this.queryParams)
            .subscribe((response: Page<Setting>) => {
                this.page = response;
                const tokens = response.items;
                for (const token of tokens) {
                    if (
                        token.name == (this.settingEnum.DefaultEmail as string)
                    ) {
                        this.editSettingComponent.defaultEmail = JSON.parse(
                            token.value as string,
                        ) as { subject: string; body: string };
                    } else if (
                        token.name == (this.settingEnum.FirstNotice as string)
                    ) {
                        this.editSettingComponent.firstNotice = JSON.parse(
                            token.value as string,
                        ) as { subject: string; body: string };
                    } else if (
                        token.name == (this.settingEnum.FinalNotice as string)
                    ) {
                        this.editSettingComponent.finalNotice = JSON.parse(
                            token.value as string,
                        ) as { subject: string; body: string };
                    }
                }
            });
    }

    settingSelectionChange(event: MatSelectionListChange): void {
        if (!this.settingSelected) {
            this.settingSelected = true;
        }

        this.editSettingComponent.setEditSetting(
            event.options[0].value as Setting,
        );
    }
}
