import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { getISOWeek, getISOWeekYear } from 'date-fns';
import saveAs from 'file-saver';
import { Subscription } from 'rxjs';
import {
    UtilizationReport,
    UtilizationReportEmployeeRow,
} from 'src/app/models/report-models/utilitization.model';
import { ReportsService } from 'src/app/services/reports.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    ErrorSnackbarComponent,
    ErrorSnackbarProps,
} from '../../snackbars/error-snackbar/error-snackbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportTypes } from 'src/app/enums/report-types';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor } from '@angular/common';
import { VisibleDirective } from '../../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';

export enum ExportType {
    CSV,
    PDF,
}

@Component({
    selector: 'app-utilization-report',
    templateUrl: './utilization-report.component.html',
    styleUrls: ['./utilization-report.component.scss'],
    imports: [
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        NgFor,
        MatTableModule,
        VisibleDirective,
        MatCardModule,
    ]
})
export class UtilizationReportComponent implements OnInit, OnDestroy {
    ExportType = ExportType;
    Object = Object;
    JSON = JSON;

    reportLoading = true;

    filterForm: FormGroup;

    report?: UtilizationReport;
    getReportSubscription?: Subscription;
    getExportSubscription?: Subscription;
    dateChangeSubscription?: Subscription;

    totalCorp = {
        totalBillable: -1,
        totalNonBillable: -1,
        totalHoliday: -1,
        totalBereavement: -1,
        totalPTO: -1,
    };

    rowColumns = [
        'employee',
        'client',
        'project',
        'task',
        'totalNonBillable',
        'totalBillable',
        'totalHoliday',
        'totalPTO',
        'utilizationPercent',
    ];

    locationTotalRowColumns = [
        'location',
        'totalNonBillable',
        'totalBillable',
        'totalHoliday',
        'totalPTO',
        'utilizationPercent',
    ];

    totalRowColumns = [
        'empty',
        'totalNonBillable',
        'totalBillable',
        'totalHoliday',
        'totalPTO',
        'utilizationPercent',
    ];

    get date() {
        return this.filterForm.get('date');
    }

    defaultTotalHoursAllotted = 40;

    constructor(
        private reportService: ReportsService,
        private snackbar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.filterForm = new FormGroup({
            date: new FormControl<Date>(new Date()),
        });
    }

    ngOnInit(): void {
        const map = this.route.snapshot.queryParamMap;

        if (map.has('day') && map.has('month') && map.has('year')) {
            const day = Number(map.get('day'));
            const month = Number(map.get('month'));
            const year = Number(map.get('year'));

            const newDate = new Date(year, month, day);
            this.filterForm.controls.date.setValue(newDate);
        }

        this.getUtilitizationReport();

        this.dateChangeSubscription = this.date.valueChanges.subscribe((_) => {
            this.updateUrl();
            this.getUtilitizationReport();
        });
    }

    updateUrl(): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                report: ReportTypes.Utilization,
                day: this.date.value.getDate().toString(),
                month: this.date.value.getMonth().toString(),
                year: this.date.value.getFullYear().toString(),
            },
        });
    }

    ngOnDestroy(): void {
        if (this.getReportSubscription) {
            this.getReportSubscription.unsubscribe();
        }

        if (this.getExportSubscription) {
            this.getExportSubscription.unsubscribe();
        }

        this.dateChangeSubscription.unsubscribe();
    }

    getQueryParams(): Map<string, any> {
        let queryParams = new Map<string, string>();
        queryParams.set('week', getISOWeek(this.date.value).toString());
        queryParams.set('year', getISOWeekYear(this.date.value).toString());

        return queryParams;
    }

    getUtilitizationReport(): void {
        this.reportLoading = true;

        this.getReportSubscription = this.reportService
            .get<UtilizationReport>(['utilization'], this.getQueryParams())
            .subscribe({
                next: (response: UtilizationReport) => {
                    this.report = response;
                    this.reportLoading = false;
                },
                error: () => {
                    this.reportLoading = false;
                    this.showErrorSnackbar(
                        'There was an error retrieving data from the server.',
                    );
                },
            });
    }

    exportReport(type: ExportType): void {
        this.reportLoading = true;

        const queryParams = this.getQueryParams();
        queryParams.set('exportType', type);

        this.getExportSubscription = this.reportService
            .exportReport(['utilization', 'export'], queryParams)
            .subscribe({
                next: (buffer: Blob) => {
                    let mimeType = 'text/csv';
                    let fileExt = 'csv';

                    if (type == ExportType.PDF) {
                        mimeType = 'application/pdf';
                        fileExt = 'pdf';
                    }

                    const data: Blob = new Blob([buffer], {
                        type: mimeType,
                    });
                    saveAs(data, `utilization_report.${fileExt}`);

                    this.reportLoading = false;
                },
                error: () => {
                    this.reportLoading = false;
                    this.showErrorSnackbar(
                        'There was an error retrieving the file from the server.',
                    );
                },
            });
    }

    getPercentUtilized(
        billableHours: number,
        nonBillableHours: number,
        holidayHours: number,
        bereavementHours: number,
        ptoHours: number,
        totalAllotted: number,
    ): string {
        const totalHours =
            billableHours + nonBillableHours + holidayHours + ptoHours;

        const hoursByOne = totalHours === 0.0 ? 1 : totalAllotted;
        const percent = billableHours / totalHours;
        return (percent * 100).toFixed(0);
    }

    getRows(
        officeLocation: string,
        employeeType: string,
    ): UtilizationReportEmployeeRow[] {
        const byOffice =
            this.report.byEmployeeType[employeeType].byOfficeLocation[
                officeLocation
            ];
        const data: UtilizationReportEmployeeRow[] = [];

        for (let key of Object.keys(byOffice.byEmployee)) {
            let employeeSet = false;
            let employeeNonBillableTotal = 0.0;
            let employeeBillableTotal = 0.0;
            let employeeHolidayTotal = 0.0;
            let employeeBereavementTotal = 0.0;
            let employeePTOTotal = 0.0;

            for (let row of byOffice.byEmployee[key]) {
                const dataRow: UtilizationReportEmployeeRow = {
                    ...row,
                    ...{
                        employee: employeeSet ? '' : key,
                        isTotal: false,
                    },
                };

                employeeNonBillableTotal += dataRow.totalNonBillable;
                employeeBillableTotal += dataRow.totalBillable;
                employeeHolidayTotal += dataRow.totalHoliday;
                employeeBereavementTotal += dataRow.totalBereavement;
                employeePTOTotal += dataRow.totalPTO;
                employeeSet = true;
                data.push(dataRow);
            }

            const employeeTotalRow: UtilizationReportEmployeeRow = {
                employee: `Total for ${key}`,
                isTotal: true,
                client: '',
                project: '',
                task: '',
                totalBillable: employeeBillableTotal,
                totalNonBillable: employeeNonBillableTotal,
                totalHoliday: employeeHolidayTotal,
                totalBereavement: employeeBereavementTotal,
                totalPTO: employeePTOTotal,
            };
            data.push(employeeTotalRow);
        }

        return data;
    }

    getLocationTotalRow(officeLocation: string, employeeType: string): any[] {
        let totalNonBillable = 0.0;
        let totalBillable = 0.0;
        let totalHoliday = 0.0;
        let totalBereavement = 0.0;
        let totalPTO = 0.0;
        let totalAllotted = 0.0;

        let employees = Object.keys(
            this.report.byEmployeeType[employeeType].byOfficeLocation[
                officeLocation
            ].byEmployee,
        );

        for (let employee of employees) {
            this.report.byEmployeeType[employeeType].byOfficeLocation[
                officeLocation
            ].byEmployee[employee].forEach((ol) => {
                totalNonBillable += ol.totalNonBillable;
                totalBillable += ol.totalBillable;
                totalHoliday += ol.totalHoliday;
                totalBereavement += ol.totalBereavement;
                totalPTO += ol.totalPTO;
                totalAllotted += this.defaultTotalHoursAllotted;
            });
        }

        return [
            {
                location: officeLocation,
                nonBillable: totalNonBillable,
                billable: totalBillable,
                holiday: totalHoliday,
                bereavement: totalBereavement,
                pto: totalPTO,
                percentUtilized: this.getPercentUtilized(
                    totalBillable,
                    totalNonBillable,
                    totalHoliday,
                    totalBereavement,
                    totalPTO,
                    totalAllotted,
                ),
            },
        ];
    }

    getEmployeeTypeTotal(employeeType: string): any[] {
        let totalNonBillable = 0.0;
        let totalBillable = 0.0;
        let totalHoliday = 0.0;
        let totalBereavement = 0.0;
        let totalPTO = 0.0;
        let totalAllotted = 0.0;

        for (let officeLocation of Object.keys(
            this.report.byEmployeeType[employeeType].byOfficeLocation,
        )) {
            let employees = Object.keys(
                this.report.byEmployeeType[employeeType].byOfficeLocation[
                    officeLocation
                ].byEmployee,
            );

            for (let employee of employees) {
                this.report.byEmployeeType[employeeType].byOfficeLocation[
                    officeLocation
                ].byEmployee[employee].forEach((ol) => {
                    totalNonBillable += ol.totalNonBillable;
                    totalBillable += ol.totalBillable;
                    totalHoliday += ol.totalHoliday;
                    totalBereavement += ol.totalBereavement;
                    totalPTO += ol.totalPTO;
                    totalAllotted += this.defaultTotalHoursAllotted;
                });
            }
        }

        if (employeeType.toLowerCase() == 'corp to corp') {
            this.totalCorp.totalBillable = totalBillable;
            this.totalCorp.totalNonBillable = totalNonBillable;
        }

        if (
            employeeType.toLowerCase() == 'dev' &&
            this.totalCorp.totalBillable != -1
        ) {
            totalBillable += this.totalCorp.totalBillable;
            totalNonBillable += this.totalCorp.totalNonBillable;
            totalHoliday += this.totalCorp.totalHoliday;
            totalBereavement += this.totalCorp.totalBereavement;
            totalPTO += this.totalCorp.totalPTO;
            totalAllotted += this.defaultTotalHoursAllotted;
        }

        return [
            {
                nonBillable: totalNonBillable,
                billable: totalBillable,
                holiday: totalHoliday,
                bereavement: totalBereavement,
                pto: totalPTO,
                percentUtilized: this.getPercentUtilized(
                    totalBillable,
                    totalNonBillable,
                    totalHoliday,
                    totalBereavement,
                    totalPTO,
                    totalAllotted,
                ),
            },
        ];
    }

    getTotalRow(): any[] {
        let totalNonBillable = 0.0;
        let totalBillable = 0.0;
        let totalHoliday = 0.0;
        let totalBereavement = 0.0;
        let totalPTO = 0.0;
        let totalAllotted = 0.0;

        let employeeTypes = Object.keys(this.report.byEmployeeType);

        for (let employeeType of employeeTypes) {
            let officeLocations = Object.keys(
                this.report.byEmployeeType[employeeType].byOfficeLocation,
            );

            for (let officeLocation of officeLocations) {
                let employees = Object.keys(
                    this.report.byEmployeeType[employeeType].byOfficeLocation[
                        officeLocation
                    ].byEmployee,
                );

                for (let employee of employees) {
                    this.report.byEmployeeType[employeeType].byOfficeLocation[
                        officeLocation
                    ].byEmployee[employee].forEach((ol) => {
                        totalNonBillable += ol.totalNonBillable;
                        totalBillable += ol.totalBillable;
                        totalBereavement += ol.totalBereavement;
                        totalHoliday += ol.totalHoliday;
                        totalPTO += ol.totalPTO;
                        totalAllotted += this.defaultTotalHoursAllotted;
                    });
                }
            }
        }
        return [
            {
                nonBillable: totalNonBillable,
                billable: totalBillable,
                holiday: totalHoliday,
                bereavement: totalBereavement,
                pto: totalPTO,
                percentUtilized: this.getPercentUtilized(
                    totalBillable,
                    totalNonBillable,
                    totalHoliday,
                    totalBereavement,
                    totalPTO,
                    totalAllotted,
                ),
            },
        ];
    }

    showErrorSnackbar(message: string): void {
        const snackbarData: ErrorSnackbarProps = {
            message: message,
        };

        this.snackbar.openFromComponent(ErrorSnackbarComponent, {
            data: snackbarData,
            duration: 2000,
        });
    }
}
