export interface IUserFeedbackJson {
    id?: string;
    createDate?: string;
    updateDate?: string;
    userFeedbackType?: string;
    description: string;
    details: string;
    submittedById: string;
    status?: string;
    submittedByName?: string;
    newComments?: number;
    ticketNum?: string;
}

interface IUserFeedbackConstructor {
    id?: string;
    createDate?: string;
    updateDate?: string;
    userFeedbackType?: string;
    description: string;
    details: string;
    submittedById?: string;
    status?: string;
    submittedByName?: string;
    newComments?: number;
    ticketNum?: string;
}

export interface UserFeedback extends IUserFeedbackConstructor {
    id?: string;
    createDate?: string;
    updateDate?: string;
    userFeedbackType?: string;
    description: string;
    details: string;
    submittedById?: string;
    status?: string;
    submittedByName?: string;
    newComments?: number;
    ticketNum?: string;
}

export class UserFeedback {
    constructor(input: IUserFeedbackConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: IUserFeedbackConstructor): UserFeedback | null {
        if (json == null) {
            return null;
        }

        return new UserFeedback({
            ...json,
        });
    }
}
