<form [formGroup]="formGroup">
    <mat-form-field>
        <mat-label>Search</mat-label>
        <input
            [autofocus]="true"
            matInput
            formControlName="search"
            autocomplete="off"
        />
    </mat-form-field>
</form>

<mat-nav-list>
    <ng-container *ngFor="let route of routes">
        <a
            mat-list-item
            *ngIf="checkPermissions(route)"
            (click)="onRouteChangeClick(route.route)"
        >
            {{ route.name }}
        </a>
    </ng-container>
</mat-nav-list>
