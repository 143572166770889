<form
    class="tw-flex tw-flex-col tw-p-6 tw-gap-2 tw-box-border tw-border-solid tw-rounded-m3lg tw-border-2 tw-border-customborder"
    [formGroup]="projectForm"
>
    <div class="tw-text-2xl">Edit Project</div>

    <div class="tw-flex tw-flex-row tw-justify-between">
        <button
            [disabled]="!project || loading"
            mat-raised-button
            class="m3-error"
            (click)="onResetClick()"
        >
            Reset
        </button>
        <button
            [disabled]="!project || loading"
            mat-raised-button
            (click)="onFormSave(true)"
        >
            Save Changes
        </button>
    </div>

    <p
        class="tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer tw-rounded-m3sm tw-p-4"
        *ngIf="failed"
    >
        Failed to edit project.
    </p>

    <mat-form-field appearance="fill">
        <mat-label>name</mat-label>
        <input [readonly]="!project" matInput formControlName="name" />
        <mat-error *ngIf="this.projectForm.controls.name.errors?.required">
            Name is required
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Primary Contact</mat-label>
        <input
            [readonly]="!project"
            matInput
            formControlName="primaryContact"
        />
        <mat-error
            *ngIf="this.projectForm.controls.primaryContact.errors?.required"
        >
            Primary Contact is required
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Start Date</mat-label>
        <input
            matInput
            [matDatepicker]="startDatePicker"
            formControlName="startDate"
        />
        <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
        <mat-error *ngIf="this.projectForm.controls.startDate.errors?.required">
            Start date is required
        </mat-error>
        <mat-error *ngIf="this.projectForm.errors?.pastDate">
            Start Date must be before End date
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>End Date</mat-label>
        <input
            matInput
            [matDatepicker]="endDatePicker"
            formControlName="endDate"
        />
        <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
        <mat-error *ngIf="this.projectForm.errors?.pastDate">
            Start Date must be before End date
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Notes</mat-label>
        <textarea
            [readonly]="!project"
            matInput
            formControlName="notes"
        ></textarea>
    </mat-form-field>
    <mat-checkbox [disabled]="!project" formControlName="status">
        Active
    </mat-checkbox>
    <mat-checkbox formControlName="nonBillable">Non-Billable</mat-checkbox>

    <mat-divider></mat-divider>

    <div
        class="tw-flex tw-flex-row tw-gap-4 tw-items-baseline tw-justify-between"
    >
        <mat-slide-toggle
            [checked]="viewingActiveTasks"
            (change)="onActiveChange($event)"
        >
            {{ viewingActiveTasks ? "Active" : "Inactive" }} Project Tasks
        </mat-slide-toggle>

        <button
            [disabled]="!viewingActiveTasks"
            mat-raised-button
            (click)="addTask()"
        >
            + Task
        </button>
    </div>

    <div>
        <table
            mat-table
            class="tw-w-full"
            [dataSource]="getDataSource()"
            matSort
        >
            <ng-container matColumnDef="name">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="tw-w-1/2"
                >
                    Name
                </th>
                <td mat-cell *matCellDef="let element" class="tw-break-all">
                    {{ element.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="billType">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="tw-w-[15%]"
                >
                    Bill Type
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.billType }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="tw-w-[15%]"></th>
                <td mat-cell *matCellDef="let element">
                    @if (viewingActiveTasks) {
                        <button
                            mat-raised-button
                            class="m3-error"
                            (click)="removeTask(element)"
                        >
                            Deactivate
                        </button>
                    } @else {
                        <button
                            mat-raised-button
                            (click)="makeTaskActive(element)"
                        >
                            Activate
                        </button>
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-button (click)="editTask(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</form>
