import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatListModule, MatSelectionList } from '@angular/material/list';

import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import {
    MatSlideToggleChange,
    MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Page } from 'src/app/models/page.model';
import { Location } from 'src/app/models/location.model';
import { LocationService } from 'src/app/services/location.service';
import { AddLocationComponent } from './add-location/add-location.component';
import { EditLocationComponent } from './edit-location/edit-location.component';
import { SEARCH_DEBOUNCE_MS } from 'src/app/constants/searchConstants';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { VisibleDirective } from '../../directives/visible.directive';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss'],
    imports: [
        MatSlideToggleModule,
        NgIf,
        MatProgressBarModule,
        MatTooltipModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        EditLocationComponent,
        VisibleDirective,
        MatCardModule,
    ],
})
export class LocationsComponent implements OnInit, AfterViewInit {
    searchForm = new FormGroup({
        search: new FormControl<string>(''),
    });
    addLocationOpen: boolean;
    formName: 'Add Location';
    selectedValue: string;
    loadingLocations = false;
    locationStatus = true;
    loadingLocationsEdit = false;
    optionSelected = false;
    actionSuccessful = false;

    @ViewChild(MatSelectionList) locationSelectList!: MatSelectionList;
    @ViewChild(EditLocationComponent)
    editLocationComponent!: EditLocationComponent;

    pageIndex = 0;
    page = new Page<Location>();
    queryParams: Map<string, unknown> = new Map<string, unknown>([
        ['status', ACTIVE],
        ['count', 10],
        ['offset', 0],
    ]);

    constructor(
        private locationService: LocationService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.populateFilters();
        this.getLocations();
    }

    ngAfterViewInit(): void {
        this.locationSelectList.selectionChange.subscribe((change) => {
            if (!this.optionSelected) {
                this.optionSelected = true;
            }
            this.editLocationComponent.location = change.options[0]
                .value as Location;
            this.editLocationComponent.initializeForm();
        });

        this.searchForm.controls.search.valueChanges
            .pipe(debounceTime(SEARCH_DEBOUNCE_MS), distinctUntilChanged())
            .subscribe((value) => {
                if (value === '' || value === null) {
                    if (this.queryParams.get('name')) {
                        this.queryParams.delete('name');
                    }
                }

                setTimeout(() => {
                    if (this.loadingLocations && this.editLocationComponent) {
                        this.editLocationComponent.resetComponent();
                    }
                    this.queryParams.set('name', value);
                    this.changePageIndex(0);
                }, SEARCH_DEBOUNCE_MS);
            });
    }

    private populateFilters(): void {
        const params: Params = this.activatedRoute.snapshot.queryParams;

        if ('status' in params) {
            this.locationStatus = params['status'] !== 'INACTIVE';
        }

        if ('name' in params) {
            this.queryParams.set('name', params['name']);
            this.searchForm.controls.search.setValue(params['name'] as string);
        }
    }

    private updateUrl(): void {
        const queryParams: Params = {};

        if (this.locationStatus !== null && this.locationStatus !== undefined) {
            queryParams['status'] = this.locationStatus ? 'ACTIVE' : 'INACTIVE';
        }

        const nameValue = this.searchForm.controls.search.value;
        if (nameValue) {
            queryParams['name'] = nameValue;
        }

        void this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
        });
    }

    locationsLoadingToggle(event: boolean): void {
        this.loadingLocations = event;
    }

    toggleActiveInactive(event: MatSlideToggleChange): void {
        this.locationStatus = event.checked;
        if (this.editLocationComponent) {
            this.editLocationComponent.resetComponent();
        }

        this.changePageIndex(0);
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number): void {
        this.pageIndex = index;
        this.getLocations();
    }

    openAddDialog(): void {
        const dialog = this.dialog.open(AddLocationComponent, {
            disableClose: true,
            width: '20%',
        });

        dialog.afterClosed().subscribe((result) => {
            if (result === true) {
                this.getLocations();
            }
        });
    }

    onUpdateSuccessLoad(event?: boolean): void {
        if (event) {
            this.optionSelected = false;
        }
        this.getLocations();
    }

    private getLocations() {
        this.loadingLocations = true;
        this.setQueryParams();
        this.locationService
            .get([], this.queryParams)
            .subscribe((page: Page<Location>) => {
                this.page = page;
                this.loadingLocations = false;
            });
    }

    private setQueryParams(): void {
        if (this.pageIndex) {
            this.queryParams.set(
                'offset',
                this.pageIndex * this.page.countRequested,
            );
        } else {
            this.queryParams.set('offset', 0);
        }

        if (this.locationStatus) {
            this.queryParams.set('status', ACTIVE);
        } else {
            this.queryParams.set('status', INACTIVE);
        }

        this.updateUrl();
    }
}
