import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { getISOWeek, getISOWeekYear } from 'date-fns';
import saveAs from 'file-saver';
import { Subscription } from 'rxjs';
import {
    UtilizationReport,
    UtilizationReportEmployeeRow,
} from 'src/app/models/report-models/utilitization.model';
import { ReportsService } from 'src/app/services/reports.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    ErrorSnackbarComponent,
    ErrorSnackbarProps,
} from '../../snackbars/error-snackbar/error-snackbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportTypes } from 'src/app/enums/report-types';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor } from '@angular/common';
import { VisibleDirective } from '../../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import { DAY, MONTH, WEEK, YEAR } from 'src/app/constants/queryParamConstants';

export enum ExportType {
    CSV,
    PDF,
}

interface IEmployeeeTotalBody {
    nonBillable: number;
    billable: number;
    holiday: number;
    pto: number;
    percentUtilized: string;
}

@Component({
    selector: 'app-utilization-report',
    templateUrl: './utilization-report.component.html',
    styleUrls: ['./utilization-report.component.scss'],
    imports: [
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        NgFor,
        MatTableModule,
        VisibleDirective,
        MatCardModule,
    ],
})
export class UtilizationReportComponent implements OnInit, OnDestroy {
    ExportType = ExportType;
    Object = Object;
    JSON = JSON;

    reportLoading = true;

    filterForm: FormGroup;

    employeeTypes: string[];

    report?: UtilizationReport;
    getReportSubscription?: Subscription;
    getExportSubscription?: Subscription;
    dateChangeSubscription?: Subscription;

    totalCorp = {
        totalBillable: -1,
        totalNonBillable: -1,
        totalHoliday: -1,
        totalPTO: -1,
    };

    rowColumns = [
        'employee',
        'client',
        'project',
        'task',
        'totalNonBillable',
        'totalBillable',
        'totalHoliday',
        'totalPTO',
        'utilizationPercent',
    ];

    locationTotalRowColumns = [
        'location',
        'totalNonBillable',
        'totalBillable',
        'totalHoliday',
        'totalPTO',
        'utilizationPercent',
    ];

    totalRowColumns = [
        'empty',
        'totalNonBillable',
        'totalBillable',
        'totalHoliday',
        'totalPTO',
        'utilizationPercent',
    ];

    get date() {
        return this.filterForm.get('date');
    }

    defaultTotalHoursAllotted = 40;

    constructor(
        private reportService: ReportsService,
        private snackbar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.filterForm = new FormGroup({
            date: new FormControl<Date>(new Date()),
        });
    }

    ngOnInit(): void {
        const map = this.route.snapshot.queryParamMap;

        if (map.has(DAY) && map.has(MONTH) && map.has(YEAR)) {
            const day = Number(map.get(DAY));
            const month = Number(map.get(MONTH));
            const year = Number(map.get(YEAR));

            const newDate = new Date(year, month, day);
            this.filterForm.controls.date.setValue(newDate);
        }

        this.getUtilitizationReport();

        this.dateChangeSubscription = this.date.valueChanges.subscribe(() => {
            this.updateUrl();
            this.getUtilitizationReport();
        });
    }

    updateUrl(): void {
        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                report: ReportTypes.Utilization,
                day: (this.date.value as Date).getDate().toString(),
                month: (this.date.value as Date).getMonth().toString(),
                year: (this.date.value as Date).getFullYear().toString(),
            },
        });
    }

    ngOnDestroy(): void {
        if (this.getReportSubscription) {
            this.getReportSubscription.unsubscribe();
        }

        if (this.getExportSubscription) {
            this.getExportSubscription.unsubscribe();
        }

        this.dateChangeSubscription.unsubscribe();
    }

    getQueryParams(): Map<string, string | ExportType> {
        const queryParams = new Map<string, string>();
        queryParams.set(WEEK, getISOWeek(this.date.value as Date).toString());
        queryParams.set(
            YEAR,
            getISOWeekYear(this.date.value as Date).toString(),
        );

        return queryParams;
    }

    getUtilitizationReport(): void {
        this.reportLoading = true;

        this.getReportSubscription = this.reportService
            .get<UtilizationReport>(['utilization'], this.getQueryParams())
            .subscribe({
                next: (response: UtilizationReport) => {
                    this.report = response;
                    const predefinedOrder = [
                        //predefined order for prod
                        'DEV',
                        'QA',
                        'QA / BA',
                        'IT',
                        'IT Staffing',
                    ];

                    this.employeeTypes = Object.keys(
                        this.report.byEmployeeType,
                    );

                    this.employeeTypes.sort((a, b) => {
                        const indexA = predefinedOrder.indexOf(a.toUpperCase());
                        const indexB = predefinedOrder.indexOf(b.toUpperCase());

                        if (indexA !== -1 && indexB !== -1) {
                            return indexA - indexB;
                        } else if (indexA !== -1) {
                            return -1;
                        } else if (indexB !== -1) {
                            return 1;
                        } else {
                            return a.localeCompare(b);
                        }
                    });

                    this.reportLoading = false;
                },
                error: () => {
                    this.reportLoading = false;
                    this.showErrorSnackbar(
                        'There was an error retrieving data from the server.',
                    );
                },
            });
    }

    exportReport(type: ExportType): void {
        this.reportLoading = true;

        const queryParams = this.getQueryParams();
        queryParams.set('exportType', type);

        this.getExportSubscription = this.reportService
            .exportReport(['utilization', 'export'], queryParams)
            .subscribe({
                next: (buffer: Blob) => {
                    let mimeType = 'text/csv';
                    let fileExt = 'csv';

                    if (type == ExportType.PDF) {
                        mimeType = 'application/pdf';
                        fileExt = 'pdf';
                    }

                    const data: Blob = new Blob([buffer], {
                        type: mimeType,
                    });
                    saveAs(data, `utilization_report.${fileExt}`);

                    this.reportLoading = false;
                },
                error: () => {
                    this.reportLoading = false;
                    this.showErrorSnackbar(
                        'There was an error retrieving the file from the server.',
                    );
                },
            });
    }

    getPercentUtilized(
        billableHours: number,
        nonBillableHours: number,
        holidayHours: number,
        ptoHours: number,
    ): string {
        const totalHours =
            billableHours + nonBillableHours + holidayHours + ptoHours;
        const percent = billableHours / totalHours;
        return (percent * 100).toFixed(0);
    }

    getRows(
        officeLocation: string,
        employeeType: string,
    ): UtilizationReportEmployeeRow[] {
        const byOffice =
            this.report.byEmployeeType[employeeType].byOfficeLocation[
                officeLocation
            ];
        const data: UtilizationReportEmployeeRow[] = [];

        for (const key of Object.keys(byOffice.byEmployee)) {
            let employeeSet = false;
            let employeeNonBillableTotal = 0.0;
            let employeeBillableTotal = 0.0;
            let employeeHolidayTotal = 0.0;
            let employeePTOTotal = 0.0;

            for (const row of byOffice.byEmployee[key]) {
                const dataRow: UtilizationReportEmployeeRow = {
                    ...row,
                    ...{
                        employee: employeeSet ? '' : key,
                        isTotal: false,
                    },
                };

                employeeNonBillableTotal += dataRow.totalNonBillable;
                employeeBillableTotal += dataRow.totalBillable;
                employeeHolidayTotal += dataRow.totalHoliday;
                employeePTOTotal += dataRow.totalPTO;
                employeeSet = true;
                data.push(dataRow);
            }

            const employeeTotalRow: UtilizationReportEmployeeRow = {
                employee: `Total for ${key}`,
                isTotal: true,
                client: '',
                project: '',
                task: '',
                totalBillable: employeeBillableTotal,
                totalNonBillable: employeeNonBillableTotal,
                totalHoliday: employeeHolidayTotal,
                totalPTO: employeePTOTotal,
            };
            data.push(employeeTotalRow);
        }

        return data;
    }

    getLocationTotalRow(officeLocation: string, employeeType: string): any[] {
        let totalNonBillable = 0.0;
        let totalBillable = 0.0;
        let totalHoliday = 0.0;
        let totalPTO = 0.0;

        const employees = Object.keys(
            this.report.byEmployeeType[employeeType].byOfficeLocation[
                officeLocation
            ].byEmployee,
        );

        for (const employee of employees) {
            this.report.byEmployeeType[employeeType].byOfficeLocation[
                officeLocation
            ].byEmployee[employee].forEach((ol) => {
                totalNonBillable += ol.totalNonBillable;
                totalBillable += ol.totalBillable;
                totalHoliday += ol.totalHoliday;
                totalPTO += ol.totalPTO;
            });
        }

        return [
            {
                location: officeLocation,
                nonBillable: totalNonBillable,
                billable: totalBillable,
                holiday: totalHoliday,
                pto: totalPTO,
                percentUtilized: this.getPercentUtilized(
                    totalBillable,
                    totalNonBillable,
                    totalHoliday,
                    totalPTO,
                ),
            },
        ];
    }

    getEmployeeTypeTotal(employeeType: string): IEmployeeeTotalBody[] {
        let totalNonBillable = 0.0;
        let totalBillable = 0.0;
        let totalHoliday = 0.0;
        let totalPTO = 0.0;

        for (const officeLocation of Object.keys(
            this.report.byEmployeeType[employeeType].byOfficeLocation,
        )) {
            const employees = Object.keys(
                this.report.byEmployeeType[employeeType].byOfficeLocation[
                    officeLocation
                ].byEmployee,
            );

            for (const employee of employees) {
                this.report.byEmployeeType[employeeType].byOfficeLocation[
                    officeLocation
                ].byEmployee[employee].forEach((ol) => {
                    totalNonBillable += ol.totalNonBillable;
                    totalBillable += ol.totalBillable;
                    totalHoliday += ol.totalHoliday;
                    totalPTO += ol.totalPTO;
                });
            }
        }

        return [
            {
                nonBillable: totalNonBillable,
                billable: totalBillable,
                holiday: totalHoliday,
                pto: totalPTO,
                percentUtilized: this.getPercentUtilized(
                    totalBillable,
                    totalNonBillable,
                    totalHoliday,
                    totalPTO,
                ),
            },
        ];
    }

    getDevQaTotal(): IEmployeeeTotalBody[] {
        let totalNonBillable = 0.0;
        let totalBillable = 0.0;
        let totalHoliday = 0.0;
        let totalPTO = 0.0;

        const employeeTypes = this.employeeTypes.filter((type) =>
            /qa|dev/i.test(type),
        );

        for (const employeeType of employeeTypes) {
            if (!this.report.byEmployeeType[employeeType]) {
                continue; // Skip if the employeeType is not present
            }

            const employeeTotals = this.getEmployeeTypeTotal(employeeType);

            totalNonBillable += employeeTotals[0].nonBillable;
            totalBillable += employeeTotals[0].billable;
            totalHoliday += employeeTotals[0].holiday;
            totalPTO += employeeTotals[0].pto;
        }

        return [
            {
                nonBillable: totalNonBillable,
                billable: totalBillable,
                holiday: totalHoliday,
                pto: totalPTO,
                percentUtilized: this.getPercentUtilized(
                    totalBillable,
                    totalNonBillable,
                    totalHoliday,
                    totalPTO,
                ),
            },
        ];
    }

    getTotalRow(): any[] {
        let totalNonBillable = 0.0;
        let totalBillable = 0.0;
        let totalHoliday = 0.0;
        let totalPTO = 0.0;

        for (const employeeType of this.employeeTypes) {
            const officeLocations = Object.keys(
                this.report.byEmployeeType[employeeType].byOfficeLocation,
            );

            for (const officeLocation of officeLocations) {
                const employees = Object.keys(
                    this.report.byEmployeeType[employeeType].byOfficeLocation[
                        officeLocation
                    ].byEmployee,
                );

                for (const employee of employees) {
                    this.report.byEmployeeType[employeeType].byOfficeLocation[
                        officeLocation
                    ].byEmployee[employee].forEach((ol) => {
                        totalNonBillable += ol.totalNonBillable;
                        totalBillable += ol.totalBillable;
                        totalHoliday += ol.totalHoliday;
                        totalPTO += ol.totalPTO;
                    });
                }
            }
        }
        return [
            {
                nonBillable: totalNonBillable,
                billable: totalBillable,
                holiday: totalHoliday,
                pto: totalPTO,
                percentUtilized: this.getPercentUtilized(
                    totalBillable,
                    totalNonBillable,
                    totalHoliday,
                    totalPTO,
                ),
            },
        ];
    }

    showErrorSnackbar(message: string): void {
        const snackbarData: ErrorSnackbarProps = {
            message: message,
        };

        this.snackbar.openFromComponent(ErrorSnackbarComponent, {
            data: snackbarData,
            duration: 2000,
        });
    }
}
