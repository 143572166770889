<div class="tw-overflow-hidden tw-w-full">
    <div
        class="tw-border-0 tw-border-b-2 tw-border-customborder tw-border-solid tw-flex tw-flex-row tw-justify-between tw-m-4"
    >
        <div>Profile Image</div>
        <mat-card class="tw-h-[300px] tw-w-[300px] tw-rounded-[50%]">
            <img
                class="tw-w-full tw-h-full tw-rounded-[50%]"
                [src]="currentProfileImage"
            />
        </mat-card>
        <button mat-raised-button (click)="openProfileImageUpdate()">
            Choose Image
        </button>
    </div>
    <div class="tw-m-4">Theme</div>
    <div
        [formGroup]="userProfileForm"
        class="tw-flex tw-flex-row tw-justify-center"
    >
        <mat-radio-group
            formControlName="theme"
            class="tw-flex tw-flex-col tw-justify-center tw-gap-3"
        >
            <mat-radio-button
                *ngFor="let option of themeOptions"
                [value]="option"
            >
                {{ option }}
            </mat-radio-button>
            <div class="tw-mb-4">
                <button mat-raised-button (click)="saveTheme()">
                    Update Theme
                </button>
            </div>
        </mat-radio-group>
    </div>
</div>
