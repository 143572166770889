<div class="tw-flex tw-justify-center tw-mt-6">
    <mat-form-field class="tw-w-fit">
        <mat-label>Select a Contractor</mat-label>
        <mat-select [formControl]="selectedUserControl">
            <mat-option
                *ngFor="let contractor of contractors"
                [value]="contractor"
            >
                {{ contractor.firstName }} {{ contractor.lastName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<app-timesheet-dashboard
    *ngIf="selectedUserControl.value"
    [userFormControl]="selectedUserControl"
></app-timesheet-dashboard>
