import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';
import { MatDialogTitle } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-header',
    templateUrl: './dialog-header.component.html',
    styleUrls: ['./dialog-header.component.scss'],
    imports: [MatDialogTitle]
})
export class DialogHeaderComponent {
    @Input() title = '';

    constructor(private themeService: ThemeService) {}

    protected get darkMode(): boolean {
        const mode = this.themeService.getCurrentTheme();
        if (mode.toLowerCase() == 'dark-theme') {
            return true;
        } else {
            return false;
        }
    }
}
