import { Injectable } from '@angular/core';
import { AbstractService } from './abstract-service.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WorkOrderService extends AbstractService {
    path = '/WorkOrder';

    exportReport(route: string[], workOrder): Observable<Blob> {
        let url = this.baseUrl + this.path;

        url += this._buildRouteFromStringArray(route);

        return this.httpClient.post(url, workOrder, {
            headers: {
                accept: 'application/octet-stream',
                'content-type': 'application/json',
            },
            responseType: 'blob',
        });
    }
}
