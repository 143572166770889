<app-dialog-header [title]="'Active Tasks'"></app-dialog-header>

<div mat-dialog-content>
    <mat-progress-bar
        [appVisible]="loadingTasks"
        class="m3-tertiary"
        mode="indeterminate"
    ></mat-progress-bar>

    <p class="tw-mb-4">
        Note: Timesheets already created will not be removed upon task edit
    </p>

    <ng-container *ngIf="user">
        <div *ngFor="let task of user.tasks">
            <div class="tw-flex tw-flex-row tw-items-start">
                <mat-checkbox
                    (change)="updateTaskStatus(task)"
                    [checked]="task.userTaskStatus.toLowerCase() === 'active'"
                ></mat-checkbox>
                <p class="tw-flex-1 tw-ml-2">
                    {{ task.clientName }} - {{ task.projectName }} -
                    {{ task.name }}
                </p>
            </div>

            <mat-divider class="tw-mb-2"></mat-divider>
        </div>
    </ng-container>
</div>
<app-dialog-exit (closeClick)="onClickClose()"></app-dialog-exit>
