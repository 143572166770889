import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
    MAT_DIALOG_DATA,
    MatDialogContent,
    MatDialogRef,
} from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { User } from 'src/app/models/User';
import { Page } from 'src/app/models/page.model';
import { TaskApprover } from 'src/app/models/task-approver';
import { ApproversService } from 'src/app/services/approvers.service';
import { UserService } from 'src/app/services/user.service';
import { UpsertTaskComponent } from '../upsert-task/upsert-task.component';
import { NgIf, NgFor, KeyValuePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { DialogFooterComponent } from '../dialog-footer/dialog-footer.component';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

interface IUpsertEditApproverDialogData {
    title: string;
    taskId?: string;
    taskApprovers?: User[];
}

@Component({
    selector: 'app-edit-approver-dialog',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        MatCheckboxModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatButtonModule,
        DialogFooterComponent,
        KeyValuePipe,
        MatChipsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
    ],
    templateUrl: './edit-approver-dialog.component.html',
    styleUrl: './edit-approver-dialog.component.scss',
})
export class EditApproverDialogComponent implements OnInit {
    saving = false;

    approversForm = new FormGroup({
        approverSearch: new FormControl<string | null>(null),
    });

    approvers: User[];

    taskApprovers: User[] = [];

    baseApprovers: User[] = [];

    filteredApprovers: User[] = [];

    separatorKeysCodes: number[] = [ENTER, COMMA];

    queryParams: Map<string, unknown> = new Map<string, unknown>([
        ['status', ACTIVE],
        ['count', -1],
        ['offset', 0],
        ['isApprover', 'true'],
    ]);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IUpsertEditApproverDialogData,
        private dialogRef: MatDialogRef<UpsertTaskComponent>,
        private approversService: ApproversService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        if (this.data.taskApprovers) {
            this.taskApprovers = this.data.taskApprovers;
        }

        this.getApprovers();

        this.approversForm.controls.approverSearch.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe((value: string) => {
                this.getApprovers(value);
            });
    }

    getApprovers(name?: string) {
        //get people capable of being an approver for this task
        if (name != null) {
            this.queryParams.set('name', name);
        }

        this.userService
            .get([], this.queryParams)
            .subscribe((response: Page<User>) => {
                this.approvers = response.items;

                if (this.data.taskId) {
                    this.getTaskApprovers();
                } else {
                    this.filterApprovers();
                }
                this.queryParams.delete('name');
            });
    }

    getTaskApprovers() {
        //get approvers currently assigned to this task
        if (this.taskApprovers.length <= 0) {
            this.approversService
                .get([this.data.taskId])
                .subscribe((response: Page<TaskApprover>) => {
                    this.taskApprovers = this.approvers.filter((user) =>
                        response.items.some(
                            (item) => item.approverId === user.userLoginId,
                        ),
                    );

                    this.baseApprovers = [...this.taskApprovers];
                    this.filterApprovers();
                });
        } else {
            this.filterApprovers();
        }
    }

    filterApprovers(): void {
        this.filteredApprovers = this.approvers.filter(
            (approver) =>
                !this.taskApprovers.some(
                    (taskApprover) => taskApprover.id === approver.id,
                ),
        );

        this.filteredApprovers = this.filteredApprovers.filter(
            (approverOption) =>
                !this.taskApprovers.some(
                    (taskApprover) =>
                        taskApprover.id === approverOption.userLoginId,
                ),
        );
    }

    deleteApprover(approver: User): void {
        this.taskApprovers = this.taskApprovers.filter(
            (taskApprover) => approver.id !== taskApprover.id,
        );
        this.filteredApprovers.push(approver);
    }

    addApprover(approver: User): void {
        this.taskApprovers.push(approver);
        this.filteredApprovers = this.filteredApprovers.filter(
            (taskApprover) => approver.id !== taskApprover.id,
        );
    }

    onSaveClick(): void {
        this.dialogRef.close({
            success: true,
            taskApprovers: this.taskApprovers,
        });
    }

    closeDialog(): void {
        this.dialogRef.close({
            success: false,
            taskApprovers: this.baseApprovers,
        });
    }
}
