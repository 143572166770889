import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HttpInterceptorService<T> implements HttpInterceptor {
    intercept(
        request: HttpRequest<T>,
        next: HttpHandler,
    ): Observable<HttpEvent<T>> {
        const contentType = this.setContentType(request);
        if (window.location.href.includes('/reset-password/')) {
            const token = this.getTokenFromURL(window.location.href);
            const authorizedRequest = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': contentType,
                },
            });
            return next.handle(authorizedRequest);
        } else if (window.location.href.includes('documents')) {
            const token = window.sessionStorage.getItem('PSSUserAuthToken');
            const authorizedRequest = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                },
            });
            return next.handle(authorizedRequest);
        } else if (window.sessionStorage.getItem('PSSUserAuthToken')) {
            const token = window.sessionStorage.getItem('PSSUserAuthToken');
            const authorizedRequest = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': contentType,
                },
            });
            return next.handle(authorizedRequest);
        } else {
            const unauthorizaedRequest = request.clone({
                setHeaders: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': contentType,
                },
            });
            return next.handle(unauthorizaedRequest);
        }
    }

    getTokenFromURL(url: string): string {
        return url.split('/').pop();
    }

    setContentType(req: HttpRequest<T>): string {
        if (req.headers.has('Content-Type')) {
            return req.headers.get('Content-Type');
        } else {
            return 'application/json';
        }
    }
}
