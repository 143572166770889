import { Component, Inject } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogContent,
} from '@angular/material/dialog';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { DocumentService } from 'src/app/services/document.service';
import { DialogFooterComponent } from '../../dialog-footer/dialog-footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { VisibleDirective } from '../../../directives/visible.directive';

@Component({
    selector: 'app-add-container',
    templateUrl: './add-container.component.html',
    styleUrls: ['./add-container.component.scss'],
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        DialogFooterComponent,
        VisibleDirective,
    ],
})
export class AddContainerComponent {
    containerForm = new FormGroup({
        categoryName: new FormControl<string | null>(null, [
            Validators.required.bind(this),
        ]),
    });

    saveFailed = false;
    saving = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: unknown,
        private dialogRef: MatDialogRef<boolean>,
        private documentService: DocumentService,
    ) {}

    onResetClick(): void {
        this.containerForm.reset();
    }

    onCloseClick(): void {
        this.dialogRef.close(false);
    }

    checkIfValidCategoryName(): boolean {
        const value = this.containerForm.controls.categoryName.value;
        const length = value.length;
        let hasMultipleDashes = false;

        for (let i = 0; i < length; i++) {
            if (i !== length) {
                if (value[i] === '-' && value[i + 1] === '-') {
                    hasMultipleDashes = true;
                    break;
                }
            }
        }

        if (hasMultipleDashes) {
            alert('Multiple dashes detected');
            return false;
        }

        if (/[^-_a-zA-Z]/.test(value)) {
            alert(
                'Category name can only include dashes, no other special character is accepted',
            );
            return false;
        }

        if (value[0] === '-' || value[length - 1] === '-') {
            alert('Start/End letter must be a letter');
            return false;
        }

        return true;
    }

    onSaveClick(): void {
        if (!this.containerForm.valid || !this.checkIfValidCategoryName()) {
            return;
        }
        this.saving = true;
        const categoryName =
            this.containerForm.controls.categoryName.value.toLowerCase();
        this.documentService
            .post([`CreateBlobContainer?containerName=${categoryName}`], null)
            .subscribe((resp) => {
                if (resp) {
                    this.dialogRef.close(true);
                }
            });
    }
}
