<app-dialog-header [title]="'Add Document'"></app-dialog-header>

<div mat-dialog-content>
    <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start">
        <mat-progress-bar
            [appVisible]="saving"
            mode="indeterminate"
        ></mat-progress-bar>
        @if (saveFailed) {
            <div class="tw-bg-m3ErrorContainer tw-text-m3OnError">
                Save Failed
            </div>
        }
        <form
            [formGroup]="documentForm"
            class="tw-flex tw-flex-col tw-items-start tw-gap-2"
        >
            <mat-form-field appearance="fill">
                <mat-label>Doc Name</mat-label>
                <input matInput formControlName="docName" />
                <mat-error
                    *ngIf="documentForm.controls.docName.errors?.required"
                >
                    Doc Name field is required
                </mat-error>
            </mat-form-field>

            <input
                #fileInput
                hidden
                type="file"
                (change)="onFileSelected($event)"
            />
            <div
                (dragover)="onDragOver($event)"
                (drop)="onFileDrop($event)"
                class="tw-relative tw-min-h-48 tw-min-w-96 tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center tw-border-dashed tw-border-customborder tw-border-4"
            >
                <div>Drag & Drop Files!</div>
                @if (file.value) {
                    <mat-chip-row (removed)="onRemove()">
                        {{ file.value.name }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                @if (
                    documentForm.controls.file.errors?.required &&
                    documentForm.controls.file.dirty
                ) {
                    <div class="tw-bg-red-600 tw-p-2 tw-rounded-full">
                        A file is required
                    </div>
                }
                @if (
                    documentForm.controls.file.errors?.fileType &&
                    documentForm.controls.file.dirty
                ) {
                    <div class="tw-bg-red-600 tw-p-2 tw-rounded-full">
                        File type not allowed
                    </div>
                }
                <button
                    matSuffix
                    mat-icon-button
                    (click)="fileInput.click()"
                    class="tw-absolute tw-right-0 tw-top-0"
                >
                    <mat-icon>cloud_upload</mat-icon>
                </button>
            </div>
        </form>

        <button
            [disabled]="saving"
            mat-raised-button
            class="m3-error"
            (click)="onResetClick()"
        >
            Reset
        </button>
    </div>
</div>

<app-dialog-footer
    [disabled]="saving"
    (buttonClick)="onSaveClick()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
