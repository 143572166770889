import { Component, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogContent,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { DialogFooterComponent } from '../../dialog-footer/dialog-footer.component';

@Component({
    selector: 'app-delete-timesheet-dialog',
    templateUrl: './delete-timesheet-dialog.component.html',
    styleUrls: ['./delete-timesheet-dialog.component.scss'],
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        MatButtonModule,
        DialogFooterComponent,
    ],
})
export class DeleteTimesheetDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: string,
        public dialogRef: MatDialogRef<DeleteTimesheetDialogComponent>,
    ) {}

    onCloseClick(): void {
        this.dialogRef.close();
    }

    yesClicked(): void {
        this.dialogRef.close(true);
    }
}
