import { Injectable } from '@angular/core';
import { AbstractService } from './abstract-service.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AdminTimesheetService extends AbstractService {
    path = '/AdminTimesheet';

    exportReport(
        route: string[],
        queryParameters?: Map<string, unknown>,
    ): Observable<Blob> {
        let url = this.baseUrl + this.path;

        url +=
            this._buildRouteFromStringArray(route) +
            this._buildQueryParameters(queryParameters);

        return this.httpClient.get(url, {
            headers: {
                accept: 'application/octet-stream',
                'content-type': 'application/octet-stream',
            },
            responseType: 'blob',
        });
    }
}
