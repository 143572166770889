import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { DayOfWeekAsString } from 'src/app/enums/day-of-week.enum.ts';
import { SettingEnum } from 'src/app/enums/settings.enum';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { Page } from 'src/app/models/page.model';
import { Setting } from 'src/app/models/setting.model';
import { EmployeeTypeService } from 'src/app/services/employee-type.service';
import { SettingsService } from 'src/app/services/setting.service';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/user.service';
import { MatIcon } from '@angular/material/icon';
import { WorkOrderNoteService } from 'src/app/services/work-order-note.service';
import { WorkOrderNote } from 'src/app/models/work-order-notes';
import { MatDialog } from '@angular/material/dialog';
import { DelWrkorderNoteComponent } from '../../del-wrkorder-note/del-wrkorder-note.component';
import { MatCardModule } from '@angular/material/card';
import { COUNT } from 'src/app/constants/queryParamConstants';

interface ISettingFormControls {
    name: FormControl<string | null>;
    value: FormControl<string | null>;
    subject: FormControl<string | null>;
    body: FormControl<string | null>;
}

@Component({
    selector: 'app-edit-setting',
    templateUrl: './edit-setting.component.html',
    styleUrls: ['./edit-setting.component.scss'],
    imports: [
        ReactiveFormsModule,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatButtonModule,
        MatIcon,
        MatCardModule,
        FormsModule,
    ],
})
export class EditSettingComponent implements OnInit, OnDestroy {
    @Input() setting: Setting = null;
    @Output() loadingSettingEdit = new EventEmitter<boolean>();
    @Output() settingChangedEvent = new EventEmitter<boolean>();

    failed = false;
    loading = false;
    newWorkOrder: boolean = false;
    workOrderNotes: WorkOrderNote[];
    selectedWorkOrderNote: WorkOrderNote;
    // I know, this looks redundant. It's for the front-end. <--  boooooooo
    SettingEnum = SettingEnum;
    daysOfWeek: string[];
    users: User[];

    defaultEmail: {
        subject: string | null;
        body: string | null;
    } = {
        subject: 'hello',
        body: 'hi',
    };

    firstNotice: {
        subject: string | null;
        body: string | null;
    } = {
        subject: 'hello',
        body: 'hi',
    };

    finalNotice: {
        subject: string | null;
        body: string | null;
    } = {
        subject: 'hello',
        body: 'hi',
    };

    employeeTypes: EmployeeType[] = [];
    employeeTypeSubscription: Subscription;

    settingForm: FormGroup<ISettingFormControls> = new FormGroup({
        name: new FormControl(null),
        value: new FormControl(null),
        subject: new FormControl(null),
        body: new FormControl(null),
    });

    constructor(
        private settingsService: SettingsService,
        private employeeTypeService: EmployeeTypeService,
        private userService: UserService,
        private workOrderNoteService: WorkOrderNoteService,
        public dialog: MatDialog,
    ) {
        this.daysOfWeek = Object.entries(DayOfWeekAsString).map((e) => e[0]);
    }

    ngOnInit() {
        this.employeeTypeSubscription = this.employeeTypeService
            .get([])
            .subscribe((response: Page<EmployeeType>) => {
                this.employeeTypes = response.items;
            });

        const params = new Map<string, number>();
        params.set(COUNT, -1);

        this.userService.get([], params).subscribe((response: Page<User>) => {
            this.users = response.items;
        });
        this.defaultEmail.body = '';
        this.defaultEmail.subject = '';

        this.getWorkOrderNotes();
    }

    ngOnDestroy(): void {
        this.employeeTypeSubscription.unsubscribe();
    }

    initializeForm(
        setting?: Setting,
        selectedWorkOrderNote?: WorkOrderNote,
    ): void {
        if (selectedWorkOrderNote) {
            this.settingForm = new FormGroup({
                name: new FormControl({ value: setting.name, disabled: true }),
                value: new FormControl(),
                subject: new FormControl({
                    value: selectedWorkOrderNote.subject,
                    disabled: true,
                }),
                body: new FormControl(selectedWorkOrderNote.body),
            });
        } else if (setting) {
            this.settingForm = new FormGroup({
                name: new FormControl({ value: setting.name, disabled: true }),
                value: new FormControl(setting.value),
                subject: new FormControl(null),
                body: new FormControl(null),
            });
            if (
                setting.name.toLowerCase() ==
                    SettingEnum.DefaultEmail.toLowerCase() ||
                setting.name.toLowerCase() ==
                    SettingEnum.FirstNotice.toLowerCase() ||
                setting.name.toLowerCase() ==
                    SettingEnum.FinalNotice.toLowerCase()
            ) {
                const email = JSON.parse(setting.value);

                if (email) {
                    this.settingForm.controls.subject.setValue(email.subject);
                    this.settingForm.controls.body.setValue(email.body);
                }
            }
        } else {
            this.settingForm = new FormGroup({
                name: new FormControl({
                    value: this.settingForm.controls.name.value,
                    disabled: true,
                }),
                value: new FormControl(),
                subject: new FormControl(null),
                body: new FormControl(null),
            });
        }
        this.getWorkOrderNotes();
    }

    setEditSetting(setting: Setting): void {
        this.setting = setting;
        this.initializeForm(setting);
    }

    onFormClose(): void {
        this.setting = null;
        this.settingChangedEvent.emit(false);
        this.loadingSettingEdit.emit(false);
        this.initializeForm();
    }

    onFormSave(): void {
        this.settingForm.updateValueAndValidity();
        if (!this.settingForm.valid) {
            return;
        } else {
            this.loadingSettingEdit.emit(true);

            const setting: Setting = {
                ...this.setting,
                ...this.settingForm.value,
            };

            if (
                this.setting.name.toLowerCase() ===
                    this.SettingEnum.DefaultEmail.toLowerCase() ||
                this.setting.name.toLowerCase() ===
                    this.SettingEnum.FinalNotice.toLowerCase() ||
                this.setting.name.toLowerCase() ===
                    this.SettingEnum.FirstNotice.toLowerCase()
            ) {
                const email = {
                    body: this.settingForm.controls.body.value,
                    subject: this.settingForm.controls.subject.value,
                };
                setting.value = JSON.stringify(email);
                this.settingsService.put([this.setting.id], setting).subscribe(
                    () => {
                        this.setting = null;
                        this.settingChangedEvent.emit(false);
                        this.loadingSettingEdit.emit(false);
                        this.initializeForm();
                    },
                    () => {
                        this.failed = true;
                    },
                );
            } else if (
                setting.name.toLowerCase() ===
                this.SettingEnum.WorkOrderNotesConfig.toLowerCase()
            ) {
                const workOrderNote: WorkOrderNote = {
                    ...this.selectedWorkOrderNote,
                    subject: this.settingForm.controls.subject.value,
                    body: this.settingForm.controls.body.value,
                };
                this.workOrderNoteService
                    .put([this.selectedWorkOrderNote.id], workOrderNote)
                    .subscribe(() => {
                        this.setting = null;
                        this.settingChangedEvent.emit(false);
                        this.loadingSettingEdit.emit(false);
                        this.initializeForm();
                    });
            } else {
                this.settingsService.put([this.setting.id], setting).subscribe(
                    () => {
                        this.setting = null;
                        this.settingChangedEvent.emit(false);
                        this.loadingSettingEdit.emit(false);
                        this.initializeForm();
                    },
                    () => {
                        this.failed = true;
                    },
                );
            }
        }
    }

    getIfEmailSetting(settingName: string) {
        return (
            settingName.toLowerCase() ==
                SettingEnum.FirstNotice.toLowerCase() ||
            settingName.toLowerCase() ==
                SettingEnum.FinalNotice.toLowerCase() ||
            settingName.toLowerCase() == SettingEnum.DefaultEmail.toLowerCase()
        );
    }

    getWorkOrderNotes(): void {
        this.workOrderNoteService
            .get([])
            .subscribe((response: WorkOrderNote[]) => {
                this.workOrderNotes = response;
            });
    }

    onSelectionChange(event): void {
        this.selectedWorkOrderNote = this.workOrderNotes.find(
            (item) => item.id == event.value.id,
        );
        if (event.value === 'New') {
            this.newWorkOrder = true;
            this.settingForm = new FormGroup({
                name: new FormControl({
                    value: this.setting.name,
                    disabled: true,
                }),
                value: new FormControl(),
                subject: new FormControl(),
                body: new FormControl(null),
            });
        } else {
            this.newWorkOrder = false;

            this.settingForm = new FormGroup({
                name: new FormControl({
                    value: this.setting.name,
                    disabled: true,
                }),
                value: new FormControl(),
                subject: new FormControl({
                    value: this.selectedWorkOrderNote.subject,
                    disabled: true,
                }),
                body: new FormControl({
                    value: this.selectedWorkOrderNote.body,
                    disabled: false,
                }),
            });
        }
    }

    onCreateClick(): void {
        this.settingForm.updateValueAndValidity();
        if (!this.settingForm.valid) {
            return;
        } else {
            this.loadingSettingEdit.emit(true);
            const workOrderNote: WorkOrderNote = {
                ...this.selectedWorkOrderNote,
                subject: this.settingForm.controls.subject.value,
                body: this.settingForm.controls.body.value,
            };
            this.workOrderNoteService.post([], workOrderNote).subscribe(() => {
                this.setting = null;
                this.settingChangedEvent.emit(false);
                this.loadingSettingEdit.emit(false);
                this.initializeForm();
            });
        }
    }

    onDeleteClick(id: string): void {
        this.loadingSettingEdit.emit(true);
        this.workOrderNoteService.delete([id]).subscribe(() => {
            this.setting = null;
            this.settingChangedEvent.emit(false);
            this.loadingSettingEdit.emit(false);
            this.initializeForm();
        });
    }

    openDialog(id: string): void {
        const dialogRef = this.dialog.open(DelWrkorderNoteComponent, {});
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.onDeleteClick(id);
            }
        });
    }
}
