<app-dialog-header
    title="This role has users attached to it."
></app-dialog-header>

<div mat-dialog-actions align="end" class="tw-gap-2">
    <button mat-button (click)="routeToUsers()" [value]="true">
        Edit Users
    </button>
    <button
        mat-raised-button
        class="m3-error"
        (click)="closeDialog()"
        [value]="false"
    >
        Continue
    </button>
</div>
