import { Component, OnInit, ViewChild } from '@angular/core';
import {
    MatDatepickerInputEvent,
    MatDatepickerModule,
} from '@angular/material/datepicker';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { saveAs } from 'file-saver';
import { Billing } from 'src/app/models/report-models/billing.model';
import { Client } from 'src/app/models/client.model';
import { Page } from 'src/app/models/page.model';
import { ClientService } from 'src/app/services/client.service';
import { ReportsService } from 'src/app/services/reports.service';
import { getISOWeek } from 'date-fns';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
    FormsModule,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportTypes } from 'src/app/enums/report-types';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { Project } from 'src/app/models/project-model';
import { ProjectsService } from 'src/app/services/projects.service';
import { Task } from 'src/app/models/task.model';
import { TaskService } from 'src/app/services/task-service.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VisibleDirective } from '../../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/user.service';

interface IFilterFormControls {
    date: FormControl<Date | null>;
    projectName: FormControl<string | null>;
    taskName: FormControl<string | null>;
    fullName: FormControl<string | null>;
    clientId: FormControl<string | null>;
    hours: FormControl<string | number | null>;
    hideBillable: FormControl<boolean>;
    salesRepId: FormControl<string | null>;
    recruiterId: FormControl<string | null>;
}

@Component({
    selector: 'app-billing-report',
    templateUrl: './billing-report.component.html',
    styleUrls: ['./billing-report.component.scss'],
    imports: [
        NgIf,
        MatProgressBarModule,
        MatRadioModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatSlideToggleModule,
        MatButtonModule,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatCheckboxModule,
        VisibleDirective,
        MatSort,
        MatCardModule,
    ]
})
export class BillingReportComponent implements OnInit {
    reports: Billing;
    reportTypes = ReportTypes;
    loadingReport = false;
    requestFailed = false;
    clientId: string;
    queryParams = new Map<string, string | boolean | number>();
    displayedColumns = [
        'clientName',
        'projectName',
        'taskName',
        'firstName',
        'lastName',
        'hours',
        'recruiterName',
        'salesRepName',
    ];
    count: number;
    pageIndex: number = 0;
    clients: Client[];
    salesReps: User[];
    recruiters: User[];
    projectNames: string[] = [];
    taskNames: string[] = [];
    showFilterMenu = false;
    enableToggle = false;
    sortAscending: string = null;
    sortProperty: string = null;
    rangeDates: boolean = false;

    dateRangeForm = new FormGroup({
        startDate: new FormControl(new Date()),
        endDate: new FormControl(new Date()),
        date: new FormControl(new Date()),
    });

    filterForm: FormGroup<IFilterFormControls> = new FormGroup({
        date: new FormControl(new Date()),
        clientId: new FormControl(),
        projectName: new FormControl(),
        taskName: new FormControl(),
        fullName: new FormControl(),
        hours: new FormControl(),
        hideBillable: new FormControl(),
        salesRepId: new FormControl(),
        recruiterId: new FormControl(),
    });

    page = new Page<Billing>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    dataSource: MatTableDataSource<Billing>;
    @ViewChild(MatSort) sort: MatSort;

    range = new FormGroup({
        fromDate: new FormControl<Date | string | null>(null, [
            Validators.required.bind(this),
        ]),
        toDate: new FormControl<Date | string | null>(null, [
            Validators.required.bind(this),
        ]),
    });

    constructor(
        private reportService: ReportsService,
        private clientService: ClientService,
        private projectService: ProjectsService,
        private taskService: TaskService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        const map = this.route.snapshot.queryParamMap;
        this.checkMappedDate();

        if (
            map.has('clientId') ||
            map.has('projectName') ||
            map.has('taskName') ||
            map.has('name') ||
            map.has('hours')
        ) {
            this.enableToggle = true;
            this.showFilterMenu = true;
            this.populateFilters();
        } else if (map.has('sortAscending') || map.has('sortProperty')) {
            this.populateFilters();
        }

        this.filterChange();
        this.getBillingReport();

        this.getClients();
        this.getUsers();

        this.filterForm.controls.fullName.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.filterForm.controls.hours.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());
    }

    setQueryParams(): void {
        if (this.clientId) {
            this.queryParams.set('clientId', this.clientId);
        } else {
            this.queryParams.delete('clientId');
        }

        if (this.pageIndex) {
            this.queryParams.set(
                'offset',
                this.pageIndex * this.page.countRequested,
            );
        } else {
            this.queryParams.set('offset', 0);
        }

        this.queryParams.set('count', this.page.countRequested);

        if (this.rangeDates) {
            this.queryParams.set(
                'startDate',
                this.formatDate(this.dateRangeForm.controls.startDate.value),
            );
            this.queryParams.set(
                'endDate',
                this.formatDate(this.dateRangeForm.controls.endDate.value),
            );
        } else {
            this.queryParams.set(
                'week',
                getISOWeek(this.filterForm.controls.date.value).toString(),
            );
            this.queryParams.set(
                'year',
                this.filterForm.controls.date.value.getFullYear().toString(),
            );
        }
    }

    checkMappedDate() {
        const map = this.route.snapshot.queryParamMap;

        if (map.has('day') && map.has('month') && map.has('year')) {
            const day = Number(map.get('day'));
            const month = Number(map.get('month'));
            const year = Number(map.get('year'));
            const newDate = new Date(year, month, day);
            this.filterForm.controls.date.setValue(newDate);
            this.dateRangeForm.controls.startDate.setValue(newDate);
            this.dateRangeForm.controls.endDate.setValue(newDate);
            this.dateRangeForm.controls.date.setValue(newDate);
        }
    }

    formatDate(date: Date) {
        const formattedStartDate = date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        });

        return formattedStartDate;
    }

    dateTypeChange(): void {
        this.queryParams.delete('startDate');
        this.queryParams.delete('endDate');

        this.checkMappedDate();
        this.changePageIndex(0);
        this.getBillingReport();
    }

    getBillingReport(): void {
        this.loadingReport = true;
        this.setQueryParams();

        this.reportService.get(['billing'], this.queryParams).subscribe(
            (response: Page<Billing>) => {
                this.page = response;
                this.dataSource = new MatTableDataSource(this.page.items);
                this.dataSource.sort = this.sort;
                this.loadingReport = false;
                this.grabFilters();
            },
            () => {
                this.requestFailed = true;
                this.loadingReport = false;
            },
        );
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number) {
        this.pageIndex = index;
        this.getBillingReport();
    }

    getClients(): void {
        const queryParams = new Map<string, string | number | boolean>();
        queryParams.set('count', -1);
        queryParams.set('status', ACTIVE);

        this.clientService
            .get([], queryParams)
            .subscribe((response: Page<Client>) => {
                this.clients = response.items;
            });
    }

    getUsers(): void {
        const queryParams = new Map<string, string | number>();
        queryParams.set('count', -1);
        queryParams.set('status', ACTIVE);

        this.userService
            .get([], queryParams)
            .subscribe((response: Page<User>) => {
                this.recruiters = response.items.filter((u) => u.isRecruiter);
                this.salesReps = response.items.filter((u) => u.isSalesRep);
            });
    }

    dateChange(event: MatDatepickerInputEvent<Date>): void {
        if (
            this.dateRangeForm.controls.startDate.value &&
            this.dateRangeForm.controls.endDate.value &&
            this.rangeDates
        ) {
            this.filterForm.controls.date.setValue(
                this.dateRangeForm.controls.startDate.value,
            );
            this.clearFilters();
            this.changePageIndex(0);
        } else if (!this.rangeDates && this.dateRangeForm.controls.date.value) {
            this.filterForm.controls.date.setValue(event.value);
            this.clearFilters();
            this.changePageIndex(0);
        }
    }

    exportReport(): void {
        this.setQueryParams();
        this.reportService
            .exportReport(['billing', 'export'], this.queryParams)
            .subscribe({
                next: (buffer: Blob) => {
                    const data: Blob = new Blob([buffer], {
                        type: 'text/csv',
                    });
                    saveAs(data, 'billing-report.csv');
                    this.loadingReport = false;
                },
                error: () => {
                    this.requestFailed = true;
                    this.loadingReport = false;
                },
            });
    }

    filterChange(): void {
        if (
            this.filterForm.controls.clientId.value &&
            this.filterForm.controls.clientId.value !== ''
        ) {
            this.clientId = this.filterForm.controls.clientId.value;
        } else {
            this.clientId = null;
        }

        if (this.filterForm.controls.hideBillable.value) {
            this.queryParams.set(
                'hideBillable',
                this.filterForm.controls.hideBillable.value,
            );
        } else {
            this.queryParams.delete('hideBillable');
        }

        if (
            this.filterForm.controls.projectName.value &&
            this.filterForm.controls.projectName.value !== ''
        ) {
            this.queryParams.set(
                'ProjectName',
                this.filterForm.controls.projectName.value,
            );
        } else {
            this.queryParams.delete('ProjectName');
        }

        if (
            this.filterForm.controls.taskName.value &&
            this.filterForm.controls.taskName.value !== ''
        ) {
            this.queryParams.set(
                'TaskName',
                this.filterForm.controls.taskName.value,
            );
        } else {
            this.queryParams.delete('TaskName');
        }

        if (
            this.filterForm.controls.recruiterId.value &&
            this.filterForm.controls.recruiterId.value !== ''
        ) {
            if (this.filterForm.controls.recruiterId.value == 'house') {
                this.queryParams.set('houseRecruiters', true);
                this.queryParams.delete('recruiterId');
            } else {
                this.queryParams.set(
                    'recruiterId',
                    this.filterForm.controls.recruiterId.value,
                );
                this.queryParams.delete('houseRecruiters');
            }
        } else {
            this.queryParams.delete('recruiterId');
            this.queryParams.delete('houseRecruiters');
        }

        if (
            this.filterForm.controls.salesRepId.value &&
            this.filterForm.controls.salesRepId.value !== ''
        ) {
            if (this.filterForm.controls.salesRepId.value == 'house') {
                this.queryParams.set('houseReps', true);
                this.queryParams.delete('salesRepId');
            } else {
                this.queryParams.set(
                    'salesRepId',
                    this.filterForm.controls.salesRepId.value,
                );
                this.queryParams.delete('houseReps');
            }
        } else {
            this.queryParams.delete('salesRepId');
            this.queryParams.delete('houseReps');
        }

        if (
            this.filterForm.controls.fullName.value &&
            this.filterForm.controls.fullName.value !== ''
        ) {
            this.queryParams.set(
                'FullName',
                this.filterForm.controls.fullName.value,
            );
        } else {
            this.queryParams.delete('FullName');
        }

        if (
            this.filterForm.controls.hours.value &&
            this.filterForm.controls.hours.value !== ''
        ) {
            this.queryParams.set('Hours', this.filterForm.controls.hours.value);
        } else {
            this.queryParams.delete('Hours');
        }

        this.updateUrl();
        this.changePageIndex(0);
    }

    grabFilters(): void {
        const queryParams = new Map<string, number | string>();
        queryParams.set('count', -1);
        queryParams.set('status', ACTIVE);

        let projects: Project[];

        this.projectService
            .get([], queryParams)
            .subscribe((response: Page<Project>) => {
                projects = response.items;

                if (this.clients) {
                    const projectNameArray = projects.map((item) => item.name);
                    this.projectNames = Array.from(new Set(projectNameArray));
                    this.projectNames.sort((b, a) => b.localeCompare(a));
                }
            });

        let tasks: Task[];

        this.taskService
            .get([], queryParams)
            .subscribe((response: Page<Task>) => {
                tasks = response.items;

                if (this.clients) {
                    const taskNameArray = tasks.map((item) => item.name);

                    this.taskNames = Array.from(new Set(taskNameArray));
                    this.taskNames.sort((b, a) => b.localeCompare(a));
                }
            });
    }

    clearFilters(): void {
        this.projectNames = [];
        this.taskNames = [];
        this.clientId = null;
        this.queryParams.delete('ProjectName');
        this.queryParams.delete('TaskName');
        this.queryParams.delete('FullName');
        this.queryParams.delete('Hours');
        this.queryParams.delete('week');
        this.queryParams.delete('year');
        this.queryParams.delete('hideBillable');
        this.queryParams.delete('startDate');
        this.queryParams.delete('endDate');
        this.queryParams.delete('recruiterId');
        this.queryParams.delete('salesRepId');

        this.filterForm = new FormGroup<IFilterFormControls>({
            date: new FormControl(this.filterForm.controls.date.value),
            clientId: new FormControl(''),
            salesRepId: new FormControl(''),
            recruiterId: new FormControl(''),
            projectName: new FormControl(''),
            taskName: new FormControl(''),
            fullName: new FormControl(''),
            hours: new FormControl(''),
            hideBillable: new FormControl(false),
        });

        this.filterForm.controls.fullName.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.filterForm.controls.hours.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.updateUrl();
        this.changePageIndex(0);
    }

    showFilters(): void {
        this.showFilterMenu = !this.showFilterMenu;
        this.clearFilters();
    }

    populateFilters(): void {
        const map = this.route.snapshot.queryParamMap;

        if (map.has('month') && map.has('year') && map.has('day')) {
            const day = Number(map.get('day'));
            const month = Number(map.get('month'));
            const year = Number(map.get('year'));
            const newDate = new Date(year, month, day);
            this.filterForm.controls.date.setValue(newDate);
        }

        if (map.has('clientId')) {
            this.clientId = map.get('clientId');
            this.filterForm.controls.clientId.setValue(this.clientId);
        }

        if (map.has('projectName')) {
            const projectName = map.get('projectName');
            this.filterForm.controls.taskName.setValue(projectName);
        }

        if (map.has('taskName')) {
            const taskName = map.get('taskName');
            this.filterForm.controls.taskName.setValue(taskName);
        }

        if (map.has('name')) {
            const name = map.get('name');
            this.filterForm.controls.fullName.setValue(name);
        }

        if (map.has('hours')) {
            const hours = map.get('hours');
            this.filterForm.controls.hours.setValue(hours);
        }

        if (map.has('salesRepId')) {
            const salesRepId = map.get('salesRepId');
            this.filterForm.controls.salesRepId.setValue(salesRepId);
        }

        if (map.has('recruiterId')) {
            const recruiterId = map.get('recruiterId');
            this.filterForm.controls.recruiterId.setValue(recruiterId);
        }

        if (map.has('sortAscending')) {
            this.queryParams.set('isSortAscending', map.get('sortAscending'));
        }

        if (map.has('sortProperty')) {
            this.queryParams.set('sortProperty', map.get('sortProperty'));
        }
    }

    updateUrl(): void {
        void this.router.navigate([]);

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                report: this.reportTypes.Billing,
                count: this.page.countRequested,
                day: this.filterForm.controls.date.value.getDate().toString(),
                month: this.filterForm.controls.date.value
                    .getMonth()
                    .toString(),
                year: this.filterForm.controls.date.value
                    .getFullYear()
                    .toString(),
                clientId: this.clientId,
                projectName: this.filterForm.controls.projectName.value,
                taskName: this.filterForm.controls.taskName.value,
                salesRepId: this.filterForm.controls.salesRepId.value,
                recruiterId: this.filterForm.controls.recruiterId.value,
                name: this.filterForm.controls.fullName.value,
                hours: this.filterForm.controls.hours.value,
                hideBillable: this.filterForm.controls.hideBillable,
                sortAscending: this.sortAscending,
                sortProperty: this.sortProperty,
            },
        });
    }
}
