import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MeService } from 'src/app/services/me.service';
import { FormControl, FormGroup } from '@angular/forms';
import {
    ITimesheetFormControls,
    IWeeklyTotalsFormControls,
} from 'src/app/interfaces/timesheets';
import { forkJoin, Observable } from 'rxjs';
import { TimesheetService } from 'src/app/services/timesheet-service.service';
import { MatDialog } from '@angular/material/dialog';
import { Task } from 'src/app/models/task.model';
import { TimesheetDate } from 'src/app/models/timesheet-date-info.model';
import { TimesheetTotalsComponent } from '../timesheet-totals/timesheet-totals.component';
import { WeekHours } from 'src/app/models/week-hours.model';
import { User } from 'src/app/models/User';
import {
    endOfISOWeek,
    getISOWeek,
    getISOWeekYear,
    isSameDay,
    startOfISOWeek,
    sub,
} from 'date-fns';
import { DeleteTimesheetDialogComponent } from './delete-timesheet-dialog/delete-timesheet-dialog.component';
import { NestedSelectComponent } from '../nested-select/nested-select.component';
import { EditTaskDialogComponent } from '../edit-task-dialog/edit-task-dialog.component';
import { DayOfWeekAsString } from 'src/app/enums/day-of-week.enum.ts';
import { MatDividerModule } from '@angular/material/divider';
import { TimesheetItemComponent } from '../timesheet-item/timesheet-item.component';
import { TimesheetControlsComponent } from '../timesheet-controls/timesheet-controls.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor } from '@angular/common';
import { VisibleDirective } from '../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import {
    SUBMISSIONURL,
    WEEK,
    YEAR,
} from 'src/app/constants/queryParamConstants';

@Component({
    selector: 'app-timesheet-dashboard',
    templateUrl: './timesheet-dashboard.component.html',
    styleUrls: ['./timesheet-dashboard.component.scss'],
    imports: [
        NgIf,
        MatProgressBarModule,
        TimesheetControlsComponent,
        NestedSelectComponent,
        NgFor,
        TimesheetItemComponent,
        MatDividerModule,
        TimesheetTotalsComponent,
        VisibleDirective,
        MatCardModule,
    ],
})
export class TimesheetDashboardComponent implements OnInit {
    @ViewChild(NestedSelectComponent)
    nestedSelectComponent!: NestedSelectComponent;
    @ViewChild(TimesheetTotalsComponent)
    timesheetTotalsComponent!: TimesheetTotalsComponent;
    @ViewChild(EditTaskDialogComponent)
    editTaskComponent!: EditTaskDialogComponent;

    @Input() userFormControl?: FormControl<User | null> =
        new FormControl<User | null>(null);

    lastSelectedDate: TimesheetDate;
    user: User = null;
    message: string = undefined;
    isContractor: boolean = undefined;
    loading = true;
    lockedTimesheet = false;
    currentUrl: URL;
    baseUrl: string;

    days = Object.entries(DayOfWeekAsString).map((e) => e[0]);
    weekDates: Date[] = Array(7)
        .fill(null)
        .map(() => new Date());
    contractors?: User[] = null;

    weeklyTotalsFormGroup = new FormGroup<IWeeklyTotalsFormControls>({
        mondayTotal: new FormControl(0),
        tuesdayTotal: new FormControl(0),
        wednesdayTotal: new FormControl(0),
        thursdayTotal: new FormControl(0),
        fridayTotal: new FormControl(0),
        saturdayTotal: new FormControl(0),
        sundayTotal: new FormControl(0),
        weekTotal: new FormControl(0),
    });

    timesheetForms: FormGroup<ITimesheetFormControls>[] = [];

    timesheetFormsPast: FormGroup<ITimesheetFormControls>[] = [];

    weekHours: WeekHours = new WeekHours({
        saved: 0,
        submitted: 0,
        locked: 0,
    });

    constructor(
        private meService: MeService,
        private timesheetService: TimesheetService,
        public editDialog: MatDialog,
    ) {
        const currentDate = new Date();
        let dateOfConsideration = currentDate;
        if (
            isSameDay(currentDate, startOfISOWeek(currentDate)) &&
            currentDate.getHours() < 12
        ) {
            dateOfConsideration = sub(currentDate, { days: 1 });
        }
        this.lastSelectedDate = new TimesheetDate({
            beginningOfWeek: startOfISOWeek(dateOfConsideration),
            endOfWeek: endOfISOWeek(dateOfConsideration),
            selectedWeek: getISOWeek(dateOfConsideration),
            selectedYear: getISOWeekYear(dateOfConsideration),
        });
    }

    ngOnInit(): void {
        this.currentUrl = new URL(window.location.href);
        this.baseUrl = `${this.currentUrl.protocol}//${this.currentUrl.hostname}:${this.currentUrl.port}/submitted-timesheets`;

        if (!this.userFormControl.value) {
            this.meService.get([]).subscribe((result: User) => {
                this.user = result;
                this.getTimesheets();
            });
        } else {
            this.user = this.userFormControl.value;
            this.getTimesheets();
        }

        this.userFormControl.valueChanges.subscribe((observer) => {
            this.user = observer;
            this.getTimesheets();
        });
    }

    protected getPastTasks(): void {
        const queryParams: Map<string, number> = new Map([
            [WEEK, this.lastSelectedDate.selectedWeek - 1],
            [YEAR, this.lastSelectedDate.selectedYear],
        ]);

        if (this.user) {
            this.loading = true;

            this.meService
                .get(['previous-tasks'], queryParams)
                .subscribe((response: Task[]) => {
                    response.forEach((task) => {
                        if (
                            this.timesheetForms.find(
                                (timesheet) =>
                                    timesheet.controls.taskId.value == task.id,
                            )
                        ) {
                            //DO NOT CREATE TIMESHEET
                        } else {
                            this.createTimesheet(task);
                        }
                    });
                });
        }

        this.loading = false;
    }

    protected setMessage(): void {
        let hoursNeedSaved = false;
        let hoursNeedSubmitted = false;
        const date = new Date();

        this.timesheetForms.forEach(
            (timesheetForm: FormGroup<ITimesheetFormControls>) => {
                if (!timesheetForm.controls.submitted.value) {
                    hoursNeedSubmitted = true;

                    if (!timesheetForm.controls.saved.value) {
                        hoursNeedSaved = true;
                    }
                }
            },
        );

        if (hoursNeedSaved) {
            this.message = 'You have unsaved hours!';
        } else if (hoursNeedSubmitted) {
            this.message = 'You have unsubmitted hours!';
        } else if (this.lastSelectedDate.beginningOfWeek > date) {
            this.message = 'Caution: Timesheet contains future dates.';
        } else {
            this.message = null;
        }
    }

    protected updateDate(date: TimesheetDate): void {
        this.lastSelectedDate = date;
        this.getTimesheets();
    }

    protected getTimesheets(): void {
        const queryParams: Map<string, number> = new Map([
            [WEEK, this.lastSelectedDate.selectedWeek],
            [YEAR, this.lastSelectedDate.selectedYear],
        ]);

        if (this.user) {
            this.loading = true;
            this.getTotals();
            this.setWeekHours();
            this.timesheetService
                .getTimesheets(['user', this.user.userLoginId], queryParams)
                .subscribe({
                    next: (response) => {
                        this.getTimesheetsCallback(response);
                        this.loading = false;
                    },
                    error: () => {
                        this.loading = false;
                    },
                });
        }
    }

    protected createTimesheet(task: Task): void {
        this.timesheetForms.push(
            new FormGroup<ITimesheetFormControls>({
                id: new FormControl<string | null>(null),
                projectId: new FormControl(task.projectId),
                taskId: new FormControl(task.id),
                week: new FormControl(this.lastSelectedDate.selectedWeek),
                year: new FormControl(this.lastSelectedDate.selectedYear),
                monday: new FormControl(0),
                tuesday: new FormControl(0),
                wednesday: new FormControl(0),
                thursday: new FormControl(0),
                friday: new FormControl(0),
                saturday: new FormControl(0),
                sunday: new FormControl(0),
                mondayNotes: new FormControl(''),
                tuesdayNotes: new FormControl(''),
                wednesdayNotes: new FormControl(''),
                thursdayNotes: new FormControl(''),
                fridayNotes: new FormControl(''),
                saturdayNotes: new FormControl(''),
                sundayNotes: new FormControl(''),
                taskName: new FormControl(task.name),
                projectName: new FormControl(task.projectName),
                usersTasksId: new FormControl(''),
                clientName: new FormControl(task.clientName),
                userProfileId: new FormControl(this.user.id),
                firstName: new FormControl(this.user.firstName),
                lastName: new FormControl(this.user.lastName),
                submitted: new FormControl(false),
                locked: new FormControl(false),
                saved: new FormControl(false),
                approvalState: new FormControl('New'),
            }),
        );

        this.nestedSelectComponent.filterAddedTasks();
    }

    private getTimesheetsCallback(
        response: FormGroup<ITimesheetFormControls>[],
    ): void {
        if (response.length > 0) {
            response.forEach((timesheet) => {
                if (timesheet.controls.locked.value) {
                    this.lockedTimesheet = true;
                } else {
                    this.lockedTimesheet = false;
                }
            });
        } else {
            this.lockedTimesheet = false;
        }

        this.timesheetForms = response;
        this.getTotals();
        this.setWeekHours();
        this.nestedSelectComponent.getGroupedTasks();
        this.setMessage();
        this.loading = false;
    }

    protected deleteTimesheetForm(
        timesheetToRemove: FormGroup<ITimesheetFormControls>,
    ): void {
        this.timesheetForms = this.timesheetForms.filter(
            (timesheet) => timesheet != timesheetToRemove,
        );
        if (
            timesheetToRemove.controls.saved.value ||
            timesheetToRemove.controls.submitted.value
        ) {
            this.timesheetService
                .delete([timesheetToRemove.controls.id.value])
                .subscribe();
        }
        this.getTotals();
        this.setWeekHours();
        this.setMessage();
        this.nestedSelectComponent.getGroupedTasks();
    }

    public getTotals(): void {
        if (this.timesheetTotalsComponent != null) {
            this.timesheetTotalsComponent.calcTotals(this.timesheetForms);
        }
    }

    setWeekHours(): void {
        this.weekHours = new WeekHours({
            saved: 0,
            submitted: 0,
            locked: 0,
        });

        if (this.timesheetForms != null) {
            this.timesheetForms.forEach((timesheetForm) => {
                var totalHours = 0;
                totalHours += timesheetForm.controls.tuesday.value;
                totalHours += timesheetForm.controls.wednesday.value;
                totalHours += timesheetForm.controls.thursday.value;
                totalHours += timesheetForm.controls.monday.value;
                totalHours += timesheetForm.controls.friday.value;
                totalHours += timesheetForm.controls.saturday.value;
                totalHours += timesheetForm.controls.sunday.value;

                this.weekHours.locked += timesheetForm.controls.locked.value
                    ? totalHours
                    : 0;
                this.weekHours.saved += timesheetForm.controls.saved.value
                    ? totalHours
                    : 0;
                this.weekHours.submitted += timesheetForm.controls.submitted
                    .value
                    ? totalHours
                    : 0;
            });
        }
    }

    protected submitTimesheets(): void {
        this.loading = this.timesheetForms.length > 0 ? true : false;

        const pendingRequests: Observable<unknown>[] = [];

        this.timesheetForms.forEach(
            (timesheetForm: FormGroup<ITimesheetFormControls>) => {
                timesheetForm.controls.submitted.setValue(true);

                this.days.forEach((day) => {
                    timesheetForm.controls[day.toLowerCase()].value ??= 0;
                });
                const queryParams = new Map<string, string>();
                if (timesheetForm.controls.id.value == null) {
                    queryParams.set(SUBMISSIONURL, this.baseUrl);
                    pendingRequests.push(
                        this.timesheetService.post(
                            [],
                            timesheetForm.getRawValue(),
                            queryParams,
                        ),
                    );
                } else if (timesheetForm.controls.id.value != null) {
                    queryParams.set(
                        'submissionUrl',
                        `${this.baseUrl}?id=${timesheetForm.controls.id.value}`,
                    );

                    if (
                        timesheetForm.dirty &&
                        timesheetForm.controls.approvalState.value != '' &&
                        timesheetForm.controls.approvalState.value != 'New'
                    ) {
                        timesheetForm.controls.approvalState.setValue(
                            'Updated',
                        );
                    }
                    pendingRequests.push(
                        this.timesheetService.put(
                            [timesheetForm.controls.id.value],
                            timesheetForm.getRawValue(),
                            queryParams,
                        ),
                    );
                }
            },
        );

        forkJoin(pendingRequests).subscribe(() => {
            this.getTimesheets();
        });
        this.setMessage();
    }

    protected saveTimesheets(): void {
        this.loading = this.timesheetForms.length > 0 ? true : false;

        const pendingRequests: Observable<unknown>[] = [];

        this.timesheetForms.forEach(
            (timesheetForm: FormGroup<ITimesheetFormControls>) => {
                timesheetForm.controls.saved.setValue(true);

                this.days.forEach((day) => {
                    timesheetForm.controls[day.toLowerCase()].value ??= 0;
                });

                if (timesheetForm.controls.id.value == null) {
                    pendingRequests.push(
                        this.timesheetService.post(
                            [],
                            timesheetForm.getRawValue(),
                        ),
                    );
                } else if (timesheetForm.controls.id.value != null) {
                    if (
                        timesheetForm.dirty &&
                        timesheetForm.controls.approvalState.value != '' &&
                        timesheetForm.controls.approvalState.value != 'New'
                    ) {
                        timesheetForm.controls.approvalState.setValue(
                            'Updated',
                        );
                    }
                    pendingRequests.push(
                        this.timesheetService.put(
                            [timesheetForm.controls.id.value],
                            timesheetForm.getRawValue(),
                        ),
                    );
                }
            },
        );

        forkJoin(pendingRequests).subscribe(() => {
            this.getTimesheets();
        });
        this.setMessage();
    }

    protected openEditTaskDialog(): void {
        const dialogRef = this.editDialog.open(EditTaskDialogComponent, {
            width: '40%',
            data: this.user,
        });

        dialogRef.beforeClosed().subscribe(() => {
            if (dialogRef.componentInstance.updated === true) {
                this.nestedSelectComponent.getGroupedTasks();
                this.getTimesheets();
            }
        });

        dialogRef
            .afterClosed()
            .subscribe(() => this.nestedSelectComponent.getGroupedTasks());
    }

    openTimesheetRemoveDialog(
        timesheetToRemove: FormGroup<ITimesheetFormControls>,
    ): void {
        const dialogRef = this.editDialog.open(DeleteTimesheetDialogComponent);

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.deleteTimesheetForm(timesheetToRemove);
            }
        });
    }
}
