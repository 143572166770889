import { NgIf, NgFor, NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditProjectComponent } from '../projects/edit-project/edit-project.component';
import { ITimesheetFormControls } from 'src/app/interfaces/timesheets';
import { User } from 'src/app/models/User';
import { Page } from 'src/app/models/page.model';
import { UserFeedback } from 'src/app/models/UserFeedback.model';
import { EditUserFeedbackComponent } from './edit-user-feedback/edit-user-feedback.component';
import { UserFeedbackService } from 'src/app/services/userFeedback.service';

@Component({
    selector: 'app-user-feedback',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatSlideToggleModule,
        NgIf,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatButtonModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        EditProjectComponent,
        NgClass,
        MatChipsModule,
        EditUserFeedbackComponent,
        MatTooltipModule,
    ],
    templateUrl: './user-feedback.component.html',
    styleUrl: './user-feedback.component.scss',
})
export class UserFeedbackComponent implements OnInit {
    constructor(private userFeedbackService: UserFeedbackService) {}
    @Input() userView?: boolean;

    searchForm = new FormGroup({
        userSearch: new FormControl<string>(null),
        statusSearch: new FormControl<string>(null),
    });
    users: User[];

    page = new Page<UserFeedback>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    viewer: string = 'User Feedback';
    pageIndex: number = 0;
    loadingUserFeedback: boolean = false;
    selectedUserFeedback: UserFeedback = null;

    queryParams = new Map<string, boolean | string | number>();
    feedbackStatuses: string[] = ['New', 'Processed', 'Duplicate'];

    ngOnInit(): void {
        if (this.userView) {
            this.viewer = 'My Feedback';
        } else {
            this.userFeedbackService
                .get(['users-feedback'])
                .subscribe((response: Page<User>) => {
                    this.users = response.items;
                });
        }

        this.setQueryParams();
    }

    setQueryParams(): void {
        this.queryParams.set('count', 10);

        if (
            this.searchForm.controls.userSearch.value &&
            this.searchForm.controls.userSearch.value !== ''
        ) {
            this.queryParams.set(
                'submittedById',
                this.searchForm.controls.userSearch.value,
            );
        } else {
            this.queryParams.delete('submittedById');
        }

        if (this.pageIndex) {
            this.queryParams.set('offset', this.pageIndex * 10);
        } else {
            this.queryParams.set('offset', 0);
        }

        if (
            this.searchForm.controls.statusSearch.value &&
            this.searchForm.controls.statusSearch.value !== ''
        ) {
            this.queryParams.set(
                'status',
                this.searchForm.controls.statusSearch.value,
            );
        } else {
            this.queryParams.delete('status');
        }

        if (this.userView) {
            this.queryParams.set('userView', true);
        } else {
            this.queryParams.delete('userView');
        }

        this.getFeedback();
    }

    getFeedback() {
        if (this.userView) {
            this.loadingUserFeedback = true;
            this.userFeedbackService
                .get(['my-feedbacks'], this.queryParams)
                .subscribe((response: Page<UserFeedback>) => {
                    this.page = response;
                    this.loadingUserFeedback = false;
                });
        } else {
            this.loadingUserFeedback = true;
            this.userFeedbackService
                .get(['feedbacks'], this.queryParams)
                .subscribe((response: Page<UserFeedback>) => {
                    this.page = response;
                    this.loadingUserFeedback = false;
                });
        }
    }

    changePage(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.setQueryParams();
    }
    feedbackSelectionChange(event: MatSelectionListChange): void {
        this.selectedUserFeedback = event.options[0].value as UserFeedback;
        this.selectedUserFeedback.newComments = 0;

        if (this.selectedUserFeedback.status === 'New') {
            this.selectedUserFeedback.status = 'Read';
        }
    }
}
