import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogTitle,
} from '@angular/material/dialog';
import { DialogFooterComponent } from '../dialog-footer/dialog-footer.component';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    imports: [MatDialogTitle, DialogFooterComponent],
})
export class ConfirmationDialogComponent implements OnInit {
    body: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private dialogBody: string,
        private dialogRef: MatDialogRef<ConfirmationDialogComponent, boolean>,
    ) {}

    ngOnInit(): void {
        this.body = this.dialogBody;
    }

    onClickClose(value: boolean): void {
        this.dialogRef.close(value);
    }
}
