import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { DialogExitComponent } from '../dialog-exit/dialog-exit.component';

@Component({
    selector: 'app-acknowledge-component',
    imports: [
        MatDialogTitle,
        MatButtonModule,
        MatInputModule,
        MatDialogContent,
        DialogExitComponent,
    ],
    templateUrl: './acknowledge-component.component.html',
    styleUrl: './acknowledge-component.component.scss',
})
export class AcknowledgeDialogComponent implements OnInit {
    body: string;
    title: string;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private dialogBody: { body: string; title: string },
        private dialogRef: MatDialogRef<AcknowledgeDialogComponent>,
    ) {}

    ngOnInit(): void {
        this.body = this.dialogBody.body;
        this.title = this.dialogBody.title;
    }

    onClickClose(): void {
        this.dialogRef.close();
    }
}
