<app-dialog-header [title]="'Update Profile Image'"></app-dialog-header>

<div class="tw-flex tw-justify-between">
    <div class="tw-flex tw-flex-nowrap">
        @if (imageChangedEvent) {
            <div class="tw-ml-[20px]">
                <button mat-raised-button (click)="fileInput.click()">
                    <mat-icon class="select-file-icon">photo_camera</mat-icon>
                    <span>Choose Different Image</span>
                </button>
                <input
                    #fileInput
                    type="file"
                    (change)="fileChangeEvent($event)"
                    class="tw-hidden"
                    accept=".png, .jpeg, .jpg"
                />
            </div>
        }
    </div>
</div>

<mat-divider></mat-divider>

<div
    class="tw-mt-[20px] tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-5"
>
    @if (!imageChangedEvent) {
        <div
            class="tw-w-[500px] tw-h-[400px] tw-items-center tw-justify-center tw-flex mat-elevation-z8"
        >
            <div class="tw-flex tw-items-center">
                <button mat-raised-button (click)="fileInput.click()">
                    <mat-icon class="select-file-icon">photo_camera</mat-icon>
                    <span>Choose Image</span>
                </button>
                <input
                    #fileInput
                    type="file"
                    class="tw-hidden"
                    (change)="fileChangeEvent($event)"
                />
            </div>
        </div>
    } @else {
        <image-cropper
            class="tw-w-[500px] tw-h-[400px] tw-items-center tw-justify-center tw-flex mat-elevation-z8"
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="1 / 1"
            [cropperMinWidth]="300"
            [cropperMinHeight]="300"
            [cropperMaxWidth]="600"
            [cropperMaxHeight]="600"
            (imageCropped)="imageCropped($event)"
            (loadImageFailed)="loadImageFailed()"
            format="png"
        ></image-cropper>
    }

    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        @if (!croppedImage) {
            <div
                class="tw-w-[350px] tw-h-[350px] tw-flex tw-items-center tw-justify-center tw-rounded-[50%]"
            >
                <div>
                    <img
                        class="tw-w-[350px] tw-h-[350px] tw-flex tw-items-center tw-justify-center tw-rounded-[50%]"
                        [src]="currentProfileImage"
                    />
                </div>
            </div>
        } @else {
            <div
                class="tw-w-[350px] tw-h-[350px] tw-flex tw-items-center tw-justify-center tw-rounded-[50%]"
            >
                <img
                    class="tw-w-[350px] tw-h-[350px] tw-flex tw-items-center tw-justify-center tw-rounded-[50%]"
                    [src]="croppedImage"
                />
            </div>
        }
    </div>
</div>

<app-dialog-footer
    (buttonClick)="saveImage()"
    (closeClick)="closeDialog()"
></app-dialog-footer>
