import { Component, OnInit, ViewChild } from '@angular/core';
import {
    MatDatepickerInputEvent,
    MatDatepickerModule,
    MatStartDate,
} from '@angular/material/datepicker';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { saveAs } from 'file-saver';
import { Billing } from 'src/app/models/report-models/billing.model';
import { Client } from 'src/app/models/client.model';
import { Page } from 'src/app/models/page.model';
import { ClientService } from 'src/app/services/client.service';
import { ReportsService } from 'src/app/services/reports.service';
import { getISOWeek } from 'date-fns';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
    FormsModule,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportTypes } from 'src/app/enums/report-types';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { Project } from 'src/app/models/project-model';
import { ProjectsService } from 'src/app/services/projects.service';
import { Task } from 'src/app/models/task.model';
import { TaskService } from 'src/app/services/task-service.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VisibleDirective } from '../../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/user.service';
import {
    ASC,
    CLIENTID,
    COUNT,
    DAY,
    ENDDATE,
    FULLNAME,
    HOURS,
    HOUSERECRUITERS,
    HOUSEREPS,
    ISSORTASCENDING,
    MONTH,
    NAME,
    OFFSET,
    PROJECTID,
    RANGEDATES,
    RECURUITERID,
    SALESREPID,
    SORTPROPERTY,
    STARTDATE,
    STATUS,
    TASKID,
    WEEK,
    YEAR,
} from 'src/app/constants/queryParamConstants';

interface IFilterFormControls {
    date: FormControl<Date | null>;
    projectId: FormControl<string | null>;
    taskId: FormControl<string | null>;
    fullName: FormControl<string | null>;
    clientId: FormControl<string | null>;
    hours: FormControl<string | number | null>;
    salesRepId: FormControl<string | null>;
    recruiterId: FormControl<string | null>;
}

interface IReductionFilters {
    week: number;
    year: number;
    startDate: string | null;
    endDate: string | null;
    name: string | null;
    hours: number;
    salesRepId: string | null;
    recruiterId: string | null;
    clientId: string | null;
    projectId: string | null;
}

@Component({
    selector: 'app-billing-report',
    templateUrl: './billing-report.component.html',
    styleUrls: ['./billing-report.component.scss'],
    imports: [
        NgIf,
        MatProgressBarModule,
        MatRadioModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatSlideToggleModule,
        MatButtonModule,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatCheckboxModule,
        VisibleDirective,
        MatSort,
        MatCardModule,
    ],
})
export class BillingReportComponent implements OnInit {
    reports: Billing;
    reportTypes = ReportTypes;
    loadingReport = false;
    requestFailed = false;
    queryParams = new Map<string, string | boolean | number>();
    displayedColumns = [
        'clientName',
        'projectName',
        'taskName',
        'firstName',
        'lastName',
        'hours',
        'recruiterName',
        'salesRepName',
    ];
    count: number;
    pageIndex: number = 0;
    clients: Client[];
    projects: Project[];
    tasks: Task[];
    salesReps: User[];
    recruiters: User[];
    projectIds: string[] = [];
    taskIds: string[] = [];
    showFilterMenu = false;
    enableToggle = false;
    sortAscending: string = null;
    sortProperty: string = null;
    rangeDates: boolean = false;

    dateRangeForm = new FormGroup({
        startDate: new FormControl(new Date()),
        endDate: new FormControl(new Date()),
        date: new FormControl(new Date()),
    });

    filterForm: FormGroup<IFilterFormControls> = new FormGroup({
        date: new FormControl(new Date()),
        clientId: new FormControl(),
        projectId: new FormControl(),
        taskId: new FormControl(),
        fullName: new FormControl(),
        hours: new FormControl(),
        salesRepId: new FormControl(),
        recruiterId: new FormControl(),
    });

    page = new Page<Billing>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    dataSource: MatTableDataSource<Billing>;
    @ViewChild(MatSort) sort: MatSort;

    range = new FormGroup({
        fromDate: new FormControl<Date | string | null>(null, [
            Validators.required.bind(this),
        ]),
        toDate: new FormControl<Date | string | null>(null, [
            Validators.required.bind(this),
        ]),
    });

    constructor(
        private reportService: ReportsService,
        private clientService: ClientService,
        private projectService: ProjectsService,
        private taskService: TaskService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        const map = this.route.snapshot.queryParamMap;
        this.checkMappedDate();

        if (
            map.has(CLIENTID) ||
            map.has(PROJECTID) ||
            map.has(TASKID) ||
            map.has(NAME) ||
            map.has(HOURS)
        ) {
            this.enableToggle = true;
            this.showFilterMenu = true;
            this.populateFilters();
        } else if (map.has(this.sortAscending) || map.has(SORTPROPERTY)) {
            this.populateFilters();
        }

        this.getBillingReport();
        this.getUsers();

        this.filterForm.controls.fullName.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.getBillingReport());

        this.filterForm.controls.hours.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.getBillingReport());
    }

    setQueryParams(): void {
        if (this.pageIndex) {
            this.queryParams.set(
                OFFSET,
                this.pageIndex * this.page.countRequested,
            );
        } else {
            this.queryParams.set(OFFSET, 0);
        }

        this.queryParams.set(COUNT, this.page.countRequested);

        if (this.rangeDates) {
            this.queryParams.set(
                STARTDATE,
                this.formatDate(this.dateRangeForm.controls.startDate.value),
            );
            this.queryParams.set(
                ENDDATE,
                this.formatDate(this.dateRangeForm.controls.endDate.value),
            );
        } else {
            this.queryParams.set(
                WEEK,
                getISOWeek(this.filterForm.controls.date.value).toString(),
            );
            this.queryParams.set(
                YEAR,
                this.filterForm.controls.date.value.getFullYear().toString(),
            );
        }

        if (
            this.filterForm.controls.clientId.value &&
            this.filterForm.controls.clientId.value !== ''
        ) {
            this.queryParams.set(
                CLIENTID,
                this.filterForm.controls.clientId.value,
            );
        } else {
            this.queryParams.delete(CLIENTID);
        }

        if (
            this.filterForm.controls.projectId.value &&
            this.filterForm.controls.projectId.value !== ''
        ) {
            this.queryParams.set(
                PROJECTID,
                this.filterForm.controls.projectId.value,
            );
        } else {
            this.queryParams.delete(PROJECTID);
        }

        if (
            this.filterForm.controls.taskId.value &&
            this.filterForm.controls.taskId.value !== ''
        ) {
            this.queryParams.set(TASKID, this.filterForm.controls.taskId.value);
        } else {
            this.queryParams.delete(TASKID);
        }

        if (
            this.filterForm.controls.recruiterId.value &&
            this.filterForm.controls.recruiterId.value !== ''
        ) {
            if (this.filterForm.controls.recruiterId.value == 'house') {
                this.queryParams.set(HOUSERECRUITERS, true);
                this.queryParams.delete(HOUSERECRUITERS);
            } else {
                this.queryParams.set(
                    RECURUITERID,
                    this.filterForm.controls.recruiterId.value,
                );
                this.queryParams.delete(HOUSERECRUITERS);
            }
        } else {
            this.queryParams.delete(RECURUITERID);
            this.queryParams.delete(HOUSERECRUITERS);
        }

        if (
            this.filterForm.controls.salesRepId.value &&
            this.filterForm.controls.salesRepId.value !== ''
        ) {
            if (this.filterForm.controls.salesRepId.value == 'house') {
                this.queryParams.set(HOUSEREPS, true);
                this.queryParams.delete(SALESREPID);
            } else {
                this.queryParams.set(
                    SALESREPID,
                    this.filterForm.controls.salesRepId.value,
                );
                this.queryParams.delete(HOUSEREPS);
            }
        } else {
            this.queryParams.delete(SALESREPID);
            this.queryParams.delete(HOUSEREPS);
        }

        if (
            this.filterForm.controls.fullName.value &&
            this.filterForm.controls.fullName.value !== ''
        ) {
            this.queryParams.set(
                FULLNAME,
                this.filterForm.controls.fullName.value,
            );
        } else {
            this.queryParams.delete(FULLNAME);
        }

        if (
            this.filterForm.controls.hours.value &&
            this.filterForm.controls.hours.value !== ''
        ) {
            this.queryParams.set(HOURS, this.filterForm.controls.hours.value);
        } else {
            this.queryParams.delete(HOURS);
        }

        this.updateUrl();
    }

    checkMappedDate() {
        const map = this.route.snapshot.queryParamMap;

        if (map.has(DAY) && map.has(MONTH) && map.has(YEAR)) {
            const day = Number(map.get(DAY));
            const month = Number(map.get(MONTH));
            const year = Number(map.get(YEAR));
            const newDate = new Date(year, month, day);
            this.filterForm.controls.date.setValue(newDate);
            this.dateRangeForm.controls.startDate.setValue(newDate);
            this.dateRangeForm.controls.endDate.setValue(newDate);
            this.dateRangeForm.controls.date.setValue(newDate);
        }
    }

    formatDate(date: Date) {
        const formattedStartDate = date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        });

        return formattedStartDate;
    }

    dateTypeChange(): void {
        this.queryParams.delete(STARTDATE);
        this.queryParams.delete(ENDDATE);

        this.checkMappedDate();
        this.changePageIndex(0);
        this.getBillingReport();
    }

    getBillingReport(): void {
        this.loadingReport = true;
        this.setQueryParams();

        this.reportService.get(['billing'], this.queryParams).subscribe(
            (response: Page<Billing>) => {
                this.page = response;
                this.dataSource = new MatTableDataSource(this.page.items);
                this.dataSource.sort = this.sort;
                this.loadingReport = false;
                this.grabFilters();
            },
            () => {
                this.requestFailed = true;
                this.loadingReport = false;
            },
        );
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number) {
        this.pageIndex = index;
        this.getBillingReport();
    }

    sortChange(sort: Sort): void {
        this.queryParams.set(
            ISSORTASCENDING,
            sort.direction.toUpperCase() == ASC ? true : false,
        );
        this.queryParams.set(SORTPROPERTY, sort.active);

        this.sortAscending =
            sort.direction.toUpperCase() == ASC ? 'true' : 'false';
        this.sortProperty = sort.active;

        this.updateUrl();
        this.getBillingReport();
    }

    getUsers(): void {
        const queryParams = new Map<string, string | number>();
        queryParams.set(COUNT, -1);
        queryParams.set(STATUS, ACTIVE);

        this.userService
            .get([], queryParams)
            .subscribe((response: Page<User>) => {
                this.recruiters = response.items.filter((u) => u.isRecruiter);
                this.salesReps = response.items.filter((u) => u.isSalesRep);
            });
    }

    dateChange(event: MatDatepickerInputEvent<Date>): void {
        if (
            this.dateRangeForm.controls.startDate.value &&
            this.dateRangeForm.controls.endDate.value &&
            this.rangeDates
        ) {
            this.filterForm.controls.date.setValue(
                this.dateRangeForm.controls.startDate.value,
            );

            this.getBillingReport();
        } else if (!this.rangeDates && this.dateRangeForm.controls.date.value) {
            this.filterForm.controls.date.setValue(event.value);
            this.getBillingReport();
        }
    }

    exportReport(): void {
        this.setQueryParams();
        this.reportService
            .exportReport(['billing', 'export'], this.queryParams)
            .subscribe({
                next: (buffer: Blob) => {
                    const data: Blob = new Blob([buffer], {
                        type: 'text/csv',
                    });
                    saveAs(data, 'billing-report.csv');
                    this.loadingReport = false;
                },
                error: () => {
                    this.requestFailed = true;
                    this.loadingReport = false;
                },
            });
    }

    grabFilters(): void {
        const queryParams = this.queryParams;
        queryParams.set(COUNT, -1);
        queryParams.set(OFFSET, 0);

        this.clientService
            .get([], queryParams)
            .subscribe((response: Page<Client>) => {
                this.clients = response.items;

                this.projectService
                    .get([], queryParams)
                    .subscribe((response: Page<Project>) => {
                        this.projects = response.items;

                        this.taskService
                            .get([], queryParams)
                            .subscribe((response: Page<Task>) => {
                                this.tasks = response.items;
                            });
                    });
            });
    }

    clearFilters(): void {
        this.queryParams.delete(CLIENTID);
        this.queryParams.delete(PROJECTID);
        this.queryParams.delete(TASKID);
        this.queryParams.delete(FULLNAME);
        this.queryParams.delete(HOURS);
        this.queryParams.delete(WEEK);
        this.queryParams.delete(YEAR);
        this.queryParams.delete(STARTDATE);
        this.queryParams.delete(ENDDATE);
        this.queryParams.delete(RECURUITERID);
        this.queryParams.delete(SALESREPID);
        this.queryParams.delete(HOUSERECRUITERS);
        this.queryParams.delete(HOUSEREPS);

        this.filterForm = new FormGroup<IFilterFormControls>({
            date: new FormControl(this.filterForm.controls.date.value),
            clientId: new FormControl(''),
            salesRepId: new FormControl(''),
            recruiterId: new FormControl(''),
            projectId: new FormControl(''),
            taskId: new FormControl(''),
            fullName: new FormControl(''),
            hours: new FormControl(''),
        });

        this.filterForm.controls.fullName.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.getBillingReport());

        this.filterForm.controls.hours.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.getBillingReport());

        this.updateUrl();
        this.changePageIndex(0);
    }

    showFilters(): void {
        this.showFilterMenu = !this.showFilterMenu;
        this.clearFilters();
    }

    populateFilters(): void {
        const map = this.route.snapshot.queryParamMap;

        if (map.has(STARTDATE) && map.has(ENDDATE) && map.has(RANGEDATES)) {
            if (map.get(RANGEDATES)) {
                this.rangeDates = map.get(RANGEDATES).toUpperCase() === 'TRUE';
                this.dateRangeForm.controls.startDate.setValue(
                    new Date(map.get(STARTDATE)),
                );
                this.dateRangeForm.controls.endDate.setValue(
                    new Date(map.get(ENDDATE)),
                );
            }
        }

        if (map.has(MONTH) && map.has(YEAR) && map.has(DAY)) {
            const day = Number(map.get(DAY));
            const month = Number(map.get(MONTH));
            const year = Number(map.get(YEAR));
            const newDate = new Date(year, month, day);
            this.filterForm.controls.date.setValue(newDate);
        }

        if (map.has(CLIENTID)) {
            this.filterForm.controls.clientId.setValue(map.get(CLIENTID));
        }

        if (map.has(PROJECTID)) {
            const projectId = map.get(PROJECTID);
            this.filterForm.controls.projectId.setValue(projectId);
        }

        if (map.has(TASKID)) {
            const taskId = map.get(TASKID);
            this.filterForm.controls.taskId.setValue(taskId);
        }

        if (map.has(NAME)) {
            const name = map.get(NAME);
            this.filterForm.controls.fullName.setValue(name);
        }

        if (map.has(HOURS)) {
            const hours = map.get(HOURS);
            this.filterForm.controls.hours.setValue(hours);
        }

        if (map.has(SALESREPID)) {
            const salesRepId = map.get(SALESREPID);
            this.filterForm.controls.salesRepId.setValue(salesRepId);
        }

        if (map.has(RECURUITERID)) {
            const recruiterId = map.get(RECURUITERID);
            this.filterForm.controls.recruiterId.setValue(recruiterId);
        }

        if (map.has(ISSORTASCENDING)) {
            this.queryParams.set(ISSORTASCENDING, map.get(ISSORTASCENDING));
        }

        if (map.has(SORTPROPERTY)) {
            this.queryParams.set(SORTPROPERTY, map.get(SORTPROPERTY));
        }
    }

    updateUrl(): void {
        void this.router.navigate([]);

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                report: this.reportTypes.Billing,
                count: this.page.countRequested,
                day: this.filterForm.controls.date.value.getDate().toString(),
                month: this.filterForm.controls.date.value
                    .getMonth()
                    .toString(),
                year: this.filterForm.controls.date.value
                    .getFullYear()
                    .toString(),
                startDate: this.formatDate(
                    this.dateRangeForm.controls.startDate.value,
                ),
                endDate: this.formatDate(
                    this.dateRangeForm.controls.endDate.value,
                ),
                clientId: this.filterForm.controls.clientId.value,
                projectId: this.filterForm.controls.projectId.value,
                rangeDates: this.rangeDates,
                taskId: this.filterForm.controls.taskId.value,
                salesRepId: this.filterForm.controls.salesRepId.value,
                recruiterId: this.filterForm.controls.recruiterId.value,
                name: this.filterForm.controls.fullName.value,
                hours: this.filterForm.controls.hours.value,
                isSortAscending: this.sortAscending,
                sortProperty: this.sortProperty,
            },
        });
    }
}
