<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loading"
></mat-progress-bar>
<div
    [appVisible]="message"
    class="tw-w-full tw-flex tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer tw-h-10 tw-text-xl tw-items-center tw-justify-center"
>
    {{ message }}
</div>

<div class="tw-flex tw-flex-col tw-items-center tw-w-full tw-gap-4 tw-mt-4">
    <div class="tw-w-4/5">
        <app-timesheet-controls
            [weekHours]="weekHours"
            (updateDateEvent)="updateDate($event)"
            (saveTimesheetsEvent)="saveTimesheets()"
            (submitTimesheetsEvent)="submitTimesheets()"
            (editTaskEvent)="openEditTaskDialog()"
        ></app-timesheet-controls>
    </div>

    <div class="tw-w-4/5">
        <div class="tw-flex tw-justify-end">
            <app-nested-select
                [lockedTimesheet]="lockedTimesheet"
                [timesheetForms]="timesheetForms"
                [lastSelectedDate]="lastSelectedDate"
                (taskSelectedEvent)="createTimesheet($event)"
                (getPastTasksEvent)="getPastTasks()"
            ></app-nested-select>
        </div>
    </div>

    <div class="tw-w-4/5">
        <ng-container *ngFor="let timesheetForm of timesheetForms">
            <app-timesheet-item
                class="tw-w-full"
                [weekHours]="weekHours"
                [timesheetForm]="timesheetForm"
                (onClickRemoveEvent)="openTimesheetRemoveDialog($event)"
                (updateTotalsEvent)="getTotals()"
                (unsavedHoursEvent)="setMessage()"
            ></app-timesheet-item>
        </ng-container>

        <ng-container *ngIf="timesheetForms?.length > 0">
            <mat-divider class="tw-w-full"></mat-divider>

            <app-timesheet-totals
                class="tw-w-full"
                [timesheetForms]="timesheetForms"
                [weeklyTotalsFormGroup]="weeklyTotalsFormGroup"
            ></app-timesheet-totals>
        </ng-container>
    </div>
</div>
