import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogContent,
} from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import {
    MatAutocompleteSelectedEvent,
    MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { EmailEditDialogComponent } from '../email-edit-dialog/email-edit-dialog.component';
import { SettingsService } from 'src/app/services/setting.service';
import { Setting } from 'src/app/models/setting.model';
import { Page } from 'src/app/models/page.model';
import { AdminTimesheet } from 'src/app/models/admin-timesheet.model';
import { DialogExitComponent } from '../dialog-exit/dialog-exit.component';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { User } from 'src/app/models/User';

@Component({
    selector: 'app-email-multi-button-dialog',
    templateUrl: './email-multi-button-dialog.component.html',
    styleUrl: './email-multi-button-dialog.component.scss',
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        MatButtonModule,
        MatTooltipModule,
        MatDividerModule,
        MatRadioModule,
        ReactiveFormsModule,
        FormsModule,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        NgFor,
        MatOptionModule,
        MatIconModule,
        DialogExitComponent,
        AsyncPipe,
    ],
})
export class EmailMultiButtonDialogComponent implements OnInit {
    queryParams = new Map<string, string | boolean>();
    settings: Setting[] = [];
    selectedOption: string = 'all';
    names = new FormControl();
    filteredOptions: Observable<AdminTimesheet[]>;
    selectedEmployeesArr: AdminTimesheet[] = [];
    employeeIds: string[] = [];
    defaultEmailStr = 'Default Email';
    firstNoticeStr = 'First Notice';
    finalNoticeStr = 'Final Notice';

    defaultEmail: {
        subject: string | null;
        body: string | null;
    } = {
        subject: '',
        body: '',
    };

    firstNoticeEmail: {
        subject: string | null;
        body: string | null;
    } = {
        subject: '',
        body: '',
    };

    finalNoticeEmail: {
        subject: string | null;
        body: string | null;
    } = {
        subject: '',
        body: '',
    };

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<EmailMultiButtonDialogComponent>,
        private settingsService: SettingsService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            unsubmittedUsers: AdminTimesheet[];
            users: User[];
        },
    ) {}

    ngOnInit(): void {
        this.filteredOptions = this.names.valueChanges.pipe(
            startWith(''),
            map((val: string) => this.filter(val)),
        );

        this.settingsService
            .get([], this.queryParams)
            .subscribe((response: Page<Setting>) => {
                this.settings = response.items;
                response.items.map((item) => {
                    switch (item.name) {
                        case this.defaultEmailStr:
                            this.defaultEmail = JSON.parse(item.value);
                            break;

                        case this.firstNoticeStr:
                            this.firstNoticeEmail = JSON.parse(item.value);
                            break;

                        case this.finalNoticeStr:
                            this.finalNoticeEmail = JSON.parse(item.value);
                    }
                });
            });

        this.selectedEmployeesArr = this.data.unsubmittedUsers.filter(
            (employee) => employee.isSelected,
        );
    }

    filter(val: string) {
        return this.data.unsubmittedUsers.filter((item: AdminTimesheet) => {
            if (typeof val === 'object') {
                val = '';
            }

            return item.name.toLowerCase().includes(val.toLowerCase());
        });
    }

    autoCompleteDisplay(item: AdminTimesheet): string {
        if (item === null) {
            return '';
        }

        return item.name;
    }

    onEmployeeSelected(event: MatAutocompleteSelectedEvent): void {
        const selectedEmployee = event.option.value as AdminTimesheet;

        if (!this.selectedEmployeesArr.includes(selectedEmployee)) {
            this.selectedEmployeesArr.push(selectedEmployee);

            this.selectedEmployeesArr.sort((a, b) =>
                a.name.localeCompare(b.name),
            );
        }

        this.names.setValue(null);

        this.filteredOptions = this.filteredOptions.pipe(
            map((employees: AdminTimesheet[]) =>
                employees
                    .filter((emp) => emp !== selectedEmployee)
                    .sort((a, b) => a.name.localeCompare(b.name)),
            ),
        );
    }

    onDeleteEmployeeSelected(employee: AdminTimesheet): void {
        this.selectedEmployeesArr = this.selectedEmployeesArr.filter(
            (selectedEmployee) => selectedEmployee !== employee,
        );
        this.selectedEmployeesArr.sort((a, b) => a.name.localeCompare(b.name));

        this.filteredOptions = this.filteredOptions.pipe(
            map((employees: AdminTimesheet[]) => {
                const updatedEmployees = [...employees, employee];

                return updatedEmployees.sort((a, b) =>
                    a.name.localeCompare(b.name),
                );
            }),
        );
    }

    getEmployeeIds(): string[] {
        if (
            this.selectedOption === 'custom' ||
            this.selectedOption === 'unsubmitted'
        ) {
            this.employeeIds = this.selectedEmployeesArr.map(
                (employee) => employee.userProfileId,
            );
        } else {
            this.employeeIds = this.data.users.map((employee) => employee.id);
        }

        return this.employeeIds;
    }

    openCustomNotice(): void {
        this.getEmployeeIds();

        this.dialog.open(EmailEditDialogComponent, {
            width: '50%',
            height: '50%',
            data: {
                header: 'Custom Email Creator',
                subject: this.defaultEmail.subject,
                body: this.defaultEmail.body,
                emailIds: this.employeeIds,
            },
        });
    }

    openFirstNotice(): void {
        this.getEmployeeIds();

        this.dialog.open(EmailEditDialogComponent, {
            width: '50%',
            height: '50%',
            data: {
                header: 'First Notice Email',
                subject: this.firstNoticeEmail.subject,
                body: this.firstNoticeEmail.body,
                emailIds: this.employeeIds,
            },
        });
    }

    openFinalNotice(): void {
        this.getEmployeeIds();

        this.dialog.open(EmailEditDialogComponent, {
            width: '50%',
            height: '50%',
            data: {
                header: 'Final Notice Email',
                subject: this.finalNoticeEmail.subject,
                body: this.finalNoticeEmail.body,
                emailIds: this.employeeIds,
            },
        });
    }

    onExitClick(): void {
        this.dialogRef.close();
    }

    getUnsubmittedEmployees() {
        this.selectedEmployeesArr = [];
        this.selectedEmployeesArr = this.data.unsubmittedUsers.filter(
            (employee) => !employee.submitted,
        );
    }

    resetEmployeeSelection() {
        this.selectedEmployeesArr = [];
    }
}
