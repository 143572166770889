import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/User';
import { Page } from 'src/app/models/page.model';
import { MatDialog } from '@angular/material/dialog';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    FormsModule,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SEARCH_DEBOUNCE_MS } from 'src/app/constants/searchConstants';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { LocationService } from 'src/app/services/location.service';
import { Employee } from 'src/app/models/Employee';
import { Location } from 'src/app/models/location.model';
import { LocationCategories } from 'src/app/models/LocationCategories';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
    MatOptionSelectionChange,
    MatOptionModule,
} from '@angular/material/core';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';

interface IFilterFormControls {
    search: FormControl<string | null>;
    location: FormControl<string | null>;
}

@Component({
    selector: 'app-employee-directory',
    templateUrl: './employee-directory.component.html',
    styleUrls: ['./employee-directory.component.scss'],
    standalone: true,
    imports: [
        MatToolbarModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        FormsModule,
        NgFor,
        MatOptionModule,
        MatInputModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatSort,
    ],
})
export class EmployeeDirectoryComponent implements OnInit {
    displayedColumns = ['lastName', 'email', 'locationName'];
    suggestNameArr: User[];
    Employees = new Page<Employee>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });
    dataSource: MatTableDataSource<Employee>;
    @ViewChild(MatSort) sort: MatSort;
    Locations: Location[];
    Location: Location;
    officeLocations: LocationCategories[] = [];
    queryParams = new Map<string, string | number | null>();
    selectedLocationId: string;

    pageIndex = 0;
    pageCount = 20;

    filterForm: FormGroup<IFilterFormControls> = new FormGroup({
        search: new FormControl(),
        location: new FormControl(),
    });

    constructor(
        private userService: UserService,
        public detailsDialog: MatDialog,
        public locationService: LocationService,
    ) {}

    ngOnInit(): void {
        this.locationService.get([]).subscribe((response: Page<Location>) => {
            this.Locations = response.items;
            const defaultOption = {
                Name: 'All Locations',
                Id: '',
            };
            this.officeLocations.push(defaultOption);
            for (const location of this.Locations) {
                const locationCategories = {
                    Name: location.name,
                    Id: location.id,
                };
                this.officeLocations.push(locationCategories);
            }
        });

        this.GetUsers();

        this.filterForm.controls.search.valueChanges
            .pipe(debounceTime(SEARCH_DEBOUNCE_MS), distinctUntilChanged())
            .subscribe((response) => {
                const queryParams = new Map<string, string | number | null>([
                    ['Name', response],
                    ['Count', 5],
                    ['status', ACTIVE],
                ]);
                this.userService
                    .get([], queryParams)
                    .subscribe((nameArr: Page<User>) => {
                        this.suggestNameArr = nameArr.items;
                    });
            });
    }

    setQueryParams(): void {
        this.queryParams.set('status', ACTIVE);
        this.queryParams.set('count', this.pageCount);

        if (this.pageIndex) {
            this.queryParams.set('offset', this.pageIndex * this.pageCount);
        } else {
            this.queryParams.set('offset', 0);
        }
    }

    GetUsers(): void {
        this.setQueryParams();

        this.userService
            .get([], this.queryParams)
            .subscribe((result: Page<Employee>) => {
                this.Employees = result;
                this.dataSource = new MatTableDataSource(this.Employees.items);
                this.dataSource.sort = this.sort;
                for (const employee of this.Employees.items) {
                    this.Location = this.Locations.find(
                        (location) => location.id === employee.locationId,
                    );
                    employee.locationName = this.Location.name;
                }
            });
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number) {
        this.pageIndex = index;
        this.GetUsers();
    }

    resetUsers(): void {
        this.queryParams.clear();

        if (this.officeLocations.length > 0) {
            this.selectedLocationId = this.officeLocations[0].Id;
        }
        this.filterForm.controls.search.reset();

        this.changePageIndex(0);
    }

    userSelectionChange(name: string): void {
        this.queryParams.set('Name', name);
        this.changePageIndex(0);
    }

    locationFilterChange(id: string, event: MatOptionSelectionChange): void {
        if (event.isUserInput) {
            if (id == null) {
                this.queryParams.delete('locationId');
            } else {
                this.queryParams.set('locationId', id);
            }

            this.changePageIndex(0);
        }
    }
}
