import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IChangePasswordForm } from 'src/app/interfaces/change-password-form';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        NgIf,
    ],
})
export class ChangePasswordComponent {
    @Input() changePasswordForm: FormGroup<IChangePasswordForm>;

    @Output() saveNewPasswordEvent = new EventEmitter();

    currentPasswordHide: boolean;
    newPasswordHide: boolean;
    confirmPasswordHide: boolean;

    constructor() {
        this.currentPasswordHide = true;
        this.newPasswordHide = true;
        this.confirmPasswordHide = true;
    }

    onSubmitClicked(): void {
        this.saveNewPasswordEvent.emit();
    }
}
