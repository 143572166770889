import { Component, Inject, EventEmitter } from '@angular/core';
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBarRef,
} from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';

export interface ErrorSnackbarProps {
    message?: string;
}

@Component({
    selector: 'app-error-snackbar',
    templateUrl: './error-snackbar.component.html',
    styleUrls: ['./error-snackbar.component.scss'],
    imports: [MatButtonModule]
})
export class ErrorSnackbarComponent {
    message: string = 'There was an error retrieving data from the server.';

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: ErrorSnackbarProps,
        private ref: MatSnackBarRef<ErrorSnackbarComponent>,
    ) {
        if (data.message) {
            this.message = data.message;
        }
    }

    closeModal(): void {
        this.ref.dismiss();
    }
}
