import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-exit',
    templateUrl: './dialog-exit.component.html',
    styleUrl: './dialog-exit.component.scss',
    imports: [MatDialogActions, MatButtonModule],
})
export class DialogExitComponent {
    @Output() closeClick = new EventEmitter();
}
