import { Token } from '../interfaces/Token';
import { Injectable } from '@angular/core';
import { AbstractService } from './abstract-service.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService extends AbstractService {
    path = '/Login';

    loggedIn: boolean;

    logoutUser(): void {
        if (window.sessionStorage.getItem('PSSUserAuthToken')) {
            window.sessionStorage.removeItem('PSSUserAuthToken');
        }

        if (window.localStorage.getItem('ME')) {
            window.localStorage.removeItem('ME');
        }

        this.loggedIn = false;
    }

    checkLoginAuthentication(): boolean {
        const key = window.sessionStorage.getItem('PSSUserAuthToken');
        if (!key) {
            this.loggedIn = false;
            return false;
        } else {
            this.loggedIn = true;
            return true;
        }
    }

    saveToken(token: Token): void {
        window.sessionStorage.setItem('PSSUserAuthToken', token['token']);
    }
}
