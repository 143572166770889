<app-dialog-header [title]="'Add Employee Type'"></app-dialog-header>
<div mat-dialog-content class="tw-flex tw-flex-col tw-gap-2 tw-items-start">
    <form class="tw-flex tw-flex-col tw-gap-2" [formGroup]="employeeTypeForm">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="employeeTypeForm.controls.name.errors?.required">
                Name field is required.
            </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="showInUtilization">
            Show in Utilization Report
        </mat-checkbox>
        <mat-checkbox formControlName="isActive">Active</mat-checkbox>
    </form>
    <button
        [disabled]="saving"
        mat-raised-button
        class="m3-error"
        (click)="onResetClick()"
    >
        Reset
    </button>
</div>

<app-dialog-footer
    [disabled]="saving"
    (buttonClick)="saveEmployeeType()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
