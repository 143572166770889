import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Setting } from 'src/app/models/setting.model';
import { SettingsService } from 'src/app/services/setting.service';
import { Page } from 'src/app/models/page.model';
import { EditSettingComponent } from '../edit-setting/edit-setting.component';
import {
    MatPaginator,
    PageEvent,
    MatPaginatorModule,
} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MatSelectionListChange, MatListModule } from '@angular/material/list';
import { Subscription } from 'rxjs';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { SettingEnum } from 'src/app/enums/settings.enum';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor } from '@angular/common';
import { VisibleDirective } from '../../directives/visible.directive';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        NgFor,
        MatPaginatorModule,
        EditSettingComponent,
        VisibleDirective,
    ],
})
export class ConfigurationComponent implements OnInit, OnDestroy {
    @ViewChild(EditSettingComponent)
    editSettingComponent!: EditSettingComponent;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    settingEnum = SettingEnum;
    settingStatus = true;
    loadingSettings = false;
    settingSelected = false;

    searchForm = new FormGroup({
        search: new FormControl<string>(''),
    });

    pageIndex = 0;
    page = new Page<Setting>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    queryParams = new Map();

    routeSubscription: Subscription;
    searchSubscription: Subscription;
    loadEditSubscription: Subscription;
    latestSettingsSubscription: Subscription;

    constructor(
        private settingsService: SettingsService,
        public dialogService: MatDialog,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.routeSubscription = this.activatedRoute.queryParams.subscribe(
            (params) => {
                if (params.id) {
                    this.settingsService
                        .get([params.id])
                        .subscribe((setting: Setting) => {
                            this.settingSelected = true;
                            this.editSettingComponent.setEditSetting(setting);
                        });
                }
            },
        );

        this.getLatestSettings();

        this.searchSubscription =
            this.searchForm.controls.search.valueChanges.subscribe(() => {
                if (this.paginator) {
                    this.paginator.firstPage();
                }
                this.changePageIndex(0);
            });
    }

    ngAfterViewInit(): void {
        this.loadEditSubscription =
            this.editSettingComponent.loadingSettingEdit.subscribe((value) => {
                this.loadingSettings = value;
                if (value === false) {
                    this.getLatestSettings();
                }
            });
    }

    ngOnDestroy(): void {
        this.routeSubscription.unsubscribe();
        this.searchSubscription.unsubscribe();
        this.loadEditSubscription.unsubscribe();
        this.latestSettingsSubscription.unsubscribe();
    }

    settingChanged(event: boolean): void {
        this.settingSelected = event;
    }

    toggleActiveInactive(): void {
        this.settingStatus = !this.settingStatus;
        this.changePageIndex(0);
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number) {
        this.pageIndex = index;
        this.getLatestSettings();
    }

    setQueryParams(): void {
        this.queryParams.set('count', 10);

        if (this.settingStatus) {
            this.queryParams.set('status', ACTIVE);
        } else {
            this.queryParams.set('status', INACTIVE);
        }

        if (this.pageIndex) {
            this.queryParams.set('offset', this.pageIndex * 10);
        } else {
            this.queryParams.set('offset', 0);
        }

        if (
            this.searchForm.controls.search.value &&
            this.searchForm.controls.search.value !== ''
        ) {
            this.queryParams.set('name', this.searchForm.controls.search.value);
        }

        if (this.searchForm.controls.search.value === '') {
            if (this.queryParams.has('name')) {
                this.queryParams.delete('name');
            }
        }
    }

    getLatestSettings(): void {
        this.setQueryParams();

        this.latestSettingsSubscription = this.settingsService
            .get([], this.queryParams)
            .subscribe((response: Page<Setting>) => {
                this.page = response;
                const tokens = response.items;
                for (let token of tokens) {
                    if (token.name == this.settingEnum.DefaultEmail) {
                        this.editSettingComponent.defaultEmail = JSON.parse(
                            token.value.toString(),
                        );
                    } else if (token.name == this.settingEnum.FirstNotice) {
                        this.editSettingComponent.firstNotice = JSON.parse(
                            token.value.toString(),
                        );
                    } else if (token.name == this.settingEnum.FinalNotice) {
                        this.editSettingComponent.finalNotice = JSON.parse(
                            token.value.toString(),
                        );
                    }
                }
            });
    }

    settingSelectionChange(event: MatSelectionListChange): void {
        if (!this.settingSelected) {
            this.settingSelected = true;
        }

        this.editSettingComponent.setEditSetting(event.options[0].value);
    }
}
