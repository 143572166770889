import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ThemeOptions } from '../enums/theme.enum';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private themeSubject = new BehaviorSubject('dark-theme');
    themeOptions: string[];

    $theme = this.themeSubject.asObservable();

    constructor() {
        this.themeOptions = Object.values(ThemeOptions);
    }

    announceThemeChange(newTheme: string): void {
        this.themeSubject.next(newTheme);
    }

    getCurrentTheme(): string {
        return this.themeSubject.getValue();
    }

    getThemeOptions(): string[] {
        return this.themeOptions;
    }

    setTheme(newTheme: string): void {
        this.announceThemeChange(newTheme);
    }
}
