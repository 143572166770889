<app-dialog-header [title]="'Add Location'"></app-dialog-header>

<div mat-dialog-content>
    <div
        *ngIf="saveFailed"
        class="tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer"
    >
        Save Failed
    </div>

    <mat-progress-bar
        [appVisible]="saving"
        mode="indeterminate"
    ></mat-progress-bar>

    <form [formGroup]="locationForm" class="tw-flex tw-flex-col">
        <mat-form-field appearance="fill">
            <mat-label>City</mat-label>
            <input matInput formControlName="city" />
            <mat-error *ngIf="locationForm.controls.city.errors?.required">
                City field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>State</mat-label>
            <mat-select formControlName="state">
                <mat-option
                    *ngFor="let state of states"
                    [value]="state.viewValue"
                >
                    {{ state.viewValue }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="locationForm.controls.state.errors?.required">
                State field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>5 Digit Zip Code</mat-label>
            <input matInput formControlName="zipCode" />
            <mat-error *ngIf="locationForm.controls.zipCode.errors?.required">
                Zip Code Field is required
            </mat-error>
            <mat-error *ngIf="locationForm.controls.zipCode.errors?.pattern">
                Zip Code must be 5 numbers
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Office Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="locationForm.controls.name.errors?.required">
                Office Name field is required
            </mat-error>
        </mat-form-field>
    </form>
    <button
        [disabled]="saving"
        mat-raised-button
        class="m3-error"
        (click)="onResetClick()"
    >
        Reset
    </button>
</div>

<app-dialog-footer
    [disabled]="saving"
    (buttonClick)="onSaveClick()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
