<div
    class="tw-flex-1 tw-flex-col tw-border-solid tw-rounded-lg tw-border-2 tw-border-customborder"
    *ngIf="timesheet"
>
    <mat-toolbar
        class="tw-items-center tw-bg-transparent tw-border-b tw-border-customborder tw-border-solid tw-border-0"
    >
        <div
            class="tw-justify-between tw-text-xl tw-flex-col tw-flex tw-w-3/4 tw-mr-2"
        >
            <div
                class="tw-overflow-hidden tw-whitespace-no-wrap tw-text-ellipsis"
                [matTooltip]="
                    timesheet.firstName +
                    ' ' +
                    timesheet.lastName +
                    ' - ' +
                    timesheet.clientName +
                    ' - ' +
                    timesheet.projectName +
                    ' - ' +
                    timesheet.taskName
                "
                matTooltipPosition="above"
            >
                {{
                    timesheet.firstName +
                        " " +
                        timesheet.lastName +
                        ": " +
                        timesheet.clientName +
                        " - " +
                        timesheet.projectName +
                        " - " +
                        timesheet.taskName
                }}
            </div>
            <div class="tw-text-lg">
                {{ startDate | date: "MM/dd/yyyy" }} -
                {{ endDate | date: "MM/dd/yyyy" }}
            </div>
        </div>

        <button
            mat-raised-button
            (click)="denyClick()"
            class="m3-error tw-mr-2 tw-w-24 tw-ml-auto"
            *ngIf="
                timesheet.approvalState !== 'Approved' &&
                timesheet.approvalState !== 'Denied'
            "
        >
            Deny
        </button>

        <button
            mat-raised-button
            (click)="approveClick()"
            class="tw-w-24"
            *ngIf="
                timesheet.approvalState !== 'Approved' &&
                timesheet.approvalState !== 'Denied'
            "
        >
            Approve
        </button>

        <mat-chip
            *ngIf="
                timesheet.approvalState === 'Approved' ||
                timesheet.approvalState === 'Denied'
            "
            [ngClass]="{
                'm3-success': timesheet.approvalState === 'Approved',
                'm3-error': timesheet.approvalState === 'Denied'
            }"
            highlighted
            class="tw-ml-auto"
        >
            <div class="tw-min-w-20 tw-text-center">
                {{ timesheet.approvalState }}
            </div>
        </mat-chip>
    </mat-toolbar>

    <div class="tw-flex tw-flex-row tw-ml-32">
        <div class="tw-flex-col tw-flex tw-mt-32 tw-w-full tw-text-lg">
            <div class="tw-flex tw-items-center">
                <div
                    class="tw-flex tw-flex-1 tw-border-b-0 tw-border-solid tw-mr-10 tw-border-customborder"
                >
                    <div class="tw-p-3">Monday:</div>
                    <div class="tw-ml-auto tw-p-3">{{ timesheet.monday }}</div>
                </div>

                <div class="tw-w-12 tw-h-12 tw-flex tw-mr-10">
                    <button
                        mat-icon-button
                        *ngIf="timesheet.mondayNotes"
                        (click)="viewNotes(timesheet.mondayNotes)"
                    >
                        <mat-icon>note_add</mat-icon>
                    </button>
                </div>
            </div>

            <div class="tw-flex tw-items-center">
                <div
                    class="tw-flex tw-flex-1 tw-border-b-0 tw-border-solid tw-mr-10 tw-border-customborder"
                >
                    <div class="tw-p-3">Tuesday:</div>
                    <div class="tw-ml-auto tw-p-3">{{ timesheet.tuesday }}</div>
                </div>
                <div class="tw-w-12 tw-h-12 tw-flex tw-mr-10">
                    <button
                        mat-icon-button
                        *ngIf="timesheet.tuesdayNotes"
                        (click)="viewNotes(timesheet.tuesdayNotes)"
                    >
                        <mat-icon>note_add</mat-icon>
                    </button>
                </div>
            </div>

            <div class="tw-flex tw-items-center">
                <div
                    class="tw-flex tw-flex-1 tw-border-b-0 tw-border-solid tw-mr-10 tw-border-customborder"
                >
                    <div class="tw-p-3">Wednesday:</div>
                    <div class="tw-ml-auto tw-p-3">
                        {{ timesheet.wednesday }}
                    </div>
                </div>
                <div class="tw-w-12 tw-h-12 tw-flex tw-mr-10">
                    <button
                        mat-icon-button
                        *ngIf="timesheet.wednesdayNotes"
                        (click)="viewNotes(timesheet.wednesdayNotes)"
                    >
                        <mat-icon>note_add</mat-icon>
                    </button>
                </div>
            </div>

            <div class="tw-flex tw-items-center">
                <div
                    class="tw-flex tw-flex-1 tw-border-b-0 tw-border-solid tw-mr-10 tw-border-customborder"
                >
                    <div class="tw-p-3">Thursday:</div>
                    <div class="tw-ml-auto tw-p-3">
                        {{ timesheet.thursday }}
                    </div>
                </div>
                <div class="tw-w-12 tw-h-12 tw-flex tw-mr-10">
                    <button
                        mat-icon-button
                        *ngIf="timesheet.thursdayNotes"
                        (click)="viewNotes(timesheet.thursdayNotes)"
                    >
                        <mat-icon>note_add</mat-icon>
                    </button>
                </div>
            </div>

            <div class="tw-flex tw-items-center">
                <div
                    class="tw-flex tw-flex-1 tw-border-b-0 tw-border-solid tw-mr-10 tw-border-customborder"
                >
                    <div class="tw-p-3">Friday:</div>
                    <div class="tw-ml-auto tw-p-3">{{ timesheet.friday }}</div>
                </div>
                <div class="tw-w-12 tw-h-12 tw-flex tw-mr-10">
                    <button
                        mat-icon-button
                        *ngIf="timesheet.fridayNotes"
                        (click)="viewNotes(timesheet.fridayNotes)"
                    >
                        <mat-icon>note_add</mat-icon>
                    </button>
                </div>
            </div>

            <div class="tw-flex tw-items-center">
                <div
                    class="tw-flex tw-flex-1 tw-border-b-0 tw-border-solid tw-mr-10 tw-border-customborder"
                >
                    <div class="tw-p-3">Saturday:</div>
                    <div class="tw-ml-auto tw-p-3">
                        {{ timesheet.saturday }}
                    </div>
                </div>
                <div class="tw-w-12 tw-h-12 tw-flex tw-mr-10">
                    <button
                        mat-icon-button
                        *ngIf="timesheet.saturdayNotes"
                        (click)="viewNotes(timesheet.saturdayNotes)"
                    >
                        <mat-icon>note_add</mat-icon>
                    </button>
                </div>
            </div>

            <div class="tw-flex tw-items-center tw-mb-10">
                <div
                    class="tw-flex tw-flex-1 tw-border-solid tw-mr-10 tw-border-customborder"
                >
                    <div class="tw-p-3">Sunday:</div>
                    <div class="tw-ml-auto tw-p-3">{{ timesheet.sunday }}</div>
                </div>
                <div class="tw-w-12 tw-h-12 tw-flex tw-mr-10">
                    <button
                        mat-icon-button
                        *ngIf="timesheet.sundayNotes"
                        (click)="viewNotes(timesheet.sundayNotes)"
                    >
                        <mat-icon>note_add</mat-icon>
                    </button>
                </div>
            </div>
            <div class="tw-mb-20 tw-ml-auto tw-mr-32">
                Total Hours:
                {{
                    timesheet.monday +
                        timesheet.tuesday +
                        timesheet.wednesday +
                        timesheet.thursday +
                        timesheet.friday +
                        timesheet.saturday +
                        timesheet.sunday
                }}
            </div>
        </div>
    </div>
</div>
