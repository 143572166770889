import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSelectionListChange, MatListModule } from '@angular/material/list';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { Subject, Subscription } from 'rxjs';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { EmployeeType } from 'src/app/models/employee-type.model';
import { Page } from 'src/app/models/page.model';
import { EmployeeTypeService } from 'src/app/services/employee-type.service';
import { AddEmployeeTypeComponent } from './add-employee-type/add-employee-type.component';
import { MatDialog } from '@angular/material/dialog';
import { EditEmployeeTypeComponent } from './edit-employee-type/edit-employee-type.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { VisibleDirective } from '../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {
    COUNT,
    NAME,
    OFFSET,
    STATUS,
} from 'src/app/constants/queryParamConstants';

const PAGE_SIZE = 10;

@Component({
    selector: 'app-employee-types',
    templateUrl: './employee-types.component.html',
    styleUrls: ['./employee-types.component.scss'],
    imports: [
        MatSlideToggleModule,
        NgIf,
        MatProgressBarModule,
        MatButtonModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        EditEmployeeTypeComponent,
        VisibleDirective,
        MatCardModule,
        MatIconModule,
    ],
})
export class EmployeeTypesComponent implements OnInit, OnDestroy {
    page: Page<EmployeeType> = new Page<EmployeeType>({
        countRequested: PAGE_SIZE,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    searchForm: FormGroup;

    pageIndex = 0;
    queryParams = new Map<string, string | number | boolean>();

    employeeTypeStatus = true;
    loadingEmployeeTypeEdit = false;
    employeeTypeSelected = false;
    loadingTypes = true;

    employeeTypeSubject: Subject<EmployeeType> = new Subject();
    latestEmployeeTypeSubscription: Subscription;
    valueChangesSubscription: Subscription;

    constructor(
        private employeeTypeService: EmployeeTypeService,
        public dialog: MatDialog,
    ) {
        this.searchForm = new FormGroup({
            search: new FormControl<string>(null),
        });
    }

    ngOnInit(): void {
        this.getLatestEmployeeTypes();
        this.valueChangesSubscription = this.searchForm.valueChanges.subscribe(
            () => this.changePageIndex(0),
        );
    }

    ngOnDestroy(): void {
        this.employeeTypeSubject.complete();
        this.unsubscribeIfSet(this.latestEmployeeTypeSubscription);
        this.unsubscribeIfSet(this.valueChangesSubscription);
    }

    unsubscribeIfSet(subscription: Subscription | null): void {
        if (subscription) {
            subscription.unsubscribe();
        }
    }

    getLatestEmployeeTypes(): void {
        this.loadingTypes = true;
        this.setQueryParams();

        this.latestEmployeeTypeSubscription = this.employeeTypeService
            .get([], this.queryParams)
            .subscribe((response: Page<EmployeeType>) => {
                this.page = response;
                this.loadingTypes = false;
            });
    }

    employeeTypeSelectionChange(event: MatSelectionListChange): void {
        this.employeeTypeSelected = true;

        const employeeType = event.options[0].value as EmployeeType;
        this.employeeTypeSubject.next(employeeType);
    }

    setQueryParams(): void {
        this.queryParams.set(COUNT, PAGE_SIZE);

        // Get the status param based on ACTIVE/INACTIVE
        if (this.employeeTypeStatus) {
            this.queryParams.set(STATUS, ACTIVE);
        } else {
            this.queryParams.set(STATUS, INACTIVE);
        }

        // Set the offset param based on the pageIndex and size
        if (this.pageIndex) {
            this.queryParams.set(OFFSET, this.pageIndex * PAGE_SIZE);
        } else {
            this.queryParams.set(OFFSET, 0);
        }

        // If value exists
        if (this.searchForm.controls.search.value !== null) {
            // If value is blank
            if (this.searchForm.controls.search.value === '') {
                // If it has the name param, delete it
                if (this.queryParams.has(NAME)) {
                    this.queryParams.delete(NAME);
                }
            } else {
                // Set the name param
                this.queryParams.set(
                    NAME,
                    this.searchForm.controls.search.value as string,
                );
            }
        }
    }

    changePageIndex(index: number) {
        this.pageIndex = index;
        this.getLatestEmployeeTypes();
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    openAddDialog(): void {
        const addDialog = this.dialog.open(AddEmployeeTypeComponent, {
            width: '25%',
        });

        addDialog.afterClosed().subscribe((response) => {
            //TODO: conditions on this response
            this.saveCompleted();
        });
    }

    saveCompleted(): void {
        this.employeeTypeSelected = false;
        this.getLatestEmployeeTypes();
    }

    toggleActiveInactive(): void {
        this.employeeTypeStatus = !this.employeeTypeStatus;
        this.changePageIndex(0);
    }
}
