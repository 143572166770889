import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { MeService } from 'src/app/services/me.service';
import { Task } from 'src/app/models/task.model';
import { ITimesheetFormControls } from 'src/app/interfaces/timesheets';
import { FormGroup } from '@angular/forms';
import { TimesheetDate } from 'src/app/models/timesheet-date-info.model';
import { NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

interface IClientGrouped {
    clientName: string;
    projects: IProjectGrouped[];
}

interface IProjectGrouped {
    projectName: string;
    tasks: Task[];
}
@Component({
    selector: 'app-nested-select',
    templateUrl: './nested-select.component.html',
    styleUrls: ['./nested-select.component.scss'],
    imports: [
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        MatIconModule,
        NgFor,
    ]
})
export class NestedSelectComponent implements AfterViewInit {
    @Input() timesheetForms: FormGroup<ITimesheetFormControls>[];
    @Input() lastSelectedDate: TimesheetDate;
    @Input() lockedTimesheet: Boolean;

    @Output() taskSelectedEvent: EventEmitter<Task> = new EventEmitter();
    @Output() getPastTasksEvent = new EventEmitter();
    selectedTask: Task;

    data: IClientGrouped[] = [];

    constructor(private meService: MeService) {}

    ngAfterViewInit(): void {
        this.getGroupedTasks();
    }

    getGroupedTasks(): void {
        this.meService
            .get(['tasks-grouped'])
            .subscribe((response: IClientGrouped[]) => {
                this.data = response;

                this.filterAddedTasks();
            });
    }

    filterAddedTasks(): void {
        this.data.forEach((client) => {
            client.projects.forEach((project) => {
                project.tasks = project.tasks.filter((task) => {
                    return (
                        this.timesheetForms.findIndex((timesheet) => {
                            return timesheet.value.taskId === task.id;
                        }) === -1
                    );
                });

                if (project.tasks.length === 0) {
                    client.projects = client.projects.filter((project) => {
                        return project.tasks.length > 0;
                    });
                }

                if (client.projects.length === 0) {
                    this.data = this.data.filter((client) => {
                        return client.projects.length > 0;
                    });
                }
            });
        });
    }
}
