import { PtoAccrualRate } from './pto-accrual-rate';
import { Task } from './task.model';

export interface IUserJson {
    userLoginId?: string;
    roleId?: string;
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    loginAttempts?: number;
    lastLoginDate?: string;
    status: string;
    id?: string;
    tasks?: Task[];
    managerId: string;
    startDate: string;
    ptoAccrualRateId?: string;
    ptoAccrualRate?: PtoAccrualRate;
    ptoBalance?: number;
    locationId: string;
    employeeTypeId?: string;
    profileImageURL: string;
    is1099: boolean;
    approver: boolean;
}

interface IUserConstructor {
    userLoginId?: string;
    roleId?: string;
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    loginAttempts?: number;
    lastLoginDate?: string;
    status: string;
    id?: string;
    tasks?: Task[];
    managerId: string;
    startDate: string;
    ptoAccrualRateId?: string;
    ptoAccrualRate?: PtoAccrualRate;
    ptoBalance?: number;
    locationId: string;
    employeeTypeId?: string;
    profileImageURL: string;
    is1099: boolean;
    approver: boolean;
}

export interface User extends IUserConstructor {
    userLoginId?: string;
    roleId?: string;
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    loginAttempts?: number;
    lastLoginDate?: string;
    status: string;
    id?: string;
    tasks?: Task[];
    managerId: string;
    startDate: string;
    ptoAccrualRateId?: string;
    ptoAccrualRate?: PtoAccrualRate;
    ptoBalance?: number;
    locationId: string;
    employeeTypeId?: string;
    profileImageURL: string;
    is1099: boolean;
    approver: boolean;
}

export class User {
    constructor(input: IUserConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: IUserConstructor): User | null {
        if (json == null) {
            return null;
        }

        return new User({
            ...json,
        });
    }
}
