import { DatePipe, NgFor } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogContent,
} from '@angular/material/dialog';
import { CalendarEvent } from 'angular-calendar';
import { DialogExitComponent } from '../../dialog-exit/dialog-exit.component';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';

interface DayDialogData {
    dayEvents: CalendarEvent[];
    date: Date;
}
@Component({
    selector: 'app-day-dialog',
    templateUrl: './day-dialog.component.html',
    styleUrls: ['./day-dialog.component.scss'],
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgFor,
        DialogExitComponent,
    ],
})
export class DayDialogComponent {
    datePipe = new DatePipe('en-us');
    title = '';

    constructor(
        private dialog: MatDialogRef<DayDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DayDialogData,
    ) {
        this.title = this.datePipe.transform(data.date, 'EEEE, MMM d, y');
    }

    onClickClose(): void {
        this.dialog.close();
    }
}
