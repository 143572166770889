import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { Page } from 'src/app/models/page.model';
import { WorkOrder } from 'src/app/models/work-order.model';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { ClientService } from 'src/app/services/client.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WorkOrderService } from 'src/app/services/work-order.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor, SlicePipe, DatePipe } from '@angular/common';
import { VisibleDirective } from '../../directives/visible.directive';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { SEARCH_DEBOUNCE_MS } from 'src/app/constants/searchConstants';
import {
    ASC,
    CLIENTID,
    COUNT,
    HASWORKORDER,
    ISSORTASCENDING,
    NAME,
    OFFSET,
    SORTPROPERTY,
} from 'src/app/constants/queryParamConstants';

@Component({
    selector: 'app-work-orders',
    templateUrl: './work-orders.component.html',
    styleUrls: ['./work-orders.component.scss'],
    imports: [
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatSlideToggleModule,
        MatButtonModule,
        MatTooltipModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        SlicePipe,
        DatePipe,
        VisibleDirective,
        MatSort,
        MatSortModule,
        MatCardModule,
    ],
})
export class WorkOrdersComponent implements OnInit {
    filterForm = new FormGroup({
        name: new FormControl<string>(null),
        clientId: new FormControl<string>(null),
    });

    showOptions = false;
    loadingReport = false;
    enableToggle = false;
    showFilterMenu: boolean = false;
    pageIndex: number = 0;
    clients: Client[];
    queryParams = new Map<string, string | number | boolean>();
    activeWorkOrders: WorkOrder[];
    sortAscending: string = null;
    sortProperty: string = null;
    isSortInitialized: boolean = false;

    displayedColumns = ['name', 'clientName', 'createDate', 'open', 'new'];

    page = new Page<WorkOrder>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    dataSource: MatTableDataSource<WorkOrder>;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private clientService: ClientService,
        private router: Router,
        private workOrderService: WorkOrderService,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.filterForm.controls.name.valueChanges
            .pipe(debounceTime(SEARCH_DEBOUNCE_MS), distinctUntilChanged())
            .subscribe(() => {
                this.filterChange();
            });

        this.filterForm.controls.clientId.valueChanges
            .pipe(debounceTime(SEARCH_DEBOUNCE_MS), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.populateFilters();
        this.filterChange();
        this.getClients();
    }

    onSortChange(): void {
        if (this.sort.active) {
            this.queryParams.set(SORTPROPERTY, this.sort.active);
            this.queryParams.set(ISSORTASCENDING, this.sort.direction === ASC);
        } else {
            this.queryParams.delete(SORTPROPERTY);
            this.queryParams.delete(ISSORTASCENDING);
        }

        this.getWorkOrders();
    }

    getWorkOrders(): void {
        this.loadingReport = true;

        this.workOrderService
            .get([], this.queryParams)
            .subscribe((response: Page<WorkOrder>) => {
                this.page = response;
                this.dataSource = new MatTableDataSource(this.page.items);
                this.dataSource.sort = this.sort;
                if (!this.isSortInitialized && this.sort) {
                    this.sort.sortChange.subscribe(() => this.onSortChange());
                    this.isSortInitialized = true;
                }
                this.loadingReport = false;
            });
    }

    filterChange(): void {
        this.queryParams.set(COUNT, 10);

        if (
            this.filterForm.controls.clientId.value &&
            this.filterForm.controls.clientId.value !== ''
        ) {
            this.queryParams.set(
                CLIENTID,
                this.filterForm.controls.clientId.value,
            );
            this.pageIndex = 0;
        } else {
            this.queryParams.delete(CLIENTID);
        }

        if (
            this.filterForm.controls.name.value &&
            this.filterForm.controls.name.value !== ''
        ) {
            this.queryParams.set(NAME, this.filterForm.controls.name.value);
            this.pageIndex = 0;
        } else {
            this.queryParams.delete(NAME);
        }

        if (this.pageIndex) {
            this.queryParams.set(OFFSET, this.pageIndex * 10);
        } else {
            this.queryParams.set(OFFSET, 0);
        }

        this.updateUrl();
        this.getWorkOrders();
    }

    changePageIndex(index: number) {
        this.pageIndex = index;
    }

    showFilters(): void {
        this.showFilterMenu = !this.showFilterMenu;
        this.clearFilters();
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
        this.filterChange();
    }

    clearFilters(): void {
        this.queryParams.delete(NAME);
        this.queryParams.delete(CLIENTID);
        this.queryParams.delete(SORTPROPERTY);
        this.queryParams.delete(ISSORTASCENDING);

        this.changePageIndex(0);
        this.filterForm.reset();
    }

    openWorkOrder(creating: boolean, workOrder?: WorkOrder) {
        if (workOrder) {
            void this.router.navigate(['openworkorder'], {
                queryParams: { workOrderId: workOrder.id, creating: creating },
            });
        } else {
            void this.router.navigate(['openworkorder'], {
                queryParams: { creating: creating },
            });
        }
    }

    private getClients(): void {
        const queryParams = new Map<string, number | boolean>();
        queryParams.set(COUNT, -1);
        queryParams.set(HASWORKORDER, true);

        this.clientService
            .get([], queryParams)
            .subscribe((response: Page<Client>) => {
                this.clients = response.items;
            });
    }

    private populateFilters(): void {
        const params: Params = this.activatedRoute.snapshot.queryParams;

        if (NAME in params) {
            this.queryParams.set(NAME, params[NAME] as string);
            this.filterForm.controls.name.setValue(params[NAME] as string);
        }

        if (CLIENTID in params) {
            this.filterForm.controls.clientId.setValue(
                params[CLIENTID] as string,
            );
        }
    }

    private updateUrl(): void {
        const queryParams: Params = {};

        const nameValue = this.filterForm.controls.name.value;
        if (nameValue) {
            queryParams[NAME] = nameValue;
        }

        const clientValue = this.filterForm.controls.clientId.value;
        if (clientValue) {
            queryParams[CLIENTID] = clientValue;
        }

        void this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
        });
    }
}
