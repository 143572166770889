<div class="tw-flex-row tw-mt-3 tw-m-auto tw-w-3/4">
    <mat-button-toggle-group [(ngModel)]="selectedReport">
        <ng-container *ngFor="let type of reportTypes | keyvalue">
            <mat-button-toggle [value]="type.value">
                {{ type.key }}
            </mat-button-toggle>
        </ng-container>
    </mat-button-toggle-group>
</div>

<div class="tw-m-auto tw-w-3/4 tw-mt-5">
    <ng-container *ngIf="selectedReport === reportTypes.Billing">
        <app-billing-report></app-billing-report>
    </ng-container>

    <ng-container *ngIf="selectedReport === reportTypes.Payroll">
        <app-payroll-report></app-payroll-report>
    </ng-container>

    <!--     <ng-container *ngIf="selectedReport === reportTypes.PaidTimeOff">
        <app-paid-time-off-report></app-paid-time-off-report> 
    </ng-container> -->

    <ng-container *ngIf="selectedReport === reportTypes.Utilization">
        <app-utilization-report></app-utilization-report>
    </ng-container>
</div>
