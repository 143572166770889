import { Component, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PASSWORDPATTERN } from 'src/app/constants/regexConstants';
import { IChangePasswordForm } from 'src/app/interfaces/change-password-form';
import { ProfileImage } from 'src/app/interfaces/profile-image';
import { IUserContactInfoForm } from 'src/app/interfaces/user-contact-info-form';
import { IUserEmergencyContactForm } from 'src/app/interfaces/user-emergency-contact-form';
import { IUserProfileForm } from 'src/app/interfaces/user-profile-form';
import { ChangePassword } from 'src/app/models/change-password.model';
import { UserProfileSettings } from 'src/app/models/user-profile-settings-models/user-profile-settings.model';
import { UserSettings } from 'src/app/models/user-profile-settings-models/user-settings.model';
import { ThemeService } from 'src/app/services/theme.service';
import { UserService } from 'src/app/services/user.service';
import { PasswordMatchValidator } from 'src/app/validators/passwords-match.validator';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { VisibleDirective } from 'src/app/directives/visible.directive';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
    imports: [
        MatDividerModule,
        MatTabsModule,
        PersonalInfoComponent,
        MyProfileComponent,
        ChangePasswordComponent,
        MatProgressBarModule,
        VisibleDirective,
    ]
})
export class UserSettingsComponent implements OnInit {
    changePasswordForm = new FormGroup<IChangePasswordForm>(
        {
            currentPassword: new FormControl<string>(''),
            password: new FormControl<string>('', [
                (control: AbstractControl) => Validators.required(control),
                () => Validators.pattern(PASSWORDPATTERN),
                () => Validators.minLength(8),
                () => Validators.maxLength(50),
            ]),
            confirmPassword: new FormControl<string>('', [
                (control: AbstractControl) => Validators.required(control),
                () => Validators.minLength(8),
                () => Validators.maxLength(50),
            ]),
        },
        PasswordMatchValidator,
    );

    userProfileForm = new FormGroup<IUserProfileForm>({
        theme: new FormControl<string>('0'),
    });

    userContactInfoForm = new FormGroup<IUserContactInfoForm>({
        address: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.maxLength(50),
        ]),
        city: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.maxLength(20),
        ]),
        state: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
        ]),
        zipCode: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.minLength(5),
            () => Validators.maxLength(5),
        ]),
        country: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.maxLength(56),
        ]),
        primaryPhoneNumber: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.minLength(12),
            () => Validators.maxLength(12),
        ]),
        secondaryPhoneNumber: new FormControl<string>('', [
            () => Validators.minLength(12),
            () => Validators.maxLength(12),
        ]),
        personalEmail: new FormControl<string>('', [
            (control: AbstractControl) => Validators.email(control),
            (control: AbstractControl) => Validators.required(control),
        ]),
    });

    userEmergencyContactForm = new FormGroup<IUserEmergencyContactForm>({
        firstName: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.maxLength(50),
        ]),
        lastName: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.maxLength(50),
        ]),
        address: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.maxLength(50),
        ]),
        city: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.maxLength(20),
        ]),
        state: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
        ]),
        zipCode: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.minLength(5),
            () => Validators.maxLength(5),
        ]),
        country: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.maxLength(56),
        ]),
        primaryPhoneNumber: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.minLength(13),
            () => Validators.maxLength(13),
        ]),
        secondaryPhoneNumber: new FormControl<string>('', [
            () => Validators.minLength(13),
            () => Validators.maxLength(13),
        ]),
        relationship: new FormControl<string>('', [
            (control: AbstractControl) => Validators.required(control),
            () => Validators.maxLength(20),
        ]),
    });

    currentProfileImage = '';
    loading: boolean = false;

    constructor(
        private userService: UserService,
        private snackBar: MatSnackBar,
        private themeService: ThemeService,
    ) {}

    ngOnInit(): void {
        this.getProfileSettings();
        this.getProfileImage();
    }

    getProfileImage(): void {
        this.loading = true;
        this.userService
            .get(['profile-image'])
            .subscribe((profileImage: ProfileImage) => {
                this.currentProfileImage = profileImage.base64Value;
                this.loading = false;
            });
    }

    getProfileSettings(): void {
        this.loading = true;
        this.userService
            .get(['get-user-profile-settings'])
            .subscribe((userProfileSettings: UserProfileSettings) => {
                if (!userProfileSettings.userSettings) {
                    userProfileSettings.userSettings = {
                        theme: this.themeService.getCurrentTheme(),
                    };
                } else {
                    this.userProfileForm.controls.theme.setValue(
                        userProfileSettings.userSettings.theme,
                    );
                }

                this.userContactInfoForm.patchValue(
                    userProfileSettings.userContactInfo,
                );
                this.userEmergencyContactForm.patchValue(
                    userProfileSettings.userEmergencyContact,
                );
                this.loading = false;
            });
    }

    saveContactInfo(): void {
        this.loading = true;
        this.userService
            .post(
                ['save-user-contact-info'],
                this.userContactInfoForm.getRawValue(),
            )
            .subscribe(() => (this.loading = false));
    }

    saveEmergencyContact(): void {
        this.loading = true;
        this.userService
            .post(
                ['save-user-emergency-contact'],
                this.userEmergencyContactForm.getRawValue(),
            )
            .subscribe(() => (this.loading = false));
    }

    saveProfileSettings(): void {
        this.loading = true;
        const profileSettings: UserSettings = new UserSettings();
        profileSettings.theme = this.userProfileForm.controls.theme.value;

        this.themeService.announceThemeChange(profileSettings.theme);

        this.userService
            .post(['save-user-settings'], profileSettings)
            .subscribe(() => (this.loading = false));
    }

    saveNewPassword(): void {
        this.loading = true;
        const currentPassword: string =
            this.changePasswordForm.controls.currentPassword.getRawValue();
        const newPassword: string =
            this.changePasswordForm.controls.password.getRawValue();

        const changePassword: ChangePassword = new ChangePassword({
            currentPassword,
            newPassword,
        });

        this.userService.post(['save-new-password'], changePassword).subscribe({
            next: () => {
                this.changePasswordForm.reset();
                this.snackBar.open('Password saved successfully.', 'Dismiss', {
                    verticalPosition: 'top',
                });
                this.loading = false;
            },
            error: () => {
                this.snackBar.open('Incorrect password entered.', 'Dismiss', {
                    verticalPosition: 'top',
                });
                this.loading = false;
            },
        });
    }
}
