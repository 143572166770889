import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { Page } from 'src/app/models/page.model';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/user.service';
import { ReportsService } from 'src/app/services/reports.service';
import { saveAs } from 'file-saver';
import { PTO } from 'src/app/models/report-models/pto.model';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportTypes } from 'src/app/enums/report-types';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { VisibleDirective } from '../../../directives/visible.directive';

@Component({
    selector: 'app-paid-time-off-report',
    templateUrl: './paid-time-off-report.component.html',
    styleUrls: ['./paid-time-off-report.component.scss'],
    imports: [
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatInputModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        DatePipe,
        VisibleDirective,
    ],
})
export class PaidTimeOffReportComponent implements OnInit, AfterViewInit {
    reports: PTO;
    reportTypes = ReportTypes;
    loadingReport = false;
    requestFailed = false;
    userProfileId: string;
    queryParams = new Map<string, string | boolean>();
    displayedColumns = ['name', 'startDate', 'hoursAccrued', 'hoursDeducted'];
    count: number;
    offset: number;
    users: User[];
    names: string[] = [];
    startDates: Date[] = [];
    showFilterMenu = false;
    enableToggle = false;
    sortAscending: string = null;
    sortProperty: string = null;

    filterForm = new FormGroup({
        name: new FormControl(null),
        startDate: new FormControl(null),
        hoursAccrued: new FormControl(null),
        hoursDeducted: new FormControl(null),
    });

    pageIndex = 0;
    page = new Page<PTO>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    @ViewChild('ptoSort') ptoSort = new MatSort();

    constructor(
        private reportService: ReportsService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        const map = this.route.snapshot.queryParamMap;
        const reportType = map.get('report');

        // Hide all PTO from UI until PTO is fixed //
        /*         if (reportType == this.reportTypes.PaidTimeOff) {
            if (map.has("name") || map.has("startDate") || map.has("hoursAccrued") || map.has("hoursDeducted")) {
                this.enableToggle = true;
                this.showFilterMenu = true;
                this.populateFilters();
            } else if (map.has("sortAscending") || map.has("sortProperty")) {
                this.populateFilters();
            }
        } */

        this.filterForm.controls.hoursAccrued.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.filterForm.controls.hoursDeducted.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.filterChange();
        this.getUsers();
    }

    ngAfterViewInit(): void {
        this.ptoSort.disableClear = true;
    }

    private setQueryParams(): void {
        if (this.userProfileId) {
            this.queryParams.set('userProfileId', this.userProfileId);
        }
        if (this.count || this.count == 0) {
            this.queryParams.set('count', this.count.toString());
        }
        if (this.pageIndex) {
            this.queryParams.set(
                'offset',
                (this.pageIndex * this.page.countRequested).toString(),
            );
        }
    }

    getPaidTimeOffReport(): void {
        this.loadingReport = true;
        this.setQueryParams();

        this.reportService.get(['pto'], this.queryParams).subscribe(
            (response: Page<PTO>) => {
                this.page = response;
                this.loadingReport = false;
                this.grabFilters();
            },
            () => {
                this.requestFailed = true;
                this.loadingReport = false;
            },
        );
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number): void {
        this.pageIndex = index;
        this.getPaidTimeOffReport();
    }

    private getUsers(): void {
        var queryParams = new Map<string, any>();
        queryParams.set('count', -1);
        queryParams.set('status', ACTIVE);

        this.userService
            .get([], queryParams)
            .subscribe((response: Page<User>) => {
                this.users = response.items;
            });
    }

    exportReport(): void {
        this.reportService
            .exportReport(['pto', 'export'], this.queryParams)
            .subscribe(
                (buffer: Blob) => {
                    const data: Blob = new Blob([buffer], {
                        type: 'text/csv',
                    });
                    saveAs(data, 'pto-report.csv');
                    this.loadingReport = false;
                },
                () => {
                    this.requestFailed = true;
                    this.loadingReport = false;
                },
            );
    }

    sortChange(sort: Sort): void {
        this.queryParams.set(
            'isSortAscending',
            sort.direction == 'asc' ? true : false,
        );
        this.queryParams.set('sortProperty', sort.active);

        this.sortAscending = sort.direction == 'asc' ? 'true' : 'false';
        this.sortProperty = sort.active;

        this.updateUrl();
        this.changePageIndex(0);
    }

    filterChange(): void {
        if (
            this.filterForm.controls.name.value &&
            this.filterForm.controls.name.value !== ''
        ) {
            this.queryParams.set('Name', this.filterForm.controls.name.value);
        } else {
            this.queryParams.delete('Name');
        }

        if (
            this.filterForm.controls.startDate.value &&
            this.filterForm.controls.startDate.value !== ''
        ) {
            this.queryParams.set(
                'StartDate',
                this.filterForm.controls.startDate.value,
            );
        } else {
            this.queryParams.delete('StartDate');
        }

        if (
            this.filterForm.controls.hoursAccrued.value &&
            this.filterForm.controls.hoursAccrued.value !== ''
        ) {
            this.queryParams.set(
                'HoursAccrued',
                this.filterForm.controls.hoursAccrued.value,
            );
        } else {
            this.queryParams.delete('HoursAccrued');
        }

        if (
            this.filterForm.controls.hoursDeducted.value &&
            this.filterForm.controls.hoursDeducted.value !== ''
        ) {
            this.queryParams.set(
                'HoursDeducted',
                this.filterForm.controls.hoursDeducted.value,
            );
        } else {
            this.queryParams.delete('HoursDeducted');
        }

        this.updateUrl();
        this.changePageIndex(0);
    }

    grabFilters(): void {
        if (this.names.length == 0) {
            const nameArray = this.page.items.map((item) => item.name);
            this.names = Array.from(new Set(nameArray));
        }

        if (this.startDates.length == 0) {
            const startDatesArray = this.page.items.map(
                (item) => item.startDate,
            );
            this.startDates = Array.from(new Set(startDatesArray));
        }
    }

    clearFilters(): void {
        this.queryParams.delete('Name');
        this.queryParams.delete('StartDate');
        this.queryParams.delete('HoursAccrued');
        this.queryParams.delete('HoursDeducted');

        this.names = [];
        this.startDates = [];

        this.filterForm = new FormGroup({
            name: new FormControl(null),
            startDate: new FormControl(null),
            hoursAccrued: new FormControl(null),
            hoursDeducted: new FormControl(null),
        });

        this.filterForm.controls.hoursAccrued.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.filterForm.controls.hoursDeducted.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.updateUrl();
        this.changePageIndex(0);
    }

    showFilters(): void {
        this.showFilterMenu = !this.showFilterMenu;
        this.clearFilters();
    }

    populateFilters(): void {
        const map = this.route.snapshot.queryParamMap;

        if (map.has('name')) {
            const name = map.get('name');
            this.filterForm.controls.name.setValue(name);
        }

        if (map.has('startDate')) {
            const dateString = map.get('startDate');
            this.filterForm.controls.startDate.setValue(dateString);
        }

        if (map.has('hoursAccrued')) {
            const hoursAccrued = map.get('hoursAccrued');
            this.filterForm.controls.hoursAccrued.setValue(hoursAccrued);
        }

        if (map.has('hoursDeducted')) {
            const hoursDeducted = map.get('hoursDeducted');
            this.filterForm.controls.hoursDeducted.setValue(hoursDeducted);
        }

        if (map.has('sortAscending')) {
            this.queryParams.set('isSortAscending', map.get('sortAscending'));
        }

        if (map.has('sortProperty')) {
            this.queryParams.set('sortProperty', map.get('sortProperty'));
        }
    }

    updateUrl(): void {
        void this.router.navigate([]);

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                // Hide all PTO from UI until PTO is fixed //
                /*             "report": this.reportTypes.PaidTimeOff, */
                count: this.count,
                offset: this.pageIndex * this.page.countRequested,
                name: this.filterForm.controls.name.value as string,
                startDate: this.filterForm.controls.startDate.value as string,
                hoursAccrued: this.filterForm.controls.hoursAccrued
                    .value as string,
                hoursDeducted: this.filterForm.controls.hoursDeducted
                    .value as string,
                sortAscending: this.sortAscending,
                sortProperty: this.sortProperty,
            },
        });
    }
}
