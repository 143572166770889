import { Component, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { AdminTimesheetService } from 'src/app/services/admin-timesheet.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { DialogFooterComponent } from '../dialog-footer/dialog-footer.component';
import {
    APPROVERIDS,
    BODY,
    SUBJECT,
} from 'src/app/constants/queryParamConstants';

interface IEmailData {
    emailIds: string[];
    subject: string;
    body: string;
}

@Component({
    selector: 'app-email-confirm-dialog',
    templateUrl: './email-confirm-dialog.component.html',
    styleUrl: './email-confirm-dialog.component.scss',
    imports: [MatButtonModule, DialogHeaderComponent, DialogFooterComponent],
})
export class EmailConfirmDialogComponent {
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<EmailConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: IEmailData,
        private adminTimesheetService: AdminTimesheetService,
        private snackBar: MatSnackBar,
    ) {}

    onClickYes(): void {
        if (this.data.emailIds.length > 0) {
            const queryParams: Map<string, unknown> = new Map<string, unknown>([
                [APPROVERIDS, this.data.emailIds],
                [SUBJECT, this.data.subject],
                [BODY, this.data.body],
            ]);

            this.adminTimesheetService.post([], null, queryParams).subscribe();
        }

        this.dialog.closeAll();
        this.snackBar.open('Email Sent', 'Close', {
            duration: 3000,
        });
    }

    onClickNo(): void {
        this.dialogRef.close();
    }
}
