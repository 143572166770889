<app-dialog-header [title]="'Add Category'"></app-dialog-header>

<div mat-dialog-content>
    <div
        class="tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer"
        [appVisible]="saveFailed"
    >
        Save Failed
    </div>

    <mat-progress-bar
        [appVisible]="saving"
        mode="indeterminate"
    ></mat-progress-bar>

    <form [formGroup]="containerForm" class="tw-flex tw-flex-col">
        Category Naming Rules

        <ul>
            <li>All letters must be lowercase.</li>
            <li>Names must be from 3 through 63 characters long.</li>
            <li>Names must start or end with a letter or number.</li>
            <li>
                Names can contain only letters, numbers, and the dash (-)
                character.
            </li>
            <li>
                Every dash (-) character must be immediately preceded and
                followed by a letter or number; consecutive dashes are not
                permitted in container names.
            </li>
        </ul>

        <mat-form-field appearance="fill">
            <mat-label>Category Name</mat-label>
            <input
                matInput
                formControlName="categoryName"
                minlength="3"
                maxlength="63"
            />
            <mat-error
                *ngIf="containerForm.controls.categoryName.errors?.required"
            >
                Category Name field is required
            </mat-error>
        </mat-form-field>
    </form>
    <button
        [disabled]="saving"
        mat-raised-button
        class="m3-error"
        (click)="onResetClick()"
    >
        Reset
    </button>
</div>

<app-dialog-footer
    [disabled]="saving"
    (buttonClick)="onSaveClick()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
