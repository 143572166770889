import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private router: Router,
        private authService: AuthenticationService,
    ) {}

    canActivate(): boolean {
        if (this.authService.checkLoginAuthentication()) {
            return true;
        } else {
            void this.router.navigate(['login'], {
                queryParams: {
                    targetUrl: window.location.href,
                },
            });
            return false;
        }
    }
}
