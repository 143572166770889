import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NavbarComponent } from './components/navbar/navbar.component';
import { Me } from './models/me.model';
import { AuthenticationService } from './services/authentication.service';
import { MeService } from './services/me.service';
import { ThemeService } from './services/theme.service';
import { ProfileImageService } from './services/profile-image-service';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import {
    MatSidenavContainer,
    MatSidenavModule,
} from '@angular/material/sidenav';
import { AppStateService } from './services/app-state-service';
import { RouteSearchComponent } from './components/route-search/route-search.component';
import { filter } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        MatSidenavModule,
        SidenavComponent,
        NavbarComponent,
        RouterOutlet,
        RouteSearchComponent,
    ],
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'AuthAppFrontEnd';
    @ViewChild('sidenav') sidenav: MatSidenavContainer;

    constructor(
        public authService: AuthenticationService,
        private appStateService: AppStateService,
        private meService: MeService,
        private themeService: ThemeService,
        private profileImageService: ProfileImageService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.themeService.$theme.subscribe(() => {
            this.onSetTheme(this.themeService.getCurrentTheme());
        });

        const loggedIn = this.authService.checkLoginAuthentication();
        //This is here to refresh the me in the state manager after a screen refresh.
        if (loggedIn) {
            const subscription = this.appStateService.me$.subscribe(
                (me: Me) => {
                    if (!me.id) {
                        this.meService.get([]).subscribe((meResponse: Me) => {
                            this.appStateService.me$.next(meResponse);
                            this.themeService.setTheme(meResponse.theme);
                            this.profileImageService.announceProfileImageChange();
                            subscription.unsubscribe();
                        });
                    }
                },
            );
        }
    }

    ngAfterViewInit() {
        this.router.events
            .pipe(
                filter<NavigationStart>(
                    (event) => event instanceof NavigationStart,
                ),
            )
            .subscribe(() => this.sidenav.close());
    }

    onSetTheme(theme: string): void {
        switch (theme) {
            case 'light-theme':
                window.document.body.classList.remove('dark-theme');
                window.document.body.classList.add(theme);
                break;
            case 'dark-theme':
                window.document.body.classList.remove('light-theme');
                window.document.body.classList.add(theme);
                break;
        }
    }
}
