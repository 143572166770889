<div class="tw-flex tw-flex-col tw-gap-2">
    <mat-progress-bar
        mode="indeterminate"
        [appVisible]="loadingReport"
    ></mat-progress-bar>

    <div
        *ngIf="requestFailed"
        class="tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer tw-p-4 tw-box-border tw-rounded-m3sm tw-text-center"
    >
        Request Failed
    </div>

    <mat-radio-group [(ngModel)]="rangeDates" (change)="dateTypeChange()">
        <mat-radio-button class="tw-mr-1" [value]="false">
            Week of
        </mat-radio-button>
        <mat-radio-button [value]="true">Date Range</mat-radio-button>
    </mat-radio-group>

    <div class="tw-flex tw-flex-row tw-flex-wrap tw-gap-2">
        <form [formGroup]="dateRangeForm">
            <mat-form-field *ngIf="rangeDates">
                <mat-label>Enter Session Period</mat-label>

                <mat-date-range-input [rangePicker]="picker">
                    <input
                        matStartDate
                        placeholder="Start Date"
                        required
                        formControlName="startDate"
                        (dateChange)="dateChange($event)"
                    />
                    <input
                        matEndDate
                        placeholder="End Date"
                        required
                        formControlName="endDate"
                        (dateChange)="dateChange($event)"
                    />
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>

                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error
                    *ngIf="
                        dateRangeForm.controls.startDate.hasError(
                            'matStartDateInvalid'
                        )
                    "
                >
                    Invalid start date
                </mat-error>
                <mat-error
                    *ngIf="
                        dateRangeForm.controls.endDate.hasError(
                            'matEndDateInvalid'
                        )
                    "
                >
                    Invalid end date
                </mat-error>

                <mat-error
                    *ngIf="dateRangeForm.controls.startDate.errors?.required"
                >
                    Start Date Required
                </mat-error>
                <mat-error
                    *ngIf="dateRangeForm.controls.endDate.errors?.required"
                >
                    End Date Required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="!rangeDates">
                <mat-label>Select Week</mat-label>

                <input
                    matInput
                    formControlName="date"
                    [matDatepicker]="picker"
                    (dateChange)="dateChange($event)"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </form>

        <form [formGroup]="filterForm" class="tw-flex tw-gap-2">
            <mat-form-field *ngIf="showFilterMenu === true" appearance="fill">
                <mat-label>Client</mat-label>

                <mat-select
                    formControlName="clientId"
                    (selectionChange)="filterChange()"
                >
                    <mat-option value="">View All</mat-option>
                    <mat-option
                        *ngFor="let client of clients"
                        [value]="client.id"
                    >
                        {{ client.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="showFilterMenu === true" appearance="fill">
                <mat-label>Project</mat-label>

                <mat-select
                    formControlName="projectName"
                    (selectionChange)="filterChange()"
                >
                    <mat-option value="">View All</mat-option>
                    <mat-option
                        *ngFor="let projectName of projectNames"
                        [value]="projectName"
                    >
                        {{ projectName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="showFilterMenu === true" appearance="fill">
                <mat-label>Task</mat-label>

                <mat-select
                    formControlName="taskName"
                    (selectionChange)="filterChange()"
                >
                    <mat-option value="">View All</mat-option>
                    <mat-option
                        *ngFor="let taskName of taskNames"
                        [value]="taskName"
                    >
                        {{ taskName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="showFilterMenu === true" appearance="fill">
                <mat-label>Name</mat-label>

                <input matInput formControlName="fullName" />
            </mat-form-field>

            <mat-form-field *ngIf="showFilterMenu === true" appearance="fill">
                <mat-label>Hours</mat-label>

                <input
                    type="number"
                    matInput
                    formControlName="hours"
                    onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
                />
            </mat-form-field>

            <mat-checkbox
                *ngIf="showFilterMenu === true"
                (change)="filterChange()"
                formControlName="hideBillable"
            >
                Hide Billable
            </mat-checkbox>
        </form>

        <div
            style="
                display: flex;
                flex-direction: row;
                gap: 0.5rem;
                margin-left: auto;
            "
        >
            <mat-slide-toggle
                [checked]="enableToggle"
                (change)="showFilters()"
                style="margin-top: 5px"
            >
                Filter
            </mat-slide-toggle>
            <button
                mat-raised-button
                matTooltip="Clear Filters"
                *ngIf="showFilterMenu === true"
                (click)="clearFilters()"
            >
                Clear Filters
            </button>
            <button
                mat-raised-button
                matTooltip="Export Report"
                (click)="exportReport()"
            >
                Export
            </button>
        </div>
    </div>

    <div
        class="mat-elevation-z8 tw-border-2 tw-border-solid tw-border-customborder tw-rounded-lg tw-overflow-hidden"
    >
        <table
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8 tw-w-full"
            matSort
        >
            <ng-container matColumnDef="clientName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Client Name
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ report.clientName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="projectName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Project
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ report.projectName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="taskName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Task Name
                </th>
                <td mat-cell *matCellDef="let report">{{ report.taskName }}</td>
            </ng-container>

            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    First Name
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ report.firstName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Last Name
                </th>
                <td mat-cell *matCellDef="let report">{{ report.lastName }}</td>
            </ng-container>

            <ng-container matColumnDef="hours">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Hours</th>
                <td mat-cell *matCellDef="let report">{{ report.hours }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
            [pageIndex]="pageIndex"
            (page)="changePage($event)"
            [pageSize]="page.countRequested"
            [length]="page.totalCount"
            [hidePageSize]="true"
        ></mat-paginator>
    </div>
</div>
