import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
    MatDialogContent,
} from '@angular/material/dialog';
import { EmailConfirmDialogComponent } from '../email-confirm-dialog/email-confirm-dialog.component';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { DialogFooterComponent } from '../dialog-footer/dialog-footer.component';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

interface IEmailFormControls {
    subject: FormControl<string>;
    body: FormControl<string>;
}

@Component({
    selector: 'app-email-edit-dialog',
    templateUrl: './email-edit-dialog.component.html',
    styleUrls: ['./email-edit-dialog.component.scss'],
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        DialogFooterComponent,
    ],
})
export class EmailEditDialogComponent implements OnInit {
    emailForm: FormGroup<IEmailFormControls>;
    saving = false;

    constructor(
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<EmailEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            header: string;
            subject: string;
            body: string;
            emailIds: string[];
        },
    ) {}

    ngOnInit(): void {
        this.initializeForm();
    }

    initializeForm(): void {
        this.emailForm = new FormGroup<IEmailFormControls>({
            subject: new FormControl(this.data.subject, [
                Validators.required.bind(this),
            ]),
            body: new FormControl(this.data.body, [
                Validators.required.bind(this),
            ]),
        });
    }

    onClickSend(): void {
        if (!this.emailForm.valid) {
            return;
        }
        this.dialog.open(EmailConfirmDialogComponent, {
            data: {
                emailIds: this.data.emailIds,
                subject: this.emailForm.get('subject').value,
                body: this.emailForm.get('body').value,
            },
        });
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }
}
