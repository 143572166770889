<div class="tw-flex tw-flex-col tw-gap-2">
    <mat-progress-bar
        mode="indeterminate"
        [appVisible]="loadingReport"
    ></mat-progress-bar>

    <div
        *ngIf="requestFailed"
        class="tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer tw-p-4 tw-box-border tw-rounded-m3sm tw-text-center"
    >
        Request Failed
    </div>

    <form
        class="tw-justify-between tw-items-baseline tw-flex tw-flex-row tw-gap-2"
        [formGroup]="filterForm"
    >
        <mat-form-field appearance="fill">
            <mat-label>Select Week</mat-label>

            <input
                matInput
                [formControl]="filterForm.controls.date"
                [matDatepicker]="picker"
                (dateChange)="dateChange($event)"
            />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
                matSuffix
                [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="showFilterMenu === true" appearance="fill">
            <mat-label>Employee</mat-label>

            <mat-select
                formControlName="employee"
                (selectionChange)="filterChange()"
            >
                <mat-option value="">View All</mat-option>
                <mat-option *ngFor="let user of users" [value]="user.id">
                    {{ user.firstName }} {{ user.lastName }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="showFilterMenu === true" appearance="fill">
            <mat-label>Billed Hours</mat-label>

            <input
                type="number"
                matInput
                formControlName="billingHours"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />
        </mat-form-field>

        <!-- Hide all PTO from UI until PTO is fixed -->
        <!--         <mat-form-field *ngIf="showFilterMenu == true" appearance="fill">
            <mat-label>PTO Hours</mat-label>

            <input type="number" matInput formControlName="ptoHours" onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'">
        </mat-form-field> -->

        <div class="tw-flex tw-flex-row tw-items-baseline tw-gap-2 tw-ml-auto">
            <mat-slide-toggle [checked]="enableToggle" (change)="showFilters()">
                Filter
            </mat-slide-toggle>

            <button
                mat-raised-button
                matTooltip="Clear Filters"
                *ngIf="showFilterMenu === true"
                (click)="clearFilters()"
            >
                Clear Filters
            </button>

            <button
                mat-raised-button
                matTooltip="Export Report"
                (click)="exportReport()"
            >
                Export
            </button>

            <button
                mat-raised-button
                matTooltip="Mark All Unpaid"
                (click)="lockUnlockAllTimesheets(false)"
            >
                Mark All Unpaid
            </button>

            <button
                mat-raised-button
                matTooltip="Mark All Paid"
                (click)="lockUnlockAllTimesheets(true)"
            >
                Mark All Paid
            </button>
        </div>
    </form>

    <div
        class="mat-elevation-z8 tw-border-2 tw-border-solid tw-border-customborder tw-rounded-lg tw-overflow-hidden"
    >
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            class="mat-elevation-z8 tw-w-full"
        >
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Employee Name
                </th>
                <td mat-cell *matCellDef="let report">{{ report.name }}</td>
            </ng-container>

            <ng-container matColumnDef="billingHours">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Billing
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ report.billingHours }}
                </td>
            </ng-container>

            <!-- Hide all PTO from UI until PTO is fixed -->
            <!--             <ng-container matColumnDef="ptoHours">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Pto</th>
                <td mat-cell *matCellDef="let report"> {{ report.ptoHours }} </td>
            </ng-container> -->

            <ng-container matColumnDef="week">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Week</th>
                <td mat-cell *matCellDef="let report">{{ report.week }}</td>
            </ng-container>

            <ng-container matColumnDef="year">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Year</th>
                <td mat-cell *matCellDef="let report">{{ report.year }}</td>
            </ng-container>

            <ng-container matColumnDef="paid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid</th>
                <td mat-cell *matCellDef="let report">
                    <mat-checkbox
                        [checked]="report.isPaid"
                        (change)="toggleLockedStatus(report)"
                    ></mat-checkbox>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
            [pageIndex]="pageIndex"
            (page)="changePage($event)"
            [pageSize]="page.countRequested"
            [length]="page.totalCount"
            [hidePageSize]="true"
        ></mat-paginator>
    </div>
</div>
