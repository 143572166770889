import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/user.service';
import { Task } from 'src/app/models/task.model';
import { MatDialog } from '@angular/material/dialog';
import { AddUserTaskComponent } from './add-user-task/add-user-task.component';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { Location } from 'src/app/models/location.model';
import { LocationService } from 'src/app/services/location.service';
import { Page } from 'src/app/models/page.model';

import { EmployeeType } from 'src/app/models/employee-type.model';
import { Subscription } from 'rxjs';
import {
    DEFAULT_EMPLOYEE_TYPE_SETTING_ID,
    EMPLOYEE_TYPE_OWNER_ID,
} from 'src/app/constants/settingContants';
import { EmployeeTypeService } from 'src/app/services/employee-type.service';
import { SettingsService } from 'src/app/services/setting.service';
import { Setting } from 'src/app/models/setting.model';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { UserPropertiesDialogComponent } from '../user-properties-dialog/user-properties-dialog.component';

interface UserPropertiesResult {
    success: boolean;
    active: boolean;
    approver: boolean;
    is1099: boolean;
    isRecruiter: boolean;
    isSalesRep: boolean;
}

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss'],
    imports: [
        ReactiveFormsModule,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTableModule,
        MatDivider,
        MatCardModule,
        MatIconModule,
    ],
})
export class EditUserComponent implements OnInit, OnDestroy {
    @ViewChild(AddUserTaskComponent)
    addUserTaskComponent!: AddUserTaskComponent;

    @Output() updateSuccess = new EventEmitter<boolean>();
    @Output() loadingUsers = new EventEmitter<boolean>();
    @Input() roles!: Role[];
    @Input() levels: number[];

    managers: User[];
    recruiters: User[];
    locations: Location[];
    queryParams = new Map<string, string>();
    user!: User;
    userForm!: FormGroup;
    failed = false;
    loading = false;
    tasks = new MatTableDataSource<Task>();
    displayedColumns: string[] = ['name', 'action'];
    showUserProperties: boolean = false;

    employeeTypes: EmployeeType[] = [];
    employeeTypeSubscription: Subscription;

    defaultEmployeeType!: EmployeeType;
    settingsSubscription: Subscription;

    get employeeTypeId() {
        return this.userForm.get('employeeTypeId').value;
    }

    constructor(
        private userService: UserService,
        private dialog: MatDialog,
        private locationService: LocationService,
        private employeeTypeService: EmployeeTypeService,
        private settingsService: SettingsService,
    ) {}

    ngOnInit(): void {
        this.initializeForm();
        this.getManagers();
        this.getLocations();

        this.employeeTypeSubscription = this.employeeTypeService
            .get([])
            .subscribe((x: Page<EmployeeType>) => {
                this.employeeTypes = x.items;
            });

        // If there was a default setting set, we need to set the Drop Down's value.
        this.settingsSubscription = this.settingsService
            .get([DEFAULT_EMPLOYEE_TYPE_SETTING_ID])
            .subscribe((setting: Setting) => {
                if (
                    setting.value.trim() !=
                    '00000000-0000-0000-0000-000000000000'
                ) {
                    this.userForm
                        .get('employeeTypeId')
                        ?.setValue(setting.value);
                }
            });
    }

    ngOnDestroy(): void {
        this.employeeTypeSubscription.unsubscribe();

        if (this.settingsSubscription) {
            this.settingsSubscription.unsubscribe();
        }
    }

    onFormSave(closeForm: boolean): void {
        this.loading = true;
        if (!this.userForm.valid) {
            this.loading = false;
            return;
        }

        this.loadingUsers.emit(this.loading);

        const userToSend = new User(this.userForm.value as User);
        userToSend.roleId = this.userForm.controls.roleId.value as string;
        userToSend.employeeTypeId = this.userForm.controls.employeeTypeId
            .value as string;
        userToSend.recruiterId = this.userForm.controls.recruiterId
            .value as string;

        if (this.userForm.controls.status.value === true) {
            userToSend.status = ACTIVE;
        } else {
            userToSend.status = INACTIVE;
        }

        this.userService.put([this.user.id], userToSend).subscribe({
            next: () => {
                this.loading = false;
                this.updateSuccess.emit(closeForm);
                this.loadingUsers.emit(this.loading);
            },
            error: () => {
                this.updateSuccess.emit(!closeForm);
                this.loadingUsers.emit(this.loading);
                this.failed = true;
                this.loading = false;
            },
        });
    }

    openPropertyDialog(): void {
        const dialogRef = this.dialog.open(UserPropertiesDialogComponent, {
            width: '500px',
            data: { user: this.user },
        });

        dialogRef.afterClosed().subscribe((result: UserPropertiesResult) => {
            if (result && result.success) {
                this.user.approver = result.approver;
                this.user.is1099 = result.is1099;
                this.user.isRecruiter = result.isRecruiter;
                this.user.isSalesRep = result.isSalesRep;
                this.userForm.patchValue(this.user);
                this.userForm.controls.status.setValue(result.active);
                this.onFormSave(false);
            }
        });
    }

    public initializeForm(): void {
        if (this.failed) {
            this.failed = false;
        }

        if (this.user) {
            this.tasks.data = this.user.tasks;
            let checked: boolean;

            if (this.user.status.toLowerCase() === ACTIVE.toLowerCase()) {
                checked = true;
            } else {
                checked = false;
            }

            this.userForm = new FormGroup({
                email: new FormControl(this.user.email, [
                    Validators.required.bind(this),
                    Validators.email.bind(this),
                ]),
                firstName: new FormControl(this.user.firstName, [
                    Validators.required.bind(this),
                ]),
                lastName: new FormControl(this.user.lastName, [
                    Validators.required.bind(this),
                ]),
                roleId: new FormControl(this.user.roleId, [
                    Validators.required.bind(this),
                ]),
                locationId: new FormControl(this.user.locationId, [
                    Validators.required.bind(this),
                ]),
                managerId: new FormControl(this.user.managerId),
                startDate: new FormControl(this.user.startDate, [
                    Validators.required.bind(this),
                ]),
                status: new FormControl(checked),
                tasks: new FormControl(this.user.tasks),
                employeeTypeId: new FormControl(this.user.employeeTypeId, [
                    Validators.required.bind(this),
                ]),
                is1099: new FormControl(this.user.is1099, {
                    validators: [Validators.required.bind(this)],
                }),
                isSalesRep: new FormControl(this.user.isSalesRep, {
                    validators: [Validators.required.bind(this)],
                }),
                approver: new FormControl(this.user.approver, {
                    validators: [Validators.required.bind(this)],
                }),
                isRecruiter: new FormControl(this.user.isRecruiter, {
                    validators: [Validators.required.bind(this)],
                }),
                recruiterId: new FormControl(this.user.recruiterId),
            });
        } else {
            this.userForm = new FormGroup({
                email: new FormControl(null, [
                    Validators.email.bind(this),
                    Validators.required.bind(this),
                ]),
                firstName: new FormControl(null, [
                    Validators.required.bind(this),
                ]),
                lastName: new FormControl(null, [
                    Validators.required.bind(this),
                ]),
                roleId: new FormControl(null, [Validators.required.bind(this)]),
                locationId: new FormControl(null, [
                    Validators.required.bind(this),
                ]),
                managerId: new FormControl(null),
                startDate: new FormControl(null, [
                    Validators.required.bind(this),
                ]),
                status: new FormControl(false),
                employeeTypeId: new FormControl(null, [
                    Validators.required.bind(this),
                ]),
                is1099: new FormControl(false, {
                    validators: [Validators.required.bind(this)],
                }),
                isSalesRep: new FormControl(false, {
                    validators: [Validators.required.bind(this)],
                }),
                isRecruiter: new FormControl(false, {
                    validators: [Validators.required.bind(this)],
                }),
                approver: new FormControl(false, {
                    validators: [Validators.required.bind(this)],
                }),
                recruiterId: new FormControl(null),
            });
        }

        const employeeTypeControl = this.userForm.get('employeeTypeId');
        if (
            employeeTypeControl &&
            employeeTypeControl.value == EMPLOYEE_TYPE_OWNER_ID
        ) {
            employeeTypeControl.disable();

            this.employeeTypes.push({
                id: EMPLOYEE_TYPE_OWNER_ID,
                name: 'Owner',
                isActive: true,
                showInUtilization: true,
            });
        } else {
            employeeTypeControl.enable();
            this.employeeTypes = this.employeeTypes.filter(
                (et) => et.id !== EMPLOYEE_TYPE_OWNER_ID,
            );
        }
    }

    public resetComponent(): void {
        this.userForm.patchValue(this.user);
        this.initializeForm();
    }

    public addTask(): void {
        const dialog = this.dialog.open(AddUserTaskComponent, {
            data: { user: this.user },
        });

        dialog.afterClosed().subscribe((response: boolean) => {
            if (response) {
                this.onFormSave(false);
                this.initializeForm();
            }
        });
    }

    public removeTask(task: Task): void {
        const dialog = this.dialog.open(ConfirmationDialogComponent, {
            data:
                'Are you sure you want to remove ' +
                task.name +
                ' from ' +
                this.user.firstName +
                '?',
        });

        dialog.afterClosed().subscribe((response: boolean) => {
            if (response === true) {
                const index = this.tasks.data.indexOf(task);
                this.tasks.data.splice(index, 1);
                this.onFormSave(false);
                this.initializeForm();
            }
        });
    }

    getManagers(): void {
        this.queryParams.set('status', ACTIVE);
        this.queryParams.set('count', '-1');
        this.userService
            .get([], this.queryParams)
            .subscribe((users: Page<User>) => {
                this.managers = users.items;
                this.recruiters = users.items.filter((u) => u.isRecruiter);
            });
    }

    getLocations(): void {
        this.locationService
            .get([])
            .subscribe((x: Page<Location>) => (this.locations = x.items));
    }
}
