import { Component, OnInit, ViewChild } from '@angular/core';
import {
    MatDialog,
    MatDialogContent,
    MatDialogRef,
} from '@angular/material/dialog';
import { TempTimeOff } from 'src/app/models/TimeOff-Temp.model';
import { CreateTimeOffDialogComponent } from '../create-time-off-dialog/create-time-off-dialog.component';
import { TimeOffService } from 'src/app/services/time-off.service';
import { NgIf, DatePipe } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogExitComponent } from '../../dialog-exit/dialog-exit.component';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { MatSort, MatSortModule } from '@angular/material/sort';

@Component({
    selector: 'app-add-time-off-dialog',
    templateUrl: './time-off-manager-dialog.component.html',
    styleUrls: ['./time-off-manager-dialog.component.scss'],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatTableModule,
        NgIf,
        DatePipe,
        DialogExitComponent,
        DialogHeaderComponent,
        MatDialogContent,
        MatSort,
        MatSortModule,
    ],
})
export class TimeOffManagerDialogComponent implements OnInit {
    myTimeOff: TempTimeOff[] = [];
    dataSource: MatTableDataSource<TempTimeOff>;
    @ViewChild(MatSort) sort: MatSort;

    displayedColumns = ['startDate', 'endDate', 'timeOffDescription', 'delete'];

    constructor(
        private dialogRef: MatDialogRef<TimeOffManagerDialogComponent>,
        public dialog: MatDialog,
        private timeOffService: TimeOffService,
    ) {}

    ngOnInit(): void {
        this.getCurrentTimeOff();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    getCurrentTimeOff(): void {
        this.timeOffService
            .get(['my-time-off'])
            .subscribe((res: TempTimeOff[]) => {
                this.myTimeOff = res;
                this.dataSource = new MatTableDataSource(this.myTimeOff);
                this.dataSource.sort = this.sort;
            });
    }

    addTimeOff(): void {
        const dialogRef = this.dialog.open(CreateTimeOffDialogComponent, {
            width: '300px',
            height: '400px',
        });

        dialogRef.beforeClosed().subscribe(() => {
            this.getCurrentTimeOff();
        });
    }

    deleteTimeOff(id: string): void {
        this.timeOffService.delete([id]).subscribe(() => {
            this.getCurrentTimeOff();
        });
    }
}
