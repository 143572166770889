<app-dialog-header [title]="'Timesheet Emails'"></app-dialog-header>

<mat-dialog-content>
    <div class="tw-flex tw-flex-col tw-gap-4">
        <div class="tw-flex tw-gap-2 tw-items-center">
            <h3>Notices</h3>
            <button
                mat-raised-button
                class="tw-ml-auto"
                matTooltip="Email First Notice"
                (click)="openFirstNotice()"
            >
                Send First Notice
            </button>
            <button
                mat-raised-button
                class="m3-error"
                matTooltip="Email Final Notice"
                (click)="openFinalNotice()"
            >
                Send Final Notice
            </button>
        </div>

        <mat-divider></mat-divider>

        <div class="tw-flex tw-flex-col tw-gap-2">
            <div class="tw-flex tw-flex-row tw-items-center tw-gap-4">
                <h3>Custom</h3>

                <button
                    mat-raised-button
                    class="tw-ml-auto"
                    matTooltip="Email Default Or Custom Email"
                    (click)="openCustomNotice()"
                >
                    Send Custom Email
                </button>
            </div>
            <mat-divider></mat-divider>
            <div class="tw-flex tw-flex-row tw-items-center tw-gap-4">
                <label>Recipients:</label>
                <mat-radio-group [(ngModel)]="selectedOption">
                    <mat-radio-button
                        (click)="resetEmployeeSelection()"
                        value="all"
                    >
                        All
                    </mat-radio-button>
                    <mat-radio-button
                        (click)="getUnsubmittedEmployees()"
                        value="unsubmitted"
                    >
                        Unsubmitted
                    </mat-radio-button>
                    <mat-radio-button
                        (click)="resetEmployeeSelection()"
                        value="custom"
                    >
                        Custom
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            @switch (selectedOption) {
                @case ("custom") {
                    <mat-form-field>
                        <input
                            matInput
                            type="text"
                            placeholder="Search"
                            [formControl]="names"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                            #auto="matAutocomplete"
                            [displayWith]="autoCompleteDisplay"
                            (optionSelected)="onEmployeeSelected($event)"
                        >
                            <mat-option
                                *ngFor="let item of filteredOptions | async"
                                [value]="item"
                            >
                                {{ item.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div>
                        <div class="tw-text-lg">Users Selected:</div>
                        <ul
                            class="tw-flex tw-flex-row tw-flex-wrap tw-justify-between"
                        >
                            <li
                                class="tw-list-none"
                                *ngFor="let employee of selectedEmployeesArr"
                            >
                                <div
                                    class="tw-flex tw-flex-row tw-items-center tw-mb-1"
                                >
                                    <div class="tw-text-lg tw-mr-2">
                                        {{ employee.name }}
                                    </div>

                                    <button
                                        mat-icon-button
                                        class="m3-error tw-self-center"
                                        (click)="
                                            onDeleteEmployeeSelected(employee)
                                        "
                                    >
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                }
                @case ("unsubmitted") {
                    <div>
                        <div class="tw-text-lg">Users Selected:</div>
                        <ul
                            class="tw-flex tw-flex-row tw-flex-wrap tw-justify-between"
                        >
                            <li
                                class="tw-list-none"
                                *ngFor="let employee of selectedEmployeesArr"
                            >
                                <div
                                    class="tw-flex tw-flex-row tw-items-center tw-mb-1"
                                >
                                    <div class="tw-text-lg tw-mr-2">
                                        {{ employee.name }}
                                    </div>

                                    <button
                                        mat-icon-button
                                        class="m3-error tw-self-center"
                                        (click)="
                                            onDeleteEmployeeSelected(employee)
                                        "
                                    >
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                }
            }
        </div>
    </div>
</mat-dialog-content>
<button mat-button class="tw-w-[25%] tw-flex tw-m-3 tw-self-end m3-error">
    Cancel
</button>
