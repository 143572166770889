import { Component } from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    MatDialogRef,
    MatDialogContent,
    MatDialog,
} from '@angular/material/dialog';
import { TempTimeOff } from 'src/app/models/TimeOff-Temp.model';
import { TimeOffService } from 'src/app/services/time-off.service';
import { DialogFooterComponent } from '../../dialog-footer/dialog-footer.component';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';

interface ITimeOffControls {
    start: FormControl<Date | null>;
    end: FormControl<Date | null>;
    description: FormControl<string>;
}

@Component({
    selector: 'app-create-time-off-dialog',
    templateUrl: './create-time-off-dialog.component.html',
    styleUrls: ['./create-time-off-dialog.component.scss'],
    imports: [
        DialogHeaderComponent,
        MatDividerModule,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        NgIf,
        MatInputModule,
        DialogFooterComponent,
    ],
})
export class CreateTimeOffDialogComponent {
    saving = false;

    timeOffForm: FormGroup<ITimeOffControls> = new FormGroup({
        start: new FormControl(null, [Validators.required.bind(this)]),
        end: new FormControl(null, [Validators.required.bind(this)]),
        description: new FormControl<string>(''),
    });

    constructor(
        private dialogRef: MatDialogRef<CreateTimeOffDialogComponent>,
        private timeOffService: TimeOffService,
        private dialog: MatDialog,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    saveTimeOff(): void {
        this.timeOffForm.markAllAsTouched();
        if (!this.timeOffForm.valid) {
            return;
        }

        const newTimeOff: TempTimeOff = {
            id: null,
            timeOffDescription: this.timeOffForm.value.description,
            startDate: this.timeOffForm.controls.start.value,
            endDate: this.timeOffForm.controls.end.value,
            userName: null,
        };

        if (this.containsWeekend(newTimeOff.startDate, newTimeOff.endDate)) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: 'Are you sure you would like to include weekend days in your time off?',
            });

            dialogRef.afterClosed().subscribe((response: boolean) => {
                if (response) {
                    this.submitTimeOff(newTimeOff);
                }
            });
        } else {
            this.submitTimeOff(newTimeOff);
        }
    }

    containsWeekend(startDate: Date, endDate: Date): boolean {
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const dayOfWeek = currentDate.getDay();
            if (dayOfWeek === 0 || dayOfWeek === 6) {
                return true;
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return false;
    }

    private submitTimeOff(timeOff: TempTimeOff): void {
        this.saving = true;
        this.timeOffService.post([], timeOff).subscribe(() => {
            this.dialogRef.close();
        });
    }
}
