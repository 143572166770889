import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { User } from 'src/app/models/User';
import { Page } from 'src/app/models/page.model';
import { UserService } from 'src/app/services/user.service';
import { TimesheetDashboardComponent } from '../timesheet-dashboard/timesheet-dashboard.component';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-contractor-timesheet-dashboard',
    templateUrl: './contractor-timesheet-dashboard.component.html',
    styleUrls: ['./contractor-timesheet-dashboard.component.scss'],
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgFor,
        MatOptionModule,
        NgIf,
        TimesheetDashboardComponent,
    ]
})
export class ContractorTimesheetDashboardComponent implements OnInit {
    contractors: User[];

    selectedUserControl = new FormControl<User | null>(null);

    queryParams: Map<string, string> = new Map([
        ['is1099', 'true'],
        ['status', ACTIVE],
        ['count', '1000'], //Setting count = 1000 to effectively remove pagination
    ]);

    constructor(private userService: UserService) {}

    ngOnInit(): void {
        this.getUsers();
    }

    private getUsers(): void {
        this.userService
            .get([], this.queryParams)
            .subscribe((page: Page<User>) => {
                this.contractors = page.items;
            });
    }
}
