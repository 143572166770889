import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProfileImageService {
    private profileImageChangeAnnounceSource = new Subject();

    profileImageChangeAnnounced$ =
        this.profileImageChangeAnnounceSource.asObservable();

    announceProfileImageChange(): void {
        this.profileImageChangeAnnounceSource.next(null);
    }
}
