<form
    class="tw-flex tw-flex-col tw-items-center tw-box-border tw-p-4 tw-gap-2 tw-rounded-m3lg tw-border-solid tw-border-2 tw-border-customborder"
    [formGroup]="clientForm"
>
    <div class="tw-text-2xl">Edit</div>

    <p class="warning" *ngIf="failed">Failed to edit clients.</p>

    <mat-form-field class="tw-w-4/5">
        <mat-label>Name</mat-label>
        <input [readonly]="!client" matInput formControlName="name" />
        <mat-error *ngIf="this.clientForm.controls.name.errors?.required">
            Name is required
        </mat-error>
    </mat-form-field>

    <div class="tw-flex tw-flex-col tw-w-4/5 tw-self-center tw-gap-2">
        <mat-checkbox [disabled]="!client" formControlName="signOffFlag">
            Client Sign Off
        </mat-checkbox>
        <mat-checkbox [disabled]="!client" formControlName="status">
            Active
        </mat-checkbox>
        <mat-checkbox formControlName="nonBillable">Non-Billable</mat-checkbox>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-between tw-w-4/5">
        <button
            [disabled]="!client || loading"
            mat-raised-button
            (click)="openProject()"
        >
            Open Projects
        </button>
        <button
            [disabled]="!client || loading"
            mat-raised-button
            class="m3-error"
            (click)="initializeForm()"
        >
            Reset
        </button>
        <button
            [disabled]="!client || loading"
            mat-raised-button
            (click)="onFormSave()"
        >
            Save Changes
        </button>
    </div>
</form>
