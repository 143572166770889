<mat-progress-bar
    [appVisible]="loadingUsers"
    mode="indeterminate"
></mat-progress-bar>

<div class="tw-flex tw-items-center tw-gap-4 tw-mt-6 tw-ml-[12.5%]">
    <div class="tw-text-2xl">Add/Edit Users</div>

    <mat-slide-toggle
        (change)="toggleActiveInactive($event)"
        [checked]="userStatus"
    >
        @if (userStatus) {
            <ng-container>Active</ng-container>
        } @else {
            <ng-container>Inactive</ng-container>
        }
    </mat-slide-toggle>
</div>

<div class="tw-flex tw-flex-row tw-justify-center tw-items-start tw-p-4">
    <div
        class="tw-flex tw-flex-col tw-p-4 tw-gap-2 tw-box-border tw-border-2 tw-border-solid tw-rounded-m3lg tw-border-customborder tw-w-1/4 tw-mr-2"
    >
        <div class="tw-flex tw-justify-between">
            <div class="tw-text-2xl">Users</div>

            <button
                [disabled]="!usersPermissions.get(Crud.Create)"
                [matTooltip]="
                    !usersPermissions.get(Crud.Create)
                        ? 'You do not have permission to create users.'
                        : ''
                "
                mat-raised-button
                (click)="openAddDialog()"
            >
                + User
            </button>
        </div>

        <div [formGroup]="searchForm">
            <mat-form-field appearance="fill" subscriptSizing="dynamic">
                <mat-label>Search Users...</mat-label>
                <input matInput type="text" formControlName="search" />
            </mat-form-field>
        </div>

        <div [formGroup]="searchForm">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>Role</mat-label>
                <mat-select
                    formControlName="roleSearch"
                    (selectionChange)="roleSelectionChange()"
                >
                    <mat-option value="">Select...</mat-option>
                    <mat-option *ngFor="let role of roleList" [value]="role.id">
                        {{ role.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-selection-list
            #userSelectionList
            hideSingleSelectionIndicator
            multiple="false"
        >
            @for (user of page.items; track user) {
                <mat-list-option [value]="user">
                    {{ user.firstName }} {{ user.lastName }}
                </mat-list-option>
                <mat-divider></mat-divider>
            }
        </mat-selection-list>

        <mat-paginator
            class="tw-rounded-b-lg"
            [pageIndex]="pageIndex"
            [pageSize]="page.countRequested"
            [length]="page.totalCount"
            [hidePageSize]="true"
            (page)="changePage($event)"
        ></mat-paginator>
    </div>

    <div class="tw-w-1/2 tw-ml-2">
        <app-edit-user
            [hidden]="!optionSelected"
            [permissions]="usersPermissions"
            [roles]="roleList"
            [levels]="levelsList"
            (updateSuccess)="onUpdateSuccessLoad($event)"
            (loadingUsers)="usersLoadingToggle($event)"
        ></app-edit-user>
    </div>
</div>
