<div class="tw-flex tw-flex-col tw-gap-2">
    <mat-progress-bar
        mode="indeterminate"
        [appVisible]="reportLoading"
    ></mat-progress-bar>

    <form class="tw-flex tw-flex-row" [formGroup]="filterForm">
        <mat-form-field appearance="fill">
            <mat-label>Select Week</mat-label>

            <input matInput formControlName="date" [matDatepicker]="picker" />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
                matSuffix
                [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <button
            mat-raised-button
            matTooltip="Export Report"
            [matMenuTriggerFor]="exportMenu"
            class="tw-ml-auto"
        >
            Export
        </button>

        <mat-menu #exportMenu="matMenu">
            <button mat-menu-item (click)="exportReport(ExportType.CSV)">
                Excel/CSV
            </button>
            <button mat-menu-item (click)="exportReport(ExportType.PDF)">
                PDF
            </button>
        </mat-menu>
    </form>

    <div
        class="tw-flex tw-flex-col tw-gap-4 tw-border-2 tw-border-solid tw-border-customborder tw-rounded-lg tw-overflow-hidden"
        *ngIf="report"
    >
        <div *ngFor="let employeeType of Object.keys(report.byEmployeeType)">
            <h2 class="employee-type-header tw-mx-4">{{ employeeType }}</h2>

            <div
                *ngFor="
                    let officeLocation of Object.keys(
                        report.byEmployeeType[employeeType].byOfficeLocation
                    )
                "
            >
                <h3 class="office-header tw-mx-4">{{ officeLocation }}</h3>

                <div class="mat-elevation-z8 tw-mb-4">
                    <table
                        mat-table
                        [dataSource]="getRows(officeLocation, employeeType)"
                        class="mat-elevation-z8 tw-w-full"
                    >
                        <ng-container matColumnDef="employee">
                            <th mat-header-cell *matHeaderCellDef>Employee</th>
                            <td
                                mat-cell
                                *matCellDef="let row"
                                [attr.colspan]="row.isTotal ? 4 : null"
                            >
                                {{ row.employee }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="client">
                            <th mat-header-cell *matHeaderCellDef>Client</th>
                            <td
                                mat-cell
                                *matCellDef="let row"
                                [hidden]="row.isTotal"
                            >
                                {{ row.client }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="project">
                            <th mat-header-cell *matHeaderCellDef>Project</th>
                            <td
                                mat-cell
                                *matCellDef="let row"
                                [hidden]="row.isTotal"
                            >
                                {{ row.project }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="task">
                            <th mat-header-cell *matHeaderCellDef>Task</th>
                            <td
                                mat-cell
                                *matCellDef="let row"
                                [hidden]="row.isTotal"
                            >
                                {{ row.task }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalNonBillable">
                            <th mat-header-cell *matHeaderCellDef>
                                Non-Billable Hours
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.totalNonBillable }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalBillable">
                            <th mat-header-cell *matHeaderCellDef>
                                Billable Hours
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.totalBillable }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalHoliday">
                            <th mat-header-cell *matHeaderCellDef>
                                Holiday Hours
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.totalHoliday }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalBereavement">
                            <th mat-header-cell *matHeaderCellDef>
                                Bereavement Hours
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.totalBereavement }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalPTO">
                            <th mat-header-cell *matHeaderCellDef>PTO Hours</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.totalPTO }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="utilizationPercent">
                            <th mat-header-cell *matHeaderCellDef>
                                Percent Utilized
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{
                                    getPercentUtilized(
                                        row.totalBillable,
                                        row.totalNonBillable,
                                        row.totalHoliday,
                                        row.totalBereavement,
                                        row.totalPTO,
                                        defaultTotalHoursAllotted
                                    )
                                }}%
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="rowColumns"></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: rowColumns"
                            [class.totalRow]="row.isTotal"
                        ></tr>
                    </table>
                </div>

                <div class="mat-elevation-z8 tw-mb-4">
                    <table
                        mat-table
                        [dataSource]="
                            getLocationTotalRow(officeLocation, employeeType)
                        "
                        class="mat-elevation-z8 tw-w-full"
                    >
                        <ng-container matColumnDef="location">
                            <td mat-cell *matCellDef="let row">
                                Total for {{ officeLocation }}
                                {{ employeeType }}:
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalNonBillable">
                            <td mat-cell *matCellDef="let row">
                                {{ row.nonBillable }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalBillable">
                            <td mat-cell *matCellDef="let row">
                                {{ row.billable }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalHoliday">
                            <td mat-cell *matCellDef="let row">
                                {{ row.holiday }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalBereavement">
                            <td mat-cell *matCellDef="let row">
                                {{ row.bereavement }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalPTO">
                            <td mat-cell *matCellDef="let row">
                                {{ row.pto }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="utilizationPercent">
                            <td mat-cell *matCellDef="let row">
                                {{ row.percentUtilized }}%
                            </td>
                        </ng-container>

                        <tr
                            mat-row
                            *matRowDef="
                                let row;
                                columns: locationTotalRowColumns
                            "
                        ></tr>
                    </table>
                </div>
            </div>

            <div class="mat-elevation-z8 tw-mb-4">
                <table
                    mat-table
                    [dataSource]="getEmployeeTypeTotal(employeeType)"
                    class="mat-elevation-z8 tw-w-full"
                >
                    <ng-container matColumnDef="location">
                        <td mat-cell *matCellDef="let row">
                            Total for {{ employeeType }}:
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalNonBillable">
                        <td mat-cell *matCellDef="let row">
                            {{ row.nonBillable }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalBillable">
                        <td mat-cell *matCellDef="let row">
                            {{ row.billable }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalHoliday">
                        <td mat-cell *matCellDef="let row">
                            {{ row.holiday }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalBereavement">
                        <td mat-cell *matCellDef="let row">
                            {{ row.bereavement }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalPTO">
                        <td mat-cell *matCellDef="let row">
                            {{ row.pto }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="utilizationPercent">
                        <td mat-cell *matCellDef="let row">
                            {{ row.percentUtilized }}%
                        </td>
                    </ng-container>

                    <tr
                        mat-row
                        *matRowDef="let row; columns: locationTotalRowColumns"
                    ></tr>
                </table>
            </div>
        </div>

        <div>
            <h2 class="employee-type-header tw-mx-4">Total</h2>

            <div class="mat-elevation-z8 tw-mb-0">
                <table
                    mat-table
                    [dataSource]="getTotalRow()"
                    class="mat-elevation-z8 tw-w-full"
                >
                    <ng-container matColumnDef="empty">
                        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                        <td mat-cell *matCellDef="let row">&nbsp;</td>
                    </ng-container>

                    <ng-container matColumnDef="totalNonBillable">
                        <th mat-header-cell *matHeaderCellDef>
                            Non-Billable Hours
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.nonBillable }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalBillable">
                        <th mat-header-cell *matHeaderCellDef>
                            Billable Hours
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.billable }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalHoliday">
                        <th mat-header-cell *matHeaderCellDef>Holiday Hours</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.holiday }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalBereavement">
                        <th mat-header-cell *matHeaderCellDef>
                            Bereavement Hours
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.bereavement }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalPTO">
                        <th mat-header-cell *matHeaderCellDef>PTO Hours</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.pto }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="utilizationPercent">
                        <th mat-header-cell *matHeaderCellDef>
                            Percent Utilized
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.percentUtilized }}%
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="totalRowColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: totalRowColumns"
                    ></tr>
                </table>
            </div>
        </div>
    </div>
</div>
