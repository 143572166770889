<app-dialog-header
    title="How would you like to export this report?"
></app-dialog-header>

<div mat-dialog-actions align="end" class="tw-gap-2">
    <button mat-button (click)="onClickClose($event)" [value]="null">
        Cancel
    </button>

    <button mat-button (click)="onClickClose($event)" [value]="true">
        PDF
    </button>
    <button mat-button (click)="onClickClose($event)" [value]="false">
        CSV
    </button>
</div>
