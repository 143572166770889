export interface IProjectJson {
    id?: string;
    name: string;
    primaryContact: string;
    startDate: string;
    endDate?: string;
    notes: string;
    status: string;
    clientId: string;
    nonBillable: boolean;
}

interface IProjectConstructor {
    id?: string;
    name: string;
    primaryContact: string;
    startDate: string;
    endDate?: string;
    notes: string;
    status: string;
    clientId: string;
    nonBillable: boolean;
}

export interface Project extends IProjectConstructor {
    id?: string;
    name: string;
    primaryContact: string;
    startDate: string;
    endDate?: string;
    notes: string;
    status: string;
    clientId: string;
    nonBillable: boolean;
}

export class Project {
    constructor(input: IProjectConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: IProjectJson): Project | null {
        if (json == null) {
            return null;
        }

        return new Project({
            ...json,
        });
    }
}
