import { Component, OnInit } from '@angular/core';
import { ReportTypes } from '../../enums/report-types';
import { ActivatedRoute } from '@angular/router';
import { UtilizationReportComponent } from './utilization-report/utilization-report.component';
import { PayrollReportComponent } from './payroll-report/payroll-report.component';
import { BillingReportComponent } from './billing-report/billing-report.component';
import { NgFor, NgIf, KeyValuePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
    imports: [
        MatButtonToggleModule,
        ReactiveFormsModule,
        FormsModule,
        NgFor,
        NgIf,
        BillingReportComponent,
        PayrollReportComponent,
        UtilizationReportComponent,
        KeyValuePipe,
    ]
})
export class ReportsComponent implements OnInit {
    reportTypes = ReportTypes;
    selectedReport: string = ReportTypes.Billing;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        const map = this.route.snapshot.queryParamMap;
        const report = map.get('report');

        if (report) {
            this.selectedReport = report;
        }
    }
}
