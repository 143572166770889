<div class="tw-w-[90%] tw-m-auto tw-mt-8">
    <div>
        <div
            class="tw-flex tw-gap-4 tw-p-4 tw-box-border tw-bg-m3SurfaceContainer tw-rounded-m3lg"
        >
            <button
                mat-raised-button
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                (click)="updateCalendarEvents(viewRender, true)"
            >
                Previous
            </button>
            <button mat-flat-button mwlCalendarToday [(viewDate)]="viewDate">
                Today
            </button>
            <button
                mat-raised-button
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                (click)="updateCalendarEvents(viewRender, true)"
            >
                Next
            </button>
            <button
                class="tw-ml-auto"
                (click)="openTimeOffManager()"
                mat-raised-button
            >
                View My Time Off
            </button>
        </div>

        <h2>{{ viewDate | calendarDate: view + "ViewTitle" : "en" }}</h2>
    </div>

    <div>
        <mwl-calendar-month-view
            [viewDate]="viewDate"
            [events]="calendarEvents"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            (beforeViewRender)="updateCalendarEvents($event)"
            (dayClicked)="dayClicked($event.day)"
            [cellTemplate]="customCellTemplate"
        ></mwl-calendar-month-view>
    </div>

    <ng-template #customCellTemplate let-day="day" let-locale="locale">
        <div class="cal-cell-top">
            <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">
                {{ day.badgeTotal }}
            </span>
            <span class="cal-day-number">
                {{ day.date | calendarDate: "monthViewDayNumber" : locale }}
            </span>
        </div>

        <div *ngIf="day.events.length > 0">
            <div *ngFor="let event of day.events; index as i">
                <div *ngIf="i < eventLimit">
                    {{ event.title }}
                </div>
                <div *ngIf="i === eventLimit">
                    {{ day.events.length - eventLimit }} More
                </div>
            </div>
        </div>
    </ng-template>
</div>
