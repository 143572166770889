<app-dialog-header [title]="'Add User'"></app-dialog-header>

<div mat-dialog-content>
    <div
        *ngIf="saveFailed"
        class="tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer tw-p-4 tw-box-border tw-rounded-m3sm tw-text-center"
    >
        Save Failed
    </div>

    <mat-progress-bar
        [appVisible]="saving"
        mode="indeterminate"
    ></mat-progress-bar>

    <form [formGroup]="userForm" class="tw-flex-col tw-flex tw-w-96">
        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>

            <input matInput formControlName="email" />

            <mat-error *ngIf="userForm.controls.email.errors?.email">
                Email field must follow pattern name&#64;subdomain.extension
            </mat-error>

            <mat-error *ngIf="userForm.controls.email.errors?.required">
                Email field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>First Name</mat-label>

            <input matInput formControlName="firstName" />

            <mat-error *ngIf="userForm.controls.firstName.errors?.required">
                First Name field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Last Name</mat-label>

            <input matInput formControlName="lastName" />

            <mat-error *ngIf="userForm.controls.lastName.errors?.required">
                Last Name field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Role</mat-label>

            <mat-select formControlName="roleId">
                <mat-option *ngFor="let role of roles" [value]="role.id">
                    {{ role.name }}
                </mat-option>
            </mat-select>

            <mat-error *ngIf="userForm.controls.roleId.errors?.required">
                Role field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Office Location</mat-label>

            <mat-select formControlName="locationId">
                <mat-option
                    *ngFor="let location of locations"
                    [value]="location.id"
                >
                    Located in {{ location.name }}
                </mat-option>
            </mat-select>

            <mat-error *ngIf="userForm.controls.locationId.errors?.required">
                Office Location field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Reports to</mat-label>

            <mat-select formControlName="managerId">
                <mat-option
                    *ngFor="let manager of managers"
                    [value]="manager.id"
                >
                    {{ manager.firstName }} {{ manager.lastName }}
                </mat-option>
            </mat-select>

            <mat-error
                *ngIf="this.userForm.controls.managerId.errors?.required"
            >
                Reports to field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>PTO Accrual</mat-label>

            <mat-select formControlName="ptoAccrualRateId">
                <mat-option
                    *ngFor="let ptoAccrualRate of ptoAccrualRates"
                    [value]="ptoAccrualRate.id"
                >
                    Level {{ ptoAccrualRate.ptoLevel }}
                </mat-option>
            </mat-select>

            <mat-error
                *ngIf="this.userForm.controls.ptoAccrualRateId.errors?.required"
            >
                PTO Accrual field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Start Date</mat-label>

            <input
                matInput
                [matDatepicker]="startDate"
                formControlName="startDate"
            />

            <mat-datepicker-toggle
                matSuffix
                [for]="startDate"
            ></mat-datepicker-toggle>

            <mat-datepicker #startDate></mat-datepicker>

            <mat-error
                *ngIf="this.userForm.controls.startDate.errors?.required"
            >
                Start Date field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Employee Type</mat-label>

            <mat-select formControlName="employeeTypeId">
                <mat-option
                    *ngFor="let employeeType of employeeTypes"
                    [value]="employeeType.id"
                >
                    {{ employeeType.name }}
                </mat-option>
            </mat-select>

            <mat-error
                *ngIf="userForm.controls.employeeTypeId.errors?.required"
            >
                Employee Type field is required
            </mat-error>
        </mat-form-field>

        <div>
            <mat-checkbox class="tw-mb-1" formControlName="is1099">
                User is a 1099 Contractor
            </mat-checkbox>
        </div>

        <div>
            <mat-checkbox class="tw-mb-1" formControlName="approver">
                User is an Approver
            </mat-checkbox>
        </div>
    </form>
    <button
        [disabled]="saving"
        class="m3-error tw-pl-3 tw-pr-3"
        mat-raised-button
        (click)="onResetClick()"
    >
        Reset
    </button>
</div>

<app-dialog-footer
    [disabled]="saving"
    (buttonClick)="onSaveClick()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
