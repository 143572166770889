<form
    class="tw-flex tw-flex-col tw-items-center tw-border-solid tw-rounded-m3lg tw-border-2 tw-border-customborder tw-gap-2 tw-p-4"
    [formGroup]="employeeTypeForm"
>
    <div class="tw-text-2xl">{{ id ? "Edit" : "Add" }}</div>

    <p class="tw-bg-red-600" *ngIf="failed">
        Failed to {{ id ? "edit" : "add" }} Employee Type.
    </p>

    <mat-form-field appearance="fill" class="tw-w-4/5">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
    </mat-form-field>

    <mat-checkbox class="tw-w-4/5" formControlName="showInUtilization">
        Show in Utilization Report
    </mat-checkbox>
    <mat-checkbox class="tw-w-4/5" formControlName="isActive">
        Active
    </mat-checkbox>

    <div class="tw-flex tw-flex-row tw-w-1/2 tw-gap-2 tw-justify-between">
        <button mat-raised-button class="m3-error" (click)="onClickReset()">
            Reset
        </button>
        <button mat-raised-button (click)="saveEmployeeType()">
            Save Changes
        </button>
    </div>
</form>
