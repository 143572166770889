<div class="tw-flex-row tw-flex">
    <mat-selection-list
        class="tw-pt-0"
        hideSingleSelectionIndicator
        [multiple]="false"
    >
        <mat-list-option
            matTooltip="Search"
            matTooltipPosition="right"
            (click)="toggleSearch()"
        >
            <mat-icon class="tw-pt-2">search</mat-icon>
        </mat-list-option>

        <ng-container *ngFor="let route of routes">
            <div *ngIf="checkPermissions(route) && route.icon != undefined">
                <mat-divider></mat-divider>

                <mat-list-option
                    matTooltip="{{ route.name }}"
                    matTooltipPosition="right"
                    (click)="onRouteChangeClick(route.route)"
                >
                    <mat-icon class="tw-pt-2">
                        {{ route.icon }}
                    </mat-icon>
                </mat-list-option>
            </div>
        </ng-container>
    </mat-selection-list>
</div>
