<div
    class="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full"
    [ngClass]="{ 'tw-bg-white': theme === 'light-theme' }"
>
    <form
        *ngIf="!completed"
        [formGroup]="forgotPasswordForm"
        class="tw-flex tw-flex-col tw-justify-center tw-w-1/4"
    >
        <!-- TODO add regex -->
        <mat-progress-bar
            [appVisible]="loading"
            class="m3-tertiary"
            mode="indeterminate"
        ></mat-progress-bar>

        <mat-form-field class="tw-w-full tw-self-center" appearance="fill">
            <mat-label>Email</mat-label>
            <input name="email" matInput formControlName="email" />
            <mat-error *ngIf="this.forgotPasswordForm.get('email').invalid">
                {{ getErrorMessage() }}
            </mat-error>
        </mat-form-field>

        <div class="tw-flex tw-justify-evenly tw-w-full">
            <button
                class="m3-tertiary tw-w-2/5"
                mat-raised-button
                type="submit"
                (click)="onForgotPasswordClick()"
            >
                Reset
            </button>
            <button
                class="tw-w-2/5"
                mat-raised-button
                routerLink="/login"
                routerLinkActive="active"
            >
                Login Page
            </button>
        </div>
    </form>
</div>
