<app-dialog-header title="Time Off Manager"></app-dialog-header>

<div matDialogContent>
    <div class="tw-flex tw-flex-row-reverse">
        <button class="tw-h-10" mat-raised-button (click)="addTimeOff()">
            Add Time Off
        </button>
    </div>

    <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Start Date
            </th>
            <td mat-cell *matCellDef="let timeOff">
                {{ timeOff.startDate | date }}
            </td>
        </ng-container>

        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
            <td mat-cell *matCellDef="let timeOff">
                {{ timeOff.endDate | date }}
            </td>
        </ng-container>

        <ng-container matColumnDef="timeOffDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Description
            </th>
            <td mat-cell *matCellDef="let timeOff">
                {{ timeOff.timeOffDescription }}
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let timeOff">
                <button
                    mat-icon-button
                    class="m3-error"
                    aria-label="Delete"
                    (click)="deleteTimeOff(timeOff.id)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div *ngIf="myTimeOff.length === 0" class="tw-text-center tw-mt-2">
        No Time Off Scheduled.
    </div>
</div>

<app-dialog-exit
    class="tw-mt-auto"
    (closeClick)="closeDialog()"
></app-dialog-exit>
