<div id="container">
    <form
        [formGroup]="userContactInfoForm"
        (ngSubmit)="userContactInfoSave()"
        id="contact-info"
    >
        <mat-label class="form-label">Contact Info</mat-label>
        <div class="form">
            <mat-form-field appearance="outline" class="full-row">
                <mat-label>Address</mat-label>
                <input
                    matInput
                    formControlName="address"
                    maxlength="50"
                    required
                />
                <mat-error
                    *ngIf="
                        userContactInfoForm.controls.address.hasError(
                            'maxLength'
                        )
                    "
                >
                    Must be shorter than 50 characters
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="city">
                <mat-label>City</mat-label>
                <input
                    matInput
                    formControlName="city"
                    maxlength="20"
                    required
                />
            </mat-form-field>

            <mat-form-field appearance="outline" class="state">
                <mat-label>State</mat-label>
                <input matInput formControlName="state" required />
            </mat-form-field>

            <mat-form-field appearance="outline" class="zipcode">
                <mat-label>Zip Code</mat-label>
                <input
                    matInput
                    formControlName="zipCode"
                    maxlength="5"
                    minlength="5"
                    required
                />
                <mat-error
                    *ngIf="
                        userContactInfoForm.controls.zipCode.hasError(
                            'minLength'
                        )
                    "
                >
                    Must be at least 5 characters
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="country">
                <mat-label>Country</mat-label>
                <input
                    matInput
                    formControlName="country"
                    maxlength="56"
                    required
                />
            </mat-form-field>

            <mat-form-field appearance="outline" class="phone1">
                <mat-label>Primary Phone Number</mat-label>
                <input
                    matInput
                    formControlName="primaryPhoneNumber"
                    placeholder="000-000-0000"
                    maxlength="12"
                    required
                />
                <mat-error
                    *ngIf="
                        userContactInfoForm.controls.primaryPhoneNumber.invalid
                    "
                >
                    Must be in 000-000-0000 format
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="phone2">
                <mat-label>Secondary Phone Number</mat-label>
                <input
                    matInput
                    formControlName="secondaryPhoneNumber"
                    maxlength="12"
                    placeholder="000-000-0000"
                />
                <mat-error
                    *ngIf="
                        userContactInfoForm.controls.secondaryPhoneNumber
                            .invalid
                    "
                >
                    Must be in 000-000-0000 format
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-row">
                <mat-label>Personal Email Address</mat-label>
                <input matInput formControlName="personalEmail" />
                <mat-error
                    *ngIf="userContactInfoForm.controls.personalEmail.invalid"
                >
                    Improper email format
                </mat-error>
            </mat-form-field>

            <button
                [disabled]="userContactInfoForm.invalid"
                mat-raised-button
                class="btn-submit"
                type="submit"
            >
                Confirm Changes
            </button>
        </div>
    </form>

    <mat-divider></mat-divider>

    <form
        [formGroup]="userEmergencyContactForm"
        (ngSubmit)="emergencyContactSaved()"
        id="emergency-contact"
    >
        <mat-label class="form-label">Emergency Contact</mat-label>
        <div class="form">
            <mat-form-field appearance="outline" class="full-row">
                <mat-label>First Name</mat-label>
                <input
                    matInput
                    formControlName="firstName"
                    maxlength="50"
                    required
                />
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-row">
                <mat-label>Last Name</mat-label>
                <input
                    matInput
                    formControlName="lastName"
                    maxlength="50"
                    required
                />
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-row">
                <mat-label>Address</mat-label>
                <input
                    matInput
                    formControlName="address"
                    maxlength="50"
                    required
                />
            </mat-form-field>

            <mat-form-field appearance="outline" class="city">
                <mat-label>City</mat-label>
                <input
                    matInput
                    formControlName="city"
                    maxlength="20"
                    required
                />
            </mat-form-field>

            <mat-form-field appearance="outline" class="state">
                <mat-label>State</mat-label>
                <input matInput formControlName="state" required />
            </mat-form-field>

            <mat-form-field appearance="outline" class="zipcode">
                <mat-label>Zip Code</mat-label>
                <input
                    matInput
                    formControlName="zipCode"
                    maxlength="5"
                    minlength="5"
                    required
                />
                <mat-error
                    *ngIf="
                        userEmergencyContactForm.controls.zipCode.hasError(
                            'minLength'
                        )
                    "
                >
                    Must be at least 5 characters
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="country">
                <mat-label>Country</mat-label>
                <input
                    matInput
                    formControlName="country"
                    maxlength="50"
                    required
                />
            </mat-form-field>

            <mat-form-field appearance="outline" class="phone1">
                <mat-label>Primary Phone Number</mat-label>
                <input
                    matInput
                    formControlName="primaryPhoneNumber"
                    placeholder="000-000-0000"
                    maxlength="12"
                    required
                />
                <mat-error
                    *ngIf="
                        userContactInfoForm.controls.primaryPhoneNumber.invalid
                    "
                >
                    Must be in 000-000-0000 format
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="phone2">
                <mat-label>Secondary Phone Number</mat-label>
                <input
                    matInput
                    formControlName="secondaryPhoneNumber"
                    maxlength="12"
                    placeholder="000-000-0000"
                />
                <mat-error
                    *ngIf="
                        userEmergencyContactForm.controls.secondaryPhoneNumber
                            .invalid
                    "
                >
                    Must be in 000-000-0000 format
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-row">
                <mat-label>Relationship</mat-label>
                <input
                    matInput
                    formControlName="relationship"
                    maxlength="20"
                    required
                />
            </mat-form-field>

            <button
                class="btn-submit"
                [disabled]="userEmergencyContactForm.invalid"
                mat-raised-button
                type="submit"
            >
                Confirm Changes
            </button>
        </div>
    </form>
</div>
