import { Component, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogContent,
    MatDialogRef,
} from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { DialogFooterComponent } from '../../dialog-footer/dialog-footer.component';
import { DialogExitComponent } from '../../dialog-exit/dialog-exit.component';
import { MatFormFieldModule } from '@angular/material/form-field';

import { User } from 'src/app/models/User';
import { ACTIVE } from 'src/app/constants/statusConstant';

interface UserDialogData {
    user: User;
}

@Component({
    selector: 'app-user-properties-dialog',
    templateUrl: './user-properties-dialog.component.html',
    standalone: true,
    imports: [
        MatDialogContent,
        MatCheckbox,
        DialogHeaderComponent,
        DialogFooterComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
    ],
})
export class UserPropertiesDialogComponent {
    loading = false;
    propertyForm = new FormGroup({
        active: new FormControl<boolean>(true),
        approver: new FormControl<boolean>(false),
        is1099: new FormControl<boolean>(false),
        isRecruiter: new FormControl<boolean>(false),
        isSalesRep: new FormControl<boolean>(false),
    });

    constructor(
        private dialogRef: MatDialogRef<UserPropertiesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UserDialogData,
    ) {
        if (data.user) {
            this.propertyForm.controls.active.setValue(
                data.user.status.toUpperCase() == ACTIVE ? true : false,
            );
            this.propertyForm.controls.approver.setValue(data.user.approver);
            this.propertyForm.controls.is1099.setValue(data.user.is1099);
            this.propertyForm.controls.isRecruiter.setValue(
                data.user.isRecruiter,
            );
            this.propertyForm.controls.isSalesRep.setValue(
                data.user.isSalesRep,
            );
        }
    }

    onConfirmClick(): void {
        this.dialogRef.close({
            success: true,
            active: this.propertyForm.controls.active.value,
            approver: this.propertyForm.controls.approver.value,
            is1099: this.propertyForm.controls.is1099.value,
            isRecruiter: this.propertyForm.controls.isRecruiter.value,
            isSalesRep: this.propertyForm.controls.isSalesRep.value,
        });
    }

    onCloseClick(): void {
        this.dialogRef.close({
            success: false,
        });
    }
}
