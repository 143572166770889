<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingTimesheets"
></mat-progress-bar>
<form
    [formGroup]="searchForm"
    class="tw-flex tw-flex-row tw-justify-center tw-gap-2 tw-items-start"
>
    <div
        class="tw-flex tw-flex-col tw-p-4 tw-gap-2 tw-border-2 tw-border-solid tw-rounded-lg tw-w-1/4 tw-mt-12 tw-border-customborder"
    >
        <div class="tw-text-2xl">Submissions</div>

        <div
            [formGroup]="searchForm"
            class="tw-flex tw-flex-col tw-gap-2 tw-w-1/2"
        >
            <mat-form-field appearance="fill" subscriptSizing="dynamic">
                <mat-label>User</mat-label>
                <mat-select
                    formControlName="userSearch"
                    (selectionChange)="setQueryParams()"
                >
                    <mat-option value="">View All</mat-option>
                    <mat-option
                        *ngFor="let user of users"
                        [value]="user.userLoginId"
                    >
                        {{ user.firstName + " " + user.lastName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" subscriptSizing="dynamic">
                <mat-label>Status</mat-label>
                <mat-select
                    formControlName="statusSearch"
                    (selectionChange)="setQueryParams()"
                >
                    <mat-option value="">View All</mat-option>
                    <mat-option
                        *ngFor="let status of approverStatuses"
                        [value]="status"
                    >
                        {{ status }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-selection-list
            #timesheetSelectionList
            hideSingleSelectionIndicator
            multiple="false"
            (selectionChange)="timesheetSelectionChange($event)"
        >
            @for (timesheet of page.items; track timesheet) {
                <mat-list-option
                    [value]="timesheet"
                    [matTooltip]="
                        timesheet.firstName +
                        ' ' +
                        timesheet.lastName +
                        ' - ' +
                        timesheet.clientName +
                        ' - ' +
                        timesheet.projectName +
                        ' - ' +
                        timesheet.taskName
                    "
                    matTooltipPosition="left"
                >
                    <div
                        class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-4"
                    >
                        <div
                            class="tw-overflow-hidden tw-whitespace-no-wrap tw-text-ellipsis"
                        >
                            {{
                                timesheet.firstName +
                                    " " +
                                    timesheet.lastName +
                                    " - " +
                                    timesheet.taskName
                            }}
                        </div>
                        <mat-chip
                            [ngClass]="{
                                'm3-success':
                                    timesheet.approvalState === 'Approved',
                                'm3-caution':
                                    timesheet.approvalState === 'Updated',
                                'm3-error': timesheet.approvalState === 'Denied'
                            }"
                            highlighted
                        >
                            <div class="tw-min-w-16 tw-text-center">
                                {{ timesheet.approvalState || "New" }}
                            </div>
                        </mat-chip>
                    </div>
                </mat-list-option>
                @if (!$last) {
                    <mat-divider></mat-divider>
                }
            }
        </mat-selection-list>

        <mat-paginator
            class="tw-rounded-b-lg"
            [pageIndex]="pageIndex"
            [pageSize]="page.countRequested"
            [length]="page.totalCount"
            [hidePageSize]="true"
            (page)="changePage($event)"
        ></mat-paginator>
    </div>

    <div class="tw-w-1/2 tw-mt-12">
        <app-edit-submissions
            *ngIf="selectedTimesheet !== null"
            [timesheet]="selectedTimesheet"
        ></app-edit-submissions>
    </div>
</form>
