<app-dialog-header [title]="'Additional User Properties'"></app-dialog-header>

<div mat-dialog-content>
    <ng-container [formGroup]="propertyForm">
        <div class="tw-border tw-p-4 tw-mt-2 tw-rounded-lg">
            <div class="tw-grid tw-grid-cols-3 tw-gap-4">
                <mat-checkbox [disabled]="!data.user" formControlName="active">
                    Active
                </mat-checkbox>

                <mat-checkbox formControlName="approver">Approver</mat-checkbox>

                <mat-checkbox formControlName="is1099">
                    1099 Contractor
                </mat-checkbox>

                <div class="tw-col-span-3 tw-flex tw-justify-center tw-gap-4">
                    <mat-checkbox formControlName="isRecruiter">
                        Recruiter
                    </mat-checkbox>

                    <mat-checkbox formControlName="isSalesRep">
                        Sales Rep
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<app-dialog-footer
    (buttonClick)="onConfirmClick()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
