<div
    class="tw-w-full tw-h-full tw-flex tw-justify-center tw-align-middle"
    [ngClass]="{ 'background-white': theme === 'light-theme' }"
>
    <div class="tw-flex tw-flex-col tw-justify-center">
        <p class="tw-text-lg" *ngIf="passwordSuccess === false">
            The passwords must match
        </p>

        <p class="tw-text-lg" *ngIf="apiSuccess === false">
            There was an error processing the request.
        </p>
    </div>

    <form
        [formGroup]="passwordResetForm"
        class="tw-flex tw-flex-col tw-justify-center tw-w-1/2"
        *ngIf="passwordSuccess !== true"
        (ngSubmit)="onResetPasswordClick()"
    >
        <!-- TODO add Regex -->
        <mat-progress-bar
            [appVisible]="loading"
            class="m3-tertiary tw-w-1/2 tw-self-center"
            mode="indeterminate"
        ></mat-progress-bar>
        <mat-form-field
            class="tw-w-1/2 tw-self-center tw-mb-8"
            appearance="fill"
        >
            <mat-label>Password</mat-label>
            <mat-hint>
                Password must be eight characters, contain one capital, one
                lowercase, one number, and one symbol (!&#64;#$%^&*-?)
            </mat-hint>
            <input
                type="password"
                name="password"
                matInput
                formControlName="password"
            />
            <mat-error
                *ngIf="passwordResetForm.controls.password.hasError('required')"
            >
                Password is required field
            </mat-error>
            <mat-error
                *ngIf="passwordResetForm.controls.password.hasError('pattern')"
            >
                Password must be eight characters, contain one capital, one
                lowercase, one number, and one symbol (!&#64;#$%^&*-?)
            </mat-error>
            <mat-error
                *ngIf="
                    passwordResetForm.controls.password.hasError(
                        'passwordMatch'
                    )
                "
            >
                Password and Confirm Password fields must match
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="tw-w-1/2 tw-self-center tw-mb-8"
            appearance="fill"
        >
            <mat-label>Confirm Password</mat-label>
            <input
                type="password"
                name="confirmPassword"
                matInput
                formControlName="confirmPassword"
            />
            <mat-hint>Passwords must match.</mat-hint>
            <mat-error
                *ngIf="
                    passwordResetForm.controls.confirmPassword.hasError(
                        'required'
                    )
                "
            >
                Confirm Password is required field
            </mat-error>
            <mat-error
                *ngIf="
                    passwordResetForm.controls.confirmPassword.hasError(
                        'pattern'
                    )
                "
            >
                Password must be eight characters, contain one capital, one
                lowercase, one number, and one symbol (!&#64;#$%^&*-?)
            </mat-error>
            <mat-error
                *ngIf="
                    passwordResetForm.controls.confirmPassword.hasError(
                        'passwordMatch'
                    )
                "
            >
                Password and Confirm Password fields must match
            </mat-error>
        </mat-form-field>

        <br />

        <div class="tw-w-full tw-flex tw-justify-center">
            <button mat-raised-button type="submit">Submit</button>
        </div>
    </form>

    <div *ngIf="passwordSuccess">
        <p>Password Successfully Changed</p>

        <div class="tw-w-full tw-flex tw-justify-center">
            <button
                class="tw-w-full tw-flex tw-justify-center"
                mat-raised-button
                routerLink="/login"
                routerLinkActive="active"
            >
                Login
            </button>
        </div>
    </div>
</div>
