<div class="tw-flex tw-flex-col tw-gap-2">
    <mat-progress-bar
        mode="indeterminate"
        [appVisible]="loadingReport"
    ></mat-progress-bar>

    <div
        *ngIf="requestFailed"
        class="tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer tw-p-4 tw-box-border tw-rounded-m3sm tw-text-center"
    >
        Request Failed
    </div>

    <mat-radio-group [(ngModel)]="rangeDates" (change)="dateTypeChange()">
        <mat-radio-button class="tw-mr-1" [value]="false">
            Week of
        </mat-radio-button>
        <mat-radio-button [value]="true">Date Range</mat-radio-button>
    </mat-radio-group>

    <mat-card>
        <mat-card-content class="tw-flex tw-flex-col">
            <div class="tw-flex tw-justify-between">
                <form [formGroup]="dateRangeForm">
                    <mat-form-field *ngIf="rangeDates">
                        <mat-label>Enter Session Period</mat-label>

                        <mat-date-range-input [rangePicker]="picker">
                            <input
                                matStartDate
                                placeholder="Start Date"
                                required
                                formControlName="startDate"
                                (dateChange)="dateChange($event)"
                            />
                            <input
                                matEndDate
                                placeholder="End Date"
                                required
                                formControlName="endDate"
                                (dateChange)="dateChange($event)"
                            />
                        </mat-date-range-input>
                        <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>

                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>

                        <mat-error
                            *ngIf="
                                dateRangeForm.controls.startDate.hasError(
                                    'matStartDateInvalid'
                                )
                            "
                        >
                            Invalid start date
                        </mat-error>
                        <mat-error
                            *ngIf="
                                dateRangeForm.controls.endDate.hasError(
                                    'matEndDateInvalid'
                                )
                            "
                        >
                            Invalid end date
                        </mat-error>

                        <mat-error
                            *ngIf="
                                dateRangeForm.controls.startDate.errors
                                    ?.required
                            "
                        >
                            Start Date Required
                        </mat-error>
                        <mat-error
                            *ngIf="
                                dateRangeForm.controls.endDate.errors?.required
                            "
                        >
                            End Date Required
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" *ngIf="!rangeDates">
                        <mat-label>Select Week</mat-label>

                        <input
                            matInput
                            formControlName="date"
                            [matDatepicker]="picker"
                            (dateChange)="dateChange($event)"
                        />
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </form>

                <div
                    class="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-ml-auto"
                >
                    <mat-slide-toggle
                        [checked]="enableToggle"
                        (change)="showFilters()"
                        class="tw-mt-1"
                    >
                        Filter
                    </mat-slide-toggle>
                    <button
                        mat-raised-button
                        matTooltip="Clear Filters"
                        *ngIf="showFilterMenu === true"
                        (click)="clearFilters()"
                    >
                        Clear Filters
                    </button>
                    <button
                        mat-raised-button
                        matTooltip="Export Report"
                        (click)="exportReport()"
                    >
                        Export
                    </button>
                </div>
            </div>

            <form [formGroup]="filterForm" class="tw-flex tw-gap-2 tw-mt-2">
                <mat-form-field
                    class="tw-w-fit"
                    *ngIf="showFilterMenu === true"
                    appearance="fill"
                >
                    <mat-label>Client</mat-label>

                    <mat-select
                        formControlName="clientId"
                        (selectionChange)="filterChange()"
                    >
                        <mat-option value="">View All</mat-option>
                        <mat-option
                            *ngFor="let client of clients"
                            [value]="client.id"
                        >
                            {{ client.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field
                    *ngIf="showFilterMenu === true"
                    appearance="fill"
                >
                    <mat-label>Project</mat-label>

                    <mat-select
                        formControlName="projectName"
                        (selectionChange)="filterChange()"
                    >
                        <mat-option value="">View All</mat-option>
                        <mat-option
                            *ngFor="let projectName of projectNames"
                            [value]="projectName"
                        >
                            {{ projectName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field
                    *ngIf="showFilterMenu === true"
                    appearance="fill"
                >
                    <mat-label>Task</mat-label>

                    <mat-select
                        formControlName="taskName"
                        (selectionChange)="filterChange()"
                    >
                        <mat-option value="">View All</mat-option>
                        <mat-option
                            *ngFor="let taskName of taskNames"
                            [value]="taskName"
                        >
                            {{ taskName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field
                    class="tw-w-fit"
                    *ngIf="showFilterMenu === true"
                    appearance="fill"
                >
                    <mat-label>Recruiter</mat-label>

                    <mat-select
                        formControlName="recruiterId"
                        (selectionChange)="filterChange()"
                    >
                        <mat-option value="">View All</mat-option>
                        <mat-option value="house">House</mat-option>
                        <mat-option
                            *ngFor="let recruiter of recruiters"
                            [value]="recruiter.id"
                        >
                            {{ recruiter.firstName }} {{ recruiter.lastName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field
                    class="tw-w-fit"
                    *ngIf="showFilterMenu === true"
                    appearance="fill"
                >
                    <mat-label>Sales Rep</mat-label>

                    <mat-select
                        formControlName="salesRepId"
                        (selectionChange)="filterChange()"
                    >
                        <mat-option value="">View All</mat-option>
                        <mat-option value="house">House</mat-option>
                        <mat-option
                            *ngFor="let salesRep of salesReps"
                            [value]="salesRep.id"
                        >
                            {{ salesRep.firstName }} {{ salesRep.lastName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field
                    *ngIf="showFilterMenu === true"
                    appearance="fill"
                >
                    <mat-label>Name</mat-label>

                    <input matInput formControlName="fullName" />
                </mat-form-field>

                <mat-form-field
                    *ngIf="showFilterMenu === true"
                    appearance="fill"
                >
                    <mat-label>Hours</mat-label>

                    <input
                        type="number"
                        matInput
                        formControlName="hours"
                        onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
                    />
                </mat-form-field>

                <mat-checkbox
                    *ngIf="showFilterMenu === true"
                    (change)="filterChange()"
                    formControlName="hideBillable"
                >
                    Hide Billable
                </mat-checkbox>
            </form>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-content class="tw-overflow-hidden">
            <table
                mat-table
                [dataSource]="dataSource"
                class="tw-w-full tw-bg-transparent"
                matSort
            >
                <ng-container matColumnDef="clientName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Client Name
                    </th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.clientName }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="projectName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Project
                    </th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.projectName }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="taskName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Task Name
                    </th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.taskName }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="recruiterName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Recruiter Name
                    </th>
                    <td mat-cell *matCellDef="let report">
                        {{
                            report.recruiterName
                                ? report.recruiterName
                                : "House"
                        }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="salesRepName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Sales Rep Name
                    </th>
                    <td mat-cell *matCellDef="let report">
                        {{
                            report.salesRepName ? report.salesRepName : "House"
                        }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        First Name
                    </th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.firstName }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Last Name
                    </th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.lastName }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="hours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Hours
                    </th>
                    <td mat-cell *matCellDef="let report">
                        {{ report.hours }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        </mat-card-content>
        <mat-card-actions class="tw-flex tw-justify-end">
            <mat-paginator
                class="tw-bg-transparent"
                [pageIndex]="pageIndex"
                (page)="changePage($event)"
                [pageSize]="page.countRequested"
                [length]="page.totalCount"
                [hidePageSize]="true"
            ></mat-paginator>
        </mat-card-actions>
    </mat-card>
</div>
