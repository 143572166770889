import { Component, Inject, OnInit } from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
    Form,
} from '@angular/forms';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogContent,
} from '@angular/material/dialog';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { Client } from 'src/app/models/client.model';
import { Project } from 'src/app/models/project-model';
import { ProjectsService } from 'src/app/services/projects.service';
import { PastDateValidator } from 'src/app/validators/past-date.validator';
import { DialogFooterComponent } from '../../dialog-footer/dialog-footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { VisibleDirective } from '../../../directives/visible.directive';

interface IProjectFormControls {
    name: FormControl<string>;
    primaryContact: FormControl<string>;
    startDate: FormControl<string>;
    endDate: FormControl<string>;
    notes: FormControl<string>;
    clientId: FormControl<string>;
    nonBillable: FormControl<boolean>;
}

@Component({
    templateUrl: './add-project.component.html',
    styleUrls: ['./add-project.component.scss'],
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatButtonModule,
        DialogFooterComponent,
        VisibleDirective,
    ]
})
export class AddProjectComponent implements OnInit {
    projectForm: FormGroup<IProjectFormControls>;
    loading = false;
    failed = false;
    activeClients!: Client[];

    constructor(
        @Inject(MAT_DIALOG_DATA) private clientId: string,
        private dialogRef: MatDialogRef<AddProjectComponent>,
        private projectService: ProjectsService,
    ) {}

    ngOnInit(): void {
        this.initializeForm();
    }

    initializeForm(): void {
        this.projectForm = new FormGroup(
            {
                name: new FormControl(null, [
                    Validators.required.bind(this),
                    Validators.maxLength(100),
                ]),
                primaryContact: new FormControl(null, [
                    Validators.required.bind(this),
                    Validators.maxLength(100),
                ]),
                startDate: new FormControl(null, [
                    Validators.required.bind(this),
                ]),
                endDate: new FormControl(null),
                notes: new FormControl(null),
                clientId: new FormControl(this.clientId, [
                    Validators.required.bind(this),
                ]),
                nonBillable: new FormControl(false),
            },
            [PastDateValidator],
        );
    }

    onFormSave(): void {
        this.projectForm.updateValueAndValidity();
        this.projectForm.markAllAsTouched();

        if (!this.projectForm.valid) {
            return;
        }

        this.loading = true;
        const project = new Project({
            name: this.projectForm.controls.name.value,
            primaryContact: this.projectForm.controls.primaryContact.value,
            startDate: this.projectForm.controls.startDate.value,
            endDate: this.projectForm.controls.endDate.value,
            notes: this.projectForm.controls.notes.value,
            clientId: this.projectForm.controls.clientId.value,
            nonBillable: this.projectForm.controls.nonBillable.value,
            status: ACTIVE,
        });

        this.projectService.post([], project).subscribe((response) => {
            this.loading = false;
            this.dialogRef.close({ success: true, projectId: response });
        });
    }

    onCloseClick(): void {
        this.dialogRef.close(false);
    }
}
