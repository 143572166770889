<app-dialog-header [title]="'Submit User Feedback'"></app-dialog-header>

<div mat-dialog-content>
    <p *ngIf="failed" class="warning">Save Failed</p>

    <form
        [formGroup]="userFeedbackForm"
        class="tw-flex tw-flex-col"
        (keydown.enter)="$event.preventDefault()"
    >
        <mat-form-field appearance="fill">
            <mat-label>Select Type</mat-label>

            <mat-select formControlName="userFeedbackType">
                <mat-option value="User">User</mat-option>
                <mat-option value="BugReport">Bug Report</mat-option>
                <mat-option value="FeatureRequest">Feature Request</mat-option>
            </mat-select>

            <mat-error
                *ngIf="
                    userFeedbackForm.controls.userFeedbackType.errors?.required
                "
            >
                Feedback Type is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Brief Description</mat-label>
            <input matInput formControlName="description" maxlength="250" />
            <mat-error
                *ngIf="userFeedbackForm.controls.description.errors?.required"
            >
                Description field is required
            </mat-error>
        </mat-form-field>

        <editor
            formControlName="details"
            rows="5"
            matInput
            maxlength="2000"
            (ngModelChange)="updateCharCount($event)"
            apiKey="zhj32u91p8yvcm6iomd6afp1y7pu8hw70umnhd6e8q4xzc0m"
            [init]="editorInit"
        ></editor>
    </form>
    <button mat-raised-button class="m3-error" (click)="initializeForm()">
        Reset
    </button>
</div>

<app-dialog-footer
    (buttonClick)="onFormSave()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
