export interface ITimesheetDateJson {
    beginningOfWeek: Date;
    endOfWeek: Date;
    selectedWeek: number;
    selectedYear: number;
}

interface ITimesheetDateConstructor {
    beginningOfWeek: Date;
    endOfWeek: Date;
    selectedWeek: number;
    selectedYear: number;
}

export interface TimesheetDate extends ITimesheetDateConstructor {
    beginningOfWeek: Date;
    endOfWeek: Date;
    selectedWeek: number;
    selectedYear: number;
}

export class TimesheetDate {
    constructor(input: ITimesheetDateConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: ITimesheetDateJson): TimesheetDate | null {
        if (json == null) {
            return null;
        }

        return new TimesheetDate({
            ...json,
        });
    }
}
