import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TimeSheet } from 'src/app/models/timesheet.model';
import { TimesheetService } from 'src/app/services/timesheet-service.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { DenialDialogComponent } from '../../denial-dialog/denial-dialog.component';
import { ApproversService } from 'src/app/services/approvers.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AcknowledgeDialogComponent } from '../../acknowledge-component/acknowledge-component.component';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { BODY, USERIDS } from 'src/app/constants/queryParamConstants';

@Component({
    selector: 'app-edit-submissions',
    imports: [
        NgIf,
        DatePipe,
        MatButtonModule,
        NgClass,
        MatChipsModule,
        MatToolbarModule,
        MatIconModule,
        MatTooltipModule,
        MatCardModule,
        MatListModule,
    ],
    templateUrl: './edit-submissions.component.html',
    styleUrl: './edit-submissions.component.scss',
})
export class EditSubmissionComponent {
    constructor(
        private timesheetService: TimesheetService,
        public dialog: MatDialog,
        private approversService: ApproversService,
    ) {}

    @Input({ required: true })
    get timesheet(): TimeSheet {
        return this._timesheet;
    }
    set timesheet(timesheet: TimeSheet) {
        this._timesheet = timesheet;
        const januaryFirst = new Date(timesheet.year, 0, 1);
        const daysToAdd = (timesheet.week - 1) * 7;
        this.startDate = new Date(
            januaryFirst.setDate(januaryFirst.getDate() + daysToAdd),
        );
        this.endDate = new Date(this.startDate);
        this.endDate.setDate(this.endDate.getDate() + 6);
    }
    _timesheet: TimeSheet;
    startDate: Date;
    endDate: Date = new Date();

    editingSubmission: boolean = false;

    approveClick() {
        const dialog = this.dialog.open(ConfirmationDialogComponent, {
            data: 'Are you sure you want to approve this timesheet?',
        });

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.timesheet.approvalState = 'Approved';
                this.timesheetService
                    .put([this.timesheet.id], this.timesheet)
                    .subscribe();
            }
        });
    }

    denyClick() {
        const emailDialog = this.dialog.open(DenialDialogComponent);

        emailDialog
            .afterClosed()
            .subscribe((result: { success: boolean; body?: string }) => {
                if (result) {
                    if (result.success) {
                        this.timesheet.approvalState = 'Denied';
                        this.timesheetService
                            .put([this.timesheet.id], this.timesheet)
                            .subscribe();

                        const queryParams: Map<string, unknown> = new Map<
                            string,
                            unknown
                        >([
                            [USERIDS, this.timesheet.userProfileId],
                            [BODY, result.body.replace(/\n/g, '\\n')],
                        ]);

                        this.approversService
                            .post(['email'], null, queryParams)
                            .subscribe();
                    }
                }
            });
    }

    viewNotes(notes: string): void {
        this.dialog.open(AcknowledgeDialogComponent, {
            data: { title: 'Notes', body: notes },
            maxWidth: '40vw',
            minWidth: '20vw',
        });
    }
}
