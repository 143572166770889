<app-dialog-header [title]="'New Project'"></app-dialog-header>

<div mat-dialog-content>
    <mat-progress-bar
        [appVisible]="loading"
        mode="indeterminate"
    ></mat-progress-bar>
    <div
        class="tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer"
        *ngIf="failed"
    >
        Save Failed
    </div>

    <form [formGroup]="projectForm" class="tw-flex tw-flex-col tw-gap-2">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="projectForm.controls.name.errors?.required">
                Name field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Primary Contact</mat-label>
            <input matInput formControlName="primaryContact" />
            <mat-error
                *ngIf="projectForm.controls.primaryContact.errors?.required"
            >
                Primary Contact field is required
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Start Date</mat-label>
            <input
                matInput
                [matDatepicker]="startDatePicker"
                formControlName="startDate"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="startDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error
                *ngIf="this.projectForm.controls.startDate.errors?.required"
            >
                Start date is required
            </mat-error>
            <mat-error *ngIf="this.projectForm.errors?.pastDate">
                Start Date must be before End date
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>End Date</mat-label>
            <input
                matInput
                [matDatepicker]="endDatePicker"
                formControlName="endDate"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="endDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="this.projectForm.errors?.pastDate">
                Start Date must be before End date
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Notes</mat-label>
            <textarea matInput formControlName="notes"></textarea>
        </mat-form-field>

        <mat-checkbox formControlName="nonBillable">Non-Billable</mat-checkbox>
    </form>
    <button
        [disabled]="loading"
        mat-raised-button
        class="m3-error"
        (click)="initializeForm()"
    >
        Reset
    </button>
</div>

<app-dialog-footer
    [disabled]="loading"
    (buttonClick)="onFormSave()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
