<app-dialog-header
    [title]="this.data.readOnly ? 'Viewing Notes' : 'Edit Notes'"
></app-dialog-header>

<div mat-dialog-content>
    <form [formGroup]="this.data.formGroup">
        <mat-form-field style="width: 100%" appearance="fill">
            <textarea
                [formControlName]="this.data.day"
                rows="5"
                matInput
                [maxlength]="maxLength"
                (ngModelChange)="updateCharCount($event)"
                [readonly]="this.data.readOnly"
            ></textarea>
        </mat-form-field>
        <div class="tw-flex tw-justify-end">
            {{ noteCounter }} / {{ maxLength }}
        </div>
    </form>
</div>

@if (this.data.readOnly) {
    <app-dialog-exit (closeClick)="closeDialog()"></app-dialog-exit>
} @else {
    <app-dialog-footer
        (buttonClick)="save()"
        (closeClick)="closeDialog()"
    ></app-dialog-footer>
}
