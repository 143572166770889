import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { MatSliderModule } from '@angular/material/slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatTreeModule } from '@angular/material/tree';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpInterceptProviders } from './app/HTTPInterceptorProviders';
import { AuthGuard } from './app/auth.guard';
import { provideRouter } from '@angular/router';
import { routes } from './app/app-routes';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            MatFormFieldModule,
            MatGridListModule,
            ImageCropperModule,
            MatTreeModule,
            PdfViewerModule,
            MatAutocompleteModule,
            MatCardModule,
            BrowserModule,
            FormsModule,
            ReactiveFormsModule,
            FormsModule,
            MatInputModule,
            MatButtonModule,
            MatToolbarModule,
            MatIconModule,
            MatSidenavModule,
            MatMenuModule,
            MatProgressBarModule,
            MatListModule,
            MatCheckboxModule,
            MatDialogModule,
            MatSlideToggleModule,
            MatPaginatorModule,
            MatSortModule,
            MatTableModule,
            MatSelectModule,
            MatProgressBarModule,
            MatProgressSpinnerModule,
            OverlayModule,
            MatTooltipModule,
            MatDatepickerModule,
            MatNativeDateModule,
            MatSnackBarModule,
            MatButtonToggleModule,
            MatExpansionModule,
            MatSliderModule,
            CalendarModule.forRoot({
                provide: DateAdapter,
                useFactory: adapterFactory,
            }),
            MatTabsModule,
            MatRadioModule,
            MatChipsModule,
            MatAutocompleteModule,
        ),
        AuthGuard,
        HttpInterceptProviders,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideRouter(routes),
    ],
}).catch((err) => console.error(err));
