export interface IPageJson<T> {
    countRequested: number;
    offsetRequested: number;
    totalCount: number;
    items: T[];
}

interface IPageConstructor<T> {
    countRequested: number;
    offsetRequested: number;
    totalCount: number;
    items: T[];
}

export interface Page<T> extends IPageConstructor<T> {
    countRequested: number;
    offsetRequested: number;
    totalCount: number;
    items: T[];
}

export class Page<T> {
    constructor(input?: IPageConstructor<T>) {
        Object.assign(this, input);
    }
}

export function fromJson<T>(json: IPageJson<T>): Page<T> | null {
    if (json == null) {
        return null;
    }

    return new Page({
        ...json,
    });
}
