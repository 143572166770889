<app-dialog-header [title]="'Add Client'"></app-dialog-header>

<div mat-dialog-content>
    <p *ngIf="failed" class="warning">Save Failed</p>

    <mat-progress-bar
        [appVisible]="loading"
        mode="indeterminate"
    ></mat-progress-bar>

    <form
        [formGroup]="clientForm"
        class="tw-flex tw-flex-col"
        (keydown.enter)="$event.preventDefault()"
    >
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" maxlength="100" />
            <mat-error *ngIf="clientForm.controls.name.errors?.required">
                Name field is required
            </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="signOffFlag">
            Client Sign Off
        </mat-checkbox>
        <mat-checkbox formControlName="nonBillable">Non-Billable</mat-checkbox>
    </form>
    <button
        [disabled]="loading"
        mat-raised-button
        class="m3-error"
        (click)="initializeForm()"
    >
        Reset
    </button>
</div>

<app-dialog-footer
    [disabled]="loading"
    (buttonClick)="onFormSave()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
