import { UserFeedbackTypes } from './../../interfaces/userFeedback-Form';
import { Component, OnInit } from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { UserFeedback } from 'src/app/models/UserFeedback.model';
import { UserFeedbackService } from 'src/app/services/userFeedback.service';
import { DialogFooterComponent } from '../dialog-footer/dialog-footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatOptionModule } from '@angular/material/core';
import { NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { IUserFeedbackFormControls } from 'src/app/interfaces/userFeedback-Form';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'app-submit-feedback-dialog',
    templateUrl: './submit-userFeedback-dialog.component.html',
    imports: [
        MatIconModule,
        MatDividerModule,
        ReactiveFormsModule,
        MatDialogContent,
        MatFormFieldModule,
        MatSelectModule,
        NgIf,
        MatOptionModule,
        MatInputModule,
        TextFieldModule,
        MatButtonModule,
        DialogHeaderComponent,
        DialogFooterComponent,
        EditorComponent,
    ],
})
export class SubmitUserFeedbackDialogComponent implements OnInit {
    userFeedbackForm!: FormGroup<IUserFeedbackFormControls>;
    noteCounter: number;
    failed = false;
    mode: string;

    constructor(
        private dialogRef: MatDialogRef<SubmitUserFeedbackDialogComponent>,
        private userFeedbackService: UserFeedbackService,
        private themeService: ThemeService,
    ) {}

    init: EditorComponent['init'] = {
        plugins: 'lists link image table code help',
    };
    ngOnInit(): void {
        this.initializeForm();
    }

    initializeForm() {
        this.userFeedbackForm = new FormGroup({
            userFeedbackType: new FormControl<UserFeedbackTypes>('General', [
                Validators.required.bind(this),
                Validators.maxLength(100),
            ]),
            description: new FormControl<string>(''),
            details: new FormControl<string>(''),
        });
    }

    onFormSave(): void {
        this.userFeedbackForm.updateValueAndValidity();
        if (!this.userFeedbackForm.valid) {
            return;
        }
        const userFeedback = new UserFeedback({
            description: this.userFeedbackForm.controls.description.value,
            details: this.userFeedbackForm.controls.details.value,
            userFeedbackType:
                this.userFeedbackForm.controls.userFeedbackType.value,
        });
        this.userFeedbackService.post([], userFeedback).subscribe(
            (response) => {
                this.dialogRef.close({
                    success: true,
                    userFeedbackId: response,
                });
            },
            () => {
                this.failed = true;
            },
        );
    }

    onCloseClick(): void {
        this.dialogRef.close(false);
    }

    updateCharCount(text: string): void {
        this.noteCounter = text.length;
    }

    protected get editorInit() {
        this.mode = this.themeService.getCurrentTheme();
        if (this.mode.toLowerCase() == 'dark-theme') {
            return {
                skin_url: '/assets/WYSIWYG-skin-url/skins/ui/CUSTOM',
                plugins: 'lists link image table code help',
                toolbar:
                    'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                font_family_formats: '',
                color_map: [],
            };
        } else {
            return {
                plugins: 'lists link image table code help',
                toolbar:
                    'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                font_family_formats: '',
                color_map: [],
            };
        }
    }
}
