import { Component, OnInit } from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { LocationService } from 'src/app/services/location.service';
import { Location } from 'src/app/models/location.model';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { DialogFooterComponent } from '../../dialog-footer/dialog-footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor } from '@angular/common';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { VisibleDirective } from '../../../directives/visible.directive';

interface State {
    value: string;
    viewValue: string;
}

interface ILocationFormControls {
    state: FormControl<string>;
    city: FormControl<string>;
    zipCode: FormControl<string>;
    name: FormControl<string>;
}

@Component({
    selector: 'app-add-location',
    templateUrl: './add-location.component.html',
    styleUrls: ['./add-location.component.scss'],
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatButtonModule,
        DialogFooterComponent,
        VisibleDirective,
    ]
})
export class AddLocationComponent implements OnInit {
    locationForm: FormGroup<ILocationFormControls>;
    saveFailed = false;
    saving = false;

    states: State[] = [
        { value: 'Alabama-0', viewValue: 'Alabama' },
        { value: 'Alaska-1', viewValue: 'Alaska' },
        { value: 'Arizona-2', viewValue: 'Arizona' },
        { value: 'Arkansas-3', viewValue: 'Arkansas' },
        { value: 'California-4', viewValue: 'California' },
        { value: 'Colorado-5', viewValue: 'Colorado' },
        { value: 'Connecticut-6', viewValue: 'Connecticut' },
        { value: 'Delaware-7', viewValue: 'Delaware' },
        { value: 'Florida-8', viewValue: 'Florida' },
        { value: 'Georgia-9', viewValue: 'Georgia' },
        { value: 'Hawaii-10', viewValue: 'Hawaii' },
        { value: 'Idaho-11', viewValue: 'Idaho' },
        { value: 'Illinois-12', viewValue: 'Illinois' },
        { value: 'Indiana-13', viewValue: 'Indiana' },
        { value: 'Iowa-14', viewValue: 'Iowa' },
        { value: 'Kansas-15', viewValue: 'Kansas' },
        { value: 'Kentucky-16', viewValue: 'Kentucky' },
        { value: 'Louisiana-17', viewValue: 'Louisiana' },
        { value: 'Maine-18', viewValue: 'Maine' },
        { value: 'Maryland-19', viewValue: 'Maryland' },
        { value: 'Massachusetts-20', viewValue: 'Massachusetts' },
        { value: 'Michigan-21', viewValue: 'Michigan' },
        { value: 'Minnesota-22', viewValue: 'Minnesota' },
        { value: 'Mississippi-23', viewValue: 'Mississippi' },
        { value: 'Missouri-24', viewValue: 'Missouri' },
        { value: 'Montana-25', viewValue: 'Montana' },
        { value: 'Nebraska-26', viewValue: 'Nebraska' },
        { value: 'Nevada-27', viewValue: 'Nevada' },
        { value: 'NewHampshire-28', viewValue: 'New Hampshire' },
        { value: 'NewJersey-29', viewValue: 'New Jersey' },
        { value: 'NewMexico-30', viewValue: 'New Mexico' },
        { value: 'NewYork-31', viewValue: 'New York' },
        { value: 'NorthCarolina-32', viewValue: 'North Carolina' },
        { value: 'NorthDakota-33', viewValue: 'North Dakota' },
        { value: 'Ohio-34', viewValue: 'Ohio' },
        { value: 'Oklahoma-35', viewValue: 'Oklahoma' },
        { value: 'Oregon-36', viewValue: 'Oregon' },
        { value: 'Pennsylvania-37', viewValue: 'Pennsylvania' },
        { value: 'RhodeIsland-38', viewValue: 'Rhode Island' },
        { value: 'SouthCarolina-39', viewValue: 'South Carolina' },
        { value: 'SouthDakota-40', viewValue: 'South Dakota' },
        { value: 'Tennessee-41', viewValue: 'Tennessee' },
        { value: 'Texas-42', viewValue: 'Texas' },
        { value: 'Utah-43', viewValue: 'Utah' },
        { value: 'Vermont-44', viewValue: 'Vermont' },
        { value: 'Virginia-45', viewValue: 'Virginia' },
        { value: 'Washington-46', viewValue: 'Washington' },
        { value: 'WestVirginia-47', viewValue: 'West Virginia' },
        { value: 'Wisconsin-48', viewValue: 'Wisconsin' },
        { value: 'Wyoming-49', viewValue: 'Wyoming' },
    ];

    constructor(
        private dialogRef: MatDialogRef<boolean>,
        private locationService: LocationService,
    ) {}

    ngOnInit(): void {
        this.initializeForm();
    }

    onResetClick(): void {
        this.locationForm.reset();
    }

    onCloseClick(): void {
        this.dialogRef.close(false);
    }

    onSaveClick(): void {
        this.locationForm.markAllAsTouched();
        if (!this.locationForm.valid) {
            return;
        }
        this.saving = true;
        const location = new Location({
            name: this.locationForm.controls.name.value,
            city: this.locationForm.controls.city.value,
            state: this.locationForm.controls.state.value,
            zipCode: this.locationForm.controls.zipCode.value,
            status: ACTIVE,
        });

        this.locationService.post([], location).subscribe(
            () => {
                this.dialogRef.close(true);
            },
            () => {
                this.saveFailed = true;
            },
        );
    }

    initializeForm(): void {
        this.locationForm = new FormGroup({
            city: new FormControl('', {
                validators: [
                    Validators.required.bind(this),
                    Validators.maxLength(50),
                ],
            }),
            state: new FormControl('', {
                validators: [Validators.required.bind(this)],
            }),
            zipCode: new FormControl('', {
                validators: [
                    Validators.required.bind(this),
                    Validators.pattern('[0-9]{5}'),
                ],
            }),
            name: new FormControl('', {
                validators: [
                    Validators.required.bind(this),
                    Validators.maxLength(75),
                ],
            }),
        });
    }
}
