<div class="report-container flex-column">
    <mat-progress-bar
        mode="indeterminate"
        [appVisible]="loadingReport"
    ></mat-progress-bar>

    <div
        *ngIf="requestFailed"
        class="tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer tw-p-4 tw-box-border tw-rounded-m3sm tw-text-center"
    >
        Request Failed
    </div>

    <form class="space-between" [formGroup]="filterForm">
        <mat-form-field *ngIf="showFilterMenu == true" appearance="fill">
            <mat-label>Name</mat-label>

            <mat-select
                formControlName="name"
                (selectionChange)="filterChange()"
            >
                <mat-option value="">View All</mat-option>
                <mat-option *ngFor="let name of names" [value]="name">
                    {{ name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="showFilterMenu == true" appearance="fill">
            <mat-label>Start Date</mat-label>

            <mat-select
                formControlName="startDate"
                (selectionChange)="filterChange()"
            >
                <mat-option value="">View All</mat-option>
                <mat-option
                    *ngFor="let startDate of startDates"
                    [value]="startDate"
                >
                    {{ startDate | date }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="showFilterMenu == true" appearance="fill">
            <mat-label>Hours Accrued</mat-label>

            <input
                type="number"
                matInput
                formControlName="hoursAccrued"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />
        </mat-form-field>

        <mat-form-field *ngIf="showFilterMenu == true" appearance="fill">
            <mat-label>Hours Deducted</mat-label>

            <input
                type="number"
                matInput
                formControlName="hoursDeducted"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />
        </mat-form-field>

        <button
            mat-raised-button
            matToolTip="Export Report"
            (click)="exportReport()"
            class="mb-1 ml-auto"
        >
            Export
        </button>

        <button
            mat-raised-button
            matToolTip="Clear All Filters"
            *ngIf="showFilterMenu == true"
            (click)="clearFilters()"
            class="clear-filters"
        >
            Clear Filters
        </button>

        <mat-slide-toggle [checked]="enableToggle" (change)="showFilters()">
            Filter
        </mat-slide-toggle>
    </form>

    <div class="mat-elevation-z8">
        <table
            mat-table
            [dataSource]="page.items"
            class="mat-elevation-z8 width-100"
            matSort
            #ptoSort="matSort"
            (matSortChange)="sortChange($event)"
        >
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let report">{{ report.name }}</td>
            </ng-container>

            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Start Date
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ report.startDate | date }}
                </td>
            </ng-container>

            <ng-container matColumnDef="hoursAccrued">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Hours Accrued
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ report.hoursAccrued }}
                </td>
            </ng-container>

            <ng-container matColumnDef="hoursDeducted">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Hours Deducted
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ report.hoursDeducted }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
            (page)="changePage($event)"
            [pageIndex]="pageIndex"
            [pageSize]="page.countRequested"
            [length]="page.totalCount"
            [hidePageSize]="true"
        ></mat-paginator>
    </div>
</div>
