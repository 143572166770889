<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingSettings"
></mat-progress-bar>

<div class="tw-flex tw-justify-center tw-mt-6">
    <div class="tw-text-2xl tw-w-3/4">Add/Edit Settings</div>
</div>

<div
    class="tw-flex tw-flex-row tw-justify-center tw-mt-6 tw-gap-4 tw-items-start"
>
    <div
        class="tw-flex tw-flex-col tw-gap-2 tw-p-6 tw-box-border tw-border-solid tw-rounded-m3lg tw-border-customborder tw-border-2 tw-w-1/4"
    >
        <div class="tw-text-2xl">Settings</div>

        <div [formGroup]="searchForm">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label>Search Settings...</mat-label>
                <input matInput type="text" formControlName="search" />
            </mat-form-field>
        </div>

        <mat-selection-list
            #settingSelectionList
            hideSingleSelectionIndicator
            class="scrollable"
            [multiple]="false"
            (selectionChange)="settingSelectionChange($event)"
        >
            @for (setting of page.items; track setting) {
                <mat-list-option [value]="setting">
                    {{ setting.name }}
                </mat-list-option>
                @if (!$last) {
                    <mat-divider></mat-divider>
                }
            }
        </mat-selection-list>

        <mat-paginator
            class="tw-rounded-b-lg"
            [pageIndex]="pageIndex"
            [pageSize]="page.countRequested"
            [length]="page.totalCount"
            [hidePageSize]="true"
            (page)="changePage($event)"
        ></mat-paginator>
    </div>

    <div class="tw-w-1/2">
        <app-edit-setting
            [hidden]="!settingSelected"
            (settingChangedEvent)="settingChanged(true)"
        ></app-edit-setting>
    </div>
</div>
