import { Component, Inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogContent,
} from '@angular/material/dialog';
import { ITimesheetFormControls } from 'src/app/interfaces/timesheets';
import { DialogFooterComponent } from '../dialog-footer/dialog-footer.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { DialogExitComponent } from '../dialog-exit/dialog-exit.component';

interface IData {
    day:
        | 'mondayNotes'
        | 'tuesdayNotes'
        | 'wednesdayNotes'
        | 'thursdayNotes'
        | 'fridayNotes'
        | 'saturdayNotes'
        | 'sundayNotes';
    formGroup: FormGroup<ITimesheetFormControls>;
    readOnly: boolean;
}

@Component({
    selector: 'app-edit-note-dialog',
    templateUrl: './edit-note-dialog.component.html',
    styleUrls: ['./edit-note-dialog.component.scss'],
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DialogFooterComponent,
        DialogExitComponent,
    ]
})
export class EditNoteDialogComponent {
    noteCounter: number;
    maxLength = 300;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IData,
        private dialog: MatDialogRef<EditNoteDialogComponent>,
    ) {
        const noteMsg = data.formGroup.controls[data.day].value;
        this.noteCounter = noteMsg ? noteMsg.length : 0;
    }

    save(): void {
        this.dialog.close();
    }

    closeDialog(): void {
        this.dialog.close();
    }

    updateCharCount(text: string): void {
        this.noteCounter = text.length;
    }
}
