import { ComponentType } from '@angular/cdk/portal';
import { Directive, inject, Injectable } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
    MatDialogConfig,
} from '@angular/material/dialog';

@Directive()
export abstract class StronglyTypedDialog<DialogData, DialogResult> {
    protected data: DialogData = inject(MAT_DIALOG_DATA);
    protected dialogRef: MatDialogRef<
        StronglyTypedDialog<DialogData, DialogResult>,
        DialogResult
    > = inject(MatDialogRef);
}

@Injectable({ providedIn: 'root' })
export class DialogService {
    protected dialog = inject(MatDialog);

    open = <DialogData, DialogResult>(
        component: ComponentType<StronglyTypedDialog<DialogData, DialogResult>>,
        config?: MatDialogConfig<DialogData>,
    ): MatDialogRef<
        StronglyTypedDialog<DialogData, DialogResult>,
        DialogResult
    > => this.dialog.open(component, config);
}
