export const APPROVALSTATE = 'approvalState';
export const APPROVERIDS = 'approverIds';
export const ASC = 'asc';
export const BILLINGHOURS = 'billingHours';
export const BODY = 'body';
export const CLIENTID = 'clientId';
export const COUNT = 'count';
export const CURRENTDATE = 'currentDate';
export const DAY = 'day';
export const DESC = 'desc';
export const EMPLOYEE = 'employee';
export const ENDDATE = 'endDate';
export const FEEDBACKID = 'feedbackId';
export const FULLNAME = 'fullName';
export const HASWORKORDER = 'hasWorkOrder';
export const HOURS = 'hours';
export const HOUSERECRUITERS = 'houseRecruiters';
export const HOUSEREPS = 'houseReps';
export const ID = 'id';
export const IS1099 = 'is1099';
export const ISAPPROVER = 'isApprover';
export const ISLOCKED = 'isLocked';
export const ISSALESREP = 'isSalesRep';
export const ISSORTASCENDING = 'isSortAscending';
export const LOCATIONID = 'locationId';
export const MONTH = 'month';
export const NAME = 'name';
export const OFFSET = 'offset';
export const PROJECTID = 'projectId';
export const PTOHOURS = 'ptoHours';
export const RANGEDATES = 'rangeDates';
export const RECURUITERID = 'recruiterId';
export const ROLEID = 'roleId';
export const SALESREPID = 'salesRepId';
export const SORTPROPERTY = 'sortProperty';
export const STARTDATE = 'startDate';
export const STATUS = 'status';
export const SUBJECT = 'subject';
export const SUBMISSIONURL = 'submissionUrl';
export const SUBMITTEDBYID = 'submittedById';
export const TASKID = 'taskId';
export const USERID = 'userId';
export const USERIDS = 'userIds';
export const USERVIEW = 'userView';
export const WEEK = 'week';
export const YEAR = 'year';
