<app-dialog-header [title]="'Create New Role'"></app-dialog-header>

<div mat-dialog-content>
    <p *ngIf="createRoleError" class="tw-text-red-400">
        *Failed to Create Role
    </p>
    <form [formGroup]="roleForm">
        <mat-form-field appearance="fill">
            <mat-label>Role Name</mat-label>
            <input
                matInput
                type="text"
                required
                minlength="1"
                formControlName="name"
            />
            <mat-error *ngIf="roleForm.controls.name.errors.required">
                Role name is required.
            </mat-error>
        </mat-form-field>
    </form>
</div>
<app-dialog-footer
    [disabled]="saving"
    (buttonClick)="submit()"
    (closeClick)="onCloseClick()"
></app-dialog-footer>
