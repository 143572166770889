import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { AppStateService } from './app-state-service';
import { Me } from '../models/me.model';

interface Error {
    status: number;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private appStateService: AppStateService,
    ) {}

    intercept<T>(
        request: HttpRequest<T>,
        next: HttpHandler,
    ): Observable<HttpEvent<T>> {
        return next.handle(request).pipe(
            catchError((err: Error) => {
                if (
                    request.url.includes('/me') &&
                    !request.url.includes('/ptorequest')
                ) {
                    this.handleMeFail();
                    return throwError('Token has expired.');
                } else if (request.url.includes('login')) {
                    return next.handle(request);
                } else {
                    if (err.status === 401) {
                        this.handleMeFail();
                        return throwError('Token has expired.');
                    } else {
                        return throwError('An error has occured');
                    }
                }
            }),
        );
    }

    handleMeFail(): void {
        this.authService.logoutUser();
        this.appStateService.me$.next(new Me());
        void this.router.navigate(['login']);
    }
}
