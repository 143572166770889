<div class="tw-flex tw-flex-col tw-w-3/4 tw-m-auto">
    <div class="tw-gap-2 tw-m-3 tw-flex tw-flex-col">
        <div class="tw-text-3xl">Settings</div>
        <div>Manage Profile preferences here</div>
        <mat-divider></mat-divider>
    </div>

    <div>
        <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Personal Info">
                <app-personal-info
                    [userContactInfoForm]="userContactInfoForm"
                    [userEmergencyContactForm]="userEmergencyContactForm"
                    (saveContactInfoEvent)="saveContactInfo()"
                    (saveEmergencyContactEvent)="saveEmergencyContact()"
                ></app-personal-info>
            </mat-tab>

            <mat-tab label="My Profile">
                <app-my-profile
                    [userProfileForm]="userProfileForm"
                    [currentProfileImage]="currentProfileImage"
                    (updateProfileImageEvent)="getProfileImage()"
                    (saveThemeEvent)="saveProfileSettings()"
                ></app-my-profile>
            </mat-tab>

            <mat-tab label="Change Password">
                <app-change-password
                    [changePasswordForm]="changePasswordForm"
                    (saveNewPasswordEvent)="saveNewPassword()"
                ></app-change-password>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
