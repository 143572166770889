import { Component, Inject, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client.model';
import { User } from 'src/app/models/User';
import { Task } from 'src/app/models/task.model';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogContent,
} from '@angular/material/dialog';
import { ClientService } from 'src/app/services/client.service';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import { Project } from 'src/app/models/project-model';
import { ProjectsService } from 'src/app/services/projects.service';
import { TaskService } from 'src/app/services/task-service.service';
import { Page } from 'src/app/models/page.model';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../../../dialog-header/dialog-header.component';

interface Data {
    user: User;
}

@Component({
    selector: 'app-add-user-task',
    templateUrl: './add-user-task.component.html',
    styleUrls: ['./add-user-task.component.scss'],
    standalone: true,
    imports: [
        DialogHeaderComponent,
        ReactiveFormsModule,
        MatDialogContent,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        NgIf,
        MatButtonModule,
    ],
})
export class AddUserTaskComponent implements OnInit {
    addUserTaskForm!: FormGroup;
    user!: User;
    tasks: Task[];
    clients: Client[];
    projects: Project[];
    queryParams = new Map();
    isClients = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Data,
        private clientService: ClientService,
        private projectService: ProjectsService,
        private taskService: TaskService,
        private dialogRef: MatDialogRef<boolean>,
    ) {}

    ngOnInit(): void {
        this.user = this.data.user;
        this.addUserTaskForm = this.initializeForm();
        this.updateClients();
    }

    public initializeForm(): FormGroup {
        return new FormGroup({
            clientId: new FormControl(null, {
                validators: [Validators.required.bind(this)],
            }),
            projectId: new FormControl(null, {
                validators: [Validators.required.bind(this)],
            }),
            taskId: new FormControl(null, {
                validators: [Validators.required.bind(this)],
            }),
        });
    }

    updateClients(): void {
        this.clientService
            .get(['GetNonMappedClients', this.user.id])
            .subscribe((response: Array<Client>) => {
                if (response === undefined || response.length == 0) {
                    this.isClients = false;
                }

                this.clients = response;
            });
    }

    updateProjects(): void {
        this.queryParams.set(
            'clientId',
            this.addUserTaskForm.get('clientId').value,
        );

        this.projectService
            .get(['GetNonMappedProjects', this.user.id], this.queryParams)
            .subscribe((response: Array<Project>) => {
                this.projects = response;
            });
    }

    updateTasks(): void {
        this.queryParams.set(
            'projectId',
            this.addUserTaskForm.get('projectId').value,
        );

        this.taskService
            .get(['GetNonMappedTasks', this.user.id], this.queryParams)
            .subscribe((response: Array<Task>) => {
                this.tasks = response;
            });
    }

    saveTask(): void {
        const tempTask = this.addUserTaskForm.get('taskId').value as Task;
        tempTask.userTaskStatus = ACTIVE.toUpperCase();
        this.user.tasks.push(tempTask);
        this.dialogRef.close(true);
    }

    onResetClick(): void {
        this.addUserTaskForm.reset();
    }

    onCloseClick(): void {
        this.dialogRef.close(false);
    }
}
